import { useState, FC, useContext, useEffect } from "react";
import PhysiciansToVerify from "./components/PhysiciansToVerify";
import PracticePhysicians from "./components/PracticePhysicians";
import MyPracticeInfo from "./components/MyPracticeInfo";
import PracticeNonPhysicians from "./components/PracticeNonPhysicians";
import PracticeDelegation from "./components/PracticeDelegation";
import IPracticeInfo from "../../../types/IPracticeInfo";
import practiceInfoContext from '../../../context/PracticeInfoContext'
import Spinner from "../../spinner/Spinner";
import PracticeInfoState from "../../../context/PracticeInfoState";
import PracticeService from "../../../services/PracticeService";
import axios from "axios";
import { appInsights } from "../../../appInsights";
import { useOktaAuth } from "@okta/okta-react";
import Roles from "../../../config/Roles";
import useUserRole from "../../../hooks/useUserRole";
import UserService from "../../../services/UserService";
import menuItemsContext from "../../../context/MenuItemsContext";
import Loader from "../../common/Loader";



const MyPractice: FC<{ name?: string }> = ({ name }) => {
  // const { practiceInfo, setPracticeInfo } = useContext(practiceInfoContext);
  const [ practiceInfo, setPracticeInfo ]= useState<IPracticeInfo>();
  const [error, setError] = useState<Error | null>(null);
  const [apiCallCount, setApiCallCount] = useState(0);
  const { setMenuItems } = useContext(menuItemsContext);
  
  const [loading, setLoading] = useState(true);
  const [userRole, setUserRole] = useUserRole();
  const { authState } = useOktaAuth();
  const accessToken = authState?.accessToken?.accessToken;
  const cancelToken = axios.CancelToken.source();

    
  useEffect(() => {
    UserService.getMenuItems(accessToken)
    .then((response) => {
      setMenuItems(response.data);
      setLoading(false);
    })
    .catch((e) => {
      if (!axios.isCancel(e)) {
        console.error(e);
        appInsights.trackException({ error: e });
        setError(e);
        setLoading(false);
      }
    });
    return () => {
      cancelToken.cancel();
    };
},
  [])

  useEffect(() => {
    setError(null)
    PracticeService.getPracticeInfo(accessToken, cancelToken)
      .then((response) => {
        setPracticeInfo(response.data);
        setLoading(false);
      })
      .catch((e) => {
        if (!axios.isCancel(e)) {
          console.error(e);
          appInsights.trackException({ error: e.response.data.message });
          setError(e.response.data.message);
          setLoading(false);
        }

      });

    return () => {
      cancelToken.cancel();
    };
  }, []);


  if (error !== null) {
    return (
      <div className="practice">
        <div className="container">
          <h2 className="heading-main">{name}</h2>
          <h5 style={{ marginLeft: 10 }}>{error + ""}</h5>
        </div>
      </div>
    );
  }
  const checkRole = ():boolean => {

    if (userRole !== undefined){
      return userRole?.practiceRoles.filter(e=>e.roleId == "3" || e.roleId == "9").length > 0 ? true : false
    }

    return false
  }
  return (
    <div className="practice">
      <div className="container">
        <h2 className="heading-main">{name}</h2>
        {loading ? (
              <div style={{marginLeft:'50%'}} ><Loader /> </div>
            ) : (
        <div className="row" id="chartLayoutContainer">

          {practiceInfo !== undefined ? <div className="col-md-4">
            <div className="chart">
              <MyPracticeInfo
                practiceInfo={practiceInfo}
              />
            </div>
            
            {(practiceInfo?.canControlDelegation || checkRole()) && (
              <div className="chart">
                <PracticeDelegation
                  practiceId={practiceInfo?.practiceId}
                  delegationEnabled={practiceInfo?.isDelegatoinEnabled}
                />
              </div>
            )}
          </div>:null}
          <div className="col-md-8">
          {practiceInfo !== undefined ? <>
                <PhysiciansToVerify  setApiCallCount={setApiCallCount} practiceId={practiceInfo.practiceId}  />
                <PracticePhysicians apiCallCount={apiCallCount} practiceId={practiceInfo.practiceId} />
                <PracticeNonPhysicians practiceId={practiceInfo.practiceId}/></> : <Spinner />}
          </div>
        </div>
            )
          }
      </div>
    </div>
  );
};

export default MyPractice;

