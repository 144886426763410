import { CancelTokenSource } from "axios";
import { customAxios as axios } from "./ServiceConfig";
import { API_BASE_URL } from "../config";

let loginas = localStorage.getItem('loginAsFlag')
let loginasEmail = localStorage.getItem('loginAsEmail')
/**
 * @param query receives the body to be sent in the post request
 * @param token receives the access token of type string
 */
const getAlumniSpotlightServiceList = async (

  token?: string,
  cancelToken?: CancelTokenSource
) => {
  const result = await axios.get<any>(
    `${API_BASE_URL}/PortalUpdate/alumnispotlight`,

    loginas !== null ? {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Pragma: "no-cache",
        Authorization: `Bearer ${token}`,
        loginas:loginas !== null ? loginas : JSON.stringify(false),
        email:loginasEmail !== null ? loginasEmail : "",
      },
      cancelToken: cancelToken?.token,
    }:{
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Pragma: "no-cache",
        Authorization: `Bearer ${token}`,
      },
      cancelToken: cancelToken?.token,
    }
  );
  return result;
};

/**
 * @param id receives the patient id
 * @param token receives the access token of type string
 */


/**
 * @param data receives the referral form
 * @param token receives the access token of type string
 */
export async function createAlumniSpotlightServiceRequest(
  data: FormData,
  token?: string,
  cancelToken?: CancelTokenSource
) {
  const result = await axios.post(
    `${API_BASE_URL}/PortalUpdate/addalumnispotlight`,
    data,
    loginas !== null ? {
      headers: {
        Accept: "application/json",
        "Content-Type": "multipart/form-data",
        Pragma: "no-cache",
        Authorization: `Bearer ${token}`,
        loginas:loginas !== null ? loginas : JSON.stringify(false),
        email:loginasEmail !== null ? loginasEmail : "",
      },
      cancelToken: cancelToken?.token,
    }:{
      headers: {
        Accept: "application/json",
        "Content-Type": "multipart/form-data",
        Pragma: "no-cache",
        Authorization: `Bearer ${token}`,
      },
      cancelToken: cancelToken?.token,
    }
  );
  return result;
}

export async function editAlumniSpotlightServiceRequest(
  data: FormData,
  token?: string,
  cancelToken?: CancelTokenSource
) {
  const result = await axios.put(
    `${API_BASE_URL}/portalupdate/editalumnispotlight`,
    data,
    loginas !== null ? {
      headers: {
        Accept: "application/json",
        "Content-Type": "multipart/form-data",
        Pragma: "no-cache",
        Authorization: `Bearer ${token}`,
        loginas:loginas !== null ? loginas : JSON.stringify(false),
        email:loginasEmail !== null ? loginasEmail : "",
      },
      cancelToken: cancelToken?.token,
    }:{
      headers: {
        Accept: "application/json",
        "Content-Type": "multipart/form-data",
        Pragma: "no-cache",
        Authorization: `Bearer ${token}`,
      },
      cancelToken: cancelToken?.token,
    }
  );
  return result;
}

const AlumniSpotlightService = {
  getAlumniSpotlightServiceList,
 // getAmbulatoryRequestById,
  createAlumniSpotlightServiceRequest,
  editAlumniSpotlightServiceRequest,
};

export default AlumniSpotlightService;
