import { FC, useState } from "react";
// file name should be navbar.css instead of Navbar.css
import styles from "./Navbar.module.css";
import { useHistory } from "react-router-dom";
import { useOktaAuth } from "@okta/okta-react";
import useUserInfo from "../../hooks/useUserInfo";
import Tab from "./Tab";
import ReminderPopup from "../common/ReminderPopup";
import TourScreen from "../common/TourScreen";
import { OKTA_AUTH_CONFIG } from "../../config";
import IdleTimeOutHandler from '../router/IdleTimeOutHandler'


const Nav: FC = () => {
  const userInfo = useUserInfo();
  const { authState, oktaAuth } = useOktaAuth();
  const history = useHistory();
  const [isActive, setIsActive] = useState(true)


  const login = async () => history.push("/login");
  const logout = async () => {
    localStorage.clear();
    localStorage.setItem("loggedIn", "false");
    localStorage.removeItem("loginAs")
    oktaAuth.signOut();
  };
  const createAccount = () =>
    history.push("/accountsandaccess/newpracticerequest");

  const onClickLogo = () => {
    window.location.href = "/";
  };

  const redirectOKTA = () => {
    let email:any = userInfo?.email;
    let isInternal;
    if (/^[a-zA-Z0-9._-]+@chla\.usc\.edu$/.test(email)) {
      isInternal = true;
   } 

    if (isInternal) {
      window.open("https://oktalogin.chla.org/enduser/settings");
    } else {
      window.open(
        OKTA_AUTH_CONFIG.issuer
          ? OKTA_AUTH_CONFIG.issuer.split("/oauth2")[0]
          : "https://extoktalogin.chla.org/enduser/settings"
      );
    }
  };

  return (
    <>
      <div className={styles.mainContainer}>
        <div className="container">
          <div className={styles.headerContainer}>
            <a
              className={styles.brandContainer}
              onClick={onClickLogo}
              id="brand"
            >
              <img
                className={styles.brandLogo}
                src={require("../../assets/images/mychla_Logo.png").default}
                alt="Image"
                style={{ cursor: "pointer" }}
              />
            </a>
            <div className={styles.loginContainer}>
              <span className={styles.referDetails}>
                <a
                  className={styles.topLink}
                  target="_blank"
                  href="http://www.chla.org/site/c.ipINKTOAJsG/b.3579533/k.229D/Refer_a_Patient__Physician_Referral_Hotline.htm#.Uw_Ex-NdV8E"
                >
                  Refer, Admit or Transfer <b>888-631-2452</b>
                </a>
                <span>|</span>
              </span>
              {!authState?.isPending && !authState?.isAuthenticated && (
                <>
                  <a className={styles.logout} onClick={createAccount}>
                    Request an Account
                  </a>
                  <span>|</span>
                  <a className={styles.topLink} rel="noopener noreferrer" href={require("../../assets/CHLA_myCHLA User Guide_Practice Owner Account Management Process.pdf").default}
                  target="_blank">
                    Quick Reference
                  </a>
                  <span>|</span>
                  <a className={styles.logout} onClick={login}>
                    Login
                  </a>
                </>
              )}
              {authState?.isAuthenticated && (
                <>
                  <IdleTimeOutHandler onActive={() => { setIsActive(true) }} onIdle={() => { setIsActive(false) }} />

                  <>
                    <>
                      <span>Welcome{" "}</span>

                      <a
                        className={styles.logout}
                        target="_blank"
                        onClick={redirectOKTA}
                      >
                        {userInfo?.name}
                      </a>
                    </>
                    <span>|</span>
                    <a className={styles.logout} onClick={logout}>
                      Logout
                    </a>
                  </>
                </>
              )}
            </div>
          </div>
        </div>
        <Tab />
      </div>
      <ReminderPopup />
      {/* <TourScreen/> */}
    </>
  );
};
export default Nav;
