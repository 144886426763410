import { FC, useEffect, useState } from "react";
import { useOktaAuth } from "@okta/okta-react";
import { Table } from "react-bootstrap";
import PracticeService from "../../../../services/PracticeService";
import IPracticeNonPhysicians, {
  NonPhysicians,
} from "../../../../types/IPracticeNonPhysicians";
import { appInsights } from "../../../../appInsights";
import axios from "axios";
import Loader from "../../../common/Loader";
import IPracticeRoles from "../../../../types/IPracticeRoles";
import CustomAccordion from "../../../accordion/CustomAccordion";
import AddNonPhysicianToPracticeModal from "./AddNonPhysicianToPracticeModal";
import AccountService from "../../../../services/AccountService";
import usePracticeInfo from "../../../../hooks/usePracticeInfo";
import IInviteNonPhysician from "../../../../types/IInviteNonPhysician";
import MButton from "../../../mButton/MButton";
import CustomAlertModal from "../../../common/CustomAlertModal";
import RemoveUserConfirmationModal from "./RemoveUserConfirmationModal";
import useUserRole from "../../../../hooks/useUserRole";

const PracticeNonPhysicians: FC<{ role?: IPracticeRoles,practiceId:number }> = ({ role,practiceId }) => {
  const [nonPhysicians, setNonPhysicians] = useState<IPracticeNonPhysicians>();
  const { authState } = useOktaAuth();
  const accessToken = authState?.accessToken?.accessToken;
  const [error, setError] = useState<Error | null>(null);
  const cancelToken = axios.CancelToken.source();
  const [loading, setLoading] = useState(true);
  const [isSubmitting, setIsSubmitting] = useState<{
    name: string;
    userId?: string;
    isSubmitting: boolean;
  }>({ name: "", userId: "", isSubmitting: false });
  const [showModal, setShowModal] = useState<boolean>(false);
  const [showRemoveModal, setShowRemoveModal] = useState<boolean>(false);
  const [response, setResponse] = useState<{
    show: boolean;
    header: string;
    body: string;
  }>({
    show: false,
    header: "",
    body: "",
  });
  const [practiceInfo] = usePracticeInfo();
  const [invited, setInvited] = useState(0);
  const [userToRemove, setUserToRemove] = useState<NonPhysicians>();
  const [userRole, setUserRole] = useUserRole();

  const checkRole = ():boolean => {

    if (userRole !== undefined){
      return userRole?.practiceRoles.filter(e=>e.roleId == "3" || e.roleId == "9").length > 0 ? true : false
    }

    return false
  }
  const renderRemoveButton = (user: NonPhysicians) => {
    if ((nonPhysicians?.isDelegated || checkRole()) && user.status === "Active") {
      return (
        <td>
          <MButton
            onClick={() => {
              setShowRemoveModal(true);
              setUserToRemove(user);
            }}
            title="Delete"
            small
            variant="danger"
            width="auto"
            marginLeft="70px"
            isSubmitting={
              isSubmitting.userId === user.userId && isSubmitting.isSubmitting
            }
          />
        </td>
      );
    } else return <td></td>;
  };
  const renderAddButton = () => {
    if(nonPhysicians !== undefined){
      if ((nonPhysicians?.isDelegated || checkRole())) {
        return (
          <div className="PhysicianButton">
                <MButton
                  onClick={() => {
                    setShowModal(true);
                  }}
                  title="Add non-physician to my practice"
                  small
                  variant="secondary-light"
                  width="auto"
                  marginLeft="70px"
                />
              </div>
        );
      } else return null;
    }
     else return null;
  };

  useEffect(() => {
    // setError(null);
    setLoading(true);
    PracticeService.getPracticeNonPhysicians(accessToken, cancelToken)
      .then((response) => {
        setNonPhysicians(response.data);
        setLoading(false);
      })
      .catch((e) => {
        if (!axios.isCancel(e)) {
          console.error(e);
          appInsights.trackException({ error: e });
          setError(e);
        }
        setLoading(false);
      });
    return () => {
      cancelToken.cancel();
    };
  }, [isSubmitting.isSubmitting, invited]);

  return (
    <>
      <CustomAccordion
        title={
          <div className="header-top d-flex">
            <h5 className="header-blue bold m-0 pe-2">
              Non-Physicians in My Practice
            </h5>
            {loading ? (
              <Loader />
            ) : (
              <h5 className="header-blue bold m-0">
                ({nonPhysicians !== undefined ? nonPhysicians.data.length : 0})
              </h5>
            )}
          </div>
        }
        content={
          <>
           {renderAddButton()}
            <div className="table-responsive-sm">
              <Table id="lab-table">
                <thead>
                  <tr>
                    <th>Name</th>
                    <th>Email</th>
                    {/* <th style={{ width: 140 }}>Role</th> */}
                    <th style={{ width: 90 }}>Status</th>
                    <th></th>
                  </tr>
                </thead>
                <tbody>
                  {nonPhysicians?.data.map((user, key) => {
                    return (
                      <tr key={key}>
                        <td>
                          {user.firstName} {user.lastName}
                        </td>
                        <td>{user.email}</td>
                        {/* <td>{user.roleName}</td> */}
                        <td>{user.status === 'In-Active'?'Inactive':user.status}</td>
                        
                        {renderRemoveButton(user)}
                      </tr>
                    );
                  })}
                </tbody>
              </Table>
            </div>
          </>
        }
      />
      <AddNonPhysicianToPracticeModal
        show={showModal}
        handleClose={() => {
          setShowModal(false);
        }}
        handleAdd={() => {
          setShowModal(false);
          setInvited((c) => c + 1);
          setResponse({
            show: true,
            header: "Success",
            body: "Your request has been processed successfully.",
          });
        }}
        handleError={() => {
          setResponse({
            show: true,
            header: "Error!",
            body: "There was an error processing your request. Please refresh the page and try again. If problems persist please contact myCHLA support.",
          });
        }}
      />
      <CustomAlertModal
        show={response.show}
        header={response.header}
        body={response.body}
        handleClose={() => {
          setResponse({
            show: false,
            header: "",
            body: "",
          });
        }}
      />
      <RemoveUserConfirmationModal
        show={showRemoveModal}
        handleClose={() => {
          setShowRemoveModal(false);
          setUserToRemove(undefined);
        }}
        handleConfirm={() => {
          setShowRemoveModal(false);
          setIsSubmitting({
            name: userToRemove?.firstName + " " + userToRemove?.lastName,
            userId: userToRemove?.userId,
            isSubmitting: true,
          });
          if (userToRemove?.userId) {
            AccountService.removeNonPhysician(
              {
                userId: userToRemove?.userId,
                practiceId: practiceId,
              },
              accessToken,
              cancelToken
            )
              .then((response) => {
                setIsSubmitting({
                  name: userToRemove?.firstName + " " + userToRemove?.lastName,
                  userId: userToRemove?.userId,
                  isSubmitting: false,
                });
              })
              .catch((e) => {
                setIsSubmitting({
                  name: userToRemove?.firstName + " " + userToRemove?.lastName,
                  userId: userToRemove?.userId,
                  isSubmitting: false,
                });
                if (!axios.isCancel(e)) {
                  console.error(e);
                  appInsights.trackException({ error: e });
                  // setError(e);
                }
              });
          }
        }}
        name={userToRemove?.firstName + " " + userToRemove?.lastName}
      />
    </>
  );
};

export default PracticeNonPhysicians;
