import { FC, useEffect, useState } from "react";
import ReactPlayer from "react-player";
import PediatricGRService from "../../../services/PediatricGRService";
import { useParams } from "react-router-dom";
import { getFormattedDate } from "../../../helpers/DateHelper";
import Loader from "../../common/Loader";
import ReactHtmlParser from "react-html-parser";

const PGRVideo: FC<{
  name?: string;
}> = ({ name }) => {
  const [url, setUrl] = useState<string>("");
  const [title, setTitle] = useState<string>("");
  const [description, setDescription] = useState<string>("");
  const [eventDate, setEventDate] = useState<Date | string>("");

  const [loading, setLoading] = useState<boolean>(true);
  const params: { id: string } = useParams();

  const fetchSAS = async () => {
    console.log(params.id);
    const id = params.id;
    const data = await PediatricGRService.pediatricGrandRoundSAS(parseInt(id));

    return data.data;
  };
  useEffect(() => {
    fetchSAS()
      .then((SASInfo) => {
        setUrl(SASInfo.sas);
        setTitle(SASInfo.title);
        setEventDate(SASInfo.eventDate);
        setDescription(SASInfo.contentMessage);

        console.log(typeof SASInfo.eventDate);
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
        console.log(err);
      });
  });


  return (
    <div className="w-100 d-flex flex-sm-row flex-column p-sm-4 p-3">
      {loading ? (
        <div className="m-auto">
          <Loader />
        </div>
      ) : (
        <>
          <ReactPlayer
            width="100%"
            height="100%"
            style={{
              margin: "20px auto",
            }}
            controls
            url={url}
            config={{ file: { attributes: { controlsList: "nodownload" } } }}
          />
          <div className="w-sm-50 w-100 m-sm-3 mt-3">
            <p
              style={{
                fontSize: 25,
                fontWeight: "bold",
                color: "#004877",
              }}
            >
              {title}
            </p>
            <p
              style={{
                fontSize: 20,
                color: "#666666",
              }}
            >
              Event Date: {getFormattedDate(new Date(eventDate))}
            </p>
            <p
              style={{
                fontSize: 20,
                color: "#666666",
              }}>
              {(ReactHtmlParser(description))}
              </p>
          </div>
        </>
      )}
    </div>
  );
};

export default PGRVideo;
