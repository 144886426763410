import { FC, useEffect, useState } from "react";
import { Badge, OverlayTrigger, Table, Col, Form, Card } from "react-bootstrap";
import { useHistory, useLocation, Link } from "react-router-dom";
import { useOktaAuth } from "@okta/okta-react";
import IDropDownInfoWell from "../../../types/IDropDownInfoWell";
import axios, { AxiosResponse } from "axios";
import MButton from "../../mButton/MButton";
import Spinner from "react-bootstrap/Spinner";
import styles from "../home/Jumbotron.module.css";
import IClinicalType from "../../../types/IClinicalType";
import { downloadFileFromEducationService } from "../../../helpers/FileHelper";
import GoButton from "../../GoButton/GoButton";

const sortable = require("../../../assets/images/sortable.png").default;

const ClinicalCarePathwaysScreen: FC<{
  name?: string;
  dropdown?: IClinicalType;
  data?: IDropDownInfoWell[];
}> = ({ name, dropdown, data = [] }) => {
  const history = useHistory();
  const tableCellPadding = { padding: 20 };
  const tableHeadPadding = { paddingLeft: 20, paddingRight: 20 };
  const { authState } = useOktaAuth();
  const accessToken = authState?.accessToken?.accessToken;
  const [loading, setLoading] = useState(false);
  const [clinicalTypeData, setClinicalTypeData] = useState<IClinicalType[]>();
  const [error, setError] = useState<Error | null>(null);
  const [formData, setFormData] = useState<FormData>();
  const cancelToken = axios.CancelToken.source();
  const { state: dropDownWells } = useLocation<IDropDownInfoWell[]>();
  const [value, setValue] = useState("");
  const handleOnChange = (id: any, e?: any) => {
    let data = new FormData();
    let title = e.nativeEvent.target[e.nativeEvent.target.selectedIndex].text.split(" - ").length !== 1
    ? e.nativeEvent.target[e.nativeEvent.target.selectedIndex].text
      .split(" - ")
      .slice(1)
      .join(" - ")
    : e.nativeEvent.target[e.nativeEvent.target.selectedIndex].text;
    data.append("id", id);
    console.log("val",title);
    setFormData(data);
    setValue(title);
  };

  return (
    <div className="col-sm-4 col-lg-3 p-2 d-flex justify-content-center">
      {data != undefined ? (
        <Card
          className="social p-4"
          style={{ width: "25rem", display: "flex" }}
        >
          <Card.Title className={styles.titleCard}>{dropdown?.type}</Card.Title>

          <Form.Select
            as="select"
            onChange={(e) => handleOnChange(e.target.value, e)}
            aria-label="Default select example"
          >
            {formData === undefined ? <option>{"Select PDF"}</option> : null}
            {data
              ?.map((option, index: number) => (
                <>
                  {option.clinicalCareType === dropdown?.type ? (
                    <option key={index} value={option.id} title={option.title}>
                      {option.title}
                    </option>
                  ) : null}
                </>
              ))
              .reverse()}
          </Form.Select>

          <br />

          {loading ? (
            <Spinner animation="border" className="m-auto" />
          ) : (
            <GoButton
              handleSubmit={() => {
                downloadFileFromEducationService(
                  formData?.get("id"),
                  value,
                  setLoading,
                  setError,
                  accessToken
                );
              }}
            />
          )}
        </Card>
      ) : (
        <Spinner animation="border" className="m-auto" />
      )}
    </div>
  );
};

export default ClinicalCarePathwaysScreen;
