import { FC, useEffect, useState } from "react";
import HeaderName from "../../headerName/HeaderName";
import { Badge, OverlayTrigger, Table, Col } from "react-bootstrap";
import MButton from "../../mButton/MButton";
import { useHistory, useLocation } from "react-router-dom";
import CustomToggle from "../../toggle/CustomToggle";
import AlumniSpotlightService from "../../../services/AlumniSpotlightService";
import { useOktaAuth } from "@okta/okta-react";
import IAlumniSpotlight from "../../../types/IAlumniSpotlight";
import { getFormattedDateFromDate } from "../../../helpers/DateHelper";
import Loader from "../../common/Loader";
import usePagination from "../../../hooks/usePagination";
import CustomPagination from "../../common/CustomPagination";
import { Checkbox } from "@mui/material";
import { appInsights } from "../../../appInsights";
import axios from "axios";
const sortable = require("../../../assets/images/sortable.png").default;

const AlumniSpotlight: FC<{
  name?: string;
}> = ({ name }) => {
  const history = useHistory();
  const tableCellPadding = { padding: 20 };
  const tableHeadPadding = { paddingLeft: 20, paddingRight: 20 };
  const handleClick = () => {
    history.push("/portalupdates/components/newalumniform");
  };
  const handleOnClick = (alumni: IAlumniSpotlight) => {
    history.push("/portalupdates/components/editalumniform", alumni);
  };
  const [error, setError] = useState<Error | null>(null);
  const { authState } = useOktaAuth();
  const accessToken = authState?.accessToken?.accessToken;
  const [loading, setLoading] = useState(true);
  const onToggle = () => {};
  const [alumniData, setAlumniData] = useState<IAlumniSpotlight[]>();

  useEffect(() => {
    setLoading(true);
    setError(null);
    AlumniSpotlightService.getAlumniSpotlightServiceList(accessToken)
      .then((response) => {
        setLoading(false);
        setAlumniData(response.data);
      })
      .catch((e: Error) => {
        if (!axios.isCancel(e)) {
          console.error(e);
          appInsights.trackException({ error: e });
          setError(e);
        }
        
        setLoading(false);
      });
  }, []);
  return (
    <div className="referrals">
      <HeaderName name={name} />
      <div className="container">
        <h2 className="heading-main">{name}</h2>
        <div className="d-flex row justify-content-end" style={{ padding: 20 }}>
          <MButton
            variant="primary"
            disabled={false}
            title="Create New"
            width={"fit-content"}
            onClick={handleClick}
          />
        </div>
        <Col
          sm={{ span: 7, offset: 3 }}
          className="d-flex justify-content-center"
        >
          {loading && <Loader />}
        </Col>
        <br />
        <div className="referral">
          <Table id="responsive-table" className="mt-2" responsive>
            <thead>
              <tr>
                <th style={tableHeadPadding}>Title</th>
                <th style={tableHeadPadding}>File Name </th>
                <th style={tableHeadPadding}>Is Active</th>
                <th style={tableHeadPadding}>
                  Publication Date
                
                </th>
                <th style={tableHeadPadding}>Modified On</th>
                <th style={tableHeadPadding}>Modified By</th>
              </tr>
            </thead>
            <tbody>
              {alumniData?.map((alumni) => {
                return (
                  <tr>
                    <td
                      style={tableCellPadding}
                      data-title="Title"
                      id="table-text-referrals"
                      onClick={() => handleOnClick(alumni)}
                    >
                      {alumni.title}
                    </td>
                    <td style={tableCellPadding} data-title="File Name">
                      {alumni.fileName}
                    </td>
                    <td style={tableCellPadding} data-title="Is Active">
                    <Checkbox disabled 
                        checked={alumni.isActive}
                                             />
                    </td>
                    <td style={tableCellPadding} data-title="Publication Date">
                      {getFormattedDateFromDate(alumni.publicationDate)}
                    </td>
                    <td style={tableCellPadding} data-title="Modified On">
                      {getFormattedDateFromDate(alumni.modifiedOn)}
                    </td>
                    <td style={tableCellPadding} data-title="Modified By">
                      {alumni.modifiedBy}
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </Table>
          {/* <div className="col d-flex justify-content-center">
              <CustomPagination
                currentPage={page}
                onPageChange={(p) => {
                  setPage(p);
                }}
                totalPages={totalPages}
                range={5}
              />
            </div> */}
        </div>
      </div>
    </div>
  );
};

export default AlumniSpotlight;
