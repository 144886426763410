import { FC } from "react";
import Loader from "../common/Loader";
import "./m-button.css";

const MButton: FC<{
  title?: string;
  color?:string;
  bColor?:string;
  variant:
  | "primary"
  | "secondary"
  | "accent"
  | "danger"
  | "danger-outline"
  | "neutral"
  | "success"
  | "light"
  | "secondary-light";
  onClick?: (e?: any) => void;
  small?: boolean;
  primary?: boolean;
  hidden?: boolean;
  paddingBottom?: any;
  borderTopRightRadius?: any;
  borderBottomRightRadius?: any;
  borderTopLeftRadius?: any;
  borderBottomLeftRadius?: any;
  marginTop?: any;
  width?: any;
  property?: any;
  marginLeft?: any;
  marginRight?: any;
  alignItem?: any;
  disabled?: boolean;
  infoButton?: any;
  type?: "button" | "reset" | "submit";
  isSubmitting?: boolean;
  loader?:boolean
}> = ({
  title,
  onClick,
  small,
  variant,
  hidden,
  paddingBottom,
  marginTop,
  borderTopRightRadius,
  borderBottomRightRadius,
  borderTopLeftRadius,
  borderBottomLeftRadius,
  width,
  property,
  disabled,
  alignItem,
  marginLeft,
  marginRight,
  infoButton,
  type = "button",
  isSubmitting,
  color,
  bColor,
  loader
}) => {
    return (
      <button
        type={type}
        disabled={disabled || isSubmitting}
        className={`m-button ${infoButton} ${small && "small"} ${variant} ${hidden && "hidden"
          } ${disabled ? "disableBtnPdf" : ""} ${"position-sticky"}`}
        onClick={onClick}
        style={{
          width: width,
          marginLeft: marginLeft,
          marginRight: marginRight,
          paddingBottom: paddingBottom,
          alignItems: "flex-end",
          borderTopRightRadius: borderTopRightRadius,
          borderBottomRightRadius: borderBottomRightRadius,
          borderTopLeftRadius: borderTopLeftRadius,
          borderBottomLeftRadius: borderBottomLeftRadius,
          marginTop: marginTop,
          borderColor: bColor,
          color : color,
          // display: 'flex',
          // flexDirection: 'row'
        }}
        
        
        
      >
        {isSubmitting || loader ? (
          <span style={{ margin: 0, padding: 0 }}>
            <Loader color="white" />
          </span>
        ) : (
          title
        )}
      </button>
    );
  };

export default MButton;
