import { FC, useState, useEffect } from "react";
import { Calendar, momentLocalizer, Views } from "react-big-calendar";
import moment from "moment";
import "./practice-appointments.css";
import * as dates from "../../../helpers/dates";
import "bootstrap/dist/css/bootstrap.min.css";
import "react-big-calendar/lib/css/react-big-calendar.css";
import { IPracticeAppointment } from "../../../types/IPracticeAppointment";
import PatientService from "../../../services/PatientService";
import { useOktaAuth } from "@okta/okta-react";
import PracticeAppointmentModal from "./components/PracticeAppointmentModal";
import SearchBar from "../../common/SearchBar";
import Loader from "../../common/Loader";
import LookbackFilter from "../../common/LookBackFilter";
import { getFormattedDateFromDate, getFormattedDateFromDateTime } from "../../../helpers/DateHelper";
import { appInsights } from "../../../appInsights";
import axios from "axios";
// import 'react-big-calendar/lib/sass/styles';
const localizer = momentLocalizer(moment);
let allViews = Object.keys(Views).map((k) => Views[k]);

const indivisualEventComponent: FC<{ children?: string }> = ({ children }) => {
  return <div style={{ backgroundColor: "lightblue" }}>{children}</div>;
};
const LegendForCalendar= () =>{
 
    return (
      <span>
        {"Legend: "}
        
        <span
          style={{
            backgroundColor: "#ff7f00",
            color: "white",
            textDecoration: "none",
          }}
        >
          No Show
        </span>
        <span
          style={{
            backgroundColor: "#fff",
            color: "#000",
            textDecoration: "line-through",
          }}
        >
          Cancelled
        </span>
        <span style={{
            backgroundColor: "#0D9DDB",
            color: "white",
            textDecoration: "none",
          }}>
        <strong> {"All Day"}</strong>
      </span>
      </span>
    );
    //
  
}
const CustomEvent = (event: any) => {
  var options = { hour12: false};
  if (event.event.SURGERY_IND == 1 || event.event.RADIOLOGY_IND == 1) {
    return (
      <span>
        <strong> {event.event.title}</strong>
      </span>
    );
  }
  return (
    <span>
      {" "}
      <strong>
        {" "}
        {event.event.start.toLocaleTimeString('en-US',options).slice(0, -3)}
      </strong>{" "}
      {event.event.title}
    </span>
  );
};

const dateConverter = (dateStr: string) => {
  return new Date(dateStr);
};

const MyAppointment = () => {
  var date = new Date();
  const [practiceAppointments, setPracticeAppointments] =
    useState<IPracticeAppointment>();
  const [showModal, setShowModal] = useState(false);
  const [searchText, setSearchText] = useState("");
  const [searched, setSearched] = useState(0);
  const [searchResult, setSearchResult] = useState([]);
  const [selectedSearchResult, setSelectedSearchResult] = useState({
    value: "",
    display: "",
  });
  const [loading, setLoading] = useState(true);
  const [selectedEvent, setSelectedEvent] = useState(undefined);
  const [startDate, setStartDate] = useState(getFormattedDateFromDate(new Date(date.getFullYear(), date.getMonth(), 1)));
  const [actualStartDate, setActualStartDate] = useState(getFormattedDateFromDate(new Date(date.getFullYear(), date.getMonth(), 1)));

  const [apiCallCount, setApiCallCount] = useState(0);
  const [appointments, setAppointments] = useState<any[]>([]);
  const { authState } = useOktaAuth();
  const accessToken = authState?.accessToken?.accessToken;
  const [viewType , setViewType] = useState<string>("month")
  const createAppointmentsArray = (
    practiceAppointments?: IPracticeAppointment
  ): any[] => {
    let appointements: any[] = [];
    if (practiceAppointments === undefined) {
      
      return [] 
    }
    practiceAppointments.RPTDATA.FUTURE_VISITS.map((item) => {
      if (item.PATIENT_NAME.toLowerCase().includes(searchText.toLowerCase())) {
        let obj = {
          id: item.EVENT_ID,
          title: item.PATIENT_NAME,
          allDay: item.RADIOLOGY_IND || item.SURGERY_IND ? true : false,
          state: item.STATE,
          start: dateConverter(item.BEG_DT_TM),
          end: dateConverter(item.END_DT_TM),
          SURGERY_IND: item.SURGERY_IND,
          RADIOLOGY_IND: item.RADIOLOGY_IND,
          resources: item.RESOURCE,
          type: item.TYPE,
          physician: item.PHYSICIAN_NAME,
          role: item.P_PERSON_PRSNL_R_DISP,
          location: item.LOCATION,
        };

        appointements.push(obj);
      }
    });
    setAppointments(appointements)
    return appointements;
  };
  useEffect(() => {
    var sd = new Date(startDate)
    var asd = new Date(actualStartDate)
    if(sd.getMonth() <= asd.getMonth() && sd.getFullYear() == asd.getFullYear()){
      setLoading(true)
      PatientService.getMyAppointments(startDate, accessToken)
      .then((response) => {
        setLoading(false);

        setPracticeAppointments(response.data);
        createAppointmentsArray(response.data)
        setActualStartDate(startDate)
      })
      .catch((e: Error) => {
        if (!axios.isCancel(e)) {
          console.error(e);
          appInsights.trackException({ error: e });
          // setError(e);
        }
        setLoading(false);
      });
    }
    
  }, [startDate]);
  useEffect(() => {
  
    createAppointmentsArray(practiceAppointments)
    
    
  }, [searchText]);
  return (
    <div className="container">
      <div className="rbc-calendar">
        {loading ? (
          <div className="col loader mb-3">
            <Loader />
          </div>
        ) : null}
        <div className="col-sm-5 col-lg-4 col-xl-3">
          <SearchBar
            placeholder="Search by patient name"
            value={searchText}
            onSearch={() => {}}
            onTextChange={(text) => setSearchText(text)}
          />
        </div>
        <br/>
        <div className="col-sm-5 col-lg-4 col-xl-3">
          {LegendForCalendar()}
        </div>
        <Calendar
          popup
          selectable={true}
          onNavigate={(focusDate: Date, flipUnit: any, prevOrNext: string) => {
            if (prevOrNext === "PREV") {
              setStartDate(
                (focusDate.getMonth() + 1) +
                  "/" +
                  (1) +
                  "/" +
                  focusDate.getFullYear()
              );
              setApiCallCount((c) => c + 1);
            }
          }}
          events={appointments}
          views={["month", "day", "week"]}
          onView={(v:string) => {
            setViewType(v)
          }}
          step={10}
          showMultiDayTimes
          max={dates.add(dates.endOf(new Date(2022, 1, 1), "day"), -1, "hours")}
          defaultDate={new Date()}
          onSelectEvent={(event: any) => {
            setSelectedEvent(event);
            setShowModal(true);
          }}
          components={{
            event: viewType == "month" ? CustomEvent : null,
          }}
          eventPropGetter={(
            event: any,
            start: any,
            end: any,
            isSelected: any
          ) => {
            
            let newStyle = {
              backgroundColor: "white",
              color: "var(--color-dark-blue)",
              borderRadius: "0px",
              // border: "none",
              borderColor:"white",
              textDecoration: "",
            };
            
            if (event === undefined) {
              return {
                className: "",
                style: newStyle,
              };
            }
           
            if(event.state == "No Show"){
              newStyle.backgroundColor = "#ff7f00";
              newStyle.color = "white"
            }
            if (event.state === "Canceled") {
              newStyle.color = "#000";
              newStyle.textDecoration = "line-through";
            }
            if(viewType == "day" || viewType == "week"){
              newStyle.backgroundColor = "var(--color-light-blue)";
              newStyle.color = "white"
              if(event.state === "Canceled"){
                newStyle.backgroundColor = "#000";
                newStyle.color = "white"
              }
              if(event.state == "No Show"){
                newStyle.backgroundColor = "#ff7f00";
                newStyle.color = "white"
              }
              
            }
            if (event.RADIOLOGY_IND == 1 || event.SURGERY_IND == 1) {
              newStyle.backgroundColor = "#0D9DDB";
              newStyle.color = "white";
            }
            
            return {
              className: "",
              style: newStyle,
            };
          }}
          localizer={localizer}
        />
      </div>
      <PracticeAppointmentModal
        show={showModal}
        handleClose={() => {
          setShowModal(false);
        }}
        selectedEvent={selectedEvent}
      />
    </div>
  );
};

export default MyAppointment;
