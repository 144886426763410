import { FC, useContext, useEffect, useState } from "react";
import { useOktaAuth } from "@okta/okta-react";
import { appInsights } from "../../../../appInsights";
import axios from "axios";
import PracticeService from "../../../../services/PracticeService";
import CustomToggle from "../../../toggle/CustomToggle";
import Loader from "../../../common/Loader";
import practiceInfoContext from "../../../../context/PracticeInfoContext";
import usePracticeInfo from "../../../../hooks/usePracticeInfo";

const PracticeDelegation: FC<{
  practiceId: number;
  delegationEnabled?: boolean;
}> = ({ practiceId, delegationEnabled }) => {
  const [practiceInfo] = usePracticeInfo();
  const { authState } = useOktaAuth();
  const accessToken = authState?.accessToken?.accessToken;
  const cancelToken = axios.CancelToken.source();
  const [delegation, setDelegation] = useState(delegationEnabled);
  const [toggle, setToggle] = useState(0);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (toggle > 0) {
      setLoading(true);
      PracticeService.practiceDelegation(practiceId, accessToken, cancelToken)
        .then((response) => {
          setLoading(false);
          window.location.reload();
        })
        .catch((e) => {
          if (!axios.isCancel(e)) {
            console.error(e);
            appInsights.trackException({ error: e });
            // setError(e);
          }
          setLoading(false);
        });
      return () => {
        cancelToken.cancel();
      };
    }
  }, [toggle]);

  const onToggle = () => {
    setToggle((prev) => prev + 1);
  };

  return (
    <>
      <div className="d-flex align-items-end">
        <h4>Delegation</h4>
      </div>
      <p>Allow practice admin staff to manage account on my behalf</p>
      {delegation !== undefined && (
        <div className="d-flex align-items-center">
          {" "}
          <CustomToggle isOn={delegationEnabled === undefined ? false : delegationEnabled} onToggle={(e)=>{
            onToggle()
          }} />
          {loading && (
            <div style={{ paddingLeft: 20 }}>
              <Loader />
            </div>
          )}
        </div>
      )}
    </>
  );
};

export default PracticeDelegation;
