import { FC, useState } from "react";
import CustomAccordion from "../../../accordion/CustomAccordion";
import MButton from "../../../mButton/MButton";
import styles from "./AdminResult.module.css";
import IPhysicianSearchResult from "../../../../types/IPhysicianSearchResult";
import AdminPracticeLocationsTable from "./AdminLocationsTable";
import AdminPhysicianPracticesTable from "./AdminPhysicianPracticesTable";
import { useHistory } from "react-router-dom";
import DeactivateConfirmationModal from "./DeactivateConfirmationModal";

const AdminPhysicianResult: FC<{
  physicianSearchResult: IPhysicianSearchResult;
}> = ({ physicianSearchResult }) => {
  //
  const [showModal, setShowModal] = useState(false)
  const history = useHistory();

  const handleEditClick = () => {
    history.push(
      `/accounts&access/physicians&practices/edit/physician/${physicianSearchResult.id}`
    );
  };

  const handleDeactivateClick = () => {
    setShowModal(true)
  };

  return (
    <div className={styles.adminPractice}>
      <div className="d-flex mb-1">
        <div className="d-flex col">
          <div className="col">
            <b>ID</b>
            <br />
            <div className="header-blue">{physicianSearchResult.id}</div>
          </div>
          <div className="col">
            <b>PHYSICIAN NAME</b>
            <br />
            <div className="header-blue">
              {physicianSearchResult.lastName +
                ", " +
                (physicianSearchResult.midName !== "" && physicianSearchResult.midName !== null ? physicianSearchResult.midName +
                  " " : "") +
                physicianSearchResult.firstName +
                " " +
                (physicianSearchResult.title !== "" && physicianSearchResult.title !== null ? physicianSearchResult.title : "")}
              {physicianSearchResult.isMyCHLA && (
                <img
                  src={require("../../../../assets/images/myCHLA.png").default}
                />
              )}
            </div>
          </div>
        </div>
        <div className="col d-flex" style={{ flexDirection: "row-reverse" }}>
          {physicianSearchResult.isActive && <MButton
            variant="danger-outline"
            title="Deactivate"
            width={"fit-content"}
            small
            onClick={handleDeactivateClick}
          />}
          <MButton
            onClick={handleEditClick}
            variant="primary"
            title="Edit"
            width={"fit-content"}
            small
          />
        </div>
      </div>
      <CustomAccordion
        small
        title={
          <h6 className="header-blue bold m-0 pe-2">
            Locations ({physicianSearchResult.locations.length}){" "}
          </h6>
        }
        content={
          <AdminPracticeLocationsTable
            locations={physicianSearchResult.locations}
            count={physicianSearchResult.locations.length}
          />
        }
      />
      <CustomAccordion
        small
        title={
          <h6 className="header-blue bold m-0 pe-2">
            Practices ({physicianSearchResult.practices!= null ? physicianSearchResult.practices.length:null})
          </h6>
        }
        content={
          <AdminPhysicianPracticesTable
            practices={physicianSearchResult.practices}
          />
        }
      />
      <DeactivateConfirmationModal
        show={showModal}
        type="physician"
        id={physicianSearchResult.id}
        handleClose={() => {
          setShowModal(false)
        }}
        handleConfirm={() => {
          setShowModal(false)
          window.location.reload()
        }}
      />
    </div>
  );
};

export default AdminPhysicianResult;
