import { FC, useCallback, useContext, useEffect, useState } from "react";
import { useOktaAuth } from "@okta/okta-react";
import { Navbar, Nav, NavDropdown } from "react-bootstrap";
import { getRoutesFromMenuItems } from "../../helpers/RouteHelper";
import "../../App.css";
import styles from "./Navbar.module.css";
import { useHistory } from "react-router-dom";
import menuItemsContext from "../../context/MenuItemsContext";
import DropdownMenu from "react-bootstrap/esm/DropdownMenu";

const Tab: FC = () => {
  const { authState } = useOktaAuth();
  const history = useHistory();
  // const [, setRoles] = useState<string[]>([]);
  const { menuItems } = useContext(menuItemsContext);
  const [dropdownMenu, setDropdownMenu] = useState("");
  let key;
  // const getRoutes = useCallback(
  //   () => {
  //     getRoutesFromMenuItems(menuItems || [])
  //   },
  //   [menuItems],
  // )

  const getUserFromToken = (token: string | undefined) => {
    if (token) {
      try {
        return JSON.parse(atob(token.split(".")[1]));
      } catch (error) {
        // ignore
      }
    }
    return null;
  };

  const handleDropdownSelect = () => {
    let parentNav = document.getElementById("responsive-navbar-nav");
    let child = parentNav!.getElementsByClassName("nav-link");

    for (let i = 0; i < child.length; i++) {
      child[i].addEventListener("click", function () {
        let current = document.getElementsByClassName("active");
        // If there's no active class
        if (current.length > 0) {
          current[0].className = current[0].className.replace(" active", "");
        }
        // Add the active class to the current/clicked button
        child[i].className += " active";
      });
    }
  };

  useEffect(() => {
    handleDropdownSelect();
    // const userInfo = getUserFromToken(authState?.accessToken?.accessToken);
    // if (userInfo?.myCHLARole !== undefined && userInfo.myCHLARole.length > 0) {
    //   setRoles(userInfo.myCHLARole);
    // }
  }, [authState?.accessToken?.accessToken, menuItems]);

  if (window.location.pathname == '/') {
    key = 'Home';
  }
  else if (window.location.pathname.includes('patients')) {
    key = "My Patients";
  }
  else if (window.location.pathname.includes('referrals')) {
    key = "My Referrals";
  }
  else if (window.location.pathname.includes('practice')) {
    key = "My Practice";
  }
  else if (window.location.pathname.includes('accountsandaccess')) {
    key = "Accounts & Access";
  }
  else if (window.location.pathname.includes('portalupdates')) {
    key = "Portal Updates";
  }
  else if (window.location.pathname.includes('physicianresources')) {
    key = "Physician Resources";
  }
  else if (window.location.pathname.includes('education')) {
    key = "Education";
  }
  else {
    key = 'Home';
  }


  return (
    <>
      <Navbar collapseOnSelect expand="lg" className={styles.navDiv}>
        <Navbar.Brand className={styles.logoContainer}>
          <img
            className={styles.brandLogo}
            src={require("../../assets/images/mychla_Logo.png").default}
            alt="Image"
          />
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="responsive-navbar-nav" />
        <Navbar.Collapse id="responsive-navbar-nav">
          <Nav
            variant="pills"
            className={`${styles.tabs} container`}
            activeKey={key}
          >
            {menuItems !== undefined &&
              getRoutesFromMenuItems(menuItems).map((r, index, row) => {
                return (
                  <div key={index} className={styles.main}>
                    {r.items.length !== 0 ? (
                      <NavDropdown
                        title={r.parent}
                        className={`${styles.navbarLink} `}
                        id={styles.responsiveNavDropdown}
                        align={index < row.length - 1 ? 'start' : 'end'}
                      >
                        {r.items.map((item, index) => {
                          return (
                            <NavDropdown.Item
                              onClick={() => history.push(item.path || "/")}
                              key={index}
                            >
                              {item.itemName}
                            </NavDropdown.Item>
                          );
                        })}
                      </NavDropdown>
                    ) : (
                      <>
                        <Nav.Link
                          onClick={() => history.push(r.path || "/")}
                          className={styles.navbarLink}
                          data-testid="home-link"
                          eventKey={r.parent}
                        >
                          {r.parent}
                        </Nav.Link>
                      </>
                    )}
                  </div>
                );
              })}
          </Nav>
        </Navbar.Collapse>
      </Navbar>
    </>
  );
};

export default Tab;
