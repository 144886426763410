import { FC, useEffect, useState } from "react";
import HeaderName from "../../headerName/HeaderName";
import {
  Badge,
  OverlayTrigger,
  Button,
  Table,
  Col,
  Card,
  NavDropdown,
} from "react-bootstrap";
import { useHistory, useLocation, Link } from "react-router-dom";
import { useOktaAuth } from "@okta/okta-react";
import EducationService from "../../../services/EducationService";
import axios from "axios";
import Loader from "../../common/Loader";
import IEducation from "../../../types/IEducation";
import Spinner from "../../spinner/Spinner";
import DivisionMonthService from "../../../services/DivisionMonthService";
import IDivisionMonth from "../../../types/IDivisionMonth";
import IPortalPracticeTip from "../../../types/IPortalPracticeTip";
import Alumni from "./Custom";
import PracticeTip from "./PracticeTip";
import Division from "./Division";
import InfowellsEdu from "./InfowellsEdu";
import HomeService from "../../../services/HomeService";
import useInfowells from "../../../hooks/useInfowells";
import { appInsights } from "../../../appInsights";

const sortable = require("../../../assets/images/sortable.png").default;

const Education: FC<{
  name?: string;
}> = ({ name }) => {
  const history = useHistory();

  const { authState } = useOktaAuth();
  const accessToken = authState?.accessToken?.accessToken;
  const cancelToken = axios.CancelToken.source();
  const [loading, setLoading] = useState(true);
  const [educationData, setEducationData] = useState<IEducation>();
  const [error, setError] = useState<Error | null>(null);
  const [infowells, setInfowells] = useInfowells();
  useEffect(() => {
    setLoading(true);
    setError(null);
    EducationService.getEducationService(accessToken)
      .then((response) => {
        setLoading(false);
        setEducationData(response.data);
      })
      .catch((e: Error) => {
        if (!axios.isCancel(e)) {
          console.error(e);
          appInsights.trackException({ error: e });
          setError(e);
        }
       
        setLoading(false);
      });
    return () => {
      cancelToken.cancel();
    };
  }, []);
  useEffect(() => {
    setLoading(true);
    // console.log(accessToken)
    HomeService.getInfowells(accessToken)    
      .then((response) => {
        setInfowells(response.data);
        setLoading(false);
      })
      .catch((e) => {
        if (!axios.isCancel(e)) {
          console.error(e);
          appInsights.trackException({ error: e });
           setError(e);
        }
        
        setLoading(false);
      });
      return () => {
        cancelToken.cancel();
      };
  }, [accessToken]);
  if (educationData != undefined) {
  }
  return (
    <div>
      <HeaderName name={name} />
      <div className="container">
        {" "}
        <h2 className="heading-main">{name}</h2>
        <br />
        <Col
          sm={{ span: 7, offset: 3 }}
          className="d-flex justify-content-center"
        >
          {loading && <Spinner margin="5rem 0" />}
        </Col>
        <br />
        <div className="row no-gutters p-2 m-0">
          {educationData?.infoWells.map((education) => {
            return <InfowellsEdu infowell={education} likedInfowells={infowells} />;
          })}
        </div>
      </div>
    </div>
  );
};

export default Education;
