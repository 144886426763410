import { FC } from "react";
import IRecentSearchPatient from "../../../../types/IRecentSearchPatient";
import {
  setRecentSearch,
  getRecentSearch,
} from "../../../../helpers/RecentSearchHelper";

const RecentSearch: FC<{ recentSearchList: IRecentSearchPatient[], updateRecentSearch?:(name: string) => void; }> = ({
  recentSearchList,
  updateRecentSearch
}) => {

  const onClickRecentSearch = (name:string) => {
    updateRecentSearch!(name)
  }

  return (
    <>
      <h4 id="recent-search-header">Recent Searches</h4>
      {recentSearchList === null || recentSearchList.length === 0 ? (
        <div>
          <p>No recent searches</p>
        </div>
      ) : (
        <div>
          {recentSearchList.map((item, index) => (
            <div key={index} >
              <a href="#" className="recentSearch" onClick={() => onClickRecentSearch(item.name)}><strong id="recent-search-name" >{item.name}</strong></a>
              <div>DOB: {item.dob}</div>
              <hr id="recent-search-divider" />
            </div>
          ))}
        </div>
      )}
    </>
  );
};

export default RecentSearch;
