import React from 'react';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import MButton from "../mButton/MButton";

export const IdleTimeOutModal = ({showModal, handleContinue, handleLogout, remainingTime}) => {

    return (
        <Modal show={showModal} onHide={handleContinue}>
            <Modal.Header closeButton>
            </Modal.Header>
            <Modal.Body>Are you still using myCHLA?</Modal.Body>
            <Modal.Footer>
            <MButton
            onClick={handleLogout}
            disabled={false}
            variant="secondary"
            title="Logout"
            width={"max-content"}
            />
            <MButton
            onClick={handleContinue}
            variant="primary"
            title="Continue my session"
            width={"max-content"}
            />
            </Modal.Footer>
        </Modal>
    )
}