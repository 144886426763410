import { FieldProps } from "formik";
import { FC } from "react";
import { Form } from "react-bootstrap";
import { Typeahead } from "react-bootstrap-typeahead";
import IFormDropdownOption from "../../types/IFormDropdownOption";

const CustomInputFormik: FC<FieldProps> = ({ field, form, ...props }) => {
  //
  const {
    label,
    required,
    nonEditable,
    type,
    disabled,
    options,
    placeholder,
    selectedItem ,
    defaultValue = "",
    itemSelected = false,
    onChange = (selectedItem : any) =>{

    }
  }: any = props;
  const isInvalid =
    form.errors[field.name] && form.touched[field.name] ? true : false;

  const renderInput = () => {
    if (nonEditable) {
      return <div>{field.value}</div>;
    } else {
      switch (type) {
        case "textarea":
          return <Form.Control {...field} {...props} as="textarea" />;
        case "checkbox":
          return (
            <Form.Check
              type={type}
              {...field}
              disabled={disabled}
              required={required}
            />
          );


        case "select":
          return (
            <Form.Select
              {...field}
              disabled={disabled}
              name={field.name}
              required={required}
              placeholder={
                placeholder !== undefined
                  ? placeholder
                  : "Please make a selection..."
              }
              value={field.value}
            >
              {options &&
                options.map((o: IFormDropdownOption) => (
                  <option value={o.id}>{o.display}</option>
                ))}
            </Form.Select>
          );
        case "typeahead":
          return (
            <Typeahead
              id={field.name}
              inputProps={{ required: required }}
              placeholder={
                placeholder !== undefined
                  ? placeholder
                  : "Please make a selection..."
              }
              options={options}
              selected={!itemSelected ? undefined : options?.filter((o: any) => (o.display === field.value || o.id === field.value))}
              defaultSelected={options?.filter((o: any) => (o.display === field.value || o.id === field.value))}
              // selected={!itemSelected ? undefined : selectedItem !== undefined ? [selectedItem] : options?.filter((o: any) => (o.display === field.value || o.id === field.value))}
              // defaultSelected={selectedItem !== undefined ? [selectedItem] : options?.filter((o: any) => (o.display === field.value || o.id === field.value))}
              labelKey="display"
              onChange={(selected: any) => {
                onChange(selected)
                form.setFieldValue(
                  field.name,
                  selected !== undefined && selected.length > 0
                    ? selected[0].id
                    : ""
                )

              }
              }
            />
          );
        default:
          return <Form.Control onWheel={(e:any) => e.target.blur()} isInvalid={isInvalid} {...field} {...props} />;
      }
    }
  };

  return (
    <Form.Group className="d-flex row align-items-center form-padding">
      <Form.Label className="col-sm-3">
        {label}
        {required ? <span className="p-0 asterik"> *</span> : <></>}
      </Form.Label>
      <div className="col-sm-9">{renderInput()}</div>
      <div className="col-sm-3"></div>
      <div className="col-sm-9" style={{ color: "var(--color-red)" }}>
        {isInvalid && form.errors[field.name]}
      </div>
    </Form.Group>
  );
};

export default CustomInputFormik;
