import { FC, useState } from "react";
import HeaderName from "../../headerName/HeaderName";


const TermsOfUse: FC<{
    name?: string;
  }> = ({ name }) => {
    return (
        <>
          <HeaderName name="myCHLA Terms of Use" />
          <div className="container">
                <h2 className="heading-main"> myCHLA Terms of Use</h2>
                <div className="row">
                    <div className="col-md-12">
                        <div className="chart">
                            <p>Children’s Hospital Los Angeles is committed to respecting patient privacy and protecting the confidentiality and security of patient information and requires that individuals accessing Children’s Hospital Los Angeles’ patient information share that commitment. You are requesting your access to Children’s Hospital Los Angeles’ patient information through the myCHLA portal of patients of your myCHLA sponsor’s physician practice (“Sponsoring Practice”). Children’s Hospital Los Angeles grants you permission to use myCHLA subject to the below terms and conditions:</p>
                            <ol>
                                <li>
                                    <span style={{textDecoration: 'underline'}}>
                                    Your Compliance
                                    </span>
                                    You certify and agree to the following:
                                    <br/>
                                    <br/>
                                    <ol type="a">
                                        <li>
                                        You understand the concepts of confidentiality, privacy, and security. You will adhere to the requirements of this agreement and all of Children’s Hospital Los Angeles’ policies and practices, as may be issued or amended from time to time; and will complete training regarding myCHLA in a form and manner determined by Children’s Hospital Los Angeles.
                                        <br/>
                                        <br/>
                                        </li>
                                        <li>
                                        You will keep your username, password, and authentication information confidential. You will not share your username, password, and authentication information with any other person or entity; and will not allow any other person to log into myCHLA using your username, password, or authentication information. You will be responsible for all activity on myCHLA that is conducted with your username, password, and/or authentication information including, but not limited to, damages and inappropriate use or disclosure of patient information. If you suspect or learn that your username, password, or authentication information has been misused or obtained by another individual or entity, you must immediately inform Children’s Hospital Los Angeles’ Service Desk by calling (888) 631-2452 and notify the owner of your Sponsoring Practice.
                                        <br/>
                                        <br/>
                                        </li>
                                        <li>
                                        You will: (i) not email any information obtained from myCHLA to a personal email account; (ii) not transport any information obtained from myCHLA through unsecured methods including, but not limited to, portable media devices such as thumb/flash drives, memory sticks, DVDs, and CDs; (iii) limit printed information to the minimal amount necessary for the provision of health care services; (iv) properly safeguard, secure, and dispose of such printouts; (v) not download information from, or log into, myCHLA while on an unsecured wireless connection or from a personal computer, handheld device, or other computer or device that is not protected by controls and access policies approved by Children’s Hospital Los Angeles; and (vi) not leave unattended a computer or device through which you are logged into myCHLA. You will be responsible for any information accessed, viewed, downloaded, or printed through myCHLA.
                                        <br/>
                                        <br/>
                                        </li>
                                        <li>
                                        You will only use or download (as expressly permitted on myCHLA) patient information contained on myCHLA in support of treatment, payment, and health care operations in connection with treatment of Sponsoring Practice’s patients who also have received or are receiving care at Children’s Hospital Los Angeles; to comply with the laws that govern health care; and to the extent permissible under all applicable laws regarding the privacy of patient information. In addition, you will only disclose information viewed through myCHLA as necessary for treatment, payment, and health care operations purposes; to comply with the laws that govern health care; and as consistent with governing privacy restrictions which may vary according to the type of information viewed.
                                        <br/>
                                        <br/>
                                        </li>
                                        <li>
                                        You will not: (i) use, reproduce, or copy all or part of the content of myCHLA except as expressly permitted by these terms and conditions and applicable law; (ii) change or delete any proprietary notices from materials downloaded or printed out from myCHLA; (iii) use, or incorporate in a product designed, marketed, sold, or licensed by you or on your behalf, the content of myCHLA for the benefit of a third party except as permitted by these terms and conditions; or (iv) use myCHLA in a manner contrary to any applicable law.
                                        <br/>
                                        <br/>
                                        </li>
                                        <li>
                                        Children’s Hospital Los Angeles has the right, at any time, to monitor and audit your compliance with these terms and conditions and your use of myCHLA. You will promptly cooperate with any audit by Children’s Hospital Los Angeles. The following information may be collected and stored for every request you initiate via the myCHLA application for mobile devices: computer IP address; URL accessed; date, frequency, and period accessed; username; password; and Sponsoring Practice’s name and address. This information may be used by Children’s Hospital Los Angeles for internal evaluation, quality control, and other reasons deemed appropriate by Children’s Hospital Los Angeles.
                                        <br/>
                                        <br/>
                                        </li>
                                        <li>
                                        Your failure to comply with these terms and conditions may result in corrective action including, but not limited to, termination of your access to myCHLA, reports to authorities charged with professional licensing and enforcement of privacy laws, and personal liability for breach of confidential information.
                                        <br/>
                                        <br/>
                                        </li>



                                    </ol>
                                    <br/>
                                </li>
                                <li>
                                    <span style={{textDecoration: 'underline'}}>Breach of Information</span>
                                    You agree that within 24 hours of becoming aware of an unauthorized use or disclosure of Children’s Hospital Los Angeles’ patient information, you will give notice as follows and take actions to eliminate the cause of the breach: (a)
                                    <strong>telephone calls to both Children’s Hospital Los Angeles’ Privacy Officer at (323) 361-2302 and Children’s Hospital Los Angeles’ Service Desk at (888) 631-2452 ;</strong>" (b) <strong>"letter sent via a nationally recognized overnight carrier to the following: Children’s Hospital Los Angeles, 4650 Sunset Boulevard, Mailstop 5, Los Angeles, California 90027, Attention: Privacy Officer;"</strong>
                                    <span style={{textDecoration: 'underline'}}>AND</span>
                                    " (c) "
                                    <strong>notification to the owner of your Sponsoring Practice. </strong>
                                    " To the extent Children’s Hospital Los Angeles deems warranted, in its sole discretion, Children’s Hospital Los Angeles will provide notice or require you to provide notice to individuals whose patient information may have been improperly accessed or disclosed. "
                                    <br/>
                                    <br/>
                                </li>
                                <li>
                                    <span style={{textDecoration: 'underline'}}>Modifications and Termination</span>
                                    " . Children’s Hospital Los Angeles may modify and terminate this agreement and any use of myCHLA by you at any time for any reason without penalty, regardless of any effect such action may have on Sponsoring Practice’s operations."
                                <br/>
                                <br/>
                                </li>
                                <li>
                                    <span style={{textDecoration: 'underline'}}>Disclaimers</span>
                                    " . Services provided through and information contained on myCHLA are provided “as is.” Children’s Hospital Los Angeles makes no, and disclaims any, warranty of any kind, express or implied, including, but not limited to, the implied warranties of title, noninfringement, merchantability, and fitness for a particular use or purpose. Further, Children’s Hospital Los Angeles disclaims any warranty that myCHLA will be available at all times or will operate without interruption or error. Children’s Hospital Los Angeles makes no warranty as to the reliability, accuracy, timeliness, usefulness, adequacy, completeness, or suitability of the services or information provided through myCHLA."
                                <br/>
                                <br/>
                                </li>
                                <li>
                                    <span style={{textDecoration: 'underline'}}>Release of Liability and Indemnification</span>
                                    " . You understand and agree that the general educational information provided on myCHLA is not to be used as a substitute for medical judgment of a qualified health care professional. In consideration for Children’s Hospital Los Angeles’ permitting you to use myCHLA, you expressly release and hold harmless Children’s Hospital Los Angeles, its trustees, officers, directors, employees, agents, and affiliates from any and all claims, liabilities, demands, causes of action, costs, expenses, and damages of every kind and nature, in law, equity, or otherwise, arising out of or in any way related to your use of myCHLA, whether arising from negligence or any other acts or omissions by Children’s Hospital Los Angeles. In addition, you will indemnify and hold harmless Children’s Hospital Los Angeles, its trustees, officers, directors, employees, agents, and affiliates against all actual and direct losses, liabilities, damages, claims, penalties, costs, or expenses (including, but not limited to, reasonable attorney’s fees and the full cost of any required notice to impacted individuals) they may suffer as the result of any claims, demands, actions, investigations, settlements, or judgments against them arising from or in connection with any breach of these terms and conditions, or from any negligence or wrongful acts or omissions, by you. The provisions of this section entitled “Release of Liability and Indemnification” will survive termination of this agreement."
                                <br/>
                                <br/>
                                </li>
                                <li>
                                    <span style={{textDecoration: 'underline'}}>Miscellaneous</span>
                                    " . myCHLA is also subject to both Children’s Hospital Los Angeles’ website General Terms of Use and User Privacy Policy. You agree to download, print, and maintain a copy of this agreement for your reference. You are signing this agreement electronically by selecting the “AGREE” button below and you agree that your electronic signature is the legal equivalent of your manual signature. Notices sent to you by Children’s Hospital Los Angeles in connection with these terms and conditions or your use of myCHLA may be delivered to you by e-mail, a general notice on myCHLA, or written communication delivered by first class U.S. mail. You may give notice to Children’s Hospital Los Angeles at any time by letter delivered by first class postage prepaid U.S. mail or overnight courier to the following address: 4650 Sunset Blvd #71, Los Angeles CA 90027. These terms and conditions are severable to the extent any are deemed invalid, illegal, or unenforceable. The parties to this agreement are independent contractors of one another; nothing in this agreement will be deemed to create any relationship of agency, partnership, or joint venture between the parties."
                                <br/>
                                <br/>
                                </li>
                            </ol>
                            <p>Updated: June 30, 2014</p>

                        </div>
                    </div>
                </div>
            </div>
          </>
    )
  }

export default TermsOfUse;