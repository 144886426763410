import { FC } from "react";
import { Modal } from "react-bootstrap";
import MButton from "../../../mButton/MButton";

const PracticeAppointmentModal: FC<{
  show: boolean;
  handleClose?: () => void;
  selectedEvent?: {
    id: number;
    title: string;
    allDay: Boolean;
    state: string;
    start: Date;
    end: Date;
    SURGERY_IND: number;
    RADIOLOGY_IND: number;
    resources: string;
    type: string;
    physician: string;
    role: string;
    location: string;
  };
}> = ({ show, handleClose, selectedEvent }) => {
  var options = { hour12: false};
  return (
    <Modal size="xl" show={show} onHide={handleClose} centered>
      <Modal.Body>
        <div>
          {!selectedEvent?.allDay? <h4>
            {selectedEvent?.start.toLocaleTimeString('en-US',options).slice(0, -3) +
              " - " +
              selectedEvent?.end.toLocaleTimeString('en-US',options).slice(0, -3) +
              " " +
              selectedEvent?.title}
          </h4> : <h4>
            {selectedEvent?.title +" "+ "(Scheduled time not available for this type of appointment.)"}
          </h4>}
          {selectedEvent ? (
            <>
              <hr id="practice-appointment-divider" />
              <p>
                <strong>Type : </strong>
                {selectedEvent.type}
              </p>
              <hr id="practice-appointment-divider" />
              <p>
                <strong>Physician : </strong> {selectedEvent.physician}
              </p>
              <hr id="practice-appointment-divider" />
              <p>
                <strong>Role : </strong>
                {selectedEvent.role}
              </p>
              <hr id="practice-appointment-divider" />
              <p>
                <strong>Resource : </strong>
                {selectedEvent.resources}
              </p>
              <hr id="practice-appointment-divider" />
              <p>
                <strong>Location : </strong>
                {selectedEvent.location}
              </p>
              <hr id="practice-appointment-divider" />
              <p>
                <strong>State : </strong>
                {selectedEvent.state}
              </p>
            </>
          ) : (
            <p>No record found</p>
          )}
        </div>
      </Modal.Body>
      <Modal.Footer>
        <MButton
          onClick={handleClose}
          variant="primary"
          title="Ok"
          width={"max-content"}
        />
      </Modal.Footer>
    </Modal>
  );
};

export default PracticeAppointmentModal;
