import axios from "axios";

export const customAxios = axios.create({
    transformResponse: [function (data) {
        if(data === "") return data;
        try{
          if(JSON.parse(data).isMyCHLA === false && window.location.pathname !== '/practice-not-active') {
            window.location.assign('/practice-not-active')
          }
          return JSON.parse(data);
        }catch{
          return data;
        }
      }],
});

const ServiceConfig = {
  customAxios,
};

export default ServiceConfig;