import { FC, useEffect, useState } from "react";
import { Form, Button, Modal, Col, Carousel } from "react-bootstrap";
import { useOktaAuth } from "@okta/okta-react";
import MButton from "../mButton/MButton";
import "./tour-screen.css";

const TourScreen = () => {
  const [show, setShow] = useState(false);
  const [index, setIndex] = useState(0);
  const { authState, oktaAuth } = useOktaAuth();
  const accessToken = authState?.accessToken?.accessToken;

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const handleSelect = (selectedIndex, e) => {
    setIndex(selectedIndex);
  };


  // const showTour = JSON.parse(localStorage.getItem("showTour"));

  useEffect(() => {
    if (authState?.isAuthenticated) {
      setShow(true);
    }
  }, [oktaAuth, authState]);

  return (
    <>
      <Modal show={show} onHide={handleClose} id="Modal-Reminder" className="modal-tour">
        <Modal.Header
          closeButton
          style={{ backgroundColor: "var(--color-light-blue)", color: "white" }}
        >
          <Modal.Title style={{ color: "white" }}>
            Welcome to myCHLA! Here's a brief tour.
          </Modal.Title>
        </Modal.Header>
        <Modal.Body style={{ padding: "0px" }} id="tour">
          <Carousel activeIndex={index} onSelect={handleSelect}>
            <Carousel.Item>
              <img
                className="d-block w-100"
                src={
                  require("../../assets/images/tourscreen/myPatient_x.jpg")
                    .default
                }
                alt="My Patient slide"
              />
              <Carousel.Caption className="caption">
                <h3 className="heading">View My Patient</h3>
                <div className="detail">
                <p>
                  <img
                    className=""
                    src={
                      require("../../assets/images/tourscreen/bullet1.png")
                        .default
                    }
                    alt="My Patient slide"
                  />
                  <span>
                    View Patients associated with physicians in your practice on
                    the My Patients tab.
                  </span>
                  </p>
                  <p>
                    <img
                      className=""
                      src={
                        require("../../assets/images/tourscreen/bullet2.png")
                          .default
                      }
                      alt="My Patient slide"
                    />
                    <span>
                      Use the dropdown menu to control which patients appear on
                      the list, based on their visit to CHLA.
                    </span>
                  </p>
                </div>
              </Carousel.Caption>
            </Carousel.Item>
            <Carousel.Item>
              <img
                className="d-block w-100"
                src={
                  require("../../assets/images/tourscreen/myPatient_labResults_x.jpg")
                    .default
                }
                alt="My Patient Lab Results slide"
              />

              <Carousel.Caption
                className="caption caption-patient"
                style={{ padding: "0px" }}
              >
                <h3 className="heading">View Patient Data</h3>
                <div  className="detail">
                <p>
                <img
                  className=""
                  src={
                    require("../../assets/images/tourscreen/bullet1.png")
                      .default
                  }
                  alt="My Patient slide"
                />
                <span>
                Click on a patient name to view clinical data - labs,
                radiology results, and notes.
                </span>
                </p>
                <p>
                  <img
                    className=""
                    src={
                      require("../../assets/images/tourscreen/bullet2.png")
                        .default
                    }
                    alt="My Patient slide"
                  />
                  <span>
                  View results by time range or by visit. For time range, use
                  the dropdown menu to set the lookback range.
                  </span>
                </p>
                <p>
                <img
                  className=""
                  src={
                    require("../../assets/images/tourscreen/bullet3.png")
                      .default
                  }
                  alt="My Patient slide"
                />
                <span>
                Labs may be viewed chronologically or by type.
                </span>
              </p>
              </div>
    
              </Carousel.Caption>
            </Carousel.Item>
            <Carousel.Item>
              <img
                className="d-block w-100"
                src={
                  require("../../assets/images/tourscreen/myPractice_x.jpg")
                    .default
                }
                alt="My Practice slide"
              />

              <Carousel.Caption className="caption">
                <h3 className="heading">My Practice</h3>
                <div  className="detail">
                <p>
                <img
                  className=""
                  src={
                    require("../../assets/images/tourscreen/bullet1.png")
                      .default
                  }
                  alt="My Patient slide"
                />
                <span>
                View members of your practice.
                </span>
                </p>
              </div>
              </Carousel.Caption>
            </Carousel.Item>
            <Carousel.Item>
              <img
                className="d-block w-100"
                src={
                  require("../../assets/images/tourscreen/myPractice_physicianVerify_x.jpg")
                    .default
                }
                alt="My Practice slide"
              />

              <Carousel.Caption className="caption">
                <h3 className="heading">My Practice</h3>
                <div  className="detail">
                <p>
                <img
                  className=""
                  src={
                    require("../../assets/images/tourscreen/bullet1.png")
                      .default
                  }
                  alt="My Patient slide"
                />
                <span>
                View Physicians as part of your practice.
                </span>
                </p>
                <p>
                  <img
                    className=""
                    src={
                      require("../../assets/images/tourscreen/bullet2.png")
                        .default
                    }
                    alt="My Patient slide"
                  />
                  <span>
                  Use the Yes/No button to verify physician in your practice.
                  </span>
                </p>
              </div>
              </Carousel.Caption>
            </Carousel.Item>
            <Carousel.Item>
              <img
                className="d-block w-100"
                src={
                  require("../../assets/images/tourscreen/myPractice_nonPhysician_x2.jpg")
                    .default
                }
                alt="My Practice slide"
              />

              <Carousel.Caption className="caption">
                <h3 className="heading">My Practice</h3>
                <div  className="detail">
                <p>
                <img
                  className=""
                  src={
                    require("../../assets/images/tourscreen/bullet1.png")
                      .default
                  }
                  alt="My Patient slide"
                />
                <span>
                View Non Physicians as part of your practice.
                </span>
                </p>
                <p>
                  <img
                    className=""
                    src={
                      require("../../assets/images/tourscreen/bullet2.png")
                        .default
                    }
                    alt="My Patient slide"
                  />
                  <span>
                  Non Physician can also be added to your practice by clicking
                    on “Add non-physician to my practice” button.
                  </span>
                </p>
              </div>
              </Carousel.Caption>
            </Carousel.Item>
            <Carousel.Item>
              <img
                className="d-block w-100"
                src={
                  require("../../assets/images/tourscreen/myReferrals_x.jpg")
                    .default
                }
                alt="My Referrals slide"
              />

              <Carousel.Caption className="caption">
                <h3 className="heading">My Referrals</h3>
                <div  className="detail">
                <p>
                <img
                  className=""
                  src={
                    require("../../assets/images/tourscreen/bullet1.png")
                      .default
                  }
                  alt="My Patient slide"
                />
                <span>
                View Referrals associated with physicians in your referrals
                on the My Referrals tab.
                </span>
                </p>
                <p>
                  <img
                    className=""
                    src={
                      require("../../assets/images/tourscreen/bullet2.png")
                        .default
                    }
                    alt="My Patient slide"
                  />
                  <span>
                  View results by time using the dropdown.
                  </span>
                </p>
              </div>
               
              </Carousel.Caption>
            </Carousel.Item>
          </Carousel>
        </Modal.Body>
        <Modal.Footer>
          <Col style={{ textAlign: "right" }}>
            <MButton
              variant="secondary"
              type="submit"
              title="Close"
              width={"fit-content"}
              onClick={handleClose}
            />
          </Col>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default TourScreen;
