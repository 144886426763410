import { FC } from "react";
import { Form } from "react-bootstrap";
import ILookbackOption from "../../types/ILookbackOption";

const LookbackFilter: FC<{
  onChange: (e: React.ChangeEvent<any>) => void;
  options: ILookbackOption[];
  initialValue?: ILookbackOption;
  value?: number
}> = ({ onChange, options, initialValue, value }) => {
  return (
    <Form.Select
      onChange={(e) => {
        onChange(e);
      }}
      defaultValue={initialValue?.value}
      value={value}
    >
      {options.map((option, index: number) => (
        <option key={index} value={option.value}>
          {option.display}
        </option>
      ))}
    </Form.Select>
  );
};

export default LookbackFilter;
