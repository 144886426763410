import IReferralForm from "../types/IReferralFormField";
const ReferralFormStructure: IReferralForm[] = [
  {
    name: "physicianId",
    label: "On Behalf Of",
    required: true,
    type: "select",
    popover:
      "Select the physician on whose behalf you are submitting this referral.",
    belongsTo: "Referral",
    affects: "physicianName",
    // initialValue: "",
  },
  {
    name: "physicianName",
    label: "",
    required: true,
    type: "hidden",
    belongsTo: "Referral",
    dependsOn: "physicianId",
  },
  {
    name: "practiceId",
    label: "Practice",
    required: true,
    type: "select",
    popover: "Select the practice from which you are submitting this referral.",
    belongsTo: "Referral",
    affects: "practiceName",
    // initialValue: 1,
  },
  {
    name: "practiceName",
    label: "",
    required: true,
    type: "hidden",
    belongsTo: "Referral",
    dependsOn: "practiceId",
  },
  {
    name: "insuranceTypeId",
    label: "Insurance Type",
    required: true,
    type: "select",
    popover:
      "Enter the patient’s insurance type. Authorizations are required for all patients with California Children’s Services (CCS), HMO, and Medi-Cal insurance, as well as all radiology referrals. Please attach the authorization in the Attachments window.",
    belongsTo: "Referral",
    affects: "insuranceType",
    // initialValue: "",
  },
  {
    name: "insuranceType",
    label: "",
    required: true,
    type: "hidden",
    belongsTo: "Referral",
    dependsOn: "insuranceTypeId",
  },
  {
    name: "patientAcuityId",
    label: "Patient Acuity",
    required: true,
    type: "select",
    popover: "Enter the patient's acuity",
    belongsTo: "Referral",
    affects: "patientAcuity",
    initialValue: 2,
  },
  {
    name: "patientAcuity",
    label: "",
    required: true,
    type: "hidden",
    belongsTo: "Referral",
    dependsOn: "patientAcuityId",
  },
  {
    name: "requestTypeId",
    label: "Request Type",
    required: true,
    type: "select",
    popover: "Is this a new appointment or a follow-up appointment?",
    belongsTo: "Referral",
    affects: "requestType",
    initialValue: 1,
  },
  {
    name: "requestType",
    label: "",
    required: true,
    type: "hidden",
    belongsTo: "Referral",
    dependsOn: "requestTypeId",
  },
  {
    name: "reason",
    label: "Reason for Referral and/or Current Diagnosis",
    required: true,
    type: "textarea",
    popover: "Enter the reason for the referral or/and the current diagnosis.",
    belongsTo: "Referral",
  },
  {
    name: "clinicKey",
    label: "Choose A Clinic",
    required: true,
    type: "select",
    popover: "Enter the clinic to which you would like to refer this patient",
    belongsTo: "Referral",
    affects: "clinicName",
  },
  {
    name: "clinicName",
    label: "",
    required: true,
    type: "hidden",

    belongsTo: "Referral",
    dependsOn: "clinicKey",
  },
  {
    name: "firstName",
    label: "Patient First Name",
    required: true,
    type: "text",
    belongsTo: "Patient",
  },
  {
    name: "middleName",
    label: "Patient Middle Name",
    required: false,
    type: "text",
    belongsTo: "Patient",
  },
  {
    name: "lastName",
    label: "Patient Last Name",
    required: true,
    type: "text",
    belongsTo: "Patient",
  },
  {
    name: "patientSexId",
    label: "Patient Sex",
    required: true,
    type: "select",
    popover: "Select the sex of the patient.",
    belongsTo: "Patient",
    affects: "patientSex",
  },
  {
    name: "patientSex",
    label: "",
    required: true,
    type: "hidden",
    belongsTo: "Patient",
    dependsOn: "patientSexId",
  },
  {
    name: "dateOfBirth",
    label: "Patient Date Of Birth",
    required: true,
    type: "date",
    belongsTo: "Patient",
  },
  {
    name: "patientStreetAddress",
    label: "Patient Street Address",
    required: true,
    type: "text",
    belongsTo: "Patient",
  },
  {
    name: "patientCity",
    label: "Patient City",
    required: true,
    type: "text",
    belongsTo: "Patient",
  },
  {
    name: "patientState",
    label: "Patient State",
    required: true,
    type: "select",
    belongsTo: "Patient",
  },
  {
    name: "zipCode",
    label: "Patient Zip Code",
    required: true,
    type: "text",
    belongsTo: "Patient",
  },
  {
    name: "phoneNumber",
    label: "Patient or Guardian Phone",
    required: true,
    type: "text",
    belongsTo: "Patient",
  },
  {
    name: "secondaryPhoneNumber",
    label: "Patient Secondary Phone",
    required: false,
    type: "text",
    belongsTo: "Patient",
  },
  {
    name: "guardianFirstName",
    label: "Guardian First Name",
    required: true,
    type: "text",
    belongsTo: "Guardian",
  },
  {
    name: "guardianLastName",
    label: "Guardian Last Name",
    required: true,
    type: "text",
    belongsTo: "Guardian",
  },
  {
    name: "guardianDob",
    label: "Guardian Date Of Birth",
    required: true,
    type: "date",
    belongsTo: "Guardian",
  },
  {
    name: "guardianSexId",
    label: "Guardian Sex",
    required: true,
    type: "select",
    popover: "Select sex of the guardian.",
    belongsTo: "Guardian",
    affects: "guardianSex",
  },
  {
    name: "guardianRelationshipId",
    label: "Guardian Relationship",
    required: true,
    type: "select",
    popover: "Select the guardian's relationship to the patient.",
    belongsTo: "Guardian",
    affects: "guardianRelationship",
  },
  {
    name: "guardianRelationship",
    label: "",
    required: false,
    type: "hidden",
    belongsTo: "Guardian",
    dependsOn: "guardianRelationshipId",
  },
  {
    name: "guardianEmail",
    label: "Guardian Email Address",
    required: false,
    type: "email",
    belongsTo: "Guardian",
  },
  {
    name: "attachedFile",
    label: "Attachments",
    required: false,
    type: "file",
    affects: "attachmentNames",
    belongsTo: "Attachments",
  },
  {
    name: "attachmentNames",
    label: "",
    required: false,
    type: "hidden",
    dependsOn: "attachedFile",
    belongsTo: "Attachments",
  },
];

export default ReferralFormStructure;
