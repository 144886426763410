import { createContext } from "react";
import IPracticeInfo from "../types/IPracticeInfo";

interface IPracticeInfoContext {
    practiceInfo: IPracticeInfo | undefined;
    setPracticeInfo: React.Dispatch<React.SetStateAction<IPracticeInfo | undefined>>;
}

const practiceInfoContext = createContext<IPracticeInfoContext>({
    practiceInfo: undefined,
    setPracticeInfo: () => { },
});

export default practiceInfoContext;