import { FC, useState } from "react";
import { Modal, Button } from "react-bootstrap";
import MButton from "../../../mButton/MButton";
import IReferralDetailed from "../../../../types/IReferralDetailed";
import IReferral, { ReferralAppointment } from "../../../../types/IReferral";
import Loading from "../../../common/Loader";

const CancelReferralModal: FC<{
  show: boolean;
  handleClose?: () => void;
  handleRemove: (referralId: number, reason: string , referral : IReferral | IReferralDetailed) => void;
  referral?:IReferral | IReferralDetailed | null;
  clinicName: string;
  referralId: number | null;
  Loader?:boolean
}> = ({ show, handleClose, handleRemove, clinicName, referralId,referral ,Loader}) => {
  const [reason, setReason] = useState("");
  const handleOnSubmit = (e: React.ChangeEvent<any>) => {
    e.preventDefault();
    if (referralId !== null && referral!= null ) {
      handleRemove(referralId, reason, referral);
    }
  };

  return (
    <Modal show={show} onHide={handleClose} centered>
      <form onSubmit={handleOnSubmit}>
        {/* <Modal.Header>Cancel {clinicName} Clinic Referral</Modal.Header> */}
        <Modal.Header>Cancel Referral</Modal.Header>
        <Modal.Body>
          <p>Are you sure you want to cancel this referral?</p>
          <input
            className="form-control"
            type="text"
            placeholder="Cancellation reason*"
            required
            onChange={(e) => setReason(e.target.value)}
          />
          <br />
        </Modal.Body>
        <Modal.Footer>
         <MButton
            title="Remove"
            variant="danger"
            type="submit"
            width="max-content"
            isSubmitting = {Loader}
          />
          <MButton
            title="Cancel"
            variant="secondary"
            onClick={handleClose}
            width="max-content"
          />
        </Modal.Footer>
      </form>
    </Modal>
  );
};

export default CancelReferralModal;
