import { FC, Suspense, useContext, useEffect, useState } from "react";
import { Route, Switch, useHistory, withRouter } from "react-router-dom";
import { Security, SecureRoute, LoginCallback } from "@okta/okta-react";
import { OktaAuth, toRelativeUrl } from "@okta/okta-auth-js";
import Login from "../auth/Login";
import { OKTA_AUTH_CONFIG } from "../../config";
import Home from "../pages/home/Home";
import Navbar from "../navbars/Navbar";
import Footer from "../footer/Footer";
import Breadcrumbs from "../breadcrumbs/Breadcrumbs";
import { getAllRoutes } from "../../helpers/RouteHelper";
import DisplayBanner from "../common/DisplayBanner";
import NewPracticeRequest from "../pages/allAccounts/NewPracticeRequest";
import UserPrivacyPolicy from "../pages/home/UserPrivacyPolicy";
import TermsOfUse from "../pages/home/TermsOfUse";
import Spinner from "../spinner/Spinner";
import LoginAsBanner from "../common/LoginAsBanner";
import { ErrorBoundary } from "react-error-boundary";
import { Alert } from "@mui/material";

// import menuItemsContext from "../../context/MenuItemsContext";

const oktaAuth = new OktaAuth(OKTA_AUTH_CONFIG);
const ErrorFallback = (error: any) => {
  return (
    <Alert severity="error" style={{ color: "#EA1D36" }}>
      {console.log('error',error)}
      {"Some Error Occured. Please refresh. If Error is persistent contanct admin."}
    </Alert>
  );
};

const AppWithRouterAccess: FC = () => {
  const history = useHistory();

  const customAuthHandler = () => {
    history.push("/login");
  };

  const restoreOriginalUri = async (_oktaAuth: any, originalUri: any) => {
    history.replace(toRelativeUrl(originalUri || "/", window.location.origin));
  };



  return (
    <Security
      {...OKTA_AUTH_CONFIG}
      oktaAuth={oktaAuth}
      onAuthRequired={customAuthHandler}
      restoreOriginalUri={restoreOriginalUri}
    >
      <div className="page-container" style={{ fontFamily: "Poppins" }}>
      <ErrorBoundary FallbackComponent={ErrorFallback}>
        <LoginAsBanner/>
        </ErrorBoundary>
        <ErrorBoundary FallbackComponent={ErrorFallback}>
        <DisplayBanner />
        </ErrorBoundary>
        <ErrorBoundary FallbackComponent={ErrorFallback}>
        <Navbar />
        </ErrorBoundary>
        {/* <Suspense fallback={<div>Loading...</div>}> */}

        <div className="content-wrap">
        <ErrorBoundary FallbackComponent={ErrorFallback}>
          <Switch>
            <Route path="/implicit/callback" component={LoginCallback} />
            <Route path="/accountsandaccess/newpracticerequest" exact render={() => <NewPracticeRequest />} />
            <Route path="/home/privacypolicy" exact render={() => <UserPrivacyPolicy />} />
            <Route path="/home/terms" exact render={() => <TermsOfUse />} />
            <Route path="/login" exact component={() => <Login />} />
            <Route path="/" exact render={() => <Home />} />

            {getAllRoutes().map((route, index) => {
              return (
                <SecureRoute
                  key={index}
                  exact={route.exact}
                  path={route.path}
                  render={(props: any) => {
                    // const crumbs = getAllRoutes()
                    //   .filter((r) => props.match.path.includes(r.path))
                    //   .map(({ path, ...rest }) => ({
                    //     path: Object.keys(props.match.params).length
                    //       ? Object.keys(props.match.params).reduce(
                    //           (path, param) =>
                    //             path.replace(
                    //               `:${param}`,
                    //               props.match.params[param]
                    //             ),
                    //           path
                    //         )
                    //       : path,
                    //     ...rest,
                    //   }));
                    const Element = route?.render;
                    if (Element !== undefined) {
                      return (
                        <>
                          <Suspense fallback={<Spinner margin={'5rem 0'} />} >
                            {/* <Breadcrumbs crumbs={crumbs} /> */}
                            <Element {...props} name={route.title} variant={route.variant} />
                          </Suspense>
                        </>
                      );
                    }
                    return <></>;
                  }}
                />
              );
            })}
            <Route
              path="*"
              render={() => {
                return <h1>404</h1>;
              }}
            />
          </Switch>
          </ErrorBoundary>
        </div>

        {/* </Suspense> */}

        <Footer />
      </div>
    </Security>
  );
};

export default AppWithRouterAccess;
