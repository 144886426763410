import React from "react";
import { FC, useEffect, useState } from "react";
import HeaderName from "../../../headerName/HeaderName";
import { Form, Col, Button, Alert as BootstrapAlert } from "react-bootstrap";
import DateHelper, { getFormattedDate, getFormattedDateFromDateTime, getFormattedDateTimeFromDateTime, getFormattedTimeFromDateTime } from "../../../../helpers/DateHelper";
import CustomAlert from "../../../common/CustomAlert";
import { useOktaAuth } from "@okta/okta-react";
import AnnouncementService from "../../../../services/AnnouncementService";
import { Redirect, useHistory, useLocation } from "react-router-dom";
import { Alert } from "@mui/material";
import DateTimeInput from "../../../common/DateTimeInput";
import MButton from "../../../mButton/MButton";
import IAnnouncementBanner from "../../../../types/IAnnouncementBanner";
import BackButton from "../../../backButton/BackButton";
import Loader from "../../../common/Loader";
import { appInsights } from "../../../../appInsights";
import axios from "axios";
const NewFomAnnouncement: FC<{
  name?: string;
}> = ({ name }) => {
  const [alertType, setAlertType] = useState<any>("");
  const [error, setError] = useState<Error | null>(null);
  const [data, setData] = useState<IAnnouncementBanner>({
    
    message: "",
    cssClassId: 0,
    validFrom: getFormattedDateTimeFromDateTime(new Date()),
    validTo: getFormattedDateTimeFromDateTime(new Date()),
    modifiedOn: "",
    modifiedBy: ""
  });
  const [message, setMessage] = useState("");
  const { authState } = useOktaAuth();
  const accessToken = authState?.accessToken?.accessToken;
  const [redirect, setRedirect] = useState({ redirect: false, message: "" });
  const MAX_DATE = getFormattedDate(new Date());
  const [loading, setLoading] = useState(false);

  const handleOnChange = (e: any, key: string) => {
    let value: any;
    if (key === "validFrom" || key === "validTo") {
      value = e;
    } else {
      value = e.target.value.trim();
      if (key === "message") {
        value = e.target.value.trimStart();
        setMessage(value);
      } else if (key === "cssClassId") {
        value = parseInt(e.target.value.trim());
        setAlertType(value);
      }
    }


    setData((prev) => ({ ...prev, [key]: value }));
  };

  const handleSubmit = (e: any) => {
    e.preventDefault();
    var from = new Date(data.validFrom);
    var to = new Date(data.validTo);
    {window.scrollTo(0, 0)}
    if(to.getTime() > from.getTime()){
     
      setLoading(true)
      AnnouncementService.createAnnouncementServiceRequest(data, accessToken)
      .then((response) => {
        setRedirect({ redirect: false, message: "success" });
        setTimeout(() => {
          setLoading(false)
          setRedirect({ redirect: true, message: "" });
        }, 3000);
      })
      .catch((err) => {
        if (!axios.isCancel(e)) {
          console.error(e);
          appInsights.trackException({ error: e });

        }
        setLoading(false)
        setRedirect({ redirect: false, message: "error" });
      });
    }
    else{
      setRedirect({ redirect: false, message: "Date and Time of 'To' should be greater than 'From'" });
    }
    
  };

  if (error) {
    return (
      <div style={{ margin: "1rem" }}>
        <CustomAlert isSuccessful={false} message={error.message} />
      </div>
    );
  }

  if (redirect.redirect) {
    return (
      <Redirect
        to={{
          pathname: "/portalupdates/announcementbanner",
          state: { message: redirect.message },
        }}
      />
    );
  } else {
    return (
      <div className="referrals">
        <HeaderName name={name} />
        <BackButton  />
        {redirect.message === "success" ? (
          <>
            {window.scrollTo(0, 0)}
            <Alert severity="success">
              Form is successfully submitted,please wait while we are
              redirecting!
            </Alert>
          </>
        ) : redirect.message === "error" ? (
          <>
            <Alert severity="error" style={{ color: "#EA1D36" }}>
              Error in submitting the form, please try again!
            </Alert>
          </>
        ) : redirect.message ? (
          <>
            <Alert severity="error" style={{ color: "#EA1D36" }}>
              {redirect.message}
            </Alert>
          </>
        ) : (
          <></>
        )}
        <div className="container">
          <h2 className="heading-main">New Announcement </h2>
          <div className="d-flex row justify-content-end"></div>

          <div className="referral">
            <form className="m-sm-5" onSubmit={handleSubmit}>
              <Form.Group className="d-flex row align-items-center form-padding">
                <Form.Label column sm={3} className="pl-0 pr-0">
                  Message <span className="p-0 asterik"> *</span>
                </Form.Label>

                <Col sm={9} className="pl-0 pr-0">
                  <Form.Control
                    onChange={(e) => handleOnChange(e, "message")}
                    placeholder=""
                    required
                    value={message}
                  />
                </Col>
              </Form.Group>
              <Form.Group className="d-flex row align-items-center form-padding">
                <Form.Label column sm={3} className="pl-0 pr-0">
                  Type <span className="p-0 asterik"> *</span>
                </Form.Label>

                <Col sm={9} className="pl-0 pr-0">
                  <Form.Select
                    onChange={(e) => handleOnChange(e, "cssClassId")}

                    required
                    placeholder=""
                  >
                    <option>Please make a selection</option>
                    <option value="1">success</option>
                    <option value="2">info</option>
                    <option value="3">danger</option>
                    <option value="4">warning</option>
                  </Form.Select>
                </Col>
              </Form.Group>

              <DateTimeInput
                label="Valid From"
                onChange={(e) => handleOnChange(e, "validFrom")}
                defaultDate={getFormattedDateFromDateTime(data.validFrom)}
                defaultTime={getFormattedTimeFromDateTime(data.validFrom)}
                from={getFormattedDateFromDateTime(data.validFrom)}
              />
              <DateTimeInput
                label="Valid To"
                onChange={(e) => handleOnChange(e, "validTo")}
                defaultDate={getFormattedDateFromDateTime(data.validTo)}
                defaultTime={getFormattedTimeFromDateTime(data.validTo)}
                from={getFormattedDateFromDateTime(data.validFrom)}
              />
              <Form.Group className="d-flex row align-items-center form-padding">
                <Form.Label column sm={3} className="pl-0 pr-0">
                  Preview
                </Form.Label>

                <Col sm={9} className="pl-0 pr-0">
                  <BootstrapAlert
                    variant={
                      alertType === 1
                        ? "success"
                        : alertType === 2
                        ? "info"
                        : alertType === 3
                        ? "danger"
                        : alertType === 4
                        ? "warning"
                        : ""
                    }
                  >
                    {alertType === 1
                      ? message
                      : alertType === 2
                      ? message
                      : alertType === 3
                      ? message
                      : alertType === 4
                      ? message
                      : ""}
                  </BootstrapAlert>
                </Col>
              </Form.Group>
              <Form.Group className="d-flex row">
                <Col
                  sm={{ span: 7, offset: 3 }}
                  className="d-flex justify-content-center"
                >
                  <MButton
                    variant="primary"
                    disabled={loading}
                    title="Save"
                    width={"fit-content"}
                    type="submit"
                  >
                    Save
                  </MButton>
                </Col>
                <Col
          sm={{ span: 7, offset: 3 }}
          className="d-flex justify-content-center"
        >
          {loading && <Loader />}
        </Col>
              </Form.Group>
            </form>
          </div>
        </div>
      </div>
    );
  }
};

export default NewFomAnnouncement;
