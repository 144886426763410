import { FC, useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import LookBackFilter from "../../common/LookBackFilter";
import { Button, Table } from "react-bootstrap";
import SearchBar from "../../common/SearchBar";
import "../../styles.css";
import CustomAlert from "../../common/CustomAlert";
import ReferralList from "./components/ReferralList";
import ReferralCard from "./components/ReferralCard";
import IRouteState from "../../../types/IRouteState";
import CustomPagination from "../../common/CustomPagination";
import ILookbackOption from "../../../types/ILookbackOption";
import ReferralService from "../../../services/ReferralService";
import { useOktaAuth } from "@okta/okta-react";
import IReferral, { ReferralAppointment } from "../../../types/IReferral";
import IReferralQuery from "../../../types/IReferralQuery";
import Loader from "../../common/Loader";
import CancelReferralModal from "./components/CancelReferralModal";
import CancellationRequestModal from "./components/CancellationRequestModal";
import CancellationReasonModal from "./components/CancellationReasonModal";
import axios from "axios";
import { appInsights } from "../../../appInsights";
import MButton from "../../mButton/MButton";
import useSessionState from "../../../hooks/useSessionState";
import ScheduledReasonModal from "./components/ScheduledReasonModal";
import IReferralDetailed from "../../../types/IReferralDetailed";

interface Props {
  name?: string;
}

type modalType = {
  show: boolean;
  reason: string | null;
};

type cancelModalType = {
  show: boolean;
  clinic: string;
  referralId: null | number;
  referral?: IReferral | IReferralDetailed | null;
};
type cancleRequestType = {
  show: boolean;
  name?: string;
  date?: Date;
  reason?: string;
};
type appointmentModalType = {
  show: boolean;
  reason: ReferralAppointment;
};
const lookBackOptions: ILookbackOption[] = require("../../../data/referralLookbackOptions.json");

const ViewReferrals: FC<Props> = ({ name }) => {
  const isBigScreen = window.innerWidth > 1000;
  const appointmentDetail = {
    aptDate: "",
    duration: "",
    type: "",
    location: "",
    resource: "",
    state: "",
  };
  const pageSize = 10;
  const [message, setMessage] = useState(false);
  const { authState } = useOktaAuth();
  const accessToken = authState?.accessToken?.accessToken;
  const [referrals, setReferrals] = useState<IReferral[]>();
  const [referral, setReferral] = useState<IReferral[] | IReferralDetailed[]>();
  const [listedReferrals, setListedReferrals] = useState<IReferral[]>();
  const [pages, setPages] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [s,setS] = useState(false);
  const [maxPages, setMaxPages] = useState(0);
  const [loading, setLoading] = useState(true);
  const [Bloading,setBloading] = useState(false);
  const [lookback, setLookback] = useState(lookBackOptions[0].value);
  const [searchText, setSearchText] = useSessionState(
    "viewreferrals_state",
    ""
  );

  const [showCancelReferralModal, setShowCancelReferralModal] =
    useState<cancelModalType>({
      show: false,
      clinic: "",
      referralId: null,
    });
  const [showCancelReasonModal, setShowCancelReasonModal] = useState<modalType>(
    {
      show: false,
      reason: null,
    }
  );
  const [showCancelRequestModal, setShowCancelRequestModal] =
    useState<cancleRequestType>({
      show: false,
      name: "",
      reason: "",
    });
  const [showScheduleReasonModal, setShowScheduleReasonModal] =
    useState<appointmentModalType>({
      show: false,
      reason: appointmentDetail,
    });
  const [query, setQuery] = useState<IReferralQuery>({
    sortfield: "ID",
    sortdirection: "DESC",
    lookback: 30,
  });
  const [apiCallCount, setApiCallCount] = useState(0);
  const [error, setError] = useState<Error>();
  const history = useHistory();
  const location = useLocation<IRouteState>();

  useEffect(() => {
    const cancelToken = axios.CancelToken.source();
    if (location.state !== undefined) {
      const state = { ...location.state };
      let msg = true;
      if (state.message !== undefined && apiCallCount > 0) {
        delete state.message;
        msg = false;
        history.replace({ state: state });
      }
      setMessage(msg);
    }
    setLoading(true);
    getReferral();
    return () => {
      cancelToken.cancel();
    };
  }, [apiCallCount]);

  useEffect(() => {
    handleSearchReferrals();
  }, [query.searchterm]);

  useEffect(() => {
    handleSortReferrals(listedReferrals);
  }, [query.sortfield, query.sortdirection]);

  const handleSearch = (searchText: string) => {
    setQuery({
      ...query,
      searchterm: searchText,
      lookback: -1,
    });
  };
 const getReferral = () =>{
  setListedReferrals([]);
  setLoading(true);
  const cancelToken = axios.CancelToken.source();
  ReferralService.getReferrals(query, accessToken, cancelToken)
  .then((response) => {
    if (error !== undefined) {
      setError(undefined);
    }
    setReferrals(response.data);
    setListedReferrals(response.data);
    setLoading(false);
    toggleCancelReferralModal(false, "", null);
  })
  .catch((e) => {
    if (!axios.isCancel(e)) {
      console.error(e);
      appInsights.trackException({ error: e });
      setError(e);
      setLoading(false);
      toggleCancelReferralModal(false, "", null);
    }
  });
 }
  const handleLookBack = (e: React.ChangeEvent<any>) => {
    setLookback(e.target.value);
    setQuery((q) => ({ ...q, lookback: e.target.value }));
    setReferrals(undefined);
    setApiCallCount((c) => c + 1);
    window.scrollTo(0, 0);
  };
  const handleReferPatientClick = (referral: IReferral) => {
    history.push("/referrals/re-refer", referral);
  };
  const handleCancelledClick = (reason: string) => {
    toggleCancelReasonModal(true, reason);
  };
  const handleScheduledClick = (reason: ReferralAppointment) => {
    toggleScheduleReasonModal(true, reason);
  };
  const handleReferralQuery = (referralQuery: IReferralQuery) => {
    setQuery(referralQuery);
  };

  const newReferral = () => {
    let path = `/referrals/newreferral`;
    history.push(path);
  };
  const toggleCancelRequestModal = (
    show: boolean,
    name?: string,
    date?: Date,
    reason?: string
  ) => {
   // console.log(show, name, reason, date);
    setShowCancelRequestModal({
      show: show,
      name: name,
      date: date,
      reason: reason,
    });
  };
  const toggleCancelReferralModal = (
    show: boolean,
    clinicName: string,
    id: number | null,
    referral?: IReferral | IReferralDetailed
  ) => {
    //setReferral(referral);
    //console.log("referral",referral)
    setShowCancelReferralModal({
      show: show,
      clinic: clinicName,
      referralId: id,
      referral: referral,
    });
  };
  const toggleCancelReasonModal = (show: boolean, reason: string | null) => {
    setShowCancelReasonModal({
      show: show,
      reason: reason,
    });
  };
  const toggleScheduleReasonModal = (
    show: boolean,
    reason: ReferralAppointment
  ) => {
    setShowScheduleReasonModal({
      show: show,
      reason: reason,
    });
  };

  const handleRemoveClick = (
    id: number,
    reason: string,
    referral?: IReferral | IReferralDetailed
  ) => {
    setBloading(true);
    toggleCancelReferralModal(true, "", id);
    if (referral != undefined) {
      referral.cancellationReason = reason;
    }
    //console.log("refe", referral);
    ReferralService.cancelReferral(
      {
        id: id,
        reason: reason,
        referral: referral,
      },
      accessToken,
      referral
      // showCancelReferralModal.referral
    )
      .then((response) => {
        if (error !== undefined) {
          setError(undefined);
        }
        setBloading(false)
        toggleCancelReferralModal(false, "", null);
        setReferrals(undefined);
        getReferral();
        //setS(true);
      })
      .catch((e) => {
        if (!axios.isCancel(e)) {
          // console.error(e);
          e.message = "Cannot process cancellation at the moment.";
          appInsights.trackException({ error: e });
          setError(e);
          setBloading(false);
          toggleCancelReferralModal(false, "", id);
        }
      });
  };

  const comp = (a: IReferral, b: IReferral): number => {
    if (query.sortfield === "PatientFullName") {
      if (query.sortdirection === "DESC") {
        return (
          (a.firstName != null || b.firstName != null) ? a.firstName
            .toUpperCase()
            .localeCompare(b.firstName.toLocaleLowerCase()) - 1
        :a.firstName);
      }
      if(query.sortdirection === "ASC") {
        return (
          (a.firstName != null || b.firstName != null) ? b.firstName
            .toUpperCase()
            .localeCompare(a.firstName.toLocaleLowerCase()) - 1
        :b.firstName);
      }
     
    }

    if (query.sortfield === "PatientBirthDate") {
      let patientOneDOB = new Date(a.dateOfBirth);
      let patientTwoDOB = new Date(b.dateOfBirth);
      if (query.sortdirection === "DESC") {
        return (patientOneDOB.getTime() - patientTwoDOB.getTime())
      }
      if (query.sortdirection === "ASC") {
        return (patientTwoDOB.getTime() - patientOneDOB.getTime())
      }

    }

    if (query.sortfield === "CreatedDateTime") {
      let patientOneCreatedOn = new Date(a.createdDtm);
      let patientTwoCreatedOn = new Date(b.createdDtm);
      if (query.sortdirection === "DESC") {
        return (patientOneCreatedOn.getTime() - patientTwoCreatedOn.getTime())
      }
      if (query.sortdirection === "ASC") {
        return (patientTwoCreatedOn.getTime() - patientOneCreatedOn.getTime())
      }
    }

    if (query.sortfield === "PhysicianFullName") {
       if (query.sortdirection === "DESC") {
        a.physicianName = a.physicianName || '';
        b.physicianName = b.physicianName || '';
        return a.physicianName.localeCompare(b.physicianName);
      }
      if(query.sortdirection === "ASC") {
        a.physicianName = a.physicianName || '';
        b.physicianName = b.physicianName || '';
        return b.physicianName.localeCompare(a.physicianName);
      }
    }

    return 0;
  };
  const handleSortReferrals = (referrals: IReferral[] | undefined) => {
    if (referrals == undefined) {
      return;
    }
    //console.log("got here");
    referrals.sort(comp);
    setListedReferrals(referrals);
    setReferrals(referrals);
  };

  const handleSearchReferrals = () => {
    const querystring = searchText.replace(/\s+/g, "").toUpperCase();
    //console.log(searchText);

    if (referrals == undefined) {
      return;
    }
    if (querystring === undefined || querystring === "") {
      setListedReferrals(referrals);
    }

    const tempReferrals: IReferral[] = referrals.filter(
      (element) =>
      element.firstName!= null ? element.firstName.toUpperCase().includes(querystring) ||
        element.lastName.toUpperCase().includes(querystring) ||
        (element.firstName + element.lastName)
          .toUpperCase()
          .includes(querystring):null
    );

    setListedReferrals([]);
    setTimeout(() => {
      setListedReferrals(tempReferrals);
    }, 10);
  };

  return (
    <>
      {/* <HeaderName name={name} /> */}
      <div className="container">
        <h2 className="heading-main">{name}</h2>
        <div className="row">
          <ul
            className="col-sm-5 col-lg-10 mb-3"
            style={{
              marginLeft: "1.5rem",
              listStylePosition: "outside",
              listStyleType: "disc",
            }}
          >
            <li>
              Please call 888-631-2452 with any questions about your referral
            </li>
            <li>
              If your referral has been cancelled, click on the Cancelled label
              to view the cancellation reason
            </li>
            <li>
              If needed, you may fax additional information about your referral
              after it has been submitted to 323-361-8988. On the cover sheet,
              please include the patient’s name, date of birth, and the clinic
              where the patient is being referred
            </li>
            <li>
              If you need to cancel your referral, please call 888-631-2452.
            </li>
          </ul>
          <div
            className="col-sm-5 col-lg-2 mb-3"
            style={{
              width: "100%",
              display: "flex",
              flexDirection: "row-reverse",
            }}
          >
            <MButton
              onClick={newReferral}
              title="Add New Referral"
              variant="primary"
              width="max-content"
            />
          </div>
        </div>
        {error !== undefined ? (
          <CustomAlert isSuccessful={false} message={error.message} />
        ) : null}
        {message && location.state && location.state.message !== undefined ? (
          <CustomAlert
            isSuccessful={location.state.message === "success"}
            message={
              location.state.message === "success"
                ? "Success! Thank you for your referral."
                : "Something went wrong. Please try submitting your referral again."
            }
          />
        ) : null}
        <div className="chart">
          <div className="d-flex row justify-content-between">
            <div className="col-sm-5 col-lg-4 col-xl-3 mb-3">
              <LookBackFilter
                onChange={handleLookBack}
                options={lookBackOptions}
                value={lookback}
              />
            </div>
            {loading ? (
              <div className="col-sm-1 Loader mb-3">
                <Loader />
              </div>
            ) : null}
            <div className="col-sm-5 col-lg-4 col-xl-3 mb-3">
              <SearchBar
                key={referrals?.length}
                placeholder="Search by Patient Name"
                onSearch={() => {
                  setQuery((q) => ({
                    ...q,
                    searchterm: searchText,
                  }));
                  // setReferrals(undefined);
                  // setApiCallCount((c) => c + 1);
                  window.scrollTo(0, 0);
                }}
                onTextChange={(text) => {
                  if (text == "") {
                    if (referrals !== undefined) {
                      setQuery((q) => ({
                        ...q,
                        searchterm: "",
                      }));
                      // setReferrals(undefined);
                      // setApiCallCount((c) => c + 1);
                      window.scrollTo(0, 0);
                    }
                  }
                  setSearchText(text);
                }}
              />
            </div>
          </div>
          {listedReferrals !== undefined ? (
            <div>
              {listedReferrals.length === 0 ? (
                !loading ? (
                  <div style={{ textAlign: "center" }}>
                    No Record Found within{" "}
                    {query.lookback == -1
                      ? "all records available"
                      : `past ${query.lookback} days`}
                  </div>
                ) : null
              ) : isBigScreen ? (
                <ReferralList
                  key={listedReferrals.length}
                  referrals={listedReferrals ?? []}
                  query={query}
                  Loader={loading}
                  modifyQuery={handleReferralQuery}
                  onCancelPress={toggleCancelReferralModal}
                  onCancelRequestPress={toggleCancelRequestModal}
                  onReferPress={handleReferPatientClick}
                  onCancelledPress={handleCancelledClick}
                  onScheduledPress={handleScheduledClick}
                />
              ) : (
                <ReferralCard
                  key={listedReferrals.length}
                  referrals={listedReferrals ?? []}
                  onCancelPress={toggleCancelReferralModal}
                  onCancelRequestPress={toggleCancelRequestModal}
                  onReferPress={handleReferPatientClick}
                  onCancelledPress={handleCancelledClick}
                  onScheduledPress={handleScheduledClick}
                  Loader={loading}
                />
              )}
            </div>
          ) : null}
        </div>
      </div>
      <CancelReferralModal

        show={showCancelReferralModal.show}
        referral={showCancelReferralModal.referral}
        clinicName={showCancelReferralModal.clinic}
        referralId={showCancelReferralModal.referralId}
        handleClose={() => toggleCancelReferralModal(false, "", null)}
        handleRemove={handleRemoveClick}
        Loader={Bloading}
      />
      <CancellationRequestModal
        show={showCancelRequestModal.show}
        name={showCancelRequestModal.name}
        date={showCancelRequestModal.date}
        reason={showCancelRequestModal.reason}
        handleClose={() => {
          setShowCancelRequestModal({
            show: false,
            name: "",
            //date: new Date('December 17, 1995 03:24:00'),
            reason: "",
          });
        }}
      />
      <CancellationReasonModal
        show={showCancelReasonModal.show}
        reason={showCancelReasonModal.reason}
        handleClose={() => toggleCancelReasonModal(false, null)}
      />
      <ScheduledReasonModal
        show={showScheduleReasonModal.show}
        reason={showScheduleReasonModal.reason}
        handleClose={() => toggleScheduleReasonModal(false, appointmentDetail)}
      />
    </>
  );
};

export default ViewReferrals;
