import { useEffect, useState } from "react";
import IMenuItem from "../types/IMenuItem";

type KEY = "menu_items";

const getMenuItemsFromSessionStorage = (
  key: KEY,
  initialValue: IMenuItem[] | undefined
) => {
  const savedValue = localStorage.getItem(key);
  const savedValueJSON = savedValue && JSON.parse(savedValue);
  if (savedValueJSON) return savedValueJSON;
  if (initialValue instanceof Function) return initialValue();
  return initialValue;
};

const useHomeData = (key: KEY = "menu_items", initialValue?: IMenuItem[]) => {
  const [menuItems, setMenuItems] = useState<IMenuItem[] | undefined>(() => {
    return getMenuItemsFromSessionStorage(key, initialValue);
  });
  useEffect(() => {
    if (menuItems !== undefined) {
      localStorage.setItem(key, JSON.stringify(menuItems));
    }
  }, [menuItems]);
  return [menuItems, setMenuItems] as const;
};

export default useHomeData;
