import { FC, useState, useEffect } from "react";
import IPatientAccessRequest from "../../../../types/IPatientAccessRequest";
import IPatientAccessQuery from "../../../../types/IPatientAccessQuery";
import { useHistory } from "react-router-dom";
import { setRecentSearch } from "../../../../helpers/RecentSearchHelper";
import {
  getFormattedDateFromDateTime,
  getFormattedTimeFromDateTime,
  getFormattedDateFromDate,
  getFormattedDate,
  getFormattedDateTime,
} from "../../../../helpers/DateHelper";
import { MDBDataTable, MDBTable, MDBTableBody, MDBTableHead } from "mdbreact";
import "./patient-access-requestlist.css";
import "@fortawesome/fontawesome-free/css/all.min.css";
// import 'mdbreact/dist/css/mdb.css';

type stateType = {
  state: {
    patientAccess: IPatientAccessRequest;
  };
};

const PatientAccessRequestList: FC<{
  patientAccessRequest: IPatientAccessRequest[];
  query: IPatientAccessQuery;
}> = ({ patientAccessRequest, query }) => {
  const history = useHistory<stateType>();
  const [tableRows, setTableRows] = useState([]);
  const [sortDirection, setSortDiection] = useState("desc");
  const [sortKey, setSortKey] = useState("");
  useEffect(() => {
    if (patientAccessRequest) {
      setTableRows(assembleData());
    }
  }, [patientAccessRequest]);
  useEffect(() => {
    if (patientAccessRequest) {
      setTableRows(assembleData());
    }
  }, [sortDirection]);

  const onPatientCardClick = (patientAccess: IPatientAccessRequest) => {
    if (
      patientAccess.patientFirstName != null &&
      patientAccess.patientLastName != null
    ) {
      let searchedItem = {
        name: `${patientAccess.patientFirstName}, ${patientAccess.patientLastName}`,
        dob: patientAccess.patientDob,
        id: patientAccess.patientAccessRequestID,
        status: patientAccess.requestStatus,
      };
      history.push(
        `/patients/patientaccessrequests/details/${patientAccess.patientAccessRequestID}`,
        { state: { patientAccess } }
      );
    }
  };

  const assembleData: any = () => {
    let patientsArray = patientAccessRequest;
    if (sortKey === "patientAccessRequestID") {
      if (sortDirection == "asc") {
        patientsArray = patientAccessRequest.sort(function (a, b) {
          return a.patientAccessRequestID - b.patientAccessRequestID;
        });
      } else {
        patientsArray = patientAccessRequest.sort(function (a, b) {
          return b.patientAccessRequestID - a.patientAccessRequestID;
        });
      }
    }

    let patients = patientsArray.map((patientAccess) => {
      return {
        patientAccessRequestID: (
          <>
            {" "}
            <div
              style={{ padding: "6px 2px 6px 2px" }}
              id="table-text-referrals"
              onClick={() => onPatientCardClick(patientAccess)}
            >
              {patientAccess.patientAccessRequestID}
            </div>
          </>
        ),

        patientFirstName:
          patientAccess.patientFirstName != null
            ? patientAccess.patientFirstName.trim()
            : null,
        patientLastName:
          patientAccess.patientLastName != null
            ? patientAccess.patientLastName.trim()
            : null,
        patientDob: `${patientAccess.patientDob}`,
        requestStatus: patientAccess.requestStatus,
        onBehalfPhysicianName: patientAccess.onBehalfPhysicianName,
        createdBy: patientAccess.createdBy,

        createdOn:
          patientAccess.createdOn != null
            ? `${patientAccess?.createdOn.split("T")[0]} ${
                patientAccess?.createdOn.split("T")[1]
              }`
            : null,
        modifiedOn:
          patientAccess.modifiedOn != null
            ? `${patientAccess?.modifiedOn.split("T")[0]} ${
                patientAccess?.modifiedOn.split("T")[1]
              }`
            : null,
      };
    });
    return patients;
  };

  const data = {
    columns: [
      {
        label: "ID",
        field: "patientAccessRequestID",
        sort: "asc",
        width: 150,
      },
      {
        label: "First Name",
        field: "patientFirstName",
        sort: "asc",
        width: 150,
      },
      {
        label: "Last Name",
        field: "patientLastName",
        sort: "asc",
        width: 150,
      },
      {
        label: "DOB",
        field: "patientDob",
        sort: "asc",
        width: 100,
      },
      {
        label: "Status",
        field: "requestStatus",
        sort: "asc",
        width: 100,
      },
      {
        label: "On Behalf Of",
        field: "onBehalfPhysicianName",
        sort: "disabled",
        width: 150,
      },
      {
        label: "Submitted By",
        field: "createdBy",
        sort: "disabled",
        width: 150,
      },
      {
        label: "Requested On",
        field: "createdOn",
        sort: "disabled",
        width: 150,
      },
      {
        label: "Last Modified",
        field: "modifiedOn",
        sort: "disabled",
        width: 150,
      },
    ],
    rows: tableRows,
  };

  return (
    <MDBDataTable
      responsive
      key={data.rows.length}
      small
      data={data}
      id="responsive-table"
      sorting={true}
      onSort={(e) => {
        if (e.column === "patientAccessRequestID") {
          setSortDiection(e.direction);
          setSortKey(e.column);
        } else {
          setSortDiection("desc");
          setSortKey("");
        }
      }}
      searching={false}
    />
  );
};

export default PatientAccessRequestList;
