import { useEffect, useState } from "react";
import IPracticeInfo from "../types/IPracticeInfo";

type KEY = "practice_info";

const getpracticeInfoFromSessionStorage = (
  key: KEY,
  initialValue: IPracticeInfo | undefined
) => {
  const savedValue = localStorage.getItem(key);
  const savedValueJSON = savedValue && JSON.parse(savedValue);
  if (savedValueJSON) return savedValueJSON;
  if (initialValue instanceof Function) return initialValue();
  return initialValue;
};

const usePracticeInfo = (
  key: KEY = "practice_info",
  initialValue?: IPracticeInfo
) => {
  const [practiceInfo, setPracticeInfo] = useState<IPracticeInfo>(() => {
    return getpracticeInfoFromSessionStorage(key, initialValue);
  });
  useEffect(() => {
    if (practiceInfo !== undefined) {
      try {
        localStorage.setItem(key, JSON.stringify(practiceInfo));
      } catch (e) {
        alert(e);
        throw e;
      } finally {
        return;
      }
    }
  }, [practiceInfo]);
  return [practiceInfo, setPracticeInfo] as const;
};

export default usePracticeInfo;
