import { useEffect, useState } from "react";
import MButton from "../../mButton/MButton";
import SearchBar from "../../common/SearchBar";
import Loader from "../../common/Loader";
import PhysicianService from "../../../services/PhysicianService";
import IPhysicianSearchResult from "../../../types/IPhysicianSearchResult";
import { useHistory } from "react-router-dom";
import { useOktaAuth } from "@okta/okta-react";
import axios from "axios";
import { Form } from "react-bootstrap";
import AdminPhysicianResult from "./components/AdminPhysicianResult";
import { appInsights } from "../../../appInsights";

const AdminPhysicianSection = () => {
  const [searchText, setSearchText] = useState("");
  const [physicianSearchResults, setPhysicianSearchResults] =
    useState<IPhysicianSearchResult[]>();
  const [serachCount, setSearchCount] = useState(0);
  const [checkBox, setCheckBox] = useState(false);
  const [loading, setLoading] = useState(false);
  const { authState } = useOktaAuth();
  const accessToken = authState?.accessToken?.accessToken;
  const cancelToken = axios.CancelToken.source();
  const history = useHistory();

  const handleClearResultsClick = () => {
    setPhysicianSearchResults(undefined);
  };

  const handleAddClick = () => {
    history.push("/accounts&access/physicians&practices/create/physician");
  };

  useEffect(() => {
    if (searchText.length >= 3) {
      setLoading(true);

      PhysicianService.searchPhysician(searchText, accessToken, cancelToken)
        .then((response) => {
          setPhysicianSearchResults(response.data);
          setLoading(false);
        })
        .catch((e: Error) => {
          if (axios.isCancel(e)) {
            // console.error(e);
            console.log("searchText",searchText);
            appInsights.trackException({ error: e });
            // setError(e);
          }
          else{
          setLoading(false);
          }
        });
    }
    else{
      
        setLoading(false);

    }
    return () => {
      cancelToken.cancel();
    };
  }, [searchText, serachCount]);

  const filterPhysician = (result: IPhysicianSearchResult[]) => {
    return result.filter((item) => item.isActive === !checkBox);
  };

  return (
    <div>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          alignItems: "center",
          marginTop: 12,
        }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            width: "100%",
          }}
        >
          <div className="col-lg-5 col-xl-4 me-3">
            <SearchBar
              onSearch={() => {
                setSearchCount((c) => c + 1);
              }}
              onTextChange={(text) => {
                setSearchText(text);
              }}
              placeholder={`Search for Physician Name or NPI`}
            />
          </div>
          {physicianSearchResults !== undefined && (
            <div className="me-3">
              <MButton
                variant="secondary"
                title="Clear Results"
                width={"fit-content"}
                onClick={handleClearResultsClick}
              />
            </div>
          )}
          {loading && <Loader />}
        </div>
        <MButton
          variant="primary"
          title="Add Physician"
          width={200}
          onClick={handleAddClick}
        />
      </div>
      <div className="mt-2 mb-2">
        <Form.Check
          label="Show deactivated records"
          type="checkbox"
          checked={checkBox}
          onChange={(e) => {
            setCheckBox(e.target.checked);
          }}
        />
      </div>
      <hr />
      <br />
      {physicianSearchResults !== undefined &&
        (filterPhysician(physicianSearchResults).length !== 0 ? (
          filterPhysician(physicianSearchResults).map(
            (physicianSearchResult) => (
              <AdminPhysicianResult
                key={physicianSearchResult.id}
                physicianSearchResult={physicianSearchResult}
              />
            )
          )
        ) : (
          <div>No Record found</div>
        ))}
    </div>
  );
};

export default AdminPhysicianSection;
