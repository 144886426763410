import { CancelTokenSource } from "axios";
import { customAxios as axios } from "./ServiceConfig";
import { API_BASE_URL } from "../config";
import IAccountRequestDropdown from "../types/IAccountRequestDropdown";
import IPhysicianFormDropdownData from "../types/IPhysicianFormDropdownData";
let loginas = localStorage.getItem('loginAsFlag')
let loginasEmail = localStorage.getItem('loginAsEmail')
/**
 * @param token receives the access token of type string
 */
const getAccountRequestEditDropdownData = async (
  token?: string,
  cancelToken?: CancelTokenSource
) => {
  const result = await axios.get<IAccountRequestDropdown>(
    `${API_BASE_URL}/DropDownData/accountrequest`,
    loginas !== null ? {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Pragma: "no-cache",
        Authorization: `Bearer ${token}`,
        loginas:loginas !== null ? loginas : JSON.stringify(false),
        email:loginasEmail !== null ? loginasEmail : "",
      },
      cancelToken: cancelToken?.token,
    }:{
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Pragma: "no-cache",
        Authorization: `Bearer ${token}`,
      },
      cancelToken: cancelToken?.token,
    }
  );
  return result;
};

/**
 * @param token receives the access token of type string
 */
const getPhysicianFormDropdownData = async (
  token?: string,
  cancelToken?: CancelTokenSource
) => {
  const result = await axios.get<IPhysicianFormDropdownData>(
    `${API_BASE_URL}/DropDownData/physicianform`,
    loginas !== null ? {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Pragma: "no-cache",
        Authorization: `Bearer ${token}`,
        loginas:loginas !== null ? loginas : JSON.stringify(false),
        email:loginasEmail !== null ? loginasEmail : "",
      },
      cancelToken: cancelToken?.token,
    }:{
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Pragma: "no-cache",
        Authorization: `Bearer ${token}`,
      },
      cancelToken: cancelToken?.token,
    }
  );
  return result;
};

const DropdownDataService = {
  getAccountRequestEditDropdownData,
  getPhysicianFormDropdownData,
};

export default DropdownDataService;
