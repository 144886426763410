import { FC, useEffect, useState } from "react";
import { Formik, Form as FormikForm, Field } from "formik";
import { useOktaAuth } from "@okta/okta-react";
import axios, { AxiosError } from "axios";
import { Form } from "react-bootstrap";
import IFormDropdownOption from "../../../types/IFormDropdownOption";
import CustomInputFormik from "../../common/CustomInputFormik";
import MButton from "../../mButton/MButton";
import MultiSelect from "../../multiselect/MultiSelect";
import AdminCreateLocationModal from "./components/AdminCreateLocationModal";
import AdminSearchPhysicianModal from "./components/AdminSearchPhysicianModal";
import * as yup from "yup";
import AdminService from "../../../services/AdminService";
import AdminPracticeLookupModal from "./components/AdminPracticeLookupModal";
import IPracticeLookup from "../../../types/IPracticeLookup";
import { useHistory, useParams } from "react-router-dom";
import { appInsights } from "../../../appInsights";
import PracticeService from "../../../services/PracticeService";
import IPracticeEdit, {
  PracticeLocation,
  PracticePhysician,
} from "../../../types/IPracticeEdit";
import Spinner from "../../spinner/Spinner";
import CustomAlertModal from "../../common/CustomAlertModal";
import AdminEditLocationModal from "./components/AdminEditLocationModal";
import AdminEditSearchPhysicianModal from "./components/AdminEditSearchPhysicianModal";
import { Alert } from "@mui/material";
import BackButton from "../../backButton/BackButton";

const AdminEditPractice: FC<{ name?: string }> = ({ name }) => {
  //
  //@ts-ignore
  const { id } = useParams<{ id?: number }>();
  const history = useHistory();

  const [error, setError] = useState<AxiosError | null>(null);
  const [apiCall, setApiCall] = useState<number>(0);
  const [defaultLocationError, setDefaultLocationError] = useState<string>("");
  const [physicianOptions, setPhysicianOptions] = useState<
    IFormDropdownOption[]
  >([]);
  const [locationOptions, setLocationOptions] = useState<IFormDropdownOption[]>(
    []
  );
  const [showLocationModal, setShowLocationModal] = useState(false);
  const [showPhysicianModal, setShowPhysicianModal] = useState(false);
  const [defaultLocation, setDefaultLocation] = useState<IFormDropdownOption>({
    id: 0,
    display: "Please select default location",
  });
  const [practiceOwner, setPracticeOwner] = useState<IFormDropdownOption>({
    id: 0,
    display: "Please Select practice Owner",
  });
  const [physicians, setPhysicians] = useState<PracticePhysician[]>([]);
  const [locations, setLocations] = useState<PracticeLocation[]>([]);
  const [selectedLocation, setSelectedLocations] = useState<PracticeLocation>();
  const [selectedPhysician, setSelectedPhysician] =
    useState<PracticePhysician>();
  const [practiceName, setPracticeName] = useState<null | string>("");
  //
  const { authState } = useOktaAuth();
  const accessToken = authState?.accessToken?.accessToken;
  const cancelToken = axios.CancelToken.source();
  //
  const [initialValues, setInitialValues] = useState<IPracticeEdit>();
  const [loading, setLoading] = useState<boolean>(true);
  const [success, setSuccess] = useState<boolean>(false);
  const [saveBtnLoad, setSaveBtnLoad] = useState<boolean>(false);
  const [formData, setFormData] = useState<PracticeLocation>({
    prctId: id !== undefined ? id : 0,
    locationId: 0,
    id: 0,
    address1: "",
    address2: "",
    mailStop: "",
    city: "",
    country: "",
    state: "",
    zip: "",
    zip4: "",
    mailAddress1: "",
    mailAddress2: "",
    mailMailStop: "",
    mailCity: "",
    mailCountry: "",
    mailState: "",
    mailZip: "",
    mailZip4: "",
    contactName: "",
    phone: "",
    phoneExt: "",
    fax: "",
    email: "",
    dateCreated: new Date(),
    dateModified: new Date(),
    createdBy: "",
    modifiedBy: "",
    activeRecord: true,
    defaultLocation: false,
    hdsSiteid: "",
  });
  useEffect(() => {
    if (id !== undefined) {
      setLoading(true);
      PracticeService.getPractice(id, accessToken, cancelToken)
        .then((response) => {
          setLoading(false);
          let { locations, physicians } = response.data;
          setInitialValues(response.data);
          setLocations(locations);
          setLocationOptions(
            locations.map((l) => ({
              id: l.id,
              display:
                l.address1 +
                (l.address2 !== "" &&
                l.address2 !== null &&
                l.address2 !== undefined
                  ? ", " + l.address2
                  : "") +
                (l.zip !== "" && l.zip !== null && l.zip !== undefined
                  ? ", " + l.zip
                  : ""),
            }))
          );
          let deafaultLocArr = locations.filter(
            (item) => item.defaultLocation === true
          );
          if (deafaultLocArr.length > 0) {
            let deafaultLoc = deafaultLocArr[0];
            setDefaultLocation({
              id: deafaultLoc.id,
              display:
                deafaultLoc.address1 +
                (deafaultLoc.address2 !== "" &&
                deafaultLoc.address2 !== null &&
                deafaultLoc.address2 !== undefined
                  ? ", " + deafaultLoc.address2
                  : "") +
                (deafaultLoc.zip !== "" &&
                deafaultLoc.zip !== null &&
                deafaultLoc.zip !== undefined
                  ? ", " + deafaultLoc.zip
                  : ""),
            });
          }
          let practiceOwnerArr = physicians.filter(
            (item) => item.isPracticeOwner
          );
          if (practiceOwnerArr.length > 0) {
            let po = practiceOwnerArr[0];
            setPracticeOwner({
              id: po.physId,
              display: po.physicianFirstName + " " + po.physicianLastName,
            });
          }
          setPhysicians(physicians);
          setPhysicianOptions(
            physicians.map((p) => ({
              id: p.physId,
              display:
                p.physicianLastName +
                " " +
                p.physicianFirstName +
                (p.physicianTitle !== "" && p.physicianTitle !== null
                  ? `, ${p.physicianTitle}`
                  : ""),
              disabled: p.isPracticeOwner
            }))
          );
        })
        .catch((e) => {
          console.error(e);
        });
    }
  }, [apiCall]);

  if (loading) {
    return <Spinner margin="5rem 0" />;
  }
  return (
    <div className="container">
      <div className="d-flex">
        <h2 className="heading-main">{name}</h2>
        <BackButton />
      </div>
      <div className="chart mt-3">
        {success === true ? (
          <>
            <Alert severity="success">Practice updated successfully!</Alert>
          </>
        ) : error ? (
          <>
            <Alert severity="error" style={{ color: "#EA1D36" }}>
              Error in submitting, please try again!
            </Alert>
          </>
        ) : (
          <></>
        )}

        {initialValues !== undefined ? (
          <Formik
            initialValues={initialValues}
            enableReinitialize={true}
            validationSchema={yup.object().shape({
              practiceName: yup.mixed().required("Required"),
              npi: yup
                .string()
                .matches(/^\d{10}$/, "NPI number is not valid")
                .nullable(),
              domain: yup.string().url("URL is not valid").nullable(),
              fax: yup
                .string()
                .matches(/^\+?[0-9]{7,}$/, "Fax number is not valid")
                .nullable(),
            })}
            onSubmit={(values: any, { setSubmitting }) => {
              setError(null);
              if (values.npi != null) {
                var npinum: any = values.npi!;
                npinum = npinum.toString();
                values.npi = npinum;
              }
              for (var key in values) {
                if (key === "practice_Location") {
                  for (var key_loc in values.practice_Location) {
                    if (values[key][key_loc] === "") {
                      values[key][key_loc] = null;
                    }
                  }
                } else if (key === "practice_Physician") {
                  for (var key_phy in values.practice_Location) {
                    if (values[key][key_phy] === "") {
                      values[key][key_phy] = null;
                    }
                  }
                }
                if (
                  key !== "practice_Location" &&
                  key !== "practice_Physician"
                ) {
                  if (values[key] === "") {
                    values[key] = null;
                  }
                }
              }
              setSuccess(false);
              console.log("landed here");
              AdminService.editPractice(values, accessToken, cancelToken)
                .then((response) => {
                  console.log("landed here");

                  setSuccess(true);
                  setSubmitting(false);
                  setApiCall(apiCall + 1);
                  // history.push("/accounts&access/physicians&practices/");
                })
                .catch((e) => {
                  console.log("landed here");
                  if (!axios.isCancel(e)) {
                    console.log("landed here");
                    setSubmitting(false);
                    console.error(e);
                    appInsights.trackException({ error: e });
                    setError(e);
                  }
                });
            }}
          >
            {({ setFieldValue, values, touched, isSubmitting }) => (
              <FormikForm>
                <Field
                  name="id"
                  required={false}
                  type="text"
                  label="System ID"
                  nonEditable={true}
                  component={CustomInputFormik}
                />
                <Field
                  name="practiceName"
                  id="practiceName"
                  required={true}
                  type="text"
                  label="Practice Name"
                  component={CustomInputFormik}
                />
                {touched.practiceName && setPracticeName(values.practiceName)}
                <Field
                  name="groupName"
                  required={false}
                  type="text"
                  label="Group Name"
                  component={CustomInputFormik}
                />
                <Field
                  name="npi"
                  required={false}
                  type="number"
                  label="Practice NPI"
                  component={CustomInputFormik}
                />
                <Field
                  name="practiceType"
                  required={true}
                  type="text"
                  label="Practice Type"
                  component={({ field, ...props }: any) => (
                    <Form.Group className="d-flex row align-items-center form-padding">
                      <Form.Label className="col-sm-3">
                        {props.label}
                        {props.required ? (
                          <span className="p-0 asterik"> *</span>
                        ) : (
                          <></>
                        )}
                      </Form.Label>
                      <div className="col-sm-9">
                        <Form.Select
                          {...field}
                          name={field.name}
                          required={props.required}
                        >
                          <option value={""}>Please make a selection...</option>
                          <option value={"Administration"}>
                            Administration
                          </option>
                          <option value={"Full-time Hospital Staff"}>
                            Full-time Hospital Staff
                          </option>
                          <option value={"Medical Teaching"}>
                            Medical Teaching
                          </option>
                          <option value={"Office Based Practice"}>
                            Office Based Practice
                          </option>
                          <option value={"Research"}>Research</option>
                          <option value={"Resident"}>Resident</option>
                        </Form.Select>
                      </div>
                    </Form.Group>
                  )}
                />

                <Field
                  name="domain"
                  required={false}
                  type="text"
                  label="Website URL"
                  component={CustomInputFormik}
                />
                <div className="d-flex row align-items-center form-padding">
                  <div className="col-sm-3"></div>
                  <div className="col-sm-9">
                    <div className="row">
                      <div className="col-md-6">
                        <Field
                          name="physicians"
                          label="Practice Physician"
                          component={({ field, ...props }: any) => {
                            return (
                              <MultiSelect
                                multipleAllowed
                                {...field}
                                {...props}
                                title={props.label}
                                options={physicianOptions}
                                onAddClick={() => {
                                  setSelectedPhysician(undefined);
                                  setShowPhysicianModal(true);
                                }}
                                onDoubleClick={(id: number) => {
                                  let selecPhysician: PracticePhysician =
                                    physicians.filter(
                                      (e) => e.physId === id
                                    )[0];
                                  setSelectedPhysician(selecPhysician);
                                  setShowPhysicianModal(true);
                                }}
                                onRemoveClick={(id) => {
                                  if(physicians.filter(phy => phy.physId === id)[0].isPracticeOwner) {
                                    alert("Can not remove practice owner.")
                                    return;
                                  }
                                  let newOptions = [
                                    ...physicianOptions.filter(
                                      (o) => o.id !== id
                                    ),
                                  ];
                                  let filteredPhysicians = physicians.map(
                                    (p) => {
                                      if (p.physId === id) {
                                        return { ...p, activeRecord: false };
                                      } else {
                                        return p;
                                      }
                                    }
                                  );
                                  setPhysicianOptions(newOptions);
                                  setPhysicians(filteredPhysicians);
                                  setFieldValue(
                                    "physicians",
                                    filteredPhysicians
                                  );
                                }}
                              />
                            );
                          }}
                        />
                      </div>
                      <div className="col-md-6">
                        <CustomAlertModal
                          show={defaultLocationError !== ""}
                          handleClose={() => {
                            setDefaultLocationError("");
                          }}
                          header={"Error!"}
                          // @ts-ignore
                          body={defaultLocationError}
                        />
                        <Field
                          name="locations"
                          label="Practice Location"
                          component={({ field, ...props }: any) => {
                            return (
                              <MultiSelect
                                multipleAllowed
                                {...field}
                                {...props}
                                title={props.label}
                                options={locationOptions}
                                isLocation={true}
                                onAddClick={() => {
                                  setSelectedLocations(undefined);
                                  setShowLocationModal(true);
                                }}
                                onDoubleClick={(
                                  id: number,
                                  display: string
                                ) => {
                                  let selecLocation: PracticeLocation =
                                    locations.filter(
                                      (l) =>
                                        l.id === id &&
                                        display ===
                                          l.address1 +
                                            (l.address2 !== "" &&
                                            l.address2 !== null &&
                                            l.address2 !== undefined
                                              ? ", " + l.address2
                                              : "") +
                                            (l.zip !== "" &&
                                            l.zip !== null &&
                                            l.zip !== undefined
                                              ? ", " + l.zip
                                              : "")
                                    )[0];
                                  setSelectedLocations(selecLocation);
                                  setShowLocationModal(true);
                                }}
                                onRemoveClick={(id) => {
                                  let newOptions = [
                                    ...locationOptions.filter(
                                      (o) => o.id !== id
                                    ),
                                  ];

                                  if (id !== 0) {
                                    let flag = false;
                                    let filteredlocations: PracticeLocation[] =
                                      locations.map((l) => {
                                        if (
                                          l.id === id &&
                                          l.defaultLocation === false
                                        ) {
                                          return { ...l, activeRecord: false };
                                        } else {
                                          if (
                                            l.defaultLocation === true &&
                                            l.id === id
                                          ) {
                                            flag = true;
                                            setDefaultLocationError(
                                              "Default Location cannot be deleted."
                                            );
                                          }
                                          return l;
                                        }
                                      });
                                    if (flag) {
                                      setLocationOptions(locationOptions);
                                    } else {
                                      setLocationOptions(newOptions);
                                    }
                                    setLocations(filteredlocations);
                                    setFieldValue(
                                      "locations",
                                      filteredlocations
                                    );
                                  } else {
                                    let filteredlocations = [
                                      ...locations.filter((o) => o.id !== id),
                                    ];
                                    setLocationOptions(newOptions);
                                    setLocations(filteredlocations);
                                    setFieldValue(
                                      "locations",
                                      filteredlocations
                                    );
                                  }
                                }}
                              />
                            );
                          }}
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <Field
                  label="Default Location"
                  name="defaultLocation"
                  type="typeahead"
                  itemSelected={true}
                  options={locationOptions}
                  placeholder={defaultLocation.display}
                  selectedItem={defaultLocation}
                  onChange={(location: any) => {
                    if (location.length > 0) {
                      PracticeService.practiceDefaultLocationChange(
                        {
                          locationId: location[0].id,
                          practiceId: id !== undefined ? id : 0,
                        },
                        accessToken,
                        cancelToken
                      )
                        .then((res) => {
                          console.log(res);
                        })
                        .catch((err) => {
                          console.log(err);
                        });
                    }
                  }}
                  component={CustomInputFormik}
                />
                <Field
                  label="Practice Owner"
                  name="practiceOwner"
                  type="typeahead"
                  itemSelected={true}
                  options={physicianOptions}
                  selectedItem={practiceOwner}
                  placeholder={practiceOwner.display}
                  onChange={(physician: any) => {
                    if (physician.length > 0) {
                      setSaveBtnLoad(true);
                      PracticeService.practiceOwnerChange(
                        {
                          physicianId: physician[0].id,
                          practiceId: id !== undefined ? id : 0,
                        },
                        accessToken,
                        cancelToken
                      )
                        .then((res) => {
                          setSaveBtnLoad(false);
                          console.log(res);
                        })
                        .catch((err) => {
                          setSaveBtnLoad(false);
                          setDefaultLocationError(err.response.data);
                          console.log(err);
                        });
                    }
                  }}
                  component={CustomInputFormik}
                />
                <div className="d-flex row align-items-center form-padding">
                  <div className="col-sm-3"></div>
                  <div className="col-sm-9 ">
                    <MButton
                      isSubmitting={isSubmitting}
                      variant="primary"
                      type="submit"
                      title="Save"
                      disabled={saveBtnLoad}
                      loader={saveBtnLoad}
                      width={"fit-content"}
                    />
                  </div>
                </div>
                <AdminEditLocationModal
                  show={showLocationModal}
                  handleClose={() => {
                    setShowLocationModal(false);
                  }}
                  selectedLocation={selectedLocation}
                  handleAdd={(practiceLocation, error) => {
                    setShowLocationModal(false);

                    let filteredlocations: PracticeLocation[] = [];
                    if (selectedLocation !== undefined) {
                      filteredlocations = locations.filter(
                        (e) => e.id !== practiceLocation.id
                      );
                    } else {
                      filteredlocations = locations;
                    }
                    setLocations((prev) => [
                      practiceLocation,
                      ...filteredlocations,
                    ]);
                    setLocationOptions((prev) => [
                      {
                        id: practiceLocation.id,
                        display:
                          practiceLocation.address1 +
                          (practiceLocation.address2 !== "" &&
                          practiceLocation.address2 !== null &&
                          practiceLocation.address2 !== undefined
                            ? ", " + practiceLocation.address2
                            : "") +
                          (practiceLocation.zip !== "" &&
                          practiceLocation.zip !== null &&
                          practiceLocation.zip !== undefined
                            ? ", " + practiceLocation.zip
                            : ""),
                      },
                      ...filteredlocations.map((l) => ({
                        id: l.id,
                        display:
                          l.address1 +
                          (l.address2 !== "" &&
                          l.address2 !== null &&
                          l.address2 !== undefined
                            ? ", " + l.address2
                            : "") +
                          (l.zip !== "" && l.zip !== null && l.zip !== undefined
                            ? ", " + l.zip
                            : ""),
                      })),
                    ]);
                    setFieldValue("locations", [
                      practiceLocation,
                      ...filteredlocations,
                    ]);
                    setSelectedLocations(undefined);
                  }}
                  practiceId={id}
                />
                <AdminEditSearchPhysicianModal
                  show={showPhysicianModal}
                  handleClose={() => {
                    setShowPhysicianModal(false);
                  }}
                  selectedEditPhysician={selectedPhysician}
                  handleAdd={(physician, name) => {
                    setShowPhysicianModal(false);
                    // @ts-ignore
                    let filteredPhysicians: PracticePhysician[] = [];
                    if (physician !== undefined) {
                      console.log(selectedPhysician)
                      console.log(physician)
                      if(physicians.filter(src => src.physId == physician.physId).length > 0) return;
                      filteredPhysicians = physicians.filter(
                        (e) => e.physId != physician.physId
                      );
                    } else {
                      filteredPhysicians = physicians;
                    }

                    setPhysicians((prev) => [physician, ...filteredPhysicians]);
                    setFieldValue("physicians", [
                      physician,
                      ...filteredPhysicians,
                    ]);
                    setPhysicianOptions((prev) => [
                      {
                        id:
                          physician.id != 0
                            ? physician.physId
                            : physician.physId,
                        display: name,
                      },
                      ...filteredPhysicians.map((l) => ({
                        id: l.physId,
                        display:
                          l.physicianLastName +
                          " " +
                          l.physicianFirstName +
                          (l.physicianTitle !== "" && l.physicianTitle !== null
                            ? `, ${l.physicianTitle}`
                            : ""),
                      })),
                    ]);
                  }}
                  practiceId={id}
                  practices={initialValues}
                />
              </FormikForm>
            )}
          </Formik>
        ) : (
          <></>
        )}
      </div>
      <CustomAlertModal
        show={error !== null}
        handleClose={() => {
          setError(null);
        }}
        header={"Error!"}
        // @ts-ignore
        body={error?.response?.data.message || 
          "There was an error processing your request. Please refresh the page and try again. If problems persist please contact myCHLA support."}
      />
    </div>
  );
};

export default AdminEditPractice;
