import { FC, useEffect, useState } from "react";
import HeaderName from "../../headerName/HeaderName";
import { Badge, OverlayTrigger, Table, Col } from "react-bootstrap";
import IDivisionMonth from "../../../types/IDivisionMonth";
import MButton from "../../mButton/MButton";
import { useHistory, useLocation } from "react-router-dom";
import CustomToggle from "../../toggle/CustomToggle";
import { useOktaAuth } from "@okta/okta-react";
import DivisionMonthService from "../../../services/DivisionMonthService";
import axios from "axios";
import { getFormattedDateFromDate } from "../../../helpers/DateHelper";
import Loader from "../../common/Loader";
import { Checkbox } from "@mui/material";
import MemberDirectoryService from "../../../services/MemberDirectoryService";
import IMemberDirectory from "../../../types/IMemberDirectory";
import { appInsights } from "../../../appInsights";
const MemberDirectory: FC<{
  name?: string;
}> = ({ name }) => {
  const history = useHistory();
  const tableCellPadding = { padding: 20 };
  const tableHeadPadding = { paddingLeft: 20, paddingRight: 20 };
  const handleClick = () => {
    history.push("/portalupdates/newmemberdirectory");
  };
  const handleOnClick = (member: IMemberDirectory) => {
    history.push("/portalupdates/editmemberdirectory", member);
  };

  const { authState } = useOktaAuth();
  const accessToken = authState?.accessToken?.accessToken;
  const cancelToken = axios.CancelToken.source();
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<Error | null>(null);
  const onToggle = () => {};

  const [memberDirectoryData, setMemberDirectoryData] = useState<IMemberDirectory[]>();

  useEffect(() => {
    setLoading(true);
    setError(null);
    MemberDirectoryService.getMemberDirectoryServiceList(accessToken)
      .then((response) => {
        setLoading(false);
        setMemberDirectoryData(response.data);
       
      })
      .catch((e: Error) => {
        if (!axios.isCancel(e)) {
          console.error(e);
          appInsights.trackException({ error: e });
           setError(e);
        }
        
        setLoading(false);
      });
    return () => {
      cancelToken.cancel();
    };
  }, []);

  return (
    <div className="referrals">
      <HeaderName name={name} />
      <div className="container">
        <h2 className="heading-main">{name}</h2>
        <div className="d-flex row justify-content-end" style={{ padding: 20 }}>
          <MButton
            variant="primary"
            disabled={false}
            title="Add New"
            width={"fit-content"}
            onClick={handleClick}
          />
        </div>
        <Col
          sm={{ span: 7, offset: 3 }}
          className="d-flex justify-content-center"
        >
          {loading && <Loader />}
        </Col>
        <br />

        <div className="referral">
          <Table id="responsive-table" className="mt-2" responsive>
            <thead>
              <tr>
                <th style={tableHeadPadding}>Title</th>
                <th style={tableHeadPadding}>File Name </th>
                <th style={tableHeadPadding}>Is Active</th>
                <th style={tableHeadPadding}>Publication Date</th>
                <th style={tableHeadPadding}>Modified On</th>
                <th style={tableHeadPadding}>Modified By</th>
              </tr>
            </thead>
            <tbody>
              {memberDirectoryData?.map((member) => {
                return (
                  <tr>
                    <td
                      style={tableCellPadding}
                      data-title="Title"
                      id="table-text-referrals"
                      onClick={() => handleOnClick(member)}
                    >
                      {member.title}
                    </td>
                    <td style={tableCellPadding} data-title="File Name">
                      {member.fileName}
                    </td>
                    <td style={tableCellPadding} data-title="Is Active">
                      <Checkbox disabled checked={member.isActive} />
                    </td>
                    <td style={tableCellPadding} data-title="Publication Date">
                      {getFormattedDateFromDate(member.publicationDate)}
                    </td>
                    <td style={tableCellPadding} data-title="Modified On">
                      {getFormattedDateFromDate(member.modifiedOn)}
                    </td>
                    <td style={tableCellPadding} data-title="Modified By">
                      {member.modifiedBy}
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </Table>
        </div>
      </div>
    </div>
  );
};

export default MemberDirectory;
