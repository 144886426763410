import { FC, useState, useEffect } from "react";
import { useLocation, useParams } from "react-router-dom";
import { useOktaAuth } from "@okta/okta-react";
import ILookbackOption from "../../../types/ILookbackOption";
import IPatientAccessRequest from "../../../types/IPatientAccessRequest";
import Spinner from "../../spinner/Spinner";
import Loader from "../../common/Loader";
import HeaderName from "../../headerName/HeaderName";
import BackButton from "../../backButton/BackButton";
import { Form, Col, Button } from "react-bootstrap";
import { appInsights } from "../../../appInsights";
import {
  getFormattedDateFromDateTime,
  getFormattedTimeFromDateTime,
  getFormattedDateFromDate,
  getFormattedDateTime
} from "../../../helpers/DateHelper";
import axios from "axios";
import "./patient-details.css";



import PatientAccessRequestService from "../../../services/PatientAccessRequestService";




type stateType = {
  state: {
    patientAccess: IPatientAccessRequest;
  };
};
const PatientAccessRequestsDetails: FC<{ name?: string }> = ({ name }) => {


  const { id } = useParams<{ id?: string | undefined }>();
  const { state } = useLocation<stateType>();
  const patientDetail: IPatientAccessRequest = state.state.patientAccess;
  const { authState } = useOktaAuth();
  const accessToken = authState?.accessToken?.accessToken;
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<Error>();
  const [patientAccess, setpatientAccess] = useState<any | null>(null);


  useEffect(() => {
    setLoading(true);
    PatientAccessRequestService.getPatientAccessRequestById(patientDetail.patientAccessRequestID, accessToken)
      .then((response) => {
        if (error !== undefined) {
          setError(undefined);
        }
        setpatientAccess(response.data);
        setLoading(false);
      })
      .catch((e) => {
        if (!axios.isCancel(e)) {
          console.error(e);
          appInsights.trackException({ error: e });
           setError(e);
        }
      
        setLoading(false);
      });
  }, []);

  if (patientDetail === undefined || Object.keys(patientDetail).length === 0) {
    return <Spinner />;
  } else {
    let headerName = { name: name + " > Details" };

    return (
      <>
        <HeaderName name={headerName.name} />
        <div id="patient-details" className="container">
          <div className="row chartLayoutContainer">
            <div className="top-content">
              <div className="top-left-content">
                <h3 className="header-blue bold me-2">Patient Access Request Details</h3>
                <BackButton  />
              </div>
            </div>
            <div className="col-md-12 chart">
              <dl className="dl-horizontal">
                <dt>ID</dt>
                <dd>{patientDetail.patientAccessRequestID}</dd>
                <dt>Patient's First Name</dt>
                <dd>{patientDetail.patientFirstName}</dd>
                <dt>Patient's Last Name</dt>
                <dd>{patientDetail.patientLastName}</dd>
                <dt>On Behalf of (Doctor's Name)</dt>
                <dd>{patientDetail.onBehalfPhysicianName}</dd>
                <dt>Status</dt>
                <dd>{patientDetail.requestStatus}</dd>
                <dt>Requested By</dt>
                <dd>{patientDetail.createdBy}</dd>
                <dt>Requested on</dt>
                <dd>{patientDetail?.createdOn!= null ? `${patientDetail?.createdOn.split("T")[0]} ${patientDetail?.createdOn.split("T")[1]}`:<></>}</dd>

                <dt>Last Modified By</dt>
                <dd>{patientDetail.modifiedBy}</dd>
                <dt>Last Modified</dt>
                <dd>{patientDetail?.modifiedOn!=null ?`${patientDetail?.modifiedOn.split("T")[0]} ${patientDetail?.modifiedOn.split("T")[1]}`:<></>}</dd>
              </dl>
            </div>
          </div>
        </div>
      </>
    )
  }
};

export default PatientAccessRequestsDetails;
