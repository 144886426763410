import { CancelTokenSource } from "axios";
import { customAxios as axios } from "./ServiceConfig";
import { API_BASE_URL } from "../config";
import IPatientAccessRequestList from "../types/IPatientAccessRequestList";
import IReferralQuery from "../types/IReferralQuery";
import IPatientAccessQuery from "../types/IPatientAccessQuery";
let loginas = localStorage.getItem('loginAsFlag')
let loginasEmail = localStorage.getItem('loginAsEmail')
/**
 * @param query receives the body to be sent in the post request
 * @param token receives the access token of type string
 */
 const getPatientAccessRequestList = async (
    data:IPatientAccessQuery,
    token?: string,
    cancelToken?: CancelTokenSource
  ) => {
    const result = await axios.post<any>(
      `${API_BASE_URL}/patient/getallpatientaccessrequest`,
      data,
      loginas !== null ? {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Pragma: "no-cache",
          Authorization: `Bearer ${token}`,
          loginas:loginas !== null ? loginas : JSON.stringify(false),
          email:loginasEmail !== null ? loginasEmail : "",
        },
        cancelToken: cancelToken?.token,
      }:{
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Pragma: "no-cache",
          Authorization: `Bearer ${token}`,
        },
        cancelToken: cancelToken?.token,
      }
    );
    return result;
  };

  /**
 * @param query receives the body to be sent in the post request
 * @param token receives the access token of type string
 */
 const getNotMyPatientList = async (
  data:IPatientAccessQuery,
  token?: string,
  cancelToken?: CancelTokenSource
) => {
  const result = await axios.post<any>(
    `${API_BASE_URL}/patient/getallnotmypatient`,
    data,
    loginas !== null ? {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Pragma: "no-cache",
        Authorization: `Bearer ${token}`,
        loginas:loginas !== null ? loginas : JSON.stringify(false),
        email:loginasEmail !== null ? loginasEmail : "",
      },
      cancelToken: cancelToken?.token,
    }:{
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Pragma: "no-cache",
        Authorization: `Bearer ${token}`,
      },
      cancelToken: cancelToken?.token,
    }
  );
  return result;
};

  /**
 * @param id receives the patient id
 * @param token receives the access token of type string
 */
const getPatientAccessRequestById = async (id: number, token?: string) => {
    const result = await axios.get(`${API_BASE_URL}/patient/getpatientaccessrequestbyid/${id}`, loginas !== null ? {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Pragma: "no-cache",
        Authorization: `Bearer ${token}`,
        loginas:loginas !== null ? loginas : JSON.stringify(false),
        email:loginasEmail !== null ? loginasEmail : "",
      },
    }:{
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Pragma: "no-cache",
        Authorization: `Bearer ${token}`,
      },
    }
  );
  return result;
};

/**
 * @param data receives the referral form
 * @param token receives the access token of type string
 */
const createNewPatientAccessRequest = async (data: any, token?: string) =>  {
    const result = await axios.post(`${API_BASE_URL}/patient/newpatientaccessrequest`, data, loginas !== null ? {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Pragma: "no-cache",
        Authorization: `Bearer ${token}`,
        loginas:loginas !== null ? loginas : JSON.stringify(false),
        email:loginasEmail !== null ? loginasEmail : "",
      },
    }:{
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Pragma: "no-cache",
        Authorization: `Bearer ${token}`,
      },
    }
  );
  return result;
}

   const editPatientAccessRequest = async (data: any, token?: string) => {
    const result = await axios.put(`${API_BASE_URL}/Patient/editpatientaccessrequest`, data, loginas !== null ? {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Pragma: "no-cache",
        Authorization: `Bearer ${token}`,
        loginas:loginas !== null ? loginas : JSON.stringify(false),
        email:loginasEmail !== null ? loginasEmail : "",
      },
    }:{
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Pragma: "no-cache",
        Authorization: `Bearer ${token}`,
      },
    }
  );
  return result;
}

  const editNotMyPatientRequest = async (data: any, token?: string) => {
    const result = await axios.put(`${API_BASE_URL}/Patient/editnotmypatient`, data, loginas !== null ? {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Pragma: "no-cache",
        Authorization: `Bearer ${token}`,
        loginas:loginas !== null ? loginas : JSON.stringify(false),
        email:loginasEmail !== null ? loginasEmail : "",
      },
    }:{
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Pragma: "no-cache",
        Authorization: `Bearer ${token}`,
      },
    });
    return result;
  }

  const PatientAccessRequestService = {
    getPatientAccessRequestList,
    getPatientAccessRequestById,
    createNewPatientAccessRequest,
    editPatientAccessRequest,
    getNotMyPatientList,
    editNotMyPatientRequest
  };

export default PatientAccessRequestService;
