import { useEffect, useState } from "react";
import MButton from "../../mButton/MButton";
import SearchBar from "../../common/SearchBar";
import Loader from "../../common/Loader";
import PracticeService from "../../../services/PracticeService";
import IPracticeSearchResult from "../../../types/IPracticeSearchResult";
import { useHistory } from "react-router-dom";
import { useOktaAuth } from "@okta/okta-react";
import axios from "axios";
import { Form } from "react-bootstrap";
import AdminPracticeResult from "./components/AdminPracticeResult";
import { appInsights } from "../../../appInsights";

var loader = false;
const AdminPracticeSection = () => {
  const [searchText, setSearchText] = useState("");
  const [practiceSearchResults, setPracticeSearchResults] =
    useState<IPracticeSearchResult[]>();
  const [serachCount, setSearchCount] = useState(0);
  const [checkBox, setCheckBox] = useState(false);
  const [loading, setLoading] = useState(false);
  const [searchValue, setSearchValue] = useState<string | undefined>(undefined);
  const { authState } = useOktaAuth();
  const accessToken = authState?.accessToken?.accessToken;
  const cancelToken = axios.CancelToken.source();
  const history = useHistory();

  const handleClearResultsClick = () => {
    setPracticeSearchResults(undefined);
  };

  const handleAddClick = () => {
    history.push("/accounts&access/physicians&practices/create/practice");
  };


  useEffect(() => {
    const value = localStorage.getItem("searchValue");
    const gotFrom = localStorage.getItem("gotFrom");

    if (gotFrom === "/physicians&practices/edit/practice/") {
      localStorage.setItem("gotFrom", "");
      localStorage.setItem("searchValue", "");
    }else {
      return;
    }

    if (value === null) {
      setSearchValue("");
    }else {
      setSearchValue(value);
    }
    
  }, [])

  useEffect(() => {
    if(!isNaN(parseInt(searchText))){
      setLoading(true);
      PracticeService.searchPracticeById(searchText, accessToken, cancelToken)
        .then((response) => {
          setPracticeSearchResults(response.data);
          setLoading(false);
        })
        .catch((e: Error) => {
          if (axios.isCancel(e)) {
            // console.error(e);
            appInsights.trackException({ error: e });
            // setError(e);
          }
          else{
          setLoading(false);
          }
        });
    }
    else if (searchText.length >= 3) {
      setLoading(true);
      PracticeService.searchPracticeByName(searchText, accessToken, cancelToken)
        .then((response) => {
          setPracticeSearchResults(response.data);
          setLoading(false);
        })
        .catch((e: Error) => {
          if (axios.isCancel(e)) {
            // console.error(e);
            appInsights.trackException({ error: e });
            // setError(e);
          }
          else{
          setLoading(false);
          }
        });
    }else{
      
      setLoading(false);

  }
    return () => {
      cancelToken.cancel();
    };
    
  }, [searchText, serachCount]);

  const filterPractice = (result: IPracticeSearchResult[]) => {
    return result.filter((item) => item.isActive === !checkBox);
  };

  return (
    <div>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          alignItems: "center",
          marginTop: 12,
        }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            width: "100%",
          }}
        >
          <div className="col-lg-5 col-xl-4 me-3">
            <SearchBar
              value={searchValue}
              key={searchValue?.length}
              onSearch={() => {
                setSearchCount((c) => c + 1);
              }}
              onTextChange={(text) => {
                setSearchText(text);
              }}
              placeholder={`Search for Practice Name or ID`}
            />
          </div>
          {practiceSearchResults !== undefined && (
            <div className="me-3">
              <MButton
                variant="secondary"
                title="Clear Results"
                width={"fit-content"}
                onClick={handleClearResultsClick}
              />
            </div>
          )}
          {loading && <Loader />}
        </div>
        <MButton
          variant="primary"
          title="Add Practice"
          width={200}
          onClick={handleAddClick}
        />
      </div>
      <div className="mt-2 mb-2">
        <Form.Check
          label="Show deactivated records"
          type="checkbox"
          checked={checkBox}
          onChange={(e) => {
            setCheckBox(e.target.checked);
          }}
        />
      </div>
      <hr />
      <br />
      {practiceSearchResults !== undefined &&
        (filterPractice(practiceSearchResults).length !== 0 ? (
          filterPractice(practiceSearchResults).map((practiceSearchResult) => (
            <AdminPracticeResult
              key={practiceSearchResult.practiceId}
              practiceSearchResult={practiceSearchResult}
              searchValue={searchText}
            />
          ))
        ) : (
          <div>No Record found</div>
        ))}
    </div>
  );
};

export default AdminPracticeSection;
