import { FC, useEffect, useState } from "react";
import { useOktaAuth } from "@okta/okta-react";
import MButton from "../../mButton/MButton";
import {
  Field,
  Formik,
  Form as FormikForm,
  setNestedObjectValues,
} from "formik";
import CustomInputFormik from "../../common/CustomInputFormik";
import HeaderName from "../../headerName/HeaderName";
import IAccountRequestDropdown from "../../../types/IAccountRequestDropdown";
import DropdownDataService from "../../../services/DropdownDataService";
import axios, { AxiosError } from "axios";
import { AccountRequest } from "../../../types/IAccountRequests";
import { useHistory, useLocation } from "react-router-dom";
import { appInsights } from "../../../appInsights";
import AccountService from "../../../services/AccountService";
import {
  getFormattedDateFromDateTime,
  getFormattedDateTime,
} from "../../../helpers/DateHelper";
import * as Yup from "yup";
import CustomAlertModal from "../../common/CustomAlertModal";
import PracticeService from "../../../services/PracticeService";
import useDebounceQuery from "../../../hooks/useDebounceQuery";

const AccountRequestEdit: FC<{
  name?: string;
}> = ({ name }) => {
  //
  const { authState } = useOktaAuth();
  const accessToken = authState?.accessToken?.accessToken;
  const cancelToken = axios.CancelToken.source();
  //
  const [error, setError] = useState<AxiosError | null>(null);
  const [inviteLoader, setInviteLoader] = useState<boolean>(false);
  const [reInviteLoader, setReInviteLoader] = useState<boolean>(false);
  const [dropdownData, setDropDownData] = useState<IAccountRequestDropdown>();
  //
  const location = useLocation<AccountRequest>();
  const request: AccountRequest = location.state;
  const history = useHistory();
  //
  const [query, setQuery] = useState<string>(request.practiceId != null && request.practiceId != undefined ? request.practiceId.toString() : "");
  const [isPO, setIsPO] = useState<boolean>();
  const debounceQuery = useDebounceQuery(query);

  const handleBackClick = () => {
    history.goBack();
  };

  useEffect(() => {
    DropdownDataService.getAccountRequestEditDropdownData(
      accessToken,
      cancelToken
    )
      .then((response) => {
        setDropDownData(response.data);
      })
      .catch((e) => {
        if (!axios.isCancel(e)) {
          console.error(e);
          appInsights.trackException({ error: e });
          setError(e);
        }
      });
  }, []);

  useEffect(() => {
    setIsPO(undefined);
    if (debounceQuery.length > 0) {
      PracticeService.isPoPresent(debounceQuery, accessToken, cancelToken)
        .then((response) => {
          setIsPO(response.data);
        })
        .catch((e) => {
          if (!axios.isCancel(e)) {
            console.error(e);
            appInsights.trackException({ error: e });
          }
        });
    }
  }, [debounceQuery]);

  const handleReinvite = (values: AccountRequest, reInvite: boolean) => {
    setError(null);
    if (reInvite) {
      setReInviteLoader(true);
    }else if (reInvite === false) {
      setInviteLoader(true);
    }
    values.npi = values.npi.toString();
    AccountService.reinvite({ ...values, reInvite }, accessToken, cancelToken)
      .then((response) => {
        history.push("/accountsandaccess/accountrequests");
        setInviteLoader(false);
        setReInviteLoader(false);
      })
      .catch((e) => {
        if (!axios.isCancel(e)) {
          console.error(e);
          appInsights.trackException({ error: e });
          setError(e);
        }
        setInviteLoader(false);
        setReInviteLoader(false);
      });
  };

  return (
    <>
      <HeaderName name={name} />
      <div className="container">
        <h2 className="heading-main">{name}</h2>
        <div className="chart">
          <div className="col-md-8">
            <div>
              <Formik
                initialValues={{
                  ...request,
                  createdOn: getFormattedDateTime(request.createdOn),
                  modifiedOn: getFormattedDateTime(request.modifiedOn),
                  // userDateOfBirth: getFormattedDateFromDateTime(request.userDateOfBirth)
                }}
                validationSchema={Yup.object().shape({
                  userEmail: Yup.string().email("Email is not valid").max(255),
                  practiceContactEmail: Yup.string()
                    .email("Email is not valid")
                    .max(255)
                    .nullable(),

                  practicePhone: Yup.string()
                    .matches(
                      /^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4}$/im,
                      "Phone number is not valid"
                    )
                    .nullable(),
                  officePhone: Yup.string()
                    .matches(
                      /^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4}$/im,
                      "Phone number is not valid"
                    )
                    .nullable(),
                  userPhone: Yup.string()
                    .matches(
                      /^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4}$/im,
                      "Phone number is not valid"
                    )
                    .nullable(),
                  practiceZip: Yup.string()
                    .matches(
                      /(^\d{5}$)|(^\d{5}-\d{4}$)/,
                      "Zip Code is not valid"
                    )
                    .nullable(),
                  npi: Yup.string()
                    .matches(/^\d{10}$/, "NPI number is not valid")
                    .nullable(),
                  practiceFax: Yup.string()
                    .matches(/^\+?[0-9]{7,}$/, "Fax number is not valid")
                    .nullable(),
                })}
                onSubmit={(values, { setSubmitting }) => {
                  if (values.npi != null) {
                    var npiNum: any = values.npi!;
                    npiNum = npiNum.toString();
                    values.npi = npiNum;
                  }

                  setError(null);
                  AccountService.editAccountRequest(
                    {
                      ...values,
                      //@ts-ignore
                      createdOn: undefined,
                      //@ts-ignore
                      modifiedOn: undefined,
                      //@ts-ignore
                      createdBy: undefined,
                      //@ts-ignore
                      modifiedBy: undefined,
                      //@ts-ignore
                      practiceId: (values.practiceId == "" || values.practiceId === undefined) ? null : values.practiceId,
                    },
                    accessToken,
                    cancelToken
                  )
                    .then((response) => {
                      setSubmitting(false);
                      history.push("/accountsandaccess/accountrequests");
                    })
                    .catch((e) => {
                      if (!axios.isCancel(e)) {
                        console.error(e);
                        appInsights.trackException({ error: e });
                        setSubmitting(false);
                        setError(e);
                      }
                    });
                }}
              >
                {({
                  isSubmitting,
                  values,
                  setFieldValue,
                  handleChange,
                }) => (
                  <FormikForm>
                    <Field
                      label="ID"
                      name="accountRequestId"
                      required={false}
                      type="text"
                      nonEditable
                      component={CustomInputFormik}
                    />
                    <Field
                      label="First Name"
                      name="userFirstName"
                      required={true}
                      type="text"
                      component={CustomInputFormik}
                    />
                    <Field
                      label="Middle Name"
                      name="userMiddleName"
                      required={false}
                      type="text"
                      component={CustomInputFormik}
                    />
                    <Field
                      label="Last Name"
                      name="userLastName"
                      required={true}
                      type="text"
                      component={CustomInputFormik}
                    />
                    <Field
                      label="Email"
                      name="userEmail"
                      required={true}
                      type="email"
                      component={CustomInputFormik}
                    />
                    <Field
                      label="Mobile Phone"
                      name="userPhone"
                      required={true}
                      type="text"
                      component={CustomInputFormik}
                    />
                    <Field
                      label="Office Phone"
                      name="officePhone"
                      required={false}
                      type="text"
                      component={CustomInputFormik}
                    />
                    <Field
                      label="Title"
                      name="userTitle"
                      required={false}
                      type="text"
                      component={CustomInputFormik}
                    />
                    <Field
                      label="User Name"
                      nonEditable
                      name="userName"
                      required={false}
                      type="text"
                      component={CustomInputFormik}
                    />
                    <Field
                      label="NPI"
                      name="npi"
                      required={true}
                      type="number"
                      component={CustomInputFormik}
                    />
                    <Field
                      label="Medical License Number"
                      name="medicalLicenseNumber"
                      required={true}
                      type="text"
                      component={CustomInputFormik}
                    />
                    <Field
                      label="Role"
                      name="expectedRoleId"
                      required={true}
                      type="select"
                      options={dropdownData?.accountRequestUserRole.map(
                        (d) => ({
                          id: d.id,
                          display: d.name,
                        })
                      )}
                      component={CustomInputFormik}
                    />
                    <Field
                      label="Practice ID"
                      name="practiceId"
                      required={false}
                      type="number"
                      onkeypress="return event.keyCode === 8 || event.charCode >= 48 && event.charCode <= 57"
                      component={CustomInputFormik}
                      onChange={(event: any) => {
                        handleChange(event);
                        setFieldValue(
                          `practiceId`,
                          event.target.value
                        );
                        setQuery(event.target.value);
                        console.log(values.practiceId);
                      }}
                    />
                    {isPO && values?.expectedRoleId == "9" ? (
                      <div className="d-flex row align-items-center form-padding">
                        <div className="col-sm-3"></div>
                        <div className="col-sm-9 text-danger">
                          Practice Owner already present
                        </div>
                      </div>
                    ) : null}
                    <Field
                      label="Practice Name"
                      name="practiceName"
                      required={false}
                      type="text"
                      component={CustomInputFormik}
                    />
                    <Field
                      label="Address 1"
                      name="practiceAddress1"
                      required={false}
                      type="text"
                      component={CustomInputFormik}
                    />
                    <Field
                      label="Address 2"
                      name="practiceAddress2"
                      required={false}
                      type="text"
                      component={CustomInputFormik}
                    />
                    <Field
                      label="City"
                      name="practiceCity"
                      required={false}
                      type="text"
                      component={CustomInputFormik}
                    />
                    <Field
                      label="State"
                      name="practiceState"
                      required={false}
                      type="text"
                      component={CustomInputFormik}
                    />
                    <Field
                      label="Zip"
                      name="practiceZip"
                      required={false}
                      type="text"
                      component={CustomInputFormik}
                    />
                    <Field
                      label="Phone"
                      name="practicePhone"
                      required={false}
                      type="text"
                      component={CustomInputFormik}
                    />
                    <Field
                      label="Fax"
                      name="practiceFax"
                      required={false}
                      type="text"
                      component={CustomInputFormik}
                    />
                    <Field
                      label="Practice Contact Name"
                      name="practiceContactName"
                      required={false}
                      type="text"
                      component={CustomInputFormik}
                    />
                    <Field
                      label="Practice Contact Email"
                      name="practiceContactEmail"
                      required={false}
                      type="text"
                      component={CustomInputFormik}
                    />
                    <Field
                      label="Notes"
                      name="notes"
                      required={false}
                      type="textarea"
                      component={CustomInputFormik}
                    />
                    <Field
                      label="Status"
                      name="accountRequestStatusId"
                      required={true}
                      type="select"
                      options={dropdownData?.accountRequestStatus.map((d) => ({
                        id: d.accountRequestStatusId,
                        display: d.status,
                      }))}
                      component={CustomInputFormik}
                    />
                    <Field
                      label="Request Type"
                      nonEditable
                      name="accountRequestType"
                      required={false}
                      type="text"
                      component={CustomInputFormik}
                    />
                    <Field
                      label="Created By"
                      nonEditable
                      name="createdBy"
                      required={false}
                      type="text"
                      component={CustomInputFormik}
                    />
                    <Field
                      label="Created On"
                      nonEditable
                      name="createdOn"
                      required={false}
                      type="text"
                      component={CustomInputFormik}
                    />
                    <Field
                      label="Modified By"
                      nonEditable
                      name="modifiedBy"
                      required={false}
                      type="text"
                      component={CustomInputFormik}
                    />
                    <Field
                      label="Modified On"
                      nonEditable
                      name="modifiedOn"
                      required={false}
                      type="text"
                      component={CustomInputFormik}
                    />
                    <Field
                      label="Is Active"
                      name="activeRecord"
                      required={false}
                      type="checkbox"
                      component={CustomInputFormik}
                    />
                    <div className="d-flex row align-items-center form-padding">
                      <div className="col-sm-3"></div>
                      <div className="col-sm-9 ">
                        <MButton
                          variant="primary"
                          type="submit"
                          title="Save"
                          width={"fit-content"}
                          disabled={isPO && values?.expectedRoleId == "9"}
                          isSubmitting={isSubmitting}
                        />
                        <MButton
                          variant="primary"
                          title="Invite"
                          width={"fit-content"}
                          loader={inviteLoader}
                          disabled={isPO && values?.expectedRoleId == "9"}
                          onClick={() => handleReinvite(values, false)}
                        />
                        <MButton
                          variant="primary"
                          title="Reinvite"
                          loader={reInviteLoader}
                          disabled={isPO && values?.expectedRoleId == "9"}
                          width={"fit-content"}
                          onClick={() => handleReinvite(values, true)}
                        />
                        <MButton
                          variant="primary"
                          onClick={handleBackClick}
                          title="Back"
                          width={"fit-content"}
                        />
                      </div>
                    </div>
                  </FormikForm>
                )}
              </Formik>
            </div>
          </div>
          <div className="col-md-4"></div>
        </div>
      </div>
      <CustomAlertModal
        show={error !== null}
        handleClose={() => {
          setError(null);
        }}
        header={"Error!"}
        // @ts-ignore
        body={error?.response?.data.message || "There was an error processing your request. Please refresh the page and try again. If problems persist please contact myCHLA support."}
      />
    </>
  );
};

export default AccountRequestEdit;
