import React, { Children, useContext, useEffect, useState } from "react";
import {
  Form,
  Formik,
  FormikConfig,
  FormikErrors,
  FormikValues,
  useFormikContext,
} from "formik";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import Loader from "../common/Loader";
import referralFormContext, { referralSubmitContext } from "../../context/ReferralFormContext";
import MButton from "../mButton/MButton";

export interface FormikStepProps
  extends Pick<FormikConfig<FormikValues>, "children" | "validationSchema"> {
  label: string;
}

const FormikStepper = ({ children, ...props }: FormikConfig<FormikValues>) => {
  //
  const { setErrors } = useContext(referralFormContext);

  const childrenArray = Children.toArray(
    children
  ) as React.ElementType<FormikStepProps>[];
  const [step, setStep] = useState(1);
  const currentChild = childrenArray[step - 1];
  const [completed, setCompleted] = useState(false);
  const isLastStep = step === childrenArray.length;
  const submitDisabled = React.useContext(referralSubmitContext);

  console.log("submitDisabled: ", submitDisabled)

  return (
    <Formik
      {...props}
      //@ts-ignore
      validationSchema={currentChild.props.validationSchema}
      onSubmit={async (values, helpers) => {
        if (isLastStep) {
          await props.onSubmit(values, helpers);
          setCompleted(true);
        } else {
          setStep((s) => s + 1);
        }
      }}
    >
      {({ isSubmitting, errors }) => {
        if(isSubmitting){
          setErrors(() => errors);
        }
        
        return (
          <Form className="m-sm-5 p-1">
            <Stepper className="pb-sm-5" alternativeLabel activeStep={step - 1}>
              {childrenArray.map((child, index) => (
                <Step
                  //@ts-ignore
                  key={child.props.label}
                  completed={step - 1 > index || completed}
                >
                  <StepLabel>
                    {
                      //@ts-ignore
                      child.props.label
                    }
                  </StepLabel>
                </Step>
              ))}
            </Stepper>
            {currentChild}
            <div className="d-flex row align-items-center form-padding">
              <div className="col-sm-3"></div>
              <div className="col-sm-9 ">
                {step > 1 ? (
                  <button
                    className= {step == 3 ? "nrw_button_left step3_button" : "nrw_button_left"}
                    type="button"
                    style={{
                      borderRadius: 100,
                      paddingLeft: "1.5rem",
                      paddingRight: "1.5rem",
                      fontWeight: "bold",
                      letterSpacing: "1px",
                    }}
                    onClick={() => {
                      setErrors({})
                      setStep((s) => s - 1)
                    }}
                    disabled={isSubmitting}
                  >
                    <i className="fa fa-arrow-circle-left"></i> Back to Step{" "}
                    {step - 1}
                  </button>
                ) : null}
                
                <button
                  className="nrw_button_right"
                  type="submit"
                  style={{
                    borderRadius: 100,
                    paddingLeft: "1.5rem",
                    paddingRight: "1.5rem",
                    fontWeight: "bold",
                    letterSpacing: "1px",
                  }}
                  disabled={ (submitDisabled || isSubmitting || Object.keys(errors).length !== 0) && isLastStep }
                >
                  
                  {isSubmitting && Object.keys(errors).length === 0 ? (
                    <div className="d-flex align-items-center">
                      Submitting...
                      <Loader height={20} width={20} />{" "}
                    </div>
                  ) : isLastStep ? (
                    <>
                      Complete Referral <i className="fa fa-check-circle"></i>
                    </>
                  ) : (
                    <>
                      Continue to Step {step + 1}{" "}
                      <i className="fa fa-arrow-circle-right"></i>
                    </>
                  )}
                </button>
              </div>
            </div>
          </Form>
        );
      }}
    </Formik>
  );
};

export default FormikStepper;
