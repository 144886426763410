import { CancelTokenSource } from "axios";
import { customAxios as axios } from "./ServiceConfig";
import { API_BASE_URL } from "../config";
import IPGRSASResponse from "../types/IPGRSASResponse";
let loginas = localStorage.getItem('loginAsFlag')
let loginasEmail = localStorage.getItem('loginAsEmail')
/**
 * @param query receives the body to be sent in the post request
 * @param token receives the access token of type string
 */
const getPediatricGRServiceList = async (

  token?: string,
  cancelToken?: CancelTokenSource
) => {
  const result = await axios.get<any>(
    `${API_BASE_URL}/portalupdate/pediatricgrandround`,

    loginas !== null ? {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Pragma: "no-cache",
        Authorization: `Bearer ${token}`,
        loginas:loginas !== null ? loginas : JSON.stringify(false),
        email:loginasEmail !== null ? loginasEmail : "",
      },
      cancelToken: cancelToken?.token,
    }:{
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Pragma: "no-cache",
        Authorization: `Bearer ${token}`,
      },
      cancelToken: cancelToken?.token,
    }
  );
  return result;
};

const getPGRSupportedMediaType = async (
  token?: string,
  cancelToken?: CancelTokenSource
) => {
  const result = await axios.get<string[]>(
    `${API_BASE_URL}/portalupdate/pediatricGrandRound/SupportedMediaTypes`,

    loginas !== null ? {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Pragma: "no-cache",
        Authorization: `Bearer ${token}`,
        loginas:loginas !== null ? loginas : JSON.stringify(false),
        email:loginasEmail !== null ? loginasEmail : "",
      },
      cancelToken: cancelToken?.token,
    }:{
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Pragma: "no-cache",
        Authorization: `Bearer ${token}`,
      },
      cancelToken: cancelToken?.token,
    }
  );
  return result;
};

/**
 * @param id receives the patient id
 * @param token receives the access token of type string
 */
// const getAmbulatoryRequestById = async (
//   id: number,
//   token?: string,
//   cancelToken?: CancelTokenSource
// ) => {
//   const result = await axios.get(
//     `${API_BASE_URL}/patient/getpatientaccessrequestbyid/${id}`,
//     {
//       headers: {
//         Accept: "application/json",
//         "Content-Type": "application/json",
//         Pragma: "no-cache",
//         Authorization: `Bearer ${token}`,
//       },
//       cancelToken: cancelToken?.token,
//     }
//   );
//   return result;
// };

/**
 * @param data receives the referral form
 * @param token receives the access token of type string
 */
export async function createPediatricGRServiceRequest(
  data: any,
  setProgress?: React.Dispatch<React.SetStateAction<number | undefined>>,
  token?: string,
  cancelToken?: CancelTokenSource
) {
  const result = await axios.post<Number>(
    `${API_BASE_URL}/portalupdate/addpediatricgrandround`,
    data,
    loginas !== null ? {
      headers: {
        Accept: "application/json",
        "Content-Type": "multipart/form-data",
        Pragma: "no-cache",
        Authorization: `Bearer ${token}`,
        loginas:loginas !== null ? loginas : JSON.stringify(false),
        email:loginasEmail !== null ? loginasEmail : "",
      },
      onUploadProgress: (data:any) => {
        //Set the progress value to show the progress bar
        if (setProgress !== undefined) setProgress(Math.round((100 * data.loaded) / data.total))
      },
      cancelToken: cancelToken?.token,
    }:{
      headers: {
        Accept: "application/json",
        "Content-Type": "multipart/form-data",
        Pragma: "no-cache",
        Authorization: `Bearer ${token}`,
      },
      onUploadProgress: (data:any) => {
        //Set the progress value to show the progress bar
        if (setProgress !== undefined) setProgress(Math.round((100 * data.loaded) / data.total))
      },
      cancelToken: cancelToken?.token,
    }
  );
  return result;
}

export async function deletePediatricGRServiceRequest(
  id: any,
  token?: string,
  cancelToken?: CancelTokenSource
) {
  const result = await axios.delete<Number>(
    `${API_BASE_URL}/portalupdate/deletepediatricgrandround/${id}`,
    loginas !== null ? {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Pragma: "no-cache",
        Authorization: `Bearer ${token}`,
        loginas:loginas !== null ? loginas : JSON.stringify(false),
        email:loginasEmail !== null ? loginasEmail : "",
      },
      cancelToken: cancelToken?.token,
    }:{
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Pragma: "no-cache",
        Authorization: `Bearer ${token}`,
      },
      cancelToken: cancelToken?.token,
    }
  );
  return result;
}

export async function editPediatricGRServiceRequest(
  data: any,
  setProgress?: React.Dispatch<React.SetStateAction<number | undefined>>,
  token?: string,
  cancelToken?: CancelTokenSource
) {
  console.log(data.get("Video"));
  const result = await axios.put<string>(
    `${API_BASE_URL}/portalupdate/editpediatricgrandround`,
    data,
    loginas !== null ? {
      headers: {
        Accept: "application/json",
        "Content-Type": "multipart/form-data",
        Pragma: "no-cache",
        Authorization: `Bearer ${token}`,
        loginas:loginas !== null ? loginas : JSON.stringify(false),
        email:loginasEmail !== null ? loginasEmail : "",
      },
      onUploadProgress: (data:any) => {
        //Set the progress value to show the progress bar
        if (setProgress !== undefined) setProgress(Math.round((100 * data.loaded) / data.total))
      },
      cancelToken: cancelToken?.token,
    }:{
      headers: {
        Accept: "application/json",
        "Content-Type": "multipart/form-data",
        Pragma: "no-cache",
        Authorization: `Bearer ${token}`,
      },
      onUploadProgress: (data:any) => {
        //Set the progress value to show the progress bar
        if (setProgress !== undefined) setProgress(Math.round((100 * data.loaded) / data.total))
      },
      cancelToken: cancelToken?.token,
    }
  );
  return result;
}

export async function uploadPGRVideo(
  data: any,
  setProgress?: React.Dispatch<React.SetStateAction<number | undefined>>,
  token?: string,
  cancelToken?: CancelTokenSource
) {
  const result = await axios.post(
    `${API_BASE_URL}/portalupdate/uploadpgrvideo`,
    data,
    loginas !== null ? {
      headers: {
        Accept: "application/json",
        "Content-Type": "multipart/form-data",
        Pragma: "no-cache",
        Authorization: `Bearer ${token}`,
        loginas:loginas !== null ? loginas : JSON.stringify(false),
        email:loginasEmail !== null ? loginasEmail : "",
      },
      onUploadProgress: (data:any) => {
        //Set the progress value to show the progress bar
        if (setProgress !== undefined) setProgress(Math.round((100 * data.loaded) / data.total))
      },
      cancelToken: cancelToken?.token,
    }:{
      headers: {
        Accept: "application/json",
        "Content-Type": "multipart/form-data",
        Pragma: "no-cache",
        Authorization: `Bearer ${token}`,
      },
      onUploadProgress: (data:any) => {
        //Set the progress value to show the progress bar
        if (setProgress !== undefined) setProgress(Math.round((100 * data.loaded) / data.total))
      },
      cancelToken: cancelToken?.token,
    }
  );
  return result;
}

export async function pediatricGrandRoundSAS(
  id: Number,
  token?: string,
  cancelToken?: CancelTokenSource
) {
  const result = await axios.get<IPGRSASResponse>(
    `${API_BASE_URL}/portalupdate/pediatricgrandroundsas/${id}`,
    loginas !== null ? {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Pragma: "no-cache",
        Authorization: `Bearer ${token}`,
        loginas:loginas !== null ? loginas : JSON.stringify(false),
        email:loginasEmail !== null ? loginasEmail : "",
      },
      cancelToken: cancelToken?.token,
    }:{
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Pragma: "no-cache",
        Authorization: `Bearer ${token}`,
      },
      cancelToken: cancelToken?.token,
    }
  );
  return result;
}

const PediatricGRService = {
  getPediatricGRServiceList,
  getPGRSupportedMediaType,
  deletePediatricGRServiceRequest,
  createPediatricGRServiceRequest,
  editPediatricGRServiceRequest,
  uploadPGRVideo,
  pediatricGrandRoundSAS
};

export default PediatricGRService;
