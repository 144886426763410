import { CancelTokenSource } from "axios";
import { customAxios as axios } from "./ServiceConfig";
import { API_BASE_URL } from "../config";
import IEducation from "../types/IEducation";
import IPhysicianResources from "../types/IPhysicianResources";
let loginas = localStorage.getItem('loginAsFlag')
let loginasEmail = localStorage.getItem('loginAsEmail')
/**
 * @param token receives the access token of type string
 */
const getPhysicianResourceService = async (
  token?: string,
  cancelToken?: CancelTokenSource
) => {
  const result = await axios.get<IPhysicianResources>(
    `${API_BASE_URL}/portalupdate/physicianresources`,
    loginas !== null ? {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Pragma: "no-cache",
        Authorization: `Bearer ${token}`,
        loginas:loginas !== null ? loginas : JSON.stringify(false),
        email:loginasEmail !== null ? loginasEmail : "",
      },
      cancelToken: cancelToken?.token,
    }:{
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Pragma: "no-cache",
        Authorization: `Bearer ${token}`,
      },
      cancelToken: cancelToken?.token,
    }
  );
  return result;
};

/**
 * @param id receives the patient id
 * @param token receives the access token of type string
 */
// const getAmbulatoryRequestById = async (
//   id: number,
//   token?: string,
//   cancelToken?: CancelTokenSource
// ) => {
//   const result = await axios.get(
//     `${API_BASE_URL}/patient/getpatientaccessrequestbyid/${id}`,
//     {
//       headers: {
//         Accept: "application/json",
//         "Content-Type": "application/json",
//         Pragma: "no-cache",
//         Authorization: `Bearer ${token}`,
//       },
//       cancelToken: cancelToken?.token,
//     }
//   );
//   return result;
// };

/**
 * @param data receives the referral form
 * @param token receives the access token of type string
 */
export async function createPhysicianResourceService(
  data: FormData,
  token?: string,
  cancelToken?: CancelTokenSource
) {
  const result = await axios.post(
    `${API_BASE_URL}/portalupdate/downloadPdf`,
    data,
    loginas !== null ? {
      headers: {
        Accept: "application/json",
        "Content-Type": "multipart/form-data",
        Pragma: "no-cache",
        Authorization: `Bearer ${token}`,
        loginas:loginas !== null ? loginas : JSON.stringify(false),
        email:loginasEmail !== null ? loginasEmail : "",
      },
      cancelToken: cancelToken?.token,
    }:{
      headers: {
        Accept: "application/json",
        "Content-Type": "multipart/form-data",
        Pragma: "no-cache",
        Authorization: `Bearer ${token}`,
      },
      cancelToken: cancelToken?.token,
    }
  );
  return result;
}
const downloadPdfProviderRelation = async (
  data: FormData,
  token?: string,
  cancelToken?: CancelTokenSource
) => {
  const result = await axios.post(
    `${API_BASE_URL}/portalupdate/getresourcedocument`,
    data,
    loginas !== null ? {
      headers: {
        Accept: "application/json",
        "Content-Type": "multipart/form-data",
        Pragma: "no-cache",
        Authorization: `Bearer ${token}`,
        loginas:loginas !== null ? loginas : JSON.stringify(false),
        email:loginasEmail !== null ? loginasEmail : "",
      },
      cancelToken: cancelToken?.token,
    }:{
      headers: {
        Accept: "application/json",
        "Content-Type": "multipart/form-data",
        Pragma: "no-cache",
        Authorization: `Bearer ${token}`,
      },
      cancelToken: cancelToken?.token,
    }
  );
  return result;
};
export async function editPhysicianResourceService(
  data: FormData,
  token?: string,
  cancelToken?: CancelTokenSource
) {
  const result = await axios.put(
    `${API_BASE_URL}/portalupdate/editdivisionofmonth`,
    data,
    loginas !== null ? {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Pragma: "no-cache",
        Authorization: `Bearer ${token}`,
        loginas:loginas !== null ? loginas : JSON.stringify(false),
        email:loginasEmail !== null ? loginasEmail : "",
      },
      cancelToken: cancelToken?.token,
    }:{
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Pragma: "no-cache",
        Authorization: `Bearer ${token}`,
      },
      cancelToken: cancelToken?.token,
    }
  );
  return result;
}

const PhysicianResourceService = {
  getPhysicianResourceService,
  // getAmbulatoryRequestById,
  downloadPdfProviderRelation,
  createPhysicianResourceService,
  editPhysicianResourceService,
};

export default PhysicianResourceService;
