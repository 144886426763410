import { FC, useState, useEffect } from "react";
import { useLocation, useParams, Redirect } from "react-router-dom";
import { useOktaAuth } from "@okta/okta-react";
import { Alert } from "@mui/material";
import IAllAccounts from "../../../types/IAllAccounts";
import Spinner from "../../spinner/Spinner";
import Loader from "../../common/Loader";
import HeaderName from "../../headerName/HeaderName";
import BackButton from "../../backButton/BackButton";
import { Form, Col, Button, Table } from "react-bootstrap";
import MButton from "../../mButton/MButton";
import AccountService from "../../../services/AccountService";
import IPatientAccessQuery from "../../../types/IPatientAccessQuery";
import IUserName from "../../../types/IUserName"
import IAllAccountsRoles from "../../../types/IAllRoles";
import axios from "axios";
import '../allAccounts/components/all-accounts.css';
import {
  getFormattedDate,
  getFormattedTime,
  getFormattedDateFromDateTime,
  getFormattedTimeFromDateTime,
  getFormattedDateFromDate
} from "../../../helpers/DateHelper";
import IReferralFormDropdownData from "../../../types/IReferralFormDropdownData";
import ReferralService from "../../../services/ReferralService";
import { appInsights } from "../../../appInsights";
import PatientAccessRequestService from "../../../services/PatientAccessRequestService";

type stateType = {
  state: {
    account: IAllAccounts;
    allAccountsRoles?: any
  };
};


const AllAccountsListEdit: FC<{ name?: string }> = ({ name }) => {
  const { state } = useLocation<stateType>();
  const isDisabled = false;
  const { authState } = useOktaAuth();
  const accessToken = authState?.accessToken?.accessToken;
  const accountEdit: IAllAccounts = state.state.account;
  const allRoles = state.state.allAccountsRoles;

  const DEFAULT_VALUE = "";

  const [formData, setFormData] = useState({});
  const [query, setQuery] = useState<IUserName>({
    userName: accountEdit.email
  });
  const [securityQuestion, setSecurityQuestion] = useState<IUserName>({
    userName: accountEdit.email,
    answer: ""
  });
  const DATE = getFormattedDate(new Date());
  const [loading, setLoading] = useState(true);
  const [loadingOKTAVerify, setLoadingOKTAVerify] = useState(false);
  const [statusOKTA, setStatusOKTA] = useState('');
  const [oktaAnswer, setOktaAnswer] = useState('');
  const [error, setError] = useState<Error>();
  const [tempPassword, setTempPassword] = useState("");
  const cancelToken = axios.CancelToken.source();
  const [allAccountsDetail, setallAccountsDetail] = useState<any>({});
  const [securityAnswerOKTA, setSecurityAnswerOKTA] = useState();
  const [redirect, setRedirect] = useState({ redirect: false, message: "" });

  const [apiCallCount, setApiCallCount] = useState(0);






  useEffect(() => {
    setLoading(true);
    // POST endpoint for getting all account details
    AccountService.getUserAccount(query, accessToken, cancelToken)
      .then((response) => {
        if (error !== undefined) {
          setError(undefined);
        }
        setallAccountsDetail(response.data);
        setStatusOKTA(response.data.accountStatus);
        setFormData(response.data)
        setLoading(false);
      })
      .catch((e) => {
        if (!axios.isCancel(e)) {
          console.error(e);
          appInsights.trackException({ error: e });
           setError(e);
        }
        
         setLoading(false);
      });

  }, [apiCallCount]);

  const navigateToPage = (page: number) => {
    setApiCallCount((c) => c + 1);
    window.scrollTo(0, 0);
  };

  const handleOnChange = ((type: any, e: any, key: any) => {
    
    let data: any = formData;
    data[key] = e.target.value;
    setFormData(data);

  });

  const handleOnChangeOKTAVerify = ((e: any) => {
   
    setSecurityQuestion({ userName: accountEdit.email, answer: e.target.value });
  });
  const handleDefaultValue = (type: any, key: any) => {
    let finalValue = DEFAULT_VALUE;

    if (key === "firstName") {
      return allAccountsDetail.firstName;
    }
    if (key === "lastName") {
      return allAccountsDetail.lastName;
    }
    if (key === "mobilePhoneNumber") {
      return allAccountsDetail.mobilePhoneNumber;
    }
    if (key === "officePhoneNumber") {
      return allAccountsDetail.officePhoneNumber;
    }
    if (key === "email") {
      return allAccountsDetail.email;
    }
  };

  const SecurityAnswerOKTAVerify = () => {
    setLoadingOKTAVerify(true);
    // POST endpoint for Security Answer verify
    AccountService.getVerifyOktaQuestion(securityQuestion, accessToken, cancelToken)
      .then((response) => {
        if (error !== undefined) {
          setError(undefined);
        }
        setSecurityAnswerOKTA(response.data);
        setLoadingOKTAVerify(false);


      })
      .catch((e) => {
        if (!axios.isCancel(e)) {
          console.error(e);
          appInsights.trackException({ error: e });
          setError(e);
        }
        
        setLoadingOKTAVerify(false);

      });

    return () => {
      cancelToken.cancel();
    };

  }
  const onPasswordReset = () => {
    setLoading(true);

    AccountService.requestPasswordReset(query, accessToken, cancelToken)
      .then((response) => {
        if (error !== undefined) {
          setError(undefined);
        }
        if (response.status == 200) {
          setRedirect({ redirect: false, message: "onPasswordReset" });
          setLoading(false);

        }

      })
      .catch((e) => {
        if (!axios.isCancel(e)) {
          console.error(e);
          appInsights.trackException({ error: e });
           setError(e);
        }
        
        setRedirect({ redirect: false, message: "errorLinks" });
        setLoading(false);


      });

  }
  const onTemporaryPasswordClick = () => {
    setLoading(true);

    AccountService.generateTempPassword(query, accessToken, cancelToken)
      .then((response) => {
        if (error !== undefined) {
          setError(undefined);
        }
        if (response.status == 200) {
          setTempPassword(response.data)
          setRedirect({ redirect: false, message: "onTemporaryPasswordClick" });
          setLoading(false);
        }
      })
      .catch((e) => {
        if (!axios.isCancel(e)) {
          console.error(e);
          appInsights.trackException({ error: e });
          setError(e);
        }
       
        setRedirect({ redirect: false, message: "errorLinks" });
        setLoading(false);


      });

  }

  const onResetMFA = () => {
    setLoading(true);

    AccountService.resetMFA(query, accessToken, cancelToken)
      .then((response) => {
        if (error !== undefined) {
          setError(undefined);
        }
        if (response.status == 200) {
          setRedirect({ redirect: false, message: "onResetMFA" });
          setLoading(false);

        }
      })
      .catch((e) => {
        if (!axios.isCancel(e)) {
          console.error(e);
          appInsights.trackException({ error: e });
         setError(e);
        }
       
        setRedirect({ redirect: false, message: "errorLinks" });
        setLoading(false);

      });
  }

  const accountStatusOnClick = (status: string) => {
    setLoading(true);
    AccountService.accountStatus(query, accessToken, cancelToken, status)
      .then((response) => {
        if (error !== undefined) {
          setError(undefined);
        }
        if (response.status == 200) {
          setStatusOKTA(status);
          setLoading(false);
          navigateToPage(1);

        }
      })
      .catch((e) => {
        if (!axios.isCancel(e)) {
          console.error(e);
          appInsights.trackException({ error: e });
           setError(e);
        }
        
        setRedirect({ redirect: false, message: "errorLinks" });
        setLoading(false);

      });

  }



  const handleSubmit = (e: any) => {
    e.preventDefault();

    AccountService.editAccountsList(formData, accessToken)
      .then((response) => {
        setRedirect({ redirect: false, message: "success" });
        setTimeout(() => {
          setRedirect({ redirect: true, message: "" });
        }, 3000);
      })
      .catch((err) => {
        if (!axios.isCancel(e)) {
          console.error(e);
          appInsights.trackException({ error: e });
           setError(e);
        }
        
        setRedirect({ redirect: false, message: "error" });
      });
  };

  let form = require("../../../data/editAllAccount.json");

  let verifyMessage;

  if (securityAnswerOKTA === true) {
    verifyMessage = <div style={{ color: 'var(--color-green)' }}><i className="fa fa-check" aria-hidden="true"></i>Correct</div>

  }
  else if (securityAnswerOKTA === false) {
    verifyMessage = <div style={{ color: 'var(--color-red)' }}><i className="fa fa-window-close" aria-hidden="true"></i>Not Correct</div>
  }
  else {
    verifyMessage = <></>
  }




  if (redirect?.redirect) {
    return (
      <Redirect
        to={{
          pathname: "/account/list",
          state: { message: redirect.message },
        }}
      />
    );
  }
  else {
    let headerName = { name: name + " > Edit" };

    return (
      <>
        <HeaderName name={headerName.name} />



        {redirect.message === "success" ? (
          <>
            {window.scrollTo(0, 0)}
            <Alert severity="success">
              Form is successfully submitted,please wait while we are
              redirecting!
            </Alert>
          </>
        ) : redirect.message === "error" ? (
          <>
            {window.scrollTo(0, 0)}
            <Alert severity="error" style={{ color: "#EA1D36" }}>
              Error in submitting the form, please try again!
            </Alert>
          </>

        ) : redirect.message === "onPasswordReset" ? (
          <>
            {window.scrollTo(0, 0)}
            <Alert severity="success">
              {`A Reset Password link has been sent to the email address ${accountEdit.email}. Please advise user to check the inbox ( or Spam folder ) and follow the instructions in the email.`}
            </Alert>
          </>
        ) :
          redirect.message === "onTemporaryPasswordClick" ? (<>
            {window.scrollTo(0, 0)}
            <Alert severity="success">
              {`User's Temporary Password: ${tempPassword}`}
            </Alert>
          </>)
            : redirect.message === "onResetMFA" ? (<>
              {window.scrollTo(0, 0)}
              <Alert severity="success">
                {`User's MFA factors have been reset. User will be prompted for new factors the next time they log in.`}
              </Alert>
            </>)
              : redirect.message === "errorLinks" ? (
                <>
                  {window.scrollTo(0, 0)}
                  <Alert severity="error" style={{ color: "#EA1D36" }}>
                    Something went wrong, please try again!
                  </Alert>
                </>

              ) : <></>
        }
        {loading ? (
          <div className="d-flex row justify-content-between">
            <div className="col loader mb-3">
              <Loader />
            </div>
          </div>
        ) :
          (Object.keys(allAccountsDetail).length === 0)
            ? (<>
              <Alert severity="error">
                {`User is not in OKTA`}
              </Alert>
              <BackButton  />

            </>) : (
              <div id="patient-details" className="container">

                <div className="row chartLayoutContainer">

                  <div className="top-content">
                    <div className="top-left-content">
                      <h3 className="header-blue bold me-2">
                        {`Account Edit | ${allAccountsDetail.firstName} ${allAccountsDetail.lastName}`}
                      </h3>
                      <BackButton  />
                    </div>
                  </div>
                  <Col sm={9} className="pl-0 pr-0">
                    <div className="new-patient-access-request">
                      <form className="m-sm-5" onSubmit={handleSubmit}>
                        {Object.entries(form).map((field, index) => {
                          const key = field[0];
                          const value: any = field[1];
                          return (
                            <Form.Group
                              key={index}
                              className="d-flex row align-items-center form-padding"
                            >
                              <Form.Label column sm={3} className="pl-0 pr-0">
                                {value.display}
                                {value.required ? (
                                  <span className="p-0 asterik"> *</span>
                                ) : null}
                              </Form.Label>
                              <Col sm={9} className="pl-0 pr-0">
                                <Form.Control
                                  onChange={(e) => handleOnChange(value.type, e, key)}
                                  type={value.type}
                                  required={value.required}
                                  defaultValue={handleDefaultValue(value.type, key)}
                                  disabled={isDisabled}
                                />
                              </Col>
                            </Form.Group>
                          );
                        })}
                        <Form.Group className="d-flex row align-items-center form-padding">
                          <Form.Label column sm={3} className="pl-0 pr-0">

                          </Form.Label>
                          <Col sm={9} className="pl-0 pr-0">
                            <Table bordered style={{ marginTop: '1rem' }}>
                              <thead>
                                <tr>
                                  <th style={{ width: '50%', paddingLeft: '10px' }}>Role(s)</th>
                                  <th style={{ paddingLeft: '10px' }}>Practices</th>
                                </tr>
                              </thead>
                              <tbody>
                                {allAccountsDetail.accountRolePerPractice.length === 0 ? <div>No Record found</div> : <></>}
                                {allAccountsDetail.accountRolePerPractice?.map((rolePractice: any) => {
                                  return (
                                    <tr key={rolePractice.practiceId} style={{ backgroundColor: 'white' }}>
                                      <td className="td-practice">{rolePractice.roleName}</td>
                                      <td className="td-practice">{rolePractice.practiceName}</td>
                                    </tr>
                                  )
                                })}

                              </tbody>

                            </Table>
                          </Col>
                        </Form.Group>

                        <Form.Group className="d-flex row align-items-center form-padding">
                          <Form.Label column sm={3} className="pl-0 pr-0">
                            Email
                          </Form.Label>
                          <Col sm={9} className="pl-0 pr-0">
                            {allAccountsDetail.email}
                          </Col>
                        </Form.Group>
                        <Form.Group className="d-flex row align-items-center form-padding">
                          <Form.Label column sm={3} className="pl-0 pr-0">
                            Account Status
                          </Form.Label>
                          <Col sm={9} className="pl-0 pr-0">
                            <div style={{ fontWeight: '600', display: 'inline' }}>{statusOKTA === 'LOCKED_OUT' ? <span style={{ color: 'var(--color-red)' }}>{statusOKTA}</span> : statusOKTA === 'ACTIVE' ? <span style={{ color: 'var(--color-green)' }}>{statusOKTA}</span> : statusOKTA === 'SUSPENDED' ? <span style={{ color: 'var(--color-red)' }}>{statusOKTA}</span> : statusOKTA === "STAGED" ? <span style={{ color: 'var(--color-red)' }}>{`${statusOKTA} (Pending user action)`}</span> : statusOKTA === "RECOVERY" ? <span style={{ color: 'var(--color-red)' }}>{statusOKTA}</span> : statusOKTA === "PASSWORD_EXPIRED" ? <span style={{ color: 'var(--color-red)' }}>{statusOKTA}</span> : statusOKTA === "PROVISIONED" ? <span style={{ color: 'var(--color-red)' }}>{`${statusOKTA} (Pending user action)`}</span> : statusOKTA} </div>
                            {statusOKTA === 'STAGED' || statusOKTA === 'PROVISIONED' || statusOKTA === 'LOCKED_OUT' || statusOKTA === 'SUSPENDED' ? (
                              <>
                                <MButton
                                  onClick={() => accountStatusOnClick(allAccountsDetail.accountStatus)}
                                  title={statusOKTA === 'STAGED' ? 'SEND ACTIVATION EMAIL' : statusOKTA === 'LOCKED_OUT' ? 'UNLOCK' : statusOKTA === 'PROVISIONED' ? 'SEND ACTIVATION EMAIL' : statusOKTA === 'SUSPENDED' ? 'ACTIVATE' : ''}
                                  variant='success'
                                  small
                                  width="auto"
                                  marginLeft="10px"
                                />
                              </>
                            ) : <></>}
                            {statusOKTA === 'SUSPENDED' ? <></> : <MButton
                              onClick={() => accountStatusOnClick('SUSPEND')}
                              title="SUSPEND"
                              variant="danger"
                              small
                              width="auto"
                              marginLeft="10px"
                            />}



                          </Col>
                        </Form.Group>

                        <Form.Group className="d-flex row align-items-center form-padding">
                          <Form.Label column sm={3} className="pl-0 pr-0">
                            Last Login
                          </Form.Label>
                          <Col sm={9} className="pl-0 pr-0">
                            {allAccountsDetail.lastLoggedIn !== null ? (
                              `${getFormattedDateFromDate(allAccountsDetail?.lastLoggedIn?.split('T')[0])} ${getFormattedTimeFromDateTime(allAccountsDetail?.lastLoggedIn)}`
                            ) : (
                              <></>
                            )}
                          </Col>
                        </Form.Group>
                        <Form.Group className="d-flex row align-items-center form-padding">
                          <Form.Label column sm={3} className="pl-0 pr-0">
                            Last Modified
                          </Form.Label>
                          <Col sm={9} className="pl-0 pr-0">
                            {allAccountsDetail.lastModifiedOn !== null ? (
                               `${getFormattedDateFromDate(allAccountsDetail?.lastModifiedOn?.split('T')[0])} ${getFormattedTimeFromDateTime(allAccountsDetail?.lastModifiedOn)}`
                            ) : (
                              <></>
                            )}
                          </Col>
                        </Form.Group>
                        <Form.Group className="d-flex row align-items-center form-padding">
                          <Form.Label column sm={3} className="pl-0 pr-0">
                            Security Question
                          </Form.Label>
                          <Col sm={9} className="pl-0 pr-0">
                            {allAccountsDetail.securityQuestion !== null ? allAccountsDetail.securityQuestion : <>None</>}
                          </Col>
                        </Form.Group>
                        {allAccountsDetail.securityQuestion ? (
                          <Form.Group className="d-flex row align-items-center form-padding">
                            <Form.Label column sm={3} className="pl-0 pr-0">
                              Verify Answer
                            </Form.Label>
                            <Col
                              sm={6}
                              className="pl-0 pr-0"
                              style={{ display: "flex" }}
                            >
                              {
                                <Form.Control
                                  onChange={(e) => handleOnChangeOKTAVerify(e)}
                                  type="password"
                                />
                              }
                              <MButton
                                onClick={() => SecurityAnswerOKTAVerify()}
                                title="Verify"
                                variant="primary"
                                small
                                width="auto"
                                marginLeft="10px"
                              />
                            </Col>
                            {loadingOKTAVerify === true ? <div className="col loader mb-3"><Loader /></div> : <div className="col verify ">{verifyMessage}</div>}
                          </Form.Group>
                        ) : <></>}


                        <Form.Group className="d-flex row">
                          <Col
                            sm={{ span: 9, offset: 3 }}
                            className="d-flex"
                          >
                            <Button className="submit-btn" type="submit">
                              Submit
                            </Button>
                          </Col>
                        </Form.Group>
                      </form>
                    </div>
                  </Col>

                  <Col sm={3} className="pl-0 pr-0">
                    {statusOKTA === 'STAGED' || statusOKTA === 'SUSPENDED' || statusOKTA === 'PROVISIONED' ? (
                      <>

                      </>
                    ) : <>
                      <MButton
                        onClick={() => onPasswordReset()}
                        title="Send Password Reset Link"
                        variant="neutral"
                        small
                        width="80%"
                      />
                      <MButton
                        onClick={() => onTemporaryPasswordClick()}
                        title="Generate Temporary Password"
                        variant="neutral"
                        small
                        width="80%"
                      />
                      <MButton
                        onClick={() => onResetMFA()}
                        title="Reset MFA Factors"
                        variant="neutral"
                        small
                        width="80%"
                      />
                    </>}

                  </Col>
                </div>
              </div>

            )}
        {/* )} */}

      </>
    );
  }
};

export default AllAccountsListEdit;
