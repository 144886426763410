export default ({ height = 24, width = 24, color = "#6C757D" }) => {
  return (
    <div className="d-flex align-items-center">
      <span
        style={{ color, height: height, width: width }}
        className="spinner-border"
        role="status"
      >
        <span className="sr-only"></span>
      </span>
      {/* <span style={{ color: "#6C757D" }} className="pl-2">
        Loading...
      </span> */}
    </div>
  );
};
