import { FC } from "react";
import { Link,useHistory } from "react-router-dom";
import "./backButton.css";

interface Props {
  link?: string;
  style?:string;
}

const BackButton: FC<Props> = ({ link, style }) => {
  const history = useHistory()
  return (
    <>
      <div className={`link-style span-link-btn ${style == 'labResults' ? style : 'back_btn'}`} >
        <a
        id='link'
          className= "new"       
          onClick={()=> {
            if (history.location.pathname.includes("/physicians&practices/edit/practice/")) {
              localStorage.setItem("gotFrom", "/physicians&practices/edit/practice/");
            }
            history.goBack()
          }}
        >
         {"\u00ab"+" Back to List "}
        </a>
      </div>
    </>
  );
};

export default BackButton;
