import { FC, useContext, useEffect, useState } from "react";
import { useOktaAuth } from "@okta/okta-react";
import HomeService from "../../../services/HomeService";
import UserService from "../../../services/UserService";
import Spinner from "../../spinner/Spinner";
import Jumbotron from "./Jumbotron";
import { Alert } from "react-bootstrap";
import menuItemsContext from "../../../context/MenuItemsContext";
import useSliderImages from "../../../hooks/useSliderImages";
import InfowellSection from "./InfowellSection";
import useUserRole from "../../../hooks/useUserRole";
import TourScreen from "../../common/TourScreen";
import { appInsights } from "../../../appInsights";
import axios from "axios";
import { useHistory, useLocation } from "react-router-dom";
import IPracticeRoles from "../../../types/IPracticeRoles";

const Home: FC = () => {
  const { authState } = useOktaAuth();
  const accessToken = authState?.accessToken?.accessToken;
  const [sliderImages, setSliderImages] = useSliderImages();
  const { setMenuItems } = useContext(menuItemsContext);
  const [error, setError] = useState<Error | null>(null);
  const [loading, setLoading] = useState(true);
  const [userRole, setUserRole] = useUserRole();

  const location = useLocation();
  const history = useHistory();

  const loggedIn = JSON.parse(window.localStorage.getItem("loggedIn") ?? "{}");
  const user_role = JSON.parse(
    window.localStorage.getItem("user_role") ?? "{}"
  );

  useEffect(() => {
    setError(null);
    if (authState?.isAuthenticated) {
      setLoading(true);
      if (
        JSON.stringify(user_role) === "{}" ||
        (JSON.stringify(user_role) !== "{}" &&
          JSON.stringify(loggedIn) !== "{}")
      ) {
        UserService.getPracticeRoles(accessToken)
          .then((response) => {
            setUserRole(response.data);
            if (
              response.data.practiceRoles.filter(
                (role) => role.roleId === "9" && role.active
              ).length !== 0
            ) {
              // user is a practice owner
              // check if user returned from docusign
              if (location.search.includes("event")) {
                let status = location.search.split("event=")[1];
                UserService.updateUserAgreement(status, accessToken).then(
                  (response) => {
                    if (response.data === true) {
                      // go on to show the ui
                      UserService.getMenuItems(accessToken)
                        .then((response) => {
                          setMenuItems(response.data);
                          setLoading(false);
                        })
                        .catch((e) => {
                          if (!axios.isCancel(e)) {
                            console.error(e);
                            appInsights.trackException({ error: e });
                            setError(e);
                            setLoading(false);
                          }
                        });
                    } else {
                      history.push("/");
                    }
                  }
                );
              } else {
                UserService.getUserAgreement(accessToken).then((response) => {
                  if (response.data.signRequired) {
                    window.location.replace(response.data.docusignUrl || "/");
                  } else {
                    UserService.getMenuItems(accessToken)
                      .then((response) => {
                        setMenuItems(response.data);
                        setLoading(false);
                      })
                      .catch((e) => {
                        if (!axios.isCancel(e)) {
                          console.error(e);
                          appInsights.trackException({ error: e });
                          setError(e);
                          setLoading(false);
                        }
                      });
                  }
                });
              }
            } else {
              UserService.getMenuItems(accessToken)
                .then((response) => {
                  setMenuItems(response.data);
                  setLoading(false);
                })
                .catch((e) => {
                  if (!axios.isCancel(e)) {
                    console.error(e);
                    appInsights.trackException({ error: e });
                    setError(e);
                    setLoading(false);
                  }
                });
            }
          })
          .catch((e) => {
            if (!axios.isCancel(e)) {
              console.error(e);
              appInsights.trackException({ error: e });
              setError(e);
              setLoading(false);
            }
          });
      }
      else {
        setUserRole(user_role);
          if (
            userRole?.practiceRoles.filter(
              (role) => role.roleId === "9" && role.active
            ).length !== 0
          ) {
            // user is a practice owner
            // check if user returned from docusign
            if (location.search.includes("event")) {
              let status = location.search.split("event=")[1];
              UserService.updateUserAgreement(status, accessToken).then(
                (response) => {
                  if (response.data === true) {
                    // go on to show the ui
                    UserService.getMenuItems(accessToken)
                      .then((response) => {
                        setMenuItems(response.data);
                        setLoading(false);
                      })
                      .catch((e) => {
                        if (!axios.isCancel(e)) {
                          console.error(e);
                          appInsights.trackException({ error: e });
                          setError(e);
                          setLoading(false);
                        }
                      });
                  } else {
                    history.push("/");
                  }
                }
              );
            } else {
              UserService.getUserAgreement(accessToken).then((response) => {
                if (response.data.signRequired) {
                  window.location.replace(response.data.docusignUrl || "/");
                } else {
                  UserService.getMenuItems(accessToken)
                    .then((response) => {
                      setMenuItems(response.data);
                      setLoading(false);
                    })
                    .catch((e) => {
                      if (!axios.isCancel(e)) {
                        console.error(e);
                        appInsights.trackException({ error: e });
                        setError(e);
                        setLoading(false);
                      }
                    });
                }
              });
            }
          } else {
            UserService.getMenuItems(accessToken)
              .then((response) => {
                setMenuItems(response.data);
                setLoading(false);
              })
              .catch((e) => {
                if (!axios.isCancel(e)) {
                  console.error(e);
                  appInsights.trackException({ error: e });
                  setError(e);
                  setLoading(false);
                }
              });
          }
      }
    } else {
      setLoading(false);
      setMenuItems([
        {
          id: 1,
          items: [],
          parent: "Home",
          viewOrder: 1,
        },
        {
          id: 2,
          items: [],
          parent: "My Patients",
          viewOrder: 2,
        },
        {
          id: 3,
          items: [],
          parent: "My Referrals",
          viewOrder: 3,
        },
        {
          id: 9,
          items: [],
          parent: "Physician Resources",
          viewOrder: 4,
        },
        {
          id: 10,
          items: [],
          parent: "Education",
          viewOrder: 5,
        },
      ]);
    }
    HomeService.getSliderImages()
      .then((response) => {
        setSliderImages(response.data);
      })
      .catch((e) => {
        if (!axios.isCancel(e)) {
          appInsights.trackException({ error: e });
          setError(e);
        }
      });
  }, [authState?.isAuthenticated]);

  if (error !== null) {
    return <Alert variant="danger">{error?.message}</Alert>;
  }
  return (
    <div className="container p-0" data-testid="auth-content">
      {loading ? (
        <Spinner margin="5rem 0" />
      ) : (
        <>
         {userRole?.lastLoggedIn != null || userRole?.lastLoggedIn != undefined ? (userRole?.lastLoggedIn === null ||
            userRole?.lastLoggedIn.length == 0) ? (
            <TourScreen />
          ) : (
            <></>
          ):null}
          <Jumbotron sliderImages={sliderImages} />
          <InfowellSection />
        </>
      )}
    </div>
  );
};

export default Home;
