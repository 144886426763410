import { FC, useEffect, useState } from "react";
import HeaderName from "../../../headerName/HeaderName";
import { Form, Col, Button } from "react-bootstrap";
import ReferralFormStructure from "../../../../data/ReferralFormStructure";
import UploadImageService from "../../../../services/UploadImageService";
import { useOktaAuth } from "@okta/okta-react";
import { Alert } from "@mui/material";
import { Redirect, useHistory, useLocation } from "react-router-dom";
import MButton from "../../../mButton/MButton";
import Loader from "../../../common/Loader";
import BackButton from "../../../backButton/BackButton";
import { appInsights } from "../../../../appInsights";
import axios from "axios";
function bytesToSize(bytes: number): string {
  const sizes: string[] = ["Bytes", "KB", "MB", "GB", "TB"];
  if (bytes === 0) return "n/a";
  const i: number = parseInt(
    Math.floor(Math.log(bytes) / Math.log(1024)).toString()
  );
  if (i === 0) return `${bytes} ${sizes[i]}`;
  return `${(bytes / Math.pow(1024, i)).toFixed(1)} ${sizes[i]}`;
}
const UploadImages: FC<{
  name?: string;
}> = ({ name }) => {
  const attachmentsFormStructure = ReferralFormStructure.filter(
    (r) => r.belongsTo === "Attachments"
  );

  const [formData, setFormData] = useState<FormData>(new FormData());
  const { authState } = useOktaAuth();
  const accessToken = authState?.accessToken?.accessToken;
  const [redirect, setRedirect] = useState({ redirect: false, message: "" });
  const [message, setMessage] = useState("");
  const [status, setStatus] = useState(0);
  const [loading, setLoading] = useState(false);
  const [flag, setFlag] = useState(false);
  const [flag1, setFlag1] = useState(false);
  const handleOnChange = (e: any, key: string) => {
    let data = new FormData();
    let value: any;
    if (key === "Attachment") {
      if (e.target.files.length !== 0) {
        let img = new Image();
        img.src = window.URL.createObjectURL(e.target.files[0]);
        img.onload = () => {
          if (img.width === 1140 && img.height === 450) {
            setFlag(false);
            value = e.target.files[0];
            data.append(key, value);
            setFormData((prev) => {
              let p = prev;
              if (p.get(key) == undefined) {
                p.append(key, value);
              } else {
                p.set(key, value);
              }
      
              return p;
            });
            return;
          } else {
            setFlag(true);
          }
        };
      } else {
        setFlag(false);
      }
    } else {
      value = e.target.value;
    }

    if (formData != undefined) {
      setFormData((prev) => {
        let p = prev;
        if (p.get(key) == undefined) {
          p.append(key, value);
        } else {
          p.set(key, value);
        }
        return p;
      });
    }
  };

  const handleSubmit = (e: any) => {
    e.preventDefault();
    if (formData === undefined) {
      alert("Cannot submit an empty form. Please attach an image to upload.");
    } else {
      setLoading(true);
      UploadImageService.createSliderImageRequest(formData, accessToken)
        .then((response) => {
          setRedirect({ redirect: false, message: "success" });
          setTimeout(() => {
            setLoading(false);
            setRedirect({ redirect: true, message: "" });
          }, 3000);
        })
        .catch((err) => {
          if (!axios.isCancel(e)) {
            appInsights.trackException({ error: e });
            // setError(e);
          }
          setMessage(err.response.data.message);
          setStatus(err.response.data.status);
          setLoading(false);
          setRedirect({ redirect: false, message: "error" });
        });
    }
  };
  if (redirect.redirect) {
    return (
      <Redirect
        to={{
          pathname: "/portalupdates/sliderimages",
          state: { message: redirect.message },
        }}
      />
    );
  } else {
    return (
      <div className="referrals">
        <HeaderName name={name} />
        <BackButton  />
        {redirect.message === "success" ? (
          <>
            {window.scrollTo(0, 0)}
            <Alert severity="success">
              Form is successfully submitted,please wait while we are
              redirecting!
            </Alert>
          </>
        ) : redirect.message === "error" ? (
          <>
            {window.scrollTo(0, 0)}
            <Alert severity="error" style={{ color: "#EA1D36" }}>
              Error in submitting the form, please try again!
            </Alert>
          </>
        ) : redirect.message ? (
          <>
            {window.scrollTo(0, 0)}
            <Alert severity="error" style={{ color: "#EA1D36" }}>
              {redirect.message}
            </Alert>
          </>
        ) : (
          <></>
        )}
        <div className="container">
          <h2 className="heading-main">Add New Slider</h2>

          <div className="d-flex row justify-content-end"></div>
          <div className="referral">
            <h2 style={{ fontSize: 20, fontWeight: "normal" }}>
              To upload an image, please make sure that:
            </h2>
            <li>The file extension is(jpg, png or gif)</li>
            <li>Image size should not exceed 200KB</li>
            <li>Image height must be 450, and width must be 1140</li>
          </div>
          <form onSubmit={handleSubmit}>
            <Form.Group controlId="formFileSm" className="mb-3">
              <Form.Label>Image to upload <span className="p-0 asterik"> *</span></Form.Label>
              <Form.Control
                type="file"
                size="sm"
                accept="image/*"
                onChange={(e) => handleOnChange(e, "Attachment")}
              />
            </Form.Group>
            {flag === true ? (
              <div style={{ color: "#EA1D36" }}>
                Image Height And Width is not Valid
              </div>
            ) : (
              <div></div>
            )}
            <div style={{ color: "#EA1D36" }}>{message} </div>
            <Form.Group>
              <Form.Label className="pl-0 pr-0">
                Target URL <span className="p-0 asterik"> *</span>
              </Form.Label>

              <Col sm={6} className="pl-0 pr-0">
                <Form.Control
                  placeholder=""
                  required
                  type="Url"
                  onChange={(e) => handleOnChange(e, "TargetUrl")}
                />
              </Col>
            </Form.Group>
            <br />
            <MButton
              variant="primary"
              disabled={flag === true || status === 400 ? true : false}
              title="Create New Slider"
              width={"fit-content"}
              type="submit"
            >
              Save
            </MButton>
            <Col
          sm={{ span: 9, offset: 3 }}
          className="d-flex justify-content-center"
        >
          {loading && <Loader />}
        </Col>
          </form>
        </div>
      </div>
    );
  }
};

export default UploadImages;
