import { useEffect, useState } from "react";

const useDebounceQuery = (query: string, time = 500) => {
    const [debounceQuery, setDebounceQuery] = useState<string>(query);

    useEffect(() => {
        const timeout = setTimeout(() => {
            setDebounceQuery(query);
        }, time);
            
        return () => {
            clearTimeout(timeout);
        }
    }, [query, time]);

    return debounceQuery;
}
export default useDebounceQuery;