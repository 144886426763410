import React from "react";
import { FC, useEffect, useState } from "react";
import HeaderName from "../../../headerName/HeaderName";
import { Badge, OverlayTrigger, Table } from "react-bootstrap";
import FormPopover from "../../../common/FormPopover";
import IReferralQuery from "../../../../types/IReferralQuery";
import IReferral from "../../../../types/IReferral";
import Sortable from "../../../common/Sortable";
import MButton from "../../../mButton/MButton";
import CustomPagination from "../../../common/CustomPagination";
import { Form, Col, Button } from "react-bootstrap";
import DateHelper, { getFormattedDate } from "../../../../helpers/DateHelper";
import SubForm from "./SubForm";
import { Redirect, useHistory, useLocation } from "react-router-dom";
import { Alert } from "@mui/material";
import { useOktaAuth } from "@okta/okta-react";
import OnlineCMEService from "../../../../services/OnlineCMEService";
import { title } from "process";
import BackButton from "../../../backButton/BackButton";
import Loader from "../../../common/Loader";
import { appInsights } from "../../../../appInsights";
import axios from "axios";
const NewOnlineCMEForms: FC<{
  name?: string;
}> = ({ name }) => {
  const isDisabled = false;
  const [delegationToggle, setdelegationToggle] = useState(true);
  const [formData, setFormData] = useState<any>({});
  const DATE = getFormattedDate(new Date());
  const [loading, setLoading] = useState(false);

  const { authState } = useOktaAuth();
  const accessToken = authState?.accessToken?.accessToken;
  const [html, setHtml] = useState("");
  const [redirect, setRedirect] = useState({
    redirect: false,
    message: "",
    errorMessage: "",
  });
  const handleFormClick = (
    title: string,
    contentTitle: string[],
    contentMessage: string[],
    contentBulletLine: string[],
    contentFullname: string[],
    contentSignature: string[]
  ) => {
    setFormData((prev: any) => ({
      ...prev,
      title: title,
      contentTitle: contentTitle,
      contentMessage: contentMessage,
      contentBulletLine: contentBulletLine,
      contentFullname: contentFullname,
      contentSignature: contentSignature,
    }));
  };
  const handleOnChange = (e: any, key: any) => {
    let value: any;
    value = e.target.value;
    if (key === "isActive") {
      value = e.target.checked;
    } else {
      value = e.target.value;
    }

    setFormData((prev: any) => ({ ...prev, [key]: value }));
  };

  let form = require("../../../../data/newOnlineCMEForm.json");

  const handleSubmit = (e: any) => {
    window.scrollTo(0, 0);
    e.preventDefault();
    if (formData.title === undefined || formData.title.trim() === "") {
      {
        window.scrollTo(0, 0);
      }
      setRedirect({
        redirect: false,
        message: "error",
        errorMessage: "Title is missing",
      });
      return;
    }
    for (let i = 0; i < formData.contentTitle.length; i++) {
      if (
        formData.contentTitle[i] != "" ||
        formData.contentTitle != undefined
      ) {
        if (formData.contentTitle[i].trim() == "") {
          {
            window.scrollTo(0, 0);
          }
          setRedirect({
            redirect: false,
            message: "error",
            errorMessage: "Do not put space as title",
          });
          return;
        } else {
          formData.contentTitle[i] = formData.contentTitle[i].trim();
        }
      }
    }

    for (let i = 0; i < formData.contentBulletLine.length; i++) {
      formData.contentBulletLine[i] = formData.contentBulletLine[i].trim();
    }
    for (let i = 0; i < formData.contentSignature.length; i++) {
      formData.contentSignature[i] = formData.contentSignature[i].trim();
    }
    setLoading(true);
    OnlineCMEService.createOnlineCMEServiceRequest(formData, accessToken)
      .then((response) => {
        setRedirect({ redirect: false, message: "success", errorMessage: "" });
        setTimeout(() => {
          setLoading(false);
          setRedirect({ redirect: true, message: "", errorMessage: "" });
        }, 3000);
      })
      .catch((err) => {
        if (!axios.isCancel(e)) {
          console.error(e);
          appInsights.trackException({ error: e });
        }
        setLoading(false);
        setRedirect({
          redirect: false,
          message: "error",
          errorMessage: "Error in submitting the form, please try again!",
        });
      });
  };

  if (redirect.redirect) {
    return (
      <Redirect
        to={{
          pathname: "/portalupdates/onlinecmelectures",
          state: { message: redirect.message },
        }}
      />
    );
  } else {
    return (
      <div className="referrals">
        <HeaderName name={name} />
        <BackButton />
        {redirect.message === "success" ? (
          <>
            {window.scrollTo(0, 0)}
            <Alert severity="success">
              Form is successfully submitted,please wait while we are
              redirecting!
            </Alert>
          </>
        ) : redirect.message === "error" ? (
          <>
            <Alert severity="error" style={{ color: "#EA1D36" }}>
              {redirect.errorMessage}
            </Alert>
          </>
        ) : redirect.message ? (
          <>
            <Alert severity="error" style={{ color: "#EA1D36" }}>
              {redirect.message}
            </Alert>
          </>
        ) : (
          <></>
        )}
        <div className="container">
          <h2 className="heading-main">New Online CME Lecture</h2>

          <div className="d-flex row justify-content-end"></div>

          <div className="referral">
            <form className="m-sm-5" onSubmit={handleSubmit}>
              {Object.entries(form).map((field, index) => {
                const key = field[0];
                const value: any = field[1];
                return (
                  <Form.Group
                    key={index}
                    className="d-flex row align-items-center form-padding"
                  >
                    <Form.Label column sm={3} className="pl-0 pr-0">
                      {value.display}
                      {value.required ? (
                        <span className="p-0 asterik"> *</span>
                      ) : null}
                    </Form.Label>
                    <Col sm={9} className="pl-0 pr-0">
                      {value.type === "textarea" ? (
                        <Form.Control
                          as={value.type}
                          required
                          onChange={(e) => handleOnChange(e, key)}
                          disabled={isDisabled}
                        />
                      ) : value.type === "select" &&
                        key === "announcementType" ? (
                        <Form.Control
                          as={value.type}
                          custom
                          required={value.required}
                          onChange={(e) => handleOnChange(e, key)}
                          disabled={isDisabled}
                        >
                          {value.value.length !== 0
                            ? value.value.map((v: any, index: any) => (
                                <option key={v.value} value={v.value}>
                                  {v.display}
                                </option>
                              ))
                            : null}
                        </Form.Control>
                      ) : value.type === "date" ? (
                        <Form.Control
                          max={DATE}
                          onChange={(e) => handleOnChange(e, key)}
                          type={value.type}
                          required
                          disabled={isDisabled}
                        />
                      ) : value.type === "Form" ? (
                        <SubForm
                          cmedata={formData}
                          onFormClick={handleFormClick}
                        />
                      ) : value.type === "checkbox" ? (
                        <Form.Check
                          type={value.type}
                          onChange={(e) => handleOnChange(e, key)}
                          disabled={isDisabled}
                        />
                      ) : (
                        <Form.Control
                          onChange={(e) => handleOnChange(e, key)}
                          type={value.type}
                          required={value.required}
                          disabled={isDisabled}
                        />
                      )}
                    </Col>
                  </Form.Group>
                );
              })}
              {/* <SubForm cmedata={formData}
                        onFormClick={handleFormClick} /> */}
              <Form.Group className="d-flex row">
                <Col
                  sm={{ span: 7, offset: 3 }}
                  className="d-flex justify-content-center"
                >
                  <MButton
                    variant="primary"
                    // disabled={loading}
                    loader={loading}
                    title="Save"
                    width={"fit-content"}
                    type="submit"
                  >
                    Save
                  </MButton>
                </Col>
                <Col
                  sm={{ span: 7, offset: 3 }}
                  className="d-flex justify-content-center"
                ></Col>
              </Form.Group>
            </form>
          </div>
        </div>
      </div>
    );
  }
};

export default NewOnlineCMEForms;
