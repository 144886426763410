import { useEffect, useState } from "react";
import IPatient from "../types/IPatient";

type KEY = "mypatients" | "sharedpatients" | "viewpatients";

const getPatientsFromSessionStorage = (
  key: KEY,
  initialValue: IPatient[] | undefined
) => {
  const savedValue = localStorage.getItem(key);
  const savedValueJSON = savedValue && JSON.parse(savedValue);
  if (savedValueJSON) return savedValueJSON;
  if (initialValue instanceof Function) return initialValue();
  return initialValue;
};

const usePatients = (key: KEY, initialValue?: IPatient[]) => {
  const [patients, setPatients] = useState<IPatient[]>(() => {
    return getPatientsFromSessionStorage(key, initialValue);
  });
  useEffect(() => {
    if (patients !== undefined) {
      localStorage.setItem(key, JSON.stringify(patients));
    }
  }, [patients]);
  return [patients, setPatients] as const;
};

export default usePatients;
