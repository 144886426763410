import { FC, useEffect, useState, useRef, useCallback } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { useOktaAuth } from "@okta/okta-react";
import HeaderName from "../../headerName/HeaderName";
import useScrollPosition from "../../../hooks/useScrollPosition";
import useSessionState from "../../../hooks/useSessionState";
import ILookbackOption from "../../../types/ILookbackOption";
import Loader from "../../common/Loader";
import IRouteState from "../../../types/IRouteState";
import INotMyPatientRequest from "../../../types/INotMyPatientRequest";
import SearchBar from "../../common/SearchBar";
import IPatientAccessQuery from "../../../types/IPatientAccessQuery";
import NotMyPatientRequestList from "../myPatients/components/NotMyPatientRequestList";
import PatientAccessRequestService from "../../../services/PatientAccessRequestService";
import LookBackFilter from "../../common/LookBackFilter";
import axios from "axios";
import { appInsights } from "../../../appInsights";


const NotMyPatientRequest: FC<{ name?: string }> = ({ name }) => {

  const lookBackOptions: ILookbackOption[] = require("../../../data/visitsLookbackOptions.json");

  const { authState } = useOktaAuth();
  const accessToken = authState?.accessToken?.accessToken;

  const [patientAccessRequest, setPatientAccessRequest] = useState<INotMyPatientRequest[]>([]);
  const [searchText, setSearchText] = useSessionState(
    "searchTextPatientsList",
    ""
  );
  const [loading, setLoading] = useState(true);

  const [query, setQuery] = useState<IPatientAccessQuery>({
    lookback: 30,
  });

  const [apiCallCount, setApiCallCount] = useState(0);
  const [error, setError] = useState<Error>();
  const cancelToken = axios.CancelToken.source();


  useEffect(() => {
    setLoading(true);
    PatientAccessRequestService.getNotMyPatientList(query, accessToken, cancelToken)
      .then((response) => {
        if (error !== undefined) {
          setError(undefined);
        }
        if (response) {
          const patients = response.data;
          if (patients !== null && patients !== undefined) {
            setPatientAccessRequest(patients);
            setLoading(false);
          }
        }
      })
      .catch((e) => {
        if (!axios.isCancel(e)) {
          console.error(e);
          appInsights.trackException({ error: e });
          setError(e);
        }
       
        setLoading(false);
      });
    return () => {
      cancelToken.cancel();
    };
  }, [apiCallCount]);

  const navigateToPage = (page: number) => {
    setApiCallCount((c) => c + 1);
    window.scrollTo(0, 0);
  };

  const handleSearch = (searchText: string) => {
    setQuery({
      ...query,
      searchterm: searchText,
    });
    console.log(query)
  };
  const handleLookBack = (e: React.ChangeEvent<any>) => {
    let temp = query;
    temp["lookback"] = e.target.value;
    setQuery(temp);
    navigateToPage(1);
  };

  const handleActiveRecordClick = (id: number, activeRecord: boolean) => {
    PatientAccessRequestService.editNotMyPatientRequest({
      id: id,
      activeRecord: activeRecord
    }, accessToken)
      .then((response) => {
        if (error !== undefined) {
          setError(undefined);
        }
        navigateToPage(1);
      })
      .catch((e) => {
        if (!axios.isCancel(e)) {
          console.error(e);
          appInsights.trackException({ error: e });
           setError(e);
        }
        
      });

  };

  return (
    <div>
      <HeaderName name={name} />
      <div className="container">
        <h2 className="heading-main">{name}</h2>
        <div className="chart">
          <div className="d-flex row justify-content-between">
            <div className="col-sm-5 col-lg-3 mb-3">
              <LookBackFilter
                onChange={handleLookBack}
                options={lookBackOptions}
              />
            </div>
            {loading ? (
              <div className="col loader mb-3">
                <Loader />
              </div>
            ) : null}
            <div className="col-sm-5 col-lg-3 mb-3">
              <SearchBar
                placeholder="Search by Patient Name"
                onSearch={() => navigateToPage(1)}
                onTextChange={handleSearch}
              />
            </div>
          </div>
          {patientAccessRequest.length === 0 ? (
            !loading ? (
              <div style={{ textAlign: "center" }}>No Record Found</div>
            ) : null
          ) : (
            <>
              <NotMyPatientRequestList
                patientAccessRequest={patientAccessRequest}
                query={query}
                handleActiveRecordClick={handleActiveRecordClick}
              />
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default NotMyPatientRequest;
