import { FC, useState, useEffect } from "react";
import { useLocation, useParams, Redirect } from "react-router-dom";
import { useOktaAuth } from "@okta/okta-react";
import { Alert } from "@mui/material";
import IAllAccounts from "../../../types/IAllAccounts";
import Spinner from "../../spinner/Spinner";
import Loader from "../../common/Loader";
import HeaderName from "../../headerName/HeaderName";
import BackButton from "../../backButton/BackButton";
import { Form, Col, Button, Table } from "react-bootstrap";
import MButton from "../../mButton/MButton";
import { getFormattedDateFromDate } from "../../../helpers/DateHelper";

// import "./patient-details.css";
import {
  getFormattedDate,
  getFormattedTime,
} from "../../../helpers/DateHelper";
import IReferralFormDropdownData from "../../../types/IReferralFormDropdownData";
import ReferralService from "../../../services/ReferralService";
import { appInsights } from "../../../appInsights";
import PatientAccessRequestService from "../../../services/PatientAccessRequestService";
import AccountService from "../../../services/AccountService";
import IUserName from "../../../types/IUserName";
import axios from "axios";


type stateType = {
  state: {
    account: IAllAccounts;
  };
};

const AllAccountsListDetail: FC<{ name?: string }> = ({ name }) => {
  const { state } = useLocation<stateType>();

  const isDisabled = false;
  const { authState } = useOktaAuth();
  const accessToken = authState?.accessToken?.accessToken;
  const accountEdit: IAllAccounts = state.state.account;
  const [allAccountsDetail, setallAccountsDetail] = useState<any>({});


  const [redirect, setRedirect] = useState({ redirect: false, message: "" });

  const DATE = getFormattedDate(new Date());
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<Error>();
  const [account, setAccount] = useState<any | null>(null);
  const [query, setQuery] = useState<IUserName>({
    userName: accountEdit.email
  });
  const cancelToken = axios.CancelToken.source();




  useEffect(() => {
    setLoading(true);
    // POST endpoint for getting all account details
    AccountService.getUserAccount(query, accessToken, cancelToken)
      .then((response) => {
        if (error !== undefined) {
          setError(undefined);
        }
        setallAccountsDetail(response.data);
        setLoading(false);
      })
      .catch((e) => {
        if (!axios.isCancel(e)) {
          console.error(e);
          appInsights.trackException({ error: e });
       setError(e);
        }
      
        setLoading(false);
      });

  }, [authState]);

  if (redirect?.redirect) {
    return (
      <Redirect
        to={{
          pathname: "/account/list",
          state: { message: redirect.message },
        }}
      />
    );
  } else {
    let headerName = { name: name + " > Detail" };

    return (
      <>
        <HeaderName name={headerName.name} />
        <div id="patient-details" className="container">
          <div className="row chartLayoutContainer">
            <div className="top-content">
              <div className="top-left-content">
                <h3 className="header-blue bold me-2">
                  {`Account Details | ${accountEdit.firstName} ${accountEdit.lastName}`}
                </h3>
                <BackButton />
              </div>
            </div>
            {loading ? (
              <div className="d-flex row justify-content-between">
                <div className="col loader mb-3">
                  <Loader />
                </div>
              </div>) : (<>
                <div className="col-md-12 chart">
                  <dl className="dl-horizontal">
                    <dt>First Name</dt>
                    <dd>{allAccountsDetail.firstName}</dd>
                    <dt>Last Name</dt>
                    <dd>{allAccountsDetail.lastName}</dd>
                    <dt>Email</dt>
                    <dd>{allAccountsDetail.email}</dd>

                    <dt>
                    </dt>
                    <dd>
                      <Table bordered style={{ marginTop: '1rem', width: 'auto' }}>
                        <thead>
                          <tr>
                            <th style={{ width: '50%' }}>Role(s)</th>
                            <th>Practices</th>
                          </tr>
                        </thead>
                        <tbody>
                          {allAccountsDetail.accountRolePerPractice?.map((rolePractice: any) => {
                            return (
                              <tr key={rolePractice.practiceId} style={{ backgroundColor: 'white' }}>
                                <td style={{padding:'.5rem .5rem'}}>{rolePractice.roleName}</td>
                                <td style={{padding:'.5rem .5rem'}}>{rolePractice.practiceName}</td>
                              </tr>
                            )
                          })}
                        </tbody>
                      </Table>
                    </dd>
                    <dt>Account Status</dt>
                    <dd>{allAccountsDetail.accountStatus}</dd>
                    <dt>Mobile Phone</dt>
                    <dd>{allAccountsDetail.mobilePhoneNumber}</dd>
                    <dt>Office Phone</dt>
                    <dd>{allAccountsDetail.officePhoneNumber}</dd>
                    <dt>Modified On</dt>
                    <dd>
                      {allAccountsDetail.lastModifiedOn !== null ? (
                        getFormattedTime(allAccountsDetail.lastModifiedOn?.split("T")[0])
                      ) : (
                        <></>
                      )}
                    </dd>
                    <dt>Last Logged In</dt>
                    <dd>
                      {allAccountsDetail.lastLoggedIn !== null ? (
                        `${getFormattedTime(
                          allAccountsDetail.lastLoggedIn?.split("T")[0]
                        )} `
                      ) : (
                        <></>
                      )}
                    </dd>

                  </dl>
                </div>
              </>)}

          </div>
        </div>
      </>
    );
  }
};

export default AllAccountsListDetail;
