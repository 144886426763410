import { FC } from "react";
import { IPhysician } from "../../../../types/IPracticeSearchResult";
import { Table } from "react-bootstrap";
import Loader from "../../../common/Loader";

const AdminPracticePhysiciansTable: FC<{ physicians: IPhysician[], count : number }> = ({
  physicians,count
}) => {
  console.log(physicians.length)
  console.log(count)
  if (count != 0) {
    if(physicians.length == count)
    {
    return (
      <div>
        <Table id="lab-table" style={{ margin: 0 }}>
          <thead>
            <tr>
              <th>ID</th>
              <th>Name</th>
              <th>Portal</th>
              <th>Email</th>
              <th>NPI</th>
              <th>Speciality</th>
            </tr>
          </thead>
          <tbody>
            {physicians.map((physician) => (
              <tr key={physician.id}>
                <td id="table-reports-radiology">{physician.id}</td>
                <td>
                <div style ={{display: "flex" ,gap : 5}}><div>{physician.lastName}, {physician.firstName} {physician.title} </div> <div style ={{fontWeight: "bolder"}}>{physician.isPracticeOwner ? " (PO)":""}</div></div>
                </td>
                <td>NA</td>
                <td>{physician.email}</td>
                <td>{physician.npi}</td>
                <td>NA</td>
              </tr>
            ))}
          </tbody>
        </Table>
      </div>
    );}
    else{
      return <Loader/>
    }
  } else {
    return <div>No record found</div>;
  }
};

export default AdminPracticePhysiciansTable;
