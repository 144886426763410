import { FC, useEffect, useState, useRef } from "react";
import { Modal, Button } from "react-bootstrap";
import { useLocation } from "react-router-dom";
import ChartService from "../../../../services/ChartService";
import parse from "html-react-parser";
import Loader from "../../../common/Loader";
import { EVENT } from "../../../../types/IPatientRadiologyResults";
import MButton from "../../../mButton/MButton";
import axios from "axios";
import { useReactToPrint } from "react-to-print";
import IPatient from "../../../../types/IPatient";
import ComponentToPrintLabs from "./ComponentToPrintLabs";
import { appInsights } from "../../../../appInsights";

type stateType = {
  state: {
    patient: IPatient;
  };
};

const ReportsModal: FC<{
  show: boolean;
  handleClose?: () => void;
  handleSave?: () => void;
  event?: EVENT;
  accessToken?: string;
  lookback: string;
}> = ({ show, handleClose, handleSave, event, accessToken, lookback }) => {
  const [body, setBody] = useState<any>();
  const [loading, setLoading] = useState<boolean>(true);
  const { state } = useLocation<stateType>();
  const patientDetail = state.state.patient;
  const componentRef = useRef(null);
  const pageStyle = `{ size: 2.5in 4in }`;
  let dateObj = new Date();
  let month = dateObj.getUTCMonth() + 1; //months from 1-12
  let day = dateObj.getUTCDate();
  let year = dateObj.getUTCFullYear();
  let titleDate = `${year}${month}${day}`;
  const documentTitle = `CHLA_${patientDetail.patientName} - ${titleDate}`;
  const cancelToken = axios.CancelToken.source();

  useEffect(() => {
    if (event !== undefined) {
      setBody(undefined);
      setLoading(true);
      let body = {
        FIN: "",
        FACILITY: "",
        ENCNTR_TYPE: "",
        DISCHARGE_DATE: "",
        CATALOG: event.CATALOG,
        ORDER_ID: event.ORDER_ID,
        EVENT_VISIT_DATE: event.EVENT_VISIT_DATE,
        EVENT_DATE: event.EVENT_DATE,
        REPORT_STATUS: event.REPORT_STATUS,
      };
      ChartService.getReportsHTML(body, accessToken, cancelToken)
        .then((res) => {
          setBody(res.data);
          setLoading(false);
        })
        .catch((e) => {
          if (!axios.isCancel(e)) {
            console.error(e);
            appInsights.trackException({ error: e });
            // setError(e);
          }
        });
    }
    return () => {
      cancelToken.cancel();
    };
  }, [event]);

  const handlePrint = useReactToPrint({
    pageStyle: () => pageStyle,
    content: () => componentRef.current,
    documentTitle: documentTitle,
  });

  return (
    <Modal size="xl" show={show} onHide={handleClose} centered>
      <Modal.Body>
        <div>
          {loading ? (
            <div
              style={{
                margin: "20vh 0",
                display: "flex",
                justifyContent: "center",
              }}
            >
              <Loader />
            </div>
          ) : (
            <>
              {parse(body)}
              <div style={{ display: "none" }}>
                <div ref={componentRef}>
                  <ComponentToPrintLabs
                    radiologyResults={parse(body)!}
                    lookback={lookback}
                    result="radiologyResults"
                  />
                </div>
              </div>
            </>
          )}
        </div>
      </Modal.Body>
      <Modal.Footer>
      <MButton
          onClick={handlePrint}
          variant="secondary"
          title="Print"
          width={"max-content"}
        />
        <MButton
          onClick={handleClose}
          variant="primary"
          // title={loading ? "Cancel" : "Ok"}
          title={"OK"}
          width={"max-content"}
        />
      </Modal.Footer>
    </Modal>
  );
};

export default ReportsModal;
