import { FC } from "react";
import { Modal, Button } from "react-bootstrap";
import MButton from "../../../mButton/MButton";

const CancellationReasonModal: FC<{
  show: boolean;
  handleClose?: () => void;
  reason: string | null;
  Loader?:boolean
}> = ({ show, handleClose, reason,Loader }) => {
  return (
    <Modal show={show} onHide={handleClose} centered>
      <Modal.Header>Cancellation Reason</Modal.Header>
      <Modal.Body>
        <p>{reason}</p>
      </Modal.Body>
      <Modal.Footer>
        <MButton
          width={"fit-content"}
          title="Close"
          variant="secondary"
          onClick={handleClose}
        >
          Close
        </MButton>
      </Modal.Footer>
    </Modal>
  );
};

export default CancellationReasonModal;
