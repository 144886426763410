import { FC, useEffect, useState } from "react";
import { useHistory, useLocation, Link } from "react-router-dom";
import { useOktaAuth } from "@okta/okta-react";
import IDropDownInfoWell from "../../../types/IDropDownInfoWell";
import HomeService from "../../../services/HomeService";
import axios, { AxiosResponse } from "axios";
import Spinner from "../../spinner/Spinner";
// import { Glyphicon } from 'react-bootstrap';
import { appInsights } from "../../../appInsights";
import ClinicalPathwayService from "../../../services/ClinicalPathwayService";
import IClinicalType from "../../../types/IClinicalType";
import ClinicalPathwayCards from "./ClinicalPathwayCards";
const sortable = require("../../../assets/images/sortable.png").default;

const ClinicalCarePathwaysScreen: FC<{
  name?: string;
}> = ({ name }) => {
  const history = useHistory();
  const { authState } = useOktaAuth();
  const accessToken = authState?.accessToken?.accessToken;
  const [loading, setLoading] = useState(false);
  const [clinicalTypeData, setClinicalTypeData] = useState<IClinicalType[]>();
  const [error, setError] = useState<Error | null>(null);
  const [formData, setFormData] = useState<FormData>();
  const cancelToken = axios.CancelToken.source();
  const { state: dropDownWells } = useLocation<IDropDownInfoWell[]>();
  //const {state : infowell} = useLocation<IInfoWell[]>();
  const [data, setData] = useState<IDropDownInfoWell[]>();
  const [load, setLoad] = useState(true);

  useEffect(() => {
    var data:string = history.location.state as string
    if (data !== null) {
      setLoading(true);
      setLoad(true);
      HomeService.getDropDownWells("ClinicalCarePathways", accessToken)
        .then((response) => {
          var data = response.data;
          setData(data);
          setLoading(false);
          setLoad(false);

        })
        .catch((e) => {
          if (!axios.isCancel(e)) {
            console.error(e);
            appInsights.trackException({ error: e });
            setError(e);
          }
          setLoading(false);
          setLoad(false);

        });
    }
    setLoading(true);
    setError(null);
    ClinicalPathwayService.getClinicalType(accessToken)
      .then((response) => {
        setLoading(false);
        setClinicalTypeData(response.data);
      })
      .catch((e: Error) => {
        if (!axios.isCancel(e)) {
          console.error(e);
          appInsights.trackException({ error: e });
          setError(e);
        }
        setLoading(false);
      });
    return () => {
      cancelToken.cancel();
    };
  }, []);
  
  return (
    <div className="container">
      <h2 className="heading-main">{name}</h2>
      {!load ?
      <div className="row no-gutters p-2 m-0">
        { clinicalTypeData?.map((dropdown, index) => {
          return <ClinicalPathwayCards dropdown={dropdown} data = {data}/>
        })}
      </div>: <Spinner margin="5rem 0" />}
    </div>
  );
};

export default ClinicalCarePathwaysScreen;
