import React from "react";
import { FC, useEffect, useState, useRef } from "react";
import HeaderName from "../../headerName/HeaderName";
import Loader from "../../common/Loader";
import { Alert, Button, Card, Tab, Tabs, Col, Nav } from "react-bootstrap";
import IPhysicianResources from "../../../types/IPhysicianResources";
import IInfoWell from "../../../types/IInfoWell";
import PediatricGRService from "../../../services/PediatricGRService";
import { useOktaAuth } from "@okta/okta-react";
import IPediatricGR from "../../../types/IPediatricGR";
import ReactHtmlParser from "react-html-parser";
import {
  getFormattedDateFromDate,
  getFormattedYear,
} from "../../../helpers/DateHelper";
import OnlineCMEService from "../../../services/OnlineCMEService";
import IOnlineCME from "../../../types/IOnlineCME";
import MButton from "../../mButton/MButton";
import styles from "../home/Jumbotron.module.css";
import BackButton from "../../backButton/BackButton";
import { appInsights } from "../../../appInsights";
import axios, { AxiosResponse } from "axios";

const sortable = require("../../../assets/images/sortable.png").default;

const CMEInfoWells: FC<{
  name?: string;
}> = ({ name }) => {
  const aref = useRef<HTMLAnchorElement>(null);

  const { authState } = useOktaAuth();
  const accessToken = authState?.accessToken?.accessToken;
  const [cmeData, setCmeData] = useState<IOnlineCME[]>();
  const [error, setError] = useState<Error | null>(null);
  const [loading, setLoading] = useState(true);
  const [allYears, setAllYears] = useState<any[]>([]);
  useEffect(() => {
    setLoading(true);
    setError(null);
    OnlineCMEService.getOnlineCMEServiceList(accessToken)
      .then((response) => {
        setLoading(false);
        setCmeData(response.data);
      })
      .catch((e: Error) => {
        if (!axios.isCancel(e)) {
          console.error(e);
          appInsights.trackException({ error: e });
          setError(e);
        }
     
        setLoading(false);
      });
  }, []);

  //  const tabArr=()=>{

  //   }
  const getAllYear = () => {
    let yearArr: any[] = [];
    if (cmeData != undefined) {
      for (var i = 0; i < cmeData?.length; i++) {
        if (!yearArr.includes(getFormattedYear(cmeData[i].eventDate))) {
          yearArr.push(getFormattedYear(cmeData[i].eventDate));
        }
      }
    }
    yearArr.sort();
    yearArr.reverse();
    setAllYears(yearArr);
  };
  useEffect(() => {
    getAllYear();
  }, [cmeData]);
  return (
    <div>
      <HeaderName name={name} />
      <BackButton  />
      <div className="container">
        {" "}
        <h2 className="heading-main">{name}</h2>
      
        <br />
        <div className="row no-gutters p-2 m-0">
          <p style={{ margin: "10px" }}>
            As a provider of expert pediatric care, we make our Online CME
            Lectures presentations available for interested viewers. This helps
            other providers of care for children throughout the world access the
            same cutting-edge information that we have at our hospital. By
            watching CME Lectures through this portal, you’ll be provided with
            video of both the presenter and the slides presented to the
            audience.
          </p>
          <p style={{ margin: "10px" }}>
            To ensure that the content provided in this portal represents
            up-to-date health care delivery information, presentations are
            archived and retained for approximately two years.
          </p>
          <div>
            <Tabs defaultActiveKey={allYears[0]} transition={false}>
              {allYears?.map((year) => {
                return (
                  <Tab eventKey={year} title={year}>
                    <div style={{ backgroundColor: "white" }}>
                      <div style={{ backgroundColor: "white" }}>
                        <br />
                        <div className="row no-gutters p-2 m-0">
                          {cmeData?.map((pgr) => {
                            if (getFormattedYear(pgr.eventDate) === year && pgr.isActive == true) {
                              return (
                                <div className="col-sm-4 col-lg-4 p-2 d-flex justify-content-center">
                                  <Card
                                    className="social p-4"
                                    style={{ width: "35rem", display: "flex" }}
                                  >
                                    {/* {ReactHtmlParser(pgr.content)} */}
                                    <Card.Title className={styles.titleCard}>
                                      {pgr.contentTitle?.map(
                                        (bullet, index) => {
                                          return (
                                            <>
                                              <h1
                                                style={{
                                                  fontSize: 25,
                                                  color: "#004877",
                                                }}
                                              >
                                                {bullet}
                                              </h1>
                                            </>
                                          );
                                        }
                                      )}
                                    </Card.Title>
                                    <Card.Text
                                      className="mt-2"
                                      style={{
                                        display: "flex",
                                        flexDirection: "column",
                                      }}
                                    >
                                      <h1
                                        style={{
                                          fontSize: 15,
                                          color: "#666666",
                                        }}
                                      >
                                        {getFormattedDateFromDate(
                                          pgr.eventDate
                                        )}
                                      </h1>{" "}
                                      <br />
                                      <h2
                                        style={{
                                          fontSize: 20,
                                          color: "#666666",
                                        }}
                                      >
                                        {pgr.contentMessage[0]}
                                      </h2>
                                      {pgr.contentBulletLine?.map(
                                        (bullet, index) => {
                                          return (
                                            <div>
                                              {index + 1}.{"  "}
                                              {bullet}
                                            </div>
                                          );
                                        }
                                      )}
                                      <br />
                                      <h2
                                        style={{
                                          fontSize: 23,
                                          color: "#666666",
                                        }}
                                      >
                                        {pgr.contentFullname[0]}
                                      </h2>
                                      {pgr.contentSignature?.map(
                                        (bullet, index) => {
                                          return <div>{bullet}</div>;
                                        }
                                      )}
                                    </Card.Text>
                                    <MButton
                                      variant="secondary"
                                      disabled={false}
                                      title={"Watch  " + "\u00bb"}
                                      width={"100%"}
                                      type="button"
                                      infoButton="infowellButton"
                                      property="infowellButton"
                                      onClick={() => window.open(pgr.videoUrl)}
                                    >
                                      {"Watch  " + "\u00bb"}
                                    </MButton>
                                  </Card>
                                </div>
                              );
                            }
                          })}
                        </div>
                      </div>
                    </div>
                  </Tab>
                );
              })}
            </Tabs>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CMEInfoWells;
