import { FC, useEffect } from "react";
import { Badge, OverlayTrigger, Table } from "react-bootstrap";
import IReferral, { ReferralAppointment } from "../../../../types/IReferral";
import IReferralDetailed from "../../../../types/IReferralDetailed";
import IReferralQuery from "../../../../types/IReferralQuery";
import FormPopover from "../../../common/FormPopover";
import Sortable from "../../../common/Sortable";
import MButton from "../../../mButton/MButton";
import InfoOutlinedIcon from "@material-ui/icons/InfoOutlined";
import Loading from "../../../common/Loader";
const sortable = require("../../../../assets/images/sortable.png").default;

const ReferralList: FC<{
  referrals: IReferral[];
  query: IReferralQuery;
  Loader?: boolean;
  modifyQuery: (query: IReferralQuery) => void;
  onCancelPress: (
    cancelPressed: boolean,
    clinicName: string,
    referralId: number,
    referral: IReferral | IReferralDetailed
  ) => void;
  onCancelRequestPress: (
    requestPressed: boolean,
    name?: string,
    date?: Date,
    reason?: string
  ) => void;
  onReferPress: (referrals: IReferral) => void;
  onEditPress?: (referralId: number) => void;
  onCancelledPress: (reason: string) => void;
  onScheduledPress: (reason: ReferralAppointment) => void;

  page?: "MyReferrals" | "SharedReferrals";
}> = ({
  referrals,
  query,
  modifyQuery,
  onCancelPress,
  onReferPress,
  onEditPress,
  onCancelledPress,
  onCancelRequestPress,
  onScheduledPress,
  page,
  Loader,
}) => {
  const handleSort = (sortfield: string) => {
    if (query.sortdirection === "DESC") {
      modifyQuery({
        ...query,
        sortfield: sortfield,
        sortdirection: "ASC",
      });
    } else {
      if (query.sortdirection === "ASC") {
        modifyQuery({
          ...query,
          sortfield: sortfield,
          sortdirection: "DESC",
        });
      }
    }
  };
  let appointment:any = [];
  const handleCancelledClick = (
    status: string,
    reason: string | null | ReferralAppointment
  ) => {
    if (
      status === "Cancelled" &&
      reason !== null &&
      reason !== "" &&
      typeof reason === "string"
    ) {
      onCancelledPress(reason);
    } else if (
      status === "Patient Scheduled" &&
      reason !== null &&
      reason !== "" &&
      typeof reason !== "string"
    ) {
      onScheduledPress(reason);
    }
  };
  return (
    <Table id="responsive-table" className="mt-2">
      <thead>
        <tr>
          <th
            className="hover"
            // onClick={(e) => {
            //   handleSort("PatientFullName");
            // }}
          >
            Patient Name{" "}
            {/* {query.sortfield === "PatientFullName" ? (
              <Sortable direction={query.sortdirection} />
            ) : (
              <img src={sortable} />
            )} */}
          </th>
          {/* <th>Referral No.</th> */}
          <th
            className="hover"
            // onClick={(e) => {
            //   handleSort("PatientBirthDate");
            // }}
          >
            DOB{" "}
            {/* {query.sortfield === "PatientBirthDate" ? (
              <Sortable direction={query.sortdirection} />
            ) : (
              <img src={sortable} />
            )} */}
          </th>
          <th>Clinic</th>
          <th
            className="hover"
            // onClick={(e) => {
            //   handleSort("CreatedDateTime");
            // }}
          >
            Created On{" "}
            {/* {query.sortfield === "CreatedDateTime" ? (
              <Sortable direction={query.sortdirection} />
            ) : (
              <img src={sortable} />
            )} */}
          </th>
          {page === undefined || page === "SharedReferrals" ? (
            <th
              className="hover"
              // onClick={(e) => {
              //   handleSort("PhysicianFullName");
              // }}
            >
              Referring Physician{" "}
              {/* {query.sortfield === "PhysicianFullName" ? (
                <Sortable direction={query.sortdirection} />
              ) : (
                <img src={sortable} />
              )} */}
            </th>
          ) : null}
          {/* <th>MRN </th> */}
          <th>Status</th>
          <th></th>
        </tr>
      </thead>
      <tbody>
        {referrals.map((referral) => {
          appointment.push(referral.referralAppointment);
          // console.log("referral.referralAppointment",referral.referralAppointment);
          return (
            <tr key={referral.id} style={{ borderTop: "0 !important" }}>
              <td data-title="Patient Name" id="table-text-referrals">
                {referral.firstName + " " + referral.lastName}
              </td>
              {/* <td data-title="Referral No.">{referral.id}</td> */}
              <td data-title="DOB">{referral.dateOfBirth?.split("T")[0]}</td>

              <td data-title="Clinic" id="table-referrals-clinic-name">
                {referral.clinicName}
              </td>

              <td data-title="Created On">
                {referral.createdDtm?.split("T")[0]}
              </td>
              {page === undefined || page === "SharedReferrals" ? (
                <td data-title="Referring Physician">
                  {referral.physicianName}
                </td>
              ) : null}
              {/* <td data-title="MRN">
                  {referral.mrn}
                </td> */}
              <td data-title="">
                <OverlayTrigger
                  placement="top"
                  overlay={
                    referral.cernerStatus === "Cancelled" ? (
                      FormPopover(
                        "Cancellation Reason",
                        referral.cancellationReason
                      )
                    ) : (
                      <></>
                    )
                  }
                >
                  <Badge
                    className="hover"
                    style={{ padding: "8px" }}
                    bg={
                      referral.cernerStatus === "Patient Scheduled"
                        ? "success"
                        : referral.cernerStatus === "Cancelled"
                        ? "danger"
                        : "secondary"
                    }
                    onClick={() => {
                      //console.log(referral)
                      handleCancelledClick(
                        referral.cernerStatus,
                        referral.cernerStatus === "Patient Scheduled" &&
                          referral.referralAppointment !== undefined
                          ? referral.referralAppointment
                          : referral.cancellationReason
                      );
                    }}
                  >
                    {(referral.cernerStatus === "Patient Scheduled" &&
                      referral.referralAppointment !== null) ||
                    (referral.cernerStatus === "Cancelled" &&
                      referral.cancellationReason !== null) ? (
                      <InfoOutlinedIcon style={{ padding: 3 }} />
                    ) : null}
                    {referral.cernerStatus}
                  </Badge>
                </OverlayTrigger>
              </td>
              {page === "SharedReferrals" ? null : (
                <>
                  <td data-title="">
                    <div className="referrals-button-list">
                      {onEditPress != null ? (
                        <MButton
                          onClick={() => onEditPress(referral.id)}
                          title="Edit"
                          small
                          variant="secondary"
                        />
                      ) : (
                        <span></span>
                      )}
                      {referral.cancelationRequest === true ? (
                          <MButton
                            onClick={() => {
                              onCancelRequestPress(
                                true,
                                referral.modifyBy,
                                referral.modifyDtm,
                                referral.cancellationReason
                              );
                            }}
                            title="Cancellation Requested"
                            color="#EA1D36"
                            // bColor="#EA1D36"
                            small
                            variant="secondary"
                            hidden={
                              referral.cernerStatus === "Cancelled"
                                ? true
                                : false
                            }
                          />
                      ) : (
                        <MButton
                          onClick={() =>
                            onCancelPress(
                              true,
                              referral.clinicName,
                              referral.id,
                              referral
                            )
                          }
                          title="Request to Cancel"
                          small
                          variant="secondary"
                          hidden={
                            referral.cernerStatus === "Cancelled" ? true : false
                          }
                        />
                      )}
                    </div>
                  </td>
                  <td data-title="">
                  <div className="referrals-button-list">
                    <MButton
                      onClick={() => onReferPress(referral)}
                      title="Refer"
                      small
                      variant="accent"
                    />
                    </div>
                  </td>
                </>
              )}
            </tr>
          );
        })}
      </tbody>
    </Table>
  );
};

export default ReferralList;
