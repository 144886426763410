import { FormikErrors, FormikValues } from "formik";
import { createContext } from "react";

interface IReferralFormContext {
    errors: FormikErrors<FormikValues>;
    setErrors: React.Dispatch<React.SetStateAction<FormikErrors<FormikValues>>>;
}

const referralFormContext = createContext<IReferralFormContext>({
    errors: {},
    setErrors: () => { },
});

export const referralSubmitContext = createContext<boolean>(false);

export default referralFormContext;
