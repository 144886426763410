import { FC, useState, useRef } from "react";
import { Modal, Form } from "react-bootstrap";
import { Redirect, useHistory } from "react-router-dom";

import MButton from "../../../mButton/MButton";
import { Field, Formik, Form as FormikForm } from "formik";
import CustomInputFormik from "../../../common/CustomInputFormik";
// import IInvitePhysician from "../../../../types/IInvitePhysician";
import { appInsights } from "../../../../appInsights";
import AccountService from "../../../../services/AccountService";
import { useOktaAuth } from "@okta/okta-react";
import Loader from "../../../common/Loader";
import axios from "axios";
import { Alert } from "@mui/material";

// import * as Yup from 'yup'

const InvitePhysicianConfirmationModal: FC<{
    show: boolean;
    user: any;
    onHide?: () => void;
    handleAdd?: () => void;
    handleError?: () => void;
}> = ({ show, user, onHide, handleAdd, handleError }) => {
    //
    const { authState } = useOktaAuth();
    const history = useHistory();
    const accessToken = authState?.accessToken?.accessToken;
    const [data, setData] = useState({});
    const emailAddress = useRef<any>(null);
    const cancelToken = axios.CancelToken.source();
    const [loading, setLoading] = useState<boolean>(false);
    const [error, setError] = useState<boolean>(false);
    const [success, setSuccess] = useState<boolean>(false);
    const [redirect, setRedirect] = useState({ redirect: false, message: "" });


    const handleOnChange = (e: any) => {
        setData({
            ...data,
            [e.target.id]: e.target.value,
        });
    }


    const handleSubmit = (e: any) => {
        setLoading(true);

        setError(false);
        e.preventDefault();

        const postBody = {
            firstName: user.firstName,
            midName: user.midName,
            lastName: user.lastName,
            medicalLicenseNumber: user.medicalLicenseNumber,
            npiNum: user.npi,
            email: emailAddress?.current?.value,
            mobilePhone: user.mobilePhone,
            title: user.title,
            verified: true
        };
        AccountService.invitePhysician(postBody, accessToken, cancelToken)
            .then((response) => {
                setLoading(false);
                setError(false);
                setSuccess(true);
                setRedirect({ redirect: false, message: "success" });

            })
            .catch((err) => {

                if (!axios.isCancel(e)) {
                    console.error(e);
                    appInsights.trackException({ error: e });

                }
                setLoading(false);
                setSuccess(false);

                setError(true);
                setRedirect({ redirect: false, message: "error" });


            });
    }

    return (
        <Modal size="lg" show={show} onHide={() => {


            if (onHide) {
                setError(false);
                setSuccess(false);
                onHide()
            }
        }} centered>
            <Modal.Header closeButton>
                <Modal.Title style={{ fontSize: '1.2rem' }} id="contained-modal-title-vcenter">
                    Confirmation Required
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <h6>{`Please confirm that you wish to invite ${user.firstName} ${user.lastName} to your practice.`}</h6>
                <p style={{ marginTop: '5%' }}>
                    <Form.Group className="mb-3" controlId="email">
                        <Form.Control
                            onChange={(e) => handleOnChange(e)}
                            type='text'
                            required
                            name="email"
                            ref={emailAddress}
                            defaultValue={user.email}
                        />

                    </Form.Group>
                    {loading ? (
                        <div className="col loader mb-3">
                            <Loader />
                        </div>
                    ) : null}
                    {success === true ? (<>
                        <Alert severity="success">
                            Invitation Sent!
                        </Alert>
                    </>) : error === true ? (
                        <>
                            <Alert severity="error" style={{ color: "#EA1D36" }}>
                                Error in submitting, please try again!
                            </Alert>
                        </>
                    ) : <></>}
                    <br></br>
                    <div style={{ marginTop: '1rem' }}>Please <span style={{ color: '#ff7f00' }}>correct the email address</span> we have on file if necessary and click 'OK' to send an invitation.</div>
                </p>
            </Modal.Body>
            <Modal.Footer>
                <MButton
                    type="submit"
                    variant="primary"
                    title="Ok"
                    width={"max-content"}
                    onClick={handleSubmit}
                // isSubmitting={isSubmitting}
                />
                <MButton
                    onClick={() => {
                        if (onHide) {
                            setError(false);
                            setSuccess(false);
                            onHide()
                        }
                    }}
                    variant="secondary"
                    title={"Cancel"}
                    width={"max-content"}
                />
            </Modal.Footer>
        </Modal>
    );
};

export default InvitePhysicianConfirmationModal;
