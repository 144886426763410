import React, { FC, useEffect, useState } from "react";
import { Card, Button, Form } from "react-bootstrap";
import useInfowells from "../../../hooks/useInfowells";
import HomeService from "../../../services/HomeService";
import Loader from "../../common/Loader";
import axios, { AxiosResponse } from "axios";
import { useOktaAuth } from "@okta/okta-react";
import { useHistory, useLocation, Link } from "react-router-dom";
import { getFormattedDateWords } from "../../../helpers/DateHelper";
import IInfoWell from "../../../types/IInfoWell";
import IDropDownInfoWell from "../../../types/IDropDownInfoWell";
import EducationService from "../../../services/EducationService";
import { idText } from "typescript";
import MButton from "../../mButton/MButton";
import styles from "../home/Jumbotron.module.css";
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Favorite from '@material-ui/icons/Favorite';
import FavoriteBorder from '@material-ui/icons/FavoriteBorder';
import { appInsights } from "../../../appInsights";
import GoButton from "../../GoButton/GoButton";
import { downloadFileFromEducationService } from "../../../helpers/FileHelper";

const InfowellsEdu: FC<{ infowell: IInfoWell ,likedInfowells?:IInfoWell[] }> = ({ infowell,likedInfowells=[] }) => {
  const { authState } = useOktaAuth();
  const accessToken = authState?.accessToken?.accessToken;
  const cancelToken = axios.CancelToken.source();
  const [infowells, setInfowells] = useInfowells();
  const [dropDownWells, setDropDownWells] = useState<IDropDownInfoWell[]>();
  const [dropDownName, setDropDownName] = useState("");
  const [loading, setLoading] = useState(true);
  const [likeButtonClicked, setLikeButtonClicked] = useState(0);
  const [error, setError] = useState<Error | null>(null);
  const [formData, setFormData] = useState<FormData>();
  const [value, setValue] = useState("");


  const history = useHistory();
  const handleOnClick = () => {
    history.push("/education/pgrinfowells");
  };
  const handleClick = () => {
    history.push("/education/cmeinfowells");
  };

  useEffect(() => {
    setLoading(true);
    if (infowell.dropDownName !== null) {
      HomeService.getDropDownWells(infowell.dropDownName, accessToken)
        .then((response) => {
          setDropDownWells(response.data);
          setLoading(false);
        })
        .catch((e) => {
          if (!axios.isCancel(e)) {
            console.error(e);
            appInsights.trackException({ error: e });
            setError(e);
            setLoading(false);
          }
          
          setLoading(false);
        });
    }

    return () => {
      cancelToken.cancel();
    };
  }, [dropDownName]);
  const handleOnChange = (id: any,e?:any) => {
    let data = new FormData();
    let title =
    e.nativeEvent.target[e.nativeEvent.target.selectedIndex].text.split(
      ": "
    )[1];
    data.append("id", id);
    setFormData(data);
    setValue(title);

  };
 
  const likeButton =(id:any)=>{
    setLoading(true);
    let data={
    InfoWellId:id,
    Favorite:!isLiked(id)}
    HomeService.likeButton(data, accessToken)
    .then((response) => {
     setLoading(false);
    })
    .catch((e) => {
      if (!axios.isCancel(e)) {
        console.error(e);
        appInsights.trackException({ error: e });
        setError(e);
      }
      
      setLoading(false);
    });
  }
  const isLiked=(id:any):boolean=>{
    var flag : boolean = false
    likedInfowells.map(item=>{
      if(item.id == id){
        flag = true
      }
    })

    return flag
  }
  return (
    <div
      className="col-sm-4 col-lg-3 p-2 d-flex justify-content-center"
      //   key={index}
    >
      <Card className="social p-4" style={{ width: "25rem", display: "flex" }}>
      <FormControlLabel
        control={<Checkbox icon={<FavoriteBorder color={"inherit"} />} 
                  checkedIcon={<Favorite color={"inherit"}/>}
                  defaultChecked={isLiked(infowell.id) }
          name="checkedH"  />}
          // onChange={}
          onChange={()=>likeButton(infowell.id)}
        label=""
      />
        <Card.Title className={styles.titleCard}>
          {infowell.title ===
          'Pediatric Grand Rounds <i class="glyphicon glyphicon-lock de-em" title="Requires Login"></i>' ? (
            <>
              Pediatric Grand Rounds{"  "}
              <i
                className="glyphicon glyphicon-lock de-em"
                title="Requires Login"
              ></i>
            </>
          ) : infowell.title ===
            'Online CME Lectures <i class="glyphicon glyphicon-lock de-em" title="Requires Login"></i>' ? (
            <>
              Online CME Lectures{"  "}
              <i
                className="glyphicon glyphicon-lock de-em"
                title="Requires Login"
              ></i>
            </>
          ) : (
            infowell.title
          )}
        </Card.Title>
        <Card.Text className="mt-2" style={{ display: 'flex', flexDirection: 'column'}}>{infowell.paragraph}</Card.Text>
      {infowell.dropDownName != null ? loading && <Loader/> :null}
        {infowell.dropDownName == null ? null : (
          
          <Form.Select
            onChange={(e) => handleOnChange(e.target.value,e)}
            aria-label="Default select example"
          >
             {formData === undefined ?
              <option>{"Select PDF"}</option>:null}
            {dropDownWells
              ?.map((option, index: number) => (
                <option key={index} value={option.id} title={option.title}>
                  {getFormattedDateWords(option.publicationDate)} :{" "}
                  {option.title}
                </option>
              ))
              .reverse()}
          </Form.Select>
        )}
        <br />
        {infowell.dropDownName !== null ? (
        <GoButton
            handleSubmit={() => {
              downloadFileFromEducationService(
                formData?.get("id"),
                value,
                setLoading,
                setError,
                accessToken
              );
            }}
          />
        ) : (
          <GoButton
          handleSubmit={()=>{            
            if (
              infowell.title ===
              'Pediatric Grand Rounds <i class="glyphicon glyphicon-lock de-em" title="Requires Login"></i>'
            ) {
              handleOnClick();
            } else if (
              infowell.title ===
              'Online CME Lectures <i class="glyphicon glyphicon-lock de-em" title="Requires Login"></i>'
            ) {
              handleClick();
            } else {
              window.open(infowell.htmlContent);
            }
          }}
        />
        )}
      </Card>
    </div>
  );
};

export default InfowellsEdu;
