import { useEffect, useState } from "react";

const getScrollPosition = (key: string, initialValue: any) => {
  const savedValue = localStorage.getItem(key);
  const savedValueNumber = savedValue && parseInt(savedValue);
  if (savedValueNumber) return savedValueNumber;
  if (initialValue instanceof Function) return initialValue();
  return initialValue;
};

const useScrollPosition = (key: string, position: number = 0) => {
  const [scrollPosition, setScrollPosition] = useState(() => {
    return getScrollPosition(key, position);
  });
  useEffect(() => {
    localStorage.setItem(key, scrollPosition.toString());
  }, [scrollPosition]);
  return [scrollPosition, setScrollPosition];
};

export default useScrollPosition;
