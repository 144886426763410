import { FC } from "react";
import { IPractice } from "../../../../types/IPhysicianSearchResult";
import { Table } from "react-bootstrap";

const AdminPhysicianPracticesTable: FC<{ practices: IPractice[] }> = ({
  practices,
}) => {
  if (practices != null ? practices.length > 0:false) {
    return (
      <div>
        <Table id="lab-table" style={{ margin: 0 }}>
          <thead>
            <tr>
              <th>Practice ID</th>
              <th>Practice Name</th>
            </tr>
          </thead>
          <tbody>
            {practices != null ? practices.map((practice) => (
              <tr>
                <td id="table-reports-radiology">{practice.practiceId}</td>
                <td>{practice.practiceName}</td>
              </tr>
            )):null}
          </tbody>
        </Table>
      </div>
    );
  } else {
    return <div>No record found</div>;
  }
};

export default AdminPhysicianPracticesTable;
