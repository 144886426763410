import { Form, OverlayTrigger } from "react-bootstrap";
import FormPopover from "./FormPopover";
import { useFormikContext } from "formik";
import { useEffect } from "react";

const SelectInput = ({ fieldInfo, options, value, defaultvalue, ...field }) => {
  const { values, touched, setFieldValue } = useFormikContext();
  const handleChange = (e) => {
    if (!isNaN(parseInt(e.target.value))) {
      setFieldValue(field.name, parseInt(e.target.value));
      setFieldValue(
        fieldInfo.affects,
        e.nativeEvent.target[e.nativeEvent.target.selectedIndex].text
      );
      fieldInfo.initialValue = parseInt(e.target.value);
    } else {
      setFieldValue(field.name, null);
      setFieldValue(fieldInfo.affects, null);
    }
  };

  useEffect(() => {
    if (value == null) return;

    const option = options.filter(src => src.id == value)
    setFieldValue(field.name, value);
    setFieldValue(fieldInfo.affects, option[0].display);
  }, [])

  const defaultValue = () => {
    let a ;

    // console.log(field.name, ": ", value);
    field.name === "physicianId"||
    field.name === "practiceId" ||
    field.name === "insuranceTypeId" ||
    field.name === "patientAcuityId"||
    field.name === "requestTypeId" ||
    field.name === "clinicKey" ||
    field.name === "patientState" ||
    field.name === "patientSexId" ||
    field.name === "guardianRelationshipId"||
    field.name === "guardianSexId"
      ? a = value
      : a = null
      
    return a
  };
  
  return (
    <Form.Group className="d-flex row align-items-center form-padding">
      <Form.Label className="col-sm-3">
        {fieldInfo.label}
        {fieldInfo.required ? <span className="p-0 asterik"> *</span> : <></>}
      </Form.Label>
      <div className="col-sm-9">
        {fieldInfo.popover !== undefined && field.disabled !== true ? (
          <OverlayTrigger
            placement="top"
            overlay={FormPopover(fieldInfo.label, fieldInfo.popover + "")}
          >
            <Form.Select
              {...field}
              value={field.value}
              required={fieldInfo.required}
              defaultValue={
                defaultValue()
              }
              onChange={handleChange}
            >
              {options.map(({ id, display }) => (
                <option key={id} value={id}>
                  {display}
                </option>
              ))}
            </Form.Select>
          </OverlayTrigger>
        ) : (
          <Form.Select
            {...field}
            required={fieldInfo.required}
            value={field.value}
            defaultValue={value}
            //onChange={handleChange}
          >
            {options.map(({ id, display }) => (
              <option key={id} value={id}>
                {display}
              </option>
            ))}
          </Form.Select>
        )}
      </div>
    </Form.Group>
  );
};

export default SelectInput;
