import { crypto } from "@okta/okta-auth-js";
import { CancelTokenSource } from "axios";
import { customAxios as axios } from "./ServiceConfig";
import { API_BASE_URL } from "../config";
import IMenuItem from "../types/IMenuItem";
import IPracticeRoles from "../types/IPracticeRoles";

/**
 * @param token receives the access token of type string
 */
let loginas = localStorage.getItem('loginAsFlag')
let loginasEmail = localStorage.getItem('loginAsEmail')
const getMenuItems = async (
  token?: string,
  cancelToken?: CancelTokenSource
) => {
  
  const result = await axios.get<IMenuItem[]>(`${API_BASE_URL}/user/menu`, loginas !== null ? {
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Pragma: "no-cache",
      Authorization: `Bearer ${token}`,
      loginas:loginas !== null ? loginas : JSON.stringify(false),
      email:loginasEmail !== null ? loginasEmail : "",
    },
    cancelToken: cancelToken?.token,
  }:{
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Pragma: "no-cache",
      Authorization: `Bearer ${token}`,
    },
    cancelToken: cancelToken?.token,
  });
  return result;
};

/**
 * @param token receives the access token of type string
 */
const getPracticeRoles = async (
  token?: string,
  cancelToken?: CancelTokenSource
) => {
  const result = await axios.get<IPracticeRoles>(`${API_BASE_URL}/user/roles`, loginas !== null ? {
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Pragma: "no-cache",
      Authorization: `Bearer ${token}`,
      loginas:loginas !== null ? loginas : JSON.stringify(false),
      email:loginasEmail !== null ? loginasEmail : "",
    },
    cancelToken: cancelToken?.token,
  }:{
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Pragma: "no-cache",
      Authorization: `Bearer ${token}`,
    },
    cancelToken: cancelToken?.token,
  });
  return result;
};

/**
 * @param token receives the access token of type string
 */
const getUserAgreement = async (
  token?: string,
  cancelToken?: CancelTokenSource
) => {
  const result = await axios.get<{
    signRequired: boolean;
    docusignUrl: null | string;
  }>(`${API_BASE_URL}/user/agreement`, loginas !== null ? {
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Pragma: "no-cache",
      Authorization: `Bearer ${token}`,
      loginas:loginas !== null ? loginas : JSON.stringify(false),
      email:loginasEmail !== null ? loginasEmail : "",
    },
    cancelToken: cancelToken?.token,
  }:{
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Pragma: "no-cache",
      Authorization: `Bearer ${token}`,
    },
    cancelToken: cancelToken?.token,
  });
  return result;
};

/**
 * @param token receives the access token of type string
 */
const updateUserAgreement = async (
  status: string,
  token?: string,
  cancelToken?: CancelTokenSource
) => {
  const result = await axios.put<boolean>(
    `${API_BASE_URL}/user/agreement`,
    {
      docuSignStatus: status,
    },
    loginas !== null ? {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Pragma: "no-cache",
        Authorization: `Bearer ${token}`,
        loginas:loginas !== null ? loginas : JSON.stringify(false),
        email:loginasEmail !== null ? loginasEmail : "",
      },
      cancelToken: cancelToken?.token,
    }:{
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Pragma: "no-cache",
        Authorization: `Bearer ${token}`,
      },
      cancelToken: cancelToken?.token,
    }
  );
  return result;
};

const UserService = {
  getMenuItems,
  getPracticeRoles,
  getUserAgreement,
  updateUserAgreement,
};

export default UserService;
