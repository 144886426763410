import { FC, useEffect, useState, useRef } from "react";
import { Form, Col, Button, Tab, Tabs, Card } from "react-bootstrap";
import DateHelper, {
  getFormattedDate,
  getFormattedDateFromDate,
} from "../../../../helpers/DateHelper";
import IOnlineCME from "../../../../types/IOnlineCME";
import parse from "html-react-parser";
import MButton from "../../../mButton/MButton";
import { IndexType } from "typescript";

const SubForm: FC<{
  name?: string;
  cmedata: IOnlineCME;
  onFormClick: (
    title: string,
    contentTitle: string[],
    contentMessage: string[],
    contentBulletLine: string[],
    contentFullname: string[],
    contentSignature: string[]
  ) => void;
}> = ({ name, cmedata, onFormClick }) => {
  const isDisabled = false;
  type FormType = {
    title: string;
    eventDate: Date;
    videoUrl: string;
    isActive: boolean;
    contentTitle: string[];
    contentMessage: string[];
    contentBulletLine: string[];
    contentFullname: string[];
    contentSignature: string[];
  };
  // console.log(cmedata);
  const [data, setData] = useState<FormType>({
    title: "",
    eventDate: new Date(),
    videoUrl: "",
    isActive: false,
    contentTitle: [],
    contentMessage: [
      "At the end of this activity participants should be able to:  ",
    ],
    contentBulletLine: [],
    contentFullname: [],
    contentSignature: [],
  });

  const [fieldCount1, setFieldCount1] = useState(1);
  const [fieldCount2, setFieldCount2] = useState(1);
  const [fieldCount3, setFieldCount3] = useState(1);
  const [title, settitle] = useState("");

  const DATE = getFormattedDate(new Date());

  const handleMultipleOnChange = (
    e: any,
    key: keyof FormType,
    index: number
  ) => {
    const { [key]: mData } = data;

    const nData: string[] = mData as string[];
    nData[index] = e.target.value;

    switch (key) {
      case "contentTitle":
        const contentTitle: string[] = [];
        data.contentTitle.map((title, index) => {
          if (title.trim() != "") contentTitle.push(title);
        });
        data.contentTitle = contentTitle;
        if (index != fieldCount1 - 1)
          setFieldCount1(Math.max(data.contentTitle.length, 1));
        break;

      case "contentBulletLine":
        const contentBulletLine: string[] = [];
        data.contentBulletLine.map((title, index) => {
          if (title.trim() != "") contentBulletLine.push(title);
        });
        data.contentBulletLine = contentBulletLine;
        if (index != fieldCount2 - 1)
          setFieldCount2(Math.max(data.contentBulletLine.length, 1));
        break;

      case "contentSignature":
        const contentSignature: string[] = [];
        data.contentSignature.map((title, index) => {
          if (title.trim() != "") contentSignature.push(title);
        });
        data.contentSignature = contentSignature;
        if (index != fieldCount3 - 1)
          setFieldCount3(Math.max(data.contentSignature.length, 1));
        break;

      default:
        break;
    }

    setData(data);
    console.log(data);
    // data.contentTitle.filter(src => src != "");
    // console.log(data.contentTitle);

    // setData((prev) => ({ ...prev, [key]: nData }));

    onFormClick(
      data.contentTitle[0],
      data.contentTitle,
      data.contentMessage,
      data.contentBulletLine,
      data.contentFullname,
      data.contentSignature
    );
  };

  return (
    <div style={{ backgroundColor: "white" }}>
      <div>
        <Tabs
          // style={{ backgroundColor: "#dbdbdb", width: 350 }}
          defaultActiveKey="Edit in Form"
          transition={false}
        >
          <Tab eventKey="Edit in Form" title="Edit in Form">
            <div className="m-sm-5">
              <form className="m-sm-5">
                {new Array(fieldCount1).fill(0).map((count, index) => {
                  return (
                    <Form.Group className="d-flex row align-items-center form-padding">
                      <Form.Label column sm={3} className="pl-0 pr-0">
                        Title {index + 1}
                        {index > 0 ? <></> :<span className="p-0 asterik"> *</span>}
                      </Form.Label>

                      <Col sm={9} className="pl-0 pr-0">
                        <Form.Control
                          onChange={(e) =>{
                            handleMultipleOnChange(e, "contentTitle", index)
                          }
                          }
                          placeholder=""
                          required
                          value={data.contentTitle[index]}
                        />
                      </Col>
                    </Form.Group>
                  );
                })}
                <a
                  className="add-new-field"
                  onClick={() =>
                    data.contentTitle.length == fieldCount1
                      ? setFieldCount1((prev) => prev + 1)
                      : null
                  }
                >
                  Add New Title
                </a>
                <Form.Group className="d-flex row align-items-center form-padding">
                  <Form.Label column sm={3} className="pl-0 pr-0">
                    Message
                  </Form.Label>

                  <Col sm={9} className="pl-0 pr-0">
                    <Form.Control
                      onChange={(e) => {
                        handleMultipleOnChange(e, "contentMessage", 0);
                      }}
                      value={data.contentMessage[0]}
                      placeholder=""
                    />
                  </Col>
                </Form.Group>

                {new Array(fieldCount2).fill(0).map((count, index) => {
                  return (
                    <Form.Group className="d-flex row align-items-center form-padding">
                      <Form.Label column sm={3} className="pl-0 pr-0">
                        Bullet Line {index + 1}
                      </Form.Label>

                      <Col sm={9} className="pl-0 pr-0">
                        <Form.Control
                          value={data.contentBulletLine[index]}
                          onChange={(e) =>
                            handleMultipleOnChange(
                              e,
                              "contentBulletLine",
                              index
                            )
                          }
                          placeholder=""
                        />
                      </Col>
                    </Form.Group>
                  );
                })}
                <a
                  className="add-new-field"
                  onClick={() =>
                    data.contentBulletLine.length == fieldCount2
                      ? setFieldCount2((prev) => prev + 1)
                      : null
                  }
                >
                  Add New Bullet Line
                </a>
                <Form.Group className="d-flex row align-items-center form-padding">
                  <Form.Label column sm={3} className="pl-0 pr-0">
                    Full Name
                  </Form.Label>

                  <Col sm={9} className="pl-0 pr-0">
                    <Form.Control
                      onChange={(e) =>
                        handleMultipleOnChange(e, "contentFullname", 0)
                      }
                      placeholder=""
                    />
                  </Col>
                </Form.Group>

                {new Array(fieldCount3).fill(0).map((count, index) => {
                  return (
                    <Form.Group className="d-flex row align-items-center form-padding">
                      <Form.Label column sm={3} className="pl-0 pr-0">
                        Signature {index + 1}
                      </Form.Label>

                      <Col sm={9} className="pl-0 pr-0">
                        <Form.Control
                          value={data.contentSignature[index]}
                          onChange={(e) =>
                            handleMultipleOnChange(e, "contentSignature", index)
                          }
                          placeholder=""
                        />
                      </Col>
                    </Form.Group>
                  );
                })}
                <a
                  className="add-new-field"
                  onClick={() =>
                    data.contentSignature.length == fieldCount3
                      ? setFieldCount3((prev) => prev + 1)
                      : null
                  }
                >
                  Add New Signature
                </a>
              </form>
            </div>
          </Tab>
          <Tab eventKey="HTML Preview" title="HTML Preview">
            <div style={{ backgroundColor: "white" }}>
              <div style={{ backgroundColor: "white" }}>
                <br />
                <Col
                  sm={{ span: 5, offset: 3 }}
                  className="d-flex justify-content-center"
                >
                  <Card className="social p-4">
                    <div>
                      {/* {ReactHtmlParser(pgr.content)} */}

                      {data.contentTitle?.map((bullet, index) => {
                        return (
                          <>
                            <h1
                              style={{
                                fontSize: 25,
                                color: "#004877",
                              }}
                            >
                              {bullet}
                            </h1>
                          </>
                        );
                      })}
                      <h1
                        style={{
                          fontSize: 15,
                          color: "#666666",
                        }}
                      >
                        {getFormattedDateFromDate(data.eventDate)}
                      </h1>
                      <h2
                        style={{
                          fontSize: 20,
                          color: "#666666",
                        }}
                      >
                        {data.contentMessage[0]}
                      </h2>
                      {data.contentBulletLine?.map((bullet, index) => {
                        return (
                          <div>
                            {index + 1}.{"  "}
                            {bullet}
                          </div>
                        );
                      })}
                      <br />
                      <h2
                        style={{
                          fontSize: 23,
                          color: "#666666",
                        }}
                      >
                        {data.contentFullname[0]}
                      </h2>
                      {data.contentSignature?.map((bullet, index) => {
                        return <div>{bullet}</div>;
                      })}

                      <MButton
                        variant="secondary"
                        disabled={false}
                        title={"Watch  " + "\u00bb"}
                        width={"100%"}
                        type="button"
                        onClick={() => window.open(data.videoUrl)}
                      >
                        {"Watch  " + "\u00bb"}
                      </MButton>
                    </div>
                  </Card>
                </Col>
              </div>
            </div>
          </Tab>
        </Tabs>
      </div>
    </div>
  );
};

export default SubForm;
