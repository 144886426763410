import { FC, useEffect, useState } from "react";
import HeaderName from "../../headerName/HeaderName";
import { Badge, OverlayTrigger, Table, Col } from "react-bootstrap";
import IDivisionMonth from "../../../types/IDivisionMonth";
import MButton from "../../mButton/MButton";
import { useHistory, useLocation } from "react-router-dom";
import CustomToggle from "../../toggle/CustomToggle";
import { useOktaAuth } from "@okta/okta-react";
import DivisionMonthService from "../../../services/DivisionMonthService";
import axios from "axios";
import { getFormattedDateFromDate } from "../../../helpers/DateHelper";
import Loader from "../../common/Loader";
import { Checkbox } from "@mui/material";
import IClinicalType from "../../../types/IClinicalType";
import IClinicalPathways from "../../../types/IClinicalPathways";
import ClinicalPathwayService from "../../../services/ClinicalPathwayService";
import { appInsights } from "../../../appInsights";
const ClinicalCarePathways: FC<{
  name?: string;
}> = ({ name }) => {
  const history = useHistory();
  const tableCellPadding = { padding: 10 };
  const tableHeadPadding = { paddingLeft: 20, paddingRight: 20 };
  const handleClick = () => {
    history.push("/portalupdates/newclinicalcarepathways");
  };
  const handleOnClick = (clinicalpathway: IClinicalPathways) => {
    history.push("/portalupdates/editclinicalcarepathways", clinicalpathway);
  };

  const { authState } = useOktaAuth();
  const accessToken = authState?.accessToken?.accessToken;
  const cancelToken = axios.CancelToken.source();
  const [loading, setLoading] = useState(true);
  const [clinicalTypeData, setClinicalTypeData] = useState<IClinicalType[]>();
  const [error, setError] = useState<Error | null>(null);
  const onToggle = () => {};

  const [clinicalPathwayData, setClinicalPathwayData] =
    useState<IClinicalPathways[]>();

  useEffect(() => {
    setLoading(true);
    setError(null);
    ClinicalPathwayService.getClinicalType(accessToken)
      .then((response) => {
        //setLoading(false);
        setClinicalTypeData(response.data);
      })
      .catch((e: Error) => {
        if (!axios.isCancel(e)) {
          console.error(e);
          appInsights.trackException({ error: e });
          setError(e);
        }

      });
    ClinicalPathwayService.getClinicalPathwayServiceList(accessToken)
      .then((response) => {
        setLoading(false);
        setClinicalPathwayData(response.data);
      })
      .catch((e: Error) => {
        if (!axios.isCancel(e)) {
          console.error(e);
          appInsights.trackException({ error: e });
          setError(e);
        }

        setLoading(false);
      });
    return () => {
      cancelToken.cancel();
    };
  }, []);
  const clinicalCareType = (id:number) => {
   return clinicalTypeData?.map((option, index: number) => {
      if(id == option.id)
      {
        return option.type;
      }
      else{
      return null
      }
    });
  };
  return (
    <div className="referrals">
      <HeaderName name={name} />
      <div className="container">
        <h2 className="heading-main">{name}</h2>
        <div className="d-flex row justify-content-end" style={{ padding: 20 }}>
          <MButton
            variant="primary"
            disabled={false}
            title="Add New"
            width={"fit-content"}
            onClick={handleClick}
          />
        </div>
        <Col
          sm={{ span: 7, offset: 3 }}
          className="d-flex justify-content-center"
        >
          {loading && clinicalPathwayData == undefined && <Loader />}
        </Col>
        <br />

        <div className="referral">
          <Table id="responsive-table" className="mt-2" responsive>
            <thead>
              <tr>
                <th style={tableHeadPadding}>Title</th>
                <th style={tableHeadPadding}>File Name </th>
                <th style={tableHeadPadding}>File Type </th>
                <th style={tableHeadPadding}>Is Active</th>
                <th style={tableHeadPadding}>Publication Date</th>
                <th style={tableHeadPadding}>Modified On</th>
                <th style={tableHeadPadding}>Modified By</th>
              </tr>
            </thead>
            <tbody>
              {clinicalPathwayData?.map((clinicalpathway) => {
                return (
                  <tr>
                    <td
                      style={tableCellPadding}
                      data-title="Title"
                      id="table-text-referrals"
                      onClick={() => handleOnClick(clinicalpathway)}
                    >
                      {clinicalpathway.title}
                    </td>
                    <td style={tableCellPadding} data-title="File Name">
                      {clinicalpathway.fileName}
                    </td>
                    <td style={tableCellPadding} data-title="File Type">
                      {clinicalCareType(clinicalpathway.clinicalCareId)}
                      {/* {clinicalpathway.clinicalCareId} */}
                    </td>
                    <td style={tableCellPadding} data-title="Is Active">
                      <Checkbox disabled checked={clinicalpathway.isActive} />
                    </td>
                    <td style={tableCellPadding} data-title="Publication Date">
                      {getFormattedDateFromDate(
                        clinicalpathway.publicationDate
                      )}
                    </td>
                    <td style={tableCellPadding} data-title="Modified On">
                      {getFormattedDateFromDate(clinicalpathway.modifiedOn)}
                    </td>
                    <td style={tableCellPadding} data-title="Modified By">
                      {clinicalpathway.modifiedBy}
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </Table>
        </div>
      </div>
    </div>
  );
};

export default ClinicalCarePathways;
