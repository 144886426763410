export const getFormattedDate = (date) => {
  let dd = date.getDate();
  let mm = date.getMonth() + 1;
  const yyyy = date.getFullYear();
  if (dd < 10) {
    dd = `0${dd}`;
  }
  if (mm < 10) {
    mm = `0${mm}`;
  }
  return `${mm}-${dd}-${yyyy}`;
};

export const getFormattedTime = (mDate) => {
  if(mDate != null || mDate != undefined)
  {
  let date = new Date(mDate);
  var hours = date.getHours();
  var minutes = date.getMinutes();
  if (isNaN(hours) || isNaN(minutes)) {
    return mDate;
  }
  var ampm = hours >= 12 ? "PM" : "AM";
  hours = hours % 12;
  hours = hours< 10 && hours !== 0 ? "0"+hours : 12;  // the hour '0' should be '12'
  minutes = minutes < 10 ? "0" + minutes : minutes;
  var strTime = hours + ":" + minutes + " " + ampm;
  return mDate.split(" ")[0] + " " + strTime;
}
else
{
  return "No date";
}
};

export const getFormattedDateFromDate = (date) => {
  if (date === undefined || date == null) {
    return "";
  }

  if (typeof date === "string") {
    date = new Date(date);
  }
  var dd = String(date.getDate()).padStart(2, "0");
  var mm = String(date.getMonth() + 1).padStart(2, "0"); //January is 0!
  var yyyy = date.getFullYear();

  return mm + "/" + dd + "/" + yyyy;
};
export const getFormattedYear = (date) => {
  if (date === undefined || date == null) {
    return "";
  }

  if (typeof date === "string") {
    date = new Date(date);
  }
  var dd = String(date.getDate()).padStart(2, "0");
  var mm = String(date.getMonth() + 1).padStart(2, "0"); //January is 0!
  var yyyy = date.getFullYear();

  return  yyyy;
};
export const getFormattedDateWords = (date) => {
  if (date === undefined || date == null) {
    return "";
  }

  if (typeof date === "string") {
    date = new Date(date);
  }
  var dd = String(date.getDate()).padStart(2, "0");
  var mm = date.toLocaleString('en-us', { month: 'long' }); //January is 0!
  var yyyy = date.getFullYear();

  return  mm + ' ' + yyyy;
};
export const getFormattedDateFromDateTime = (date) => {
  if (date === undefined || date == null) {
    return "";
  }

  if (typeof date === "string") {
    date = new Date(date);
  }
  var dd = String(date.getDate()).padStart(2, "0");
  var mm = String(date.getMonth() + 1).padStart(2, "0"); //January is 0!
  var yyyy = date.getFullYear();

  return yyyy + "-" + mm + "-" + dd;
};
export const getFormattedTimeFromDateTime = (date) => {
  if (date === undefined || date == null) {
    return "";
  }

  if (typeof date === "string") {
    date = new Date(date);
  }
  var hours = String(date.getHours()).padStart(2, "0");
  var minutes = String(date.getMinutes()).padStart(2, "0"); 
  var ampm = hours >= 12 ? "PM" : "AM";
  hours = hours % 12;
  hours = hours < 10 && hours !== 0 ? "0"+hours : 12; 
 var strTime  = hours + ":" + minutes  +" " + ampm;
 return  " " + strTime;
  
};
export const editFormattedTimeFromDateTime = (date) => {
  if (date === undefined || date == null) {
    return "";
  }

  if (typeof date === "string") {
    date = new Date(date);
  }
  var hours = String(date.getHours()).padStart(2, "0");
  var minutes = String(date.getMinutes()).padStart(2, "0"); //January is 0!

  return hours+":"+minutes
};
export const getFormattedDateTimeFromDateTime = (date) => {
  if (date === undefined || date == null) {
    return "";
  }

  if (typeof date === "string") {
    date = new Date(date);
  }
  var dd = String(date.getDate()).padStart(2, "0");
  var mm = String(date.getMonth() + 1).padStart(2, "0"); //January is 0!
  var yyyy = date.getFullYear();
  var hours = String(date.getHours()).padStart(2, "0");
  var minutes = String(date.getMinutes()).padStart(2, "0"); //January is 0!
  return yyyy + "-" + mm + "-" + dd+"T"+hours+":"+minutes;
};

export const getFormattedDateTime=(mDate)=>{
  if (mDate == undefined || mDate == null) {
    return "";
  }
  let date = new Date(mDate);
  var hours = date.getHours();
  var minutes = date.getMinutes();
  if (isNaN(hours) || isNaN(minutes)) {
    return mDate;
  }
  var ampm = hours >= 12 ? "PM" : "AM";
  hours = hours % 12;
  hours = hours ? hours : 12; // the hour '0' should be '12'
  minutes = minutes < 10 ? "0" + minutes : minutes;
  var strTime = hours + ":" + minutes + " " + ampm;
  return mDate.split("T")[0] + " " + strTime;
}

export default {
  
  getFormattedDate,
  getFormattedTime,
  getFormattedDateFromDate,
  getFormattedDateFromDateTime,
  getFormattedTimeFromDateTime,
  getFormattedDateTimeFromDateTime,
  getFormattedDateTime,
  editFormattedTimeFromDateTime
};
