import { useEffect, useState } from "react";
import IPracticeRoles from "../types/IPracticeRoles";

type KEY = "user_role";

const getuserRoleFromSessionStorage = (
  key: KEY,
  initialValue: IPracticeRoles | undefined
) => {
  const savedValue = localStorage.getItem(key);
  const savedValueJSON = savedValue && JSON.parse(savedValue);
  if (savedValueJSON) return savedValueJSON;
  if (initialValue instanceof Function) return initialValue();
  return initialValue;
};

const useUserRole = (key: KEY = "user_role", initialValue?: IPracticeRoles) => {
  const [userRole, setUserRole] = useState<IPracticeRoles | undefined>(() => {
    return getuserRoleFromSessionStorage(key, initialValue);
  });
  useEffect(() => {
    if (userRole !== undefined) {
      localStorage.setItem(key, JSON.stringify(userRole));
    }
  }, [userRole]);
  return [userRole, setUserRole] as const;
};

export default useUserRole;
