import { FC } from "react";
import { Modal } from "react-bootstrap";
import MButton from "../mButton/MButton";

const CustomAlertModal: FC<{
    show: boolean;
    header: string;
    body: string;
    handleClose?: () => void;
}> = ({ show, header, body, handleClose, }) => {

    return (
        <Modal show={show} onHide={handleClose} centered>
            <Modal.Header>{header}</Modal.Header>
            <Modal.Body>
                {body}
            </Modal.Body>
            <Modal.Footer>
                <MButton
                    onClick={handleClose}
                    variant="primary"
                    title="Ok"
                    width={"max-content"}
                />
            </Modal.Footer>
        </Modal>
    );
};

export default CustomAlertModal;
