import { FC, useEffect, useState } from "react";
import { useOktaAuth } from "@okta/okta-react";
import { Table } from "react-bootstrap";
import PracticeService from "../../../../services/PracticeService";
import IPracticePhysicians, {
  Physicians,
} from "../../../../types/IPracticePhysicians";
import axios from "axios";
import Loader from "../../../common/Loader";
import IPracticeRoles from "../../../../types/IPracticeRoles";
import CustomAccordion from "../../../accordion/CustomAccordion";
import AddPhysicianToPracticeModal from "./AddPhysicianToPracticeModal";
import AccountService from "../../../../services/AccountService";
import usePracticeInfo from "../../../../hooks/usePracticeInfo";
import MButton from "../../../mButton/MButton";
import { appInsights } from "../../../../appInsights";
import CustomAlertModal from "../../../common/CustomAlertModal";
import RemoveUserConfirmationModal from "./RemoveUserConfirmationModal";
import InvitePhysicianConfirmationModal from "./InvitePhysicianConfirmationModal";
import useUserRole from "../../../../hooks/useUserRole";

const PracticePhysicians: FC<{ role?: IPracticeRoles, apiCallCount: any ,practiceId:number}> = ({ role, apiCallCount,practiceId }) => {
  const [practicePhysicians, setPracticePhysicians] =
    useState<IPracticePhysicians>();
  const { authState } = useOktaAuth();
  const accessToken = authState?.accessToken?.accessToken;
  const cancelToken = axios.CancelToken.source();
  const [loading, setLoading] = useState(true);
  const [showModal, setShowModal] = useState(false);
  const [modalShow, setModalShow] = useState(false);
  const [username, setUserName] = useState('');
  const [showRemoveModal, setShowRemoveModal] = useState(false);
  const [error, setError] = useState<boolean>(false);
  const [isSubmitting, setIsSubmitting] = useState<{
    name: string;
    physicianId?: number;
    isSubmitting: boolean;
  }>({ name: "", physicianId: undefined, isSubmitting: false });
  const [practiceInfo] = usePracticeInfo();
  const [invited, setInvited] = useState(0);
  const [userRole, setUserRole] = useUserRole();
  const [response, setResponse] = useState<{
    show: boolean;
    header: string;
    body: string;
  }>({
    show: false,
    header: "",
    body: "",
  });
  const [userToRemove, setUserToRemove] = useState<Physicians>();

  useEffect(() => {
    setLoading(true);
    PracticeService.getPracticePhysicians(accessToken, cancelToken)
      .then((response) => {
        setPracticePhysicians(response.data);
        setLoading(false);
      })
      .catch((e) => {
        if (!axios.isCancel(e)) {
          console.error(e);
          appInsights.trackException({ error: e });
          // setError(e);
        }
        setLoading(false);
      });
    return () => {
      cancelToken.cancel();
    };
  }, [isSubmitting.isSubmitting, invited, apiCallCount]);
  const checkRole = ():boolean => {

    if (userRole !== undefined){
      return userRole?.practiceRoles.filter(e=>e.roleId == "3" || e.roleId == "9").length > 0 ? true : false
    }

    return false
  }
  const renderRemoveButton = (user: Physicians) => {

    if ((practicePhysicians?.isDelegated || checkRole()) && user.status === "Active") {
      return (
        <td>
          <MButton
            onClick={() => {
              setShowRemoveModal(true);
              setUserToRemove(user);
            }}
            title="Delete"
            small
            variant="danger"
            width="auto"
            marginLeft="70px"
            isSubmitting={
              isSubmitting.physicianId === user.physicianId &&
              isSubmitting.isSubmitting
            }
          />
        </td>
      );
    } else return <td></td>;
  };
  const renderAddButton = () => {

    if ((practicePhysicians?.isDelegated || checkRole()) ) {
      return (
        <div className="PhysicianButton">
              <MButton
                onClick={() => {
                  setShowModal(true);
                }}
                title="Add a physician to my practice"
                small
                variant="secondary-light"
                width="auto"
                marginLeft="70px"
              />
            </div>
      );
    } else return null;
  };
  const inviteModal = (user: any) => {
    setModalShow(true);
    setUserName(user);
  }

  return (
    <>
      <CustomAccordion
        title={
          <div className="header-top d-flex">
            <h5 className="header-blue bold m-0 pe-2">
              Physicians in My Practice
            </h5>
            {loading ? (
              <Loader />
            ) : (
              <h5 className="header-blue bold m-0">
                (
                {practicePhysicians !== undefined
                  ? practicePhysicians.data.length
                  : 0}
                )
              </h5>
            )}
          </div>
        }
        content={
          <>
           {renderAddButton()}

            <div className="table-responsive-sm">
              <Table id="lab-table">
                <thead>
                  <tr>
                    <th>Name</th>
                    <th>Email</th>
                    
                    {(practicePhysicians?.isDelegated || checkRole()) ?<th style={{ width: 90 }}> </th> :null}
                    <th>Status</th>
                    
                    <th></th>
                  </tr>
                </thead>
                <tbody>
                  {practicePhysicians?.data.map((user, key) => {
                    return (
                      <tr key={key}>
                        <td>
                          {user.firstName} {user.lastName}
                        </td>
                        <td>{user.email}</td>

                        {(practicePhysicians?.isDelegated || checkRole()) ? user.accountExists === false ? <td><a id="link" className="about" onClick={() => inviteModal(user)} style={{ cursor: 'pointer' }} >Invite physician to join myCHLA</a></td> : <td>{user.userName}</td>:null}
                        <td style={{ width: 90 }}>{user.status === 'In-Active' ? 'Inactive' : user.status}</td>

                        {renderRemoveButton(user)}
                      </tr>
                    );
                  })}
                </tbody>
              </Table>
            </div>
          </>
        }
      />
      <InvitePhysicianConfirmationModal
        show={modalShow}
        user={username}
        onHide={() => setModalShow(false)}
        handleAdd={() => {
          setModalShow(false);
          setResponse({
            show: true,
            header: "Success",
            body: "Your request has been processed successfully.",
          });
        }}
      />

      <AddPhysicianToPracticeModal
        show={showModal}
        handleClose={() => {
          setShowModal(false);
        }}
        handleAdd={() => {
          setShowModal(false);
          setInvited((c) => c + 1);
          setResponse({
            show: true,
            header: "Success",
            body: "Your request has been processed successfully.",
          });
        }}
        handleError={() => {
          setResponse({
            show: true,
            header: "Error!",
            body: "There was an error processing your request. Please refresh the page and try again. If problems persist please contact myCHLA support.",
          });
        }}
      />
      <CustomAlertModal
        show={response.show}
        header={response.header}
        body={response.body}
        handleClose={() => {
          setResponse({
            show: false,
            header: "",
            body: "",
          });
        }}
      />
      <RemoveUserConfirmationModal
        show={showRemoveModal}
        handleClose={() => {
          setShowRemoveModal(false);
          setUserToRemove(undefined);
        }}
        handleConfirm={() => {
          setShowRemoveModal(false);
          setIsSubmitting({
            name: userToRemove?.firstName + " " + userToRemove?.lastName,
            physicianId: userToRemove?.physicianId,
            isSubmitting: true,
          });
          if (userToRemove?.physicianId) {
            AccountService.removePhysician(
              {
                physicianId: userToRemove?.physicianId,
                practiceId: practiceId,
              },
              accessToken,
              cancelToken
            )
              .then((response) => {
                setIsSubmitting({
                  name: userToRemove?.firstName + " " + userToRemove?.lastName,
                  physicianId: userToRemove?.physicianId,
                  isSubmitting: false,
                });
              })
              .catch((e) => {
                setIsSubmitting({
                  name: userToRemove?.firstName + " " + userToRemove?.lastName,
                  physicianId: userToRemove?.physicianId,
                  isSubmitting: false,
                });
                if (!axios.isCancel(e)) {
                  console.error(e);
                  appInsights.trackException({ error: e });
                  // setError(e);
                }
              });
          }
        }}
        name={userToRemove?.firstName + " " + userToRemove?.lastName}
      />
    </>
  );
};

export default PracticePhysicians;
