import { FC, useEffect, useRef } from "react";
import OktaSignIn from "@okta/okta-signin-widget";
import "@okta/okta-signin-widget/dist/css/okta-sign-in.min.css";
import { Tokens } from "@okta/okta-auth-js";

import { useOktaAuth } from "@okta/okta-react";

interface Props {
  config: any;
  onSuccess: (tokens: Tokens | undefined) => void;
  onError: (err: any) => void;
}

const OktaSignInWidget: FC<Props> = ({ config, onSuccess, onError }) => {
  const widgetRef = useRef(null);
  const { oktaAuth, authState } = useOktaAuth();

  useEffect(() => {
    if (!widgetRef.current) return;
    const widget = new OktaSignIn(config);
      widget
      .showSignInToGetTokens({
        el: widgetRef.current,
      })
      .then(onSuccess)
      .catch(onError);
      return () => widget.remove();   
    
  }, [config, onSuccess, onError]);

  return <div ref={widgetRef} />;
};
export default OktaSignInWidget;
