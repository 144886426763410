import { FC, useState } from "react";
import MButton from "../../mButton/MButton";
import { Link, Redirect, useHistory, useLocation } from "react-router-dom";
import { Alert } from "@mui/material";
import { Field, Formik, Form as FormikForm } from "formik";
import CustomInputFormik from "../../common/CustomInputFormik";
import HeaderName from "../../headerName/HeaderName";
import AccountService from "../../../services/AccountService";
import { useOktaAuth } from "@okta/okta-react";
import { appInsights } from "../../../appInsights";
import axios from "axios";
import * as Yup from "yup";
import IFormDropdownOption from "../../../types/IFormDropdownOption";

const NewPracticeRequest: FC<{
  name?: string;
}> = ({ name }) => {
  //
  const [loading, setLoading] = useState<boolean>(true);

  const { authState } = useOktaAuth();
  const accessToken = authState?.accessToken?.accessToken;
  const [redirect, setRedirect] = useState({ redirect: false, message: "" });
  const states: IFormDropdownOption[] =
    require("../../../data/states.json").map((s: any) => ({
      id: s.abbreviation,
      display: s.abbreviation,
    }));

  if (redirect.redirect) {
    return (
      <Redirect
        to={{
          pathname: "/",
          state: { message: redirect.message },
        }}
      />
    );
  } else {
    return (
      <>
        <HeaderName name="New Account Request" />
        {redirect.message === "success" ? (
          <>
            {window.scrollTo(0, 0)}
            <Alert severity="success">
              Form is successfully submitted,please wait while we are
              redirecting!
            </Alert>
          </>
        ) : redirect.message === "error" ? (
          <>
            {window.scrollTo(0, 0)}
            <Alert severity="error" style={{ color: "#EA1D36" }}>
              Error in submitting the form, please try again!
            </Alert>
          </>
        ) : redirect.message ? (
          <>
            {window.scrollTo(0, 0)}
            <Alert severity="error" style={{ color: "#EA1D36" }}>
              {redirect.message}
            </Alert>
          </>
        ) : (
          <></>
        )}
        <div className="container">
          <h2 className="heading-main">New Practice Request</h2>
          <div className="row">
            <div className="col-md-8">
              <div className="chart">
                <Formik
                  initialValues={{}}
                  validationSchema={Yup.object().shape({
                    userEmail: Yup.string()
                      .email("Email is not valid")
                      .max(255),
                    practiceContactEmail: Yup.string()
                      .email("Email is not valid")
                      .max(255)
                      .nullable(),
                    practicePhone: Yup.string().matches(
                      /^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4}$/im,
                      "Phone number is not valid"
                    ),
                    officePhone: Yup.string()
                      .matches(
                        /^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4}$/im,
                        "Phone number is not valid"
                      )
                      .nullable(),
                    userPhone: Yup.string().matches(
                      /^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4}$/im,
                      "Phone number is not valid"
                    ),
                    practiceZip: Yup.string().matches(
                      /(^\d{5}$)|(^\d{5}-\d{4}$)/,
                      "Zip Code is not valid"
                    ),
                    npi: Yup.string().matches(
                      /^\d{10}$/,
                      "NPI number is not valid"
                    ),
                    practiceFax:  Yup.string().matches(
                      /^\+?[0-9]{7,}$/,
                      "Fax number is not valid"
                    ).nullable(),
                  })}
                  onSubmit={(values) => {
                    AccountService.newAccountRequest(values)
                      .then((response) => {
                        setRedirect({ redirect: false, message: "success" });
                        setTimeout(() => {
                          setRedirect({ redirect: true, message: "" });
                        }, 3000);
                      })
                      .catch((err: any) => {
                        if (!axios.isCancel(err)) {
                          console.error(err);
                          appInsights.trackException({ error: err });
                          // setError(e);
                        }
                        setRedirect({ redirect: false, message: "error" });
                      });
                  }}
                >
                  {({ }) => (
                    <FormikForm>
                      <div className="d-flex row align-items-center form-padding">
                        <div className="col-sm-3"></div>
                        <h4
                          className="col-sm-9 mb-0 mt-3"
                          style={{ color: "#0d9ddb" }}
                        >
                          <strong>Owner Information</strong>
                        </h4>
                      </div>
                      <Field
                        label="Title"
                        name="userTitle"
                        required={false}
                        type="text"
                        component={CustomInputFormik}
                      />
                      <Field
                        label="First Name"
                        name="userFirstName"
                        required={true}
                        type="text"
                        component={CustomInputFormik}
                      />
                      <Field
                        label="Middle Name"
                        name="userMiddleName"
                        required={false}
                        type="text"
                        component={CustomInputFormik}
                      />
                      <Field
                        label="Last Name"
                        name="userLastName"
                        required={true}
                        type="text"
                        component={CustomInputFormik}
                      />
                      <Field
                        label="Email"
                        name="userEmail"
                        required={true}
                        type="email"
                        component={CustomInputFormik}
                      />
                      <Field
                        label="Mobile Phone"
                        name="userPhone"
                        required={true}
                        type="text"
                        component={CustomInputFormik}
                      />
                      <Field
                        label="Office Phone"
                        name="officePhone"
                        required={false}
                        type="text"
                        component={CustomInputFormik}
                      />

                      <Field
                        label="National Provider Identifier (NPI)"
                        name="npi"
                        required={true}
                        type="text"
                        component={CustomInputFormik}
                      />
                      <Field
                        label="Medical License Number"
                        name="medicalLicenseNumber"
                        required={true}
                        type="text"
                        component={CustomInputFormik}
                      />
                      <div className="d-flex row align-items-center form-padding">
                        <div className="col-sm-3"></div>
                        <h4
                          className="col-sm-9 mb-0 mt-3"
                          style={{ color: "#0d9ddb" }}
                        >
                          <strong>Practice Information</strong>
                        </h4>
                      </div>
                      <Field
                        label="Practice Name"
                        name="practiceName"
                        required={true}
                        type="text"
                        component={CustomInputFormik}
                      />
                      <Field
                        label="Address 1"
                        name="practiceAddress1"
                        required={true}
                        type="text"
                        component={CustomInputFormik}
                      />
                      <Field
                        label="Address 2"
                        name="practiceAddress2"
                        required={false}
                        type="text"
                        component={CustomInputFormik}
                      />
                      <Field
                        label="City"
                        name="practiceCity"
                        required={true}
                        type="text"
                        component={CustomInputFormik}
                      />
                      <Field
                        label="State"
                        name="practiceState"
                        required={true}
                        type="typeahead"
                        placeholder="Please make a selection..."
                        options={states}
                        component={CustomInputFormik}
                      />
                      <Field
                        label="Zip"
                        name="practiceZip"
                        required={true}
                        type="text"
                        component={CustomInputFormik}
                      />
                      <Field
                        label="Phone"
                        name="practicePhone"
                        required={true}
                        type="text"
                        component={CustomInputFormik}
                      />
                      <Field
                        label="Fax"
                        name="practiceFax"
                        required={false}
                        type="text"
                        component={CustomInputFormik}
                      />
                      <Field
                        label="Practice Contact Name"
                        name="practiceContactName"
                        required={false}
                        type="text"
                        component={CustomInputFormik}
                      />
                      <Field
                        label="Email"
                        name="practiceContactEmail"
                        required={false}
                        type="text"
                        component={CustomInputFormik}
                      />
                      <div
                        style={{
                          // display: "flex",
                          flexDirection: "row-reverse",
                          margin: "20px 0px",
                          marginLeft: "25%",
                        }}
                      >
                        <MButton
                          type="submit"
                          variant="primary"
                          title="Request Account"
                          width={"fit-content"}
                        />
                      </div>
                    </FormikForm>
                  )}
                </Formik>
              </div>
            </div>
            <div className="col-md-4">
              <div className="chart">
                <h5>
                  <strong>You will need</strong>
                </h5>
                <ul>
                  <li>Your mobile phone number</li>
                  <li>Your email address</li>
                  <li>Your personal NPI and license number</li>
                </ul>
                <h5>
                  <strong>Resources</strong>
                </h5>
                <ul>
                  <li>
                  
                    <Link style={{ color: "var(--color-light-blue)" }} to={require("../../../assets/CHLA_myCHLA User Guide_Practice Owner Account Management Process.pdf").default} target="_blank">
                      Quick Reference
                    </Link>
                  </li>
                </ul>
                <p>
                  If your practice has already enrolled in myCHLA, please
                  contact your practice owner to create your account.
                </p>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
};
export default NewPracticeRequest;
