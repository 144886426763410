import { FC, useState, useEffect } from "react";
import { useLocation, useParams, Redirect } from "react-router-dom";
import { useOktaAuth } from "@okta/okta-react";
import { Alert } from "@mui/material";
import ILookbackOption from "../../../types/ILookbackOption";
import IPatientAccessRequest from "../../../types/IPatientAccessRequest";
import Spinner from "../../spinner/Spinner";
import Loader from "../../common/Loader";
import HeaderName from "../../headerName/HeaderName";
import BackButton from "../../backButton/BackButton";
import { Form, Col, Button } from "react-bootstrap";
import "./patient-details.css";
import { getFormattedDate } from "../../../helpers/DateHelper";
import IReferralFormDropdownData from "../../../types/IReferralFormDropdownData";
import ReferralService from "../../../services/ReferralService";
import { appInsights } from "../../../appInsights";
import MButton from "../../mButton/MButton";
import axios from "axios";
import {
  getFormattedDateFromDateTime,
  getFormattedTimeFromDateTime,
  getFormattedDateFromDate,
  getFormattedDateTime
} from "../../../helpers/DateHelper";

import PatientAccessRequestService from "../../../services/PatientAccessRequestService";

type stateType = {
  state: {
    patientAccess: IPatientAccessRequest;
  };
};
const PatientAccessRequestsEdit: FC<{ name?: string }> = ({ name }) => {
  const [referralFormDropdownData, setReferralFormDropdownData] =
    useState<IReferralFormDropdownData>();
  const { state } = useLocation<stateType>();

  const isDisabled = false;
  const { authState } = useOktaAuth();
  const accessToken = authState?.accessToken?.accessToken;
  const patientDetail: IPatientAccessRequest = state.state.patientAccess;

  const [redirect, setRedirect] = useState({ redirect: false, message: "" });
  const [formData, setFormData] = useState({
    patientAccessRequestID: patientDetail.patientAccessRequestID,
    patientFirstName: patientDetail.patientFirstName,
    patientLastName: patientDetail.patientLastName,
    patientDob: (patientDetail.patientDob != null || patientDetail.patientDob != undefined) ? new Date(patientDetail.patientDob).toISOString():null,
    patientMRN: patientDetail.patientMRN,
    patientGenderId: patientDetail.patientGenderId,
    onBehalfPhysicianId: patientDetail.onBehalfPhysicianID,
    requestStatus: patientDetail.requestStatus,
    notes: patientDetail.notes,
  });
  const DATE = getFormattedDate(new Date());
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<Error>();
  const [patientAccess, setpatientAccess] = useState<any | null>(null);

  useEffect(() => {
    ReferralService.getReferralFormDataList(accessToken)
      .then((response) => {
        setReferralFormDropdownData(response.data);
      })
      .catch((e) => {
        if (!axios.isCancel(e)) {
          console.error(e);
          appInsights.trackException({ error: e });
          setError(e);
        }
       
      });
  }, []);

  const handleOnChange = (e: any, key: any) => {
    let data: any = formData;
    if (key === "patientGenderId" || key === "onBehalfPhysicianId") {
      data[key] = Number(e.target.value);
    } else if (key === "requestStatus") {
      data[key] = e.target.value;
    } else {
      data[key] = e.target.value;
    }
    setFormData(data);
  };
  const handleDefaultValue = (type: any, key: any) => {
    if (type === "select") {
      if (key === "onBehalfPhysicianId") {
        return patientDetail.onBehalfPhysicianID;
      }
      if (key === "patientGenderId") {
        return patientDetail.patientGenderId;
      }
      if (key === "requestStatus") {
        return patientDetail.requestStatus;
      }
    } else {
      if (key === "patientDob") {
        var dateDefaultValue = new Date(patientDetail.patientDob);
        dateDefaultValue.setDate(dateDefaultValue.getDate());
        var date = dateDefaultValue.toISOString().substring(0,10);
        return date;
      }
      if (key === "patientFirstName") {
        return patientDetail.patientFirstName;
      }
      if (key === "patientLastName") {
        return patientDetail.patientLastName;
      }

      if (key === "patientMRN") {
        return patientDetail.patientMRN;
      }
      if (key === "notes") {
        return patientDetail.notes;
      }
    }
  };

  const handleSubmit = (e: any) => {
    e.preventDefault();
    setLoading(true);
    PatientAccessRequestService.editPatientAccessRequest(formData, accessToken)
      .then((response) => {
        
        setRedirect({ redirect: false, message: "success" });
        setTimeout(() => {
          setRedirect({ redirect: true, message: "" });
          setLoading(false)
        }, 3000);
      })
      .catch((err) => {
        if (!axios.isCancel(e)) {
          console.error(e);
          appInsights.trackException({ error: e });
           setError(e);
        }
        setLoading(false)
        setRedirect({ redirect: false, message: "error" });
      });
  };

  let form = require("../../../data/editPatientAccessRequest.json");
  const DEFAULT_OPTION = "Please make a selection...";

  const { onBehalfPhysicianId } = form;

  form = {
    ...form,
    onBehalfPhysicianId: {
      ...onBehalfPhysicianId,
      value: ([{ id: "", display: DEFAULT_OPTION }] as any[])
        .concat(referralFormDropdownData?.physicians)
        .map((display: any) => {
          if (display !== undefined) {
            return {
              value: display.id,
              display: display.display,
            };
          }
        }),
    },
  };

  if (
    referralFormDropdownData === undefined ||
    Object.keys(referralFormDropdownData).length === 0
  ) {
    return <Spinner margin="5rem 0" />;
  }
  if (redirect.redirect) {
    return (
      <Redirect
        to={{
          pathname: "/patientaccessrequest/patientaccessrequests",
          state: { message: redirect.message },
        }}
      />
    );
  } else {
    let headerName = { name: name + " > Edit" };

    return (
      <>
        <HeaderName name={headerName.name} />
        <BackButton  />
        {redirect.message === "success" ? (
          <>
            {window.scrollTo(0, 0)}
            <Alert severity="success">
              Form is successfully submitted,please wait while we are
              redirecting!
            </Alert>
          </>
        ) : redirect.message === "error" ? (
          <>
            {window.scrollTo(0, 0)}
            <Alert severity="error" style={{ color: "#EA1D36" }}>
              Error in submitting the form, please try again!
            </Alert>
          </>
        ) : redirect.message ? (
          <>
            {window.scrollTo(0, 0)}
            <Alert severity="error" style={{ color: "#EA1D36" }}>
              {redirect.message}
            </Alert>
          </>
        ) : (
          <></>
        )}
        <div id="patient-details" className="container">
          <div className="row chartLayoutContainer">
            <div className="top-content">
              <div className="top-left-content">
                <h3 className="header-blue bold me-2">
                  Edit Patient Access Request
                </h3>
                {/* <BackButton  /> */}
              </div>
            </div>
            <div className="new-patient-access-request">
              <form className="m-sm-5" onSubmit={handleSubmit}>
                <Form.Group className="d-flex row align-items-center form-padding">
                  <Form.Label column sm={3} className="pl-0 pr-0">
                    ID
                  </Form.Label>
                  <Col sm={9} className="pl-0 pr-0">
                    {patientDetail.patientAccessRequestID}
                  </Col>
                </Form.Group>
                {Object.entries(form).map((field, index) => {
                  const key = field[0];
                  const value: any = field[1];
                  return (
                    <Form.Group
                      key={index}
                      className="d-flex row align-items-center form-padding"
                    >
                      <Form.Label column sm={3} className="pl-0 pr-0">
                        {value.display}
                        {value.required ? (
                          <span className="p-0 asterik"> *</span>
                        ) : null}
                      </Form.Label>
                      <Col sm={9} className="pl-0 pr-0">
                        {value.type === "select" && key === "requestStatus" ? (
                          <Form.Select
                            as={value.type}
                            custom
                            required={value.required}
                            defaultValue={handleDefaultValue(value.type, key)}
                            onChange={(e) => handleOnChange(e, key)}
                            disabled={isDisabled}
                          >
                            {value.value.length !== 0
                              ? value.value.map((v: any, index: any) => (
                                  <option key={v.value} value={v.display}>
                                    {v.display}
                                  </option>
                                ))
                              : null}
                          </Form.Select>
                        ):value.type === "select" && key === "onBehalfPhysicianId" ? null
                        : value.type === "select" ? (
                          <Form.Select
                            as={value.type}
                            custom
                            required={value.required}
                            defaultValue={handleDefaultValue(value.type, key)}
                            onChange={(e) => handleOnChange(e, key)}
                            disabled={isDisabled}
                          >
                            {value.value.length !== 0
                              ? value.value.map((v: any, index: any) => (
                                  <option key={v.value} value={v.value}>
                                    {v.display}
                                  </option>
                                ))
                              : null}
                          </Form.Select>
                        ) : value.type === "textarea" ? (
                          <Form.Control
                            as={value.type}
                            required={value.required}
                            defaultValue={handleDefaultValue(value.type, key)}
                            onChange={(e) => handleOnChange(e, key)}
                            disabled={isDisabled}
                          />
                        ) : value.type === "date" && key === "patientDob" ? (
                          <Form.Control
                            max={DATE}
                            onChange={(e) => handleOnChange(e, key)}
                            type={value.type}
                            required={value.required}
                            defaultValue={handleDefaultValue(value.type, key)}
                            disabled={isDisabled}
                          />
                        ) : (
                          <Form.Control
                            onChange={(e) => handleOnChange(e, key)}
                            type={value.type}
                            required={value.required}
                            defaultValue={handleDefaultValue(value.type, key)}
                            disabled={isDisabled}
                          />
                        )}
                      </Col>
                    </Form.Group>
                  );
                })}
                 <Form.Group className="d-flex row align-items-center form-padding">
                  <Form.Label column sm={3} className="pl-0 pr-0">
                  On Behalf Of (Doctor's Name)
                  </Form.Label>
                  <Col sm={9} className="pl-0 pr-0">
                    {patientDetail.onBehalfPhysicianName}
                  </Col>
                </Form.Group>

                <Form.Group className="d-flex row align-items-center form-padding">
                  <Form.Label column sm={3} className="pl-0 pr-0">
                    Requested By
                  </Form.Label>
                  <Col sm={9} className="pl-0 pr-0">
                    {patientDetail.createdBy}
                  </Col>
                </Form.Group>

                <Form.Group className="d-flex row align-items-center form-padding">
                  <Form.Label column sm={3} className="pl-0 pr-0">
                    Requested On
                  </Form.Label>

                  <Col sm={9} className="pl-0 pr-0">
                    {patientDetail?.createdOn!= null ? `${patientDetail?.createdOn.split("T")[0]} ${patientDetail?.createdOn.split("T")[1]}`:null}
                  </Col>
                </Form.Group>

                <Form.Group className="d-flex row align-items-center form-padding">
                  <Form.Label column sm={3} className="pl-0 pr-0">
                    Last Modified By
                  </Form.Label>
                  <Col sm={9} className="pl-0 pr-0">
                    {patientDetail.modifiedBy}
                  </Col>
                </Form.Group>

                <Form.Group className="d-flex row align-items-center form-padding">
                  <Form.Label column sm={3} className="pl-0 pr-0">
                    Last Modified
                  </Form.Label>
                  <Col sm={9} className="pl-0 pr-0">
                  {patientDetail?.modifiedOn!=null?`${patientDetail?.modifiedOn.split("T")[0]} ${patientDetail?.modifiedOn.split("T")[1]}`:<></>}
                  </Col>
                </Form.Group>

                <Form.Group className="d-flex row">
                <Col
                  sm={{ span: 9, offset: 3 }}
                  className="d-flex justify-content-center"
                >
                  <MButton
                    variant="primary"
                    
                    loader = {loading}
                    title="Submit"
                    width={"fit-content"}
                    type="submit"
                  >
                    Submit
                  </MButton>
                </Col>
                <Col
                  sm={{ span: 7, offset: 3 }}
                  className="d-flex justify-content-center"
                >
                  
                </Col>

                </Form.Group>
              </form>
            </div>
          </div>
        </div>
      </>
    );
  }
};

export default PatientAccessRequestsEdit;
