import { FC, useEffect, useState } from "react";
import { Badge, OverlayTrigger, Table } from "react-bootstrap";
import IPatientAccessRequest from "../../../../types/IPatientAccessRequest";
import IPatientAccessQuery from "../../../../types/IPatientAccessQuery";
import { useHistory } from "react-router-dom";
import { setRecentSearch } from "../../../../helpers/RecentSearchHelper";
import {
  getFormattedDateFromDateTime,
  getFormattedTimeFromDateTime,
  getFormattedDateFromDate,
  getFormattedDateTime
} from "../../../../helpers/DateHelper";
import { MDBDataTable, MDBTable, MDBTableBody, MDBTableHead } from "mdbreact";
import "./patient-access-requestlist.css";

type stateType = {
  state: {
    patientAccess: IPatientAccessRequest;
  };
};

const ProxyPatientAccessRequestList: FC<{
  patientAccessRequest: IPatientAccessRequest[];
  query: IPatientAccessQuery;
}> = ({ patientAccessRequest, query }) => {
  const history = useHistory<stateType>();
  const [tableRows, setTableRows] = useState([]);
  const [sortDirection , setSortDiection] = useState("desc");
  const [sortKey , setSortKey] = useState("");

  useEffect(() => {
    if (patientAccessRequest) {
      setTableRows(assembleData());
    }
  }, [patientAccessRequest]);
  useEffect(() => {
    if (patientAccessRequest) {
      setTableRows(assembleData());
    }
  }, [sortDirection]);

  const onPatientCardClick = (patientAccess: IPatientAccessRequest) => {
    let searchedItem = {
      name: `${patientAccess.patientFirstName}, ${patientAccess.patientLastName}`,
      dob: patientAccess.patientDob,
    };
    history.push(
      `/patientaccessrequest/edit/${patientAccess.patientAccessRequestID}`,
      { state: { patientAccess } }
    );
  };

  const assembleData: any = () => {
    let patientsArray = patientAccessRequest
    if(sortKey === "patientAccessRequestID"){
      if(sortDirection == "asc"){
        patientsArray = patientAccessRequest.sort(function(a, b) { 
          return a.patientAccessRequestID - b.patientAccessRequestID ;
        });
      }
      else{
        patientsArray = patientAccessRequest.sort(function(a, b) { 
          return b.patientAccessRequestID - a.patientAccessRequestID ;
        });
      }
    }
    
    let patients = patientsArray.map((patientAccess) => {
      return {
        patientAccessRequestID: (
          <>
            {" "}
            <div
              style={{ padding: "6px 2px 6px 2px" }}
              id="table-text-referrals"
              onClick={() => onPatientCardClick(patientAccess)}
            >
              {patientAccess.patientAccessRequestID}
            </div>
          </>
        ),
        patientFirstName: patientAccess.patientFirstName,
        patientLastName: patientAccess.patientLastName,
        patientGender: patientAccess.patientGender,
        patientMRN: patientAccess.patientMRN,
        patientDob: (`${patientAccess.patientDob}`),
        requestStatus: patientAccess.requestStatus,
        onBehalfPhysicianName: patientAccess.onBehalfPhysicianName,
        createdBy: patientAccess.createdBy,
        createdOn:(patientAccess?.createdOn!=null?`${patientAccess?.createdOn.split("T")[0]} ${patientAccess?.createdOn.split("T")[1]}`:null),
        modifiedOn:(patientAccess?.modifiedOn!=null?`${patientAccess?.modifiedOn.split("T")[0]} ${patientAccess?.modifiedOn.split("T")[1]}`:null),
       
      };
    });
    return patients;
  };
  const data = {
    columns: [
      {
        label: "ID",
        field: "patientAccessRequestID",
        sort: "asc",
        width: 150,
        hight: 200,
      },
      {
        label: "First Name",
        field: "patientFirstName",
        sort: "asc",
        width: 150,
      },
      {
        label: "Last Name",
        field: "patientLastName",
        sort: "asc",
        width: 150,
      },
      {
        label: "Gender",
        field: "patientGender",
        sort: "disabled",
        width: 150,
      },
      {
        label: "MRN",
        field: "patientMRN",
        sort: "disabled",
        width: 150,
      },
      {
        label: "DOB",
        field: "patientDob",
        sort: "asc",
        width: 100,
      },
      {
        label: "Status",
        field: "requestStatus",
        sort: "asc",
        width: 100,
      },
      {
        label: "On Behalf Of",
        field: "onBehalfPhysicianName",
        sort: "disabled",
        width: 150,
      },
      {
        label: "Submitted By",
        field: "createdBy",
        sort: "disabled",
        width: 150,
      },
      {
        label: "Requested On",
        field: "createdOn",
        sort: "disabled",
        width: 150,
      },
      {
        label: "Last Modified",
        field: "modifiedOn",
        sort: "disabled",
        width: 150,
      },
    ],
    rows: tableRows,
  };

  return (
    <MDBDataTable
      responsive
      key={data.rows.length}
      small
      data={data}
      id="responsive-table"
      onSort={(e)=>{
        if(e.column === "patientAccessRequestID"){
          setSortDiection(e.direction)
          setSortKey(e.column)
        }
        else{
          setSortDiection("desc")
          setSortKey("")
        }
      }}
      sorting="true"
    />
  );
};

export default ProxyPatientAccessRequestList;
