import React, { FC, useState } from "react";
import "./custom-toggle.css";

const CustomToggle: FC<{
  onToggle: (e: React.MouseEvent<HTMLInputElement, MouseEvent>) => void;
  isOn: boolean;
  isDisabled?: boolean;
}> = ({ onToggle, isOn, isDisabled }) => {
  const [checked, setChecked] = useState<boolean>(isOn);
  return (
    <div id="custom-toggle">
      {" "}
      <label className="switch">
        {" "}
        <input
          disabled={isDisabled}
          type="checkbox"
          checked={checked}
          onClick={(e) => {
            onToggle(e);
            setChecked((prev) => !prev);
          }}
        />
        <span className="slider">{checked ? `ON` : `OFF`}</span>{" "}
      </label>{" "}
    </div>
  );
};

export default CustomToggle;
