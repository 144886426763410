import { Component, FC } from "react";
import { Carousel } from "react-bootstrap";
// import "./jumbotron.css";
import ISliderImage from "../../../types/ISliderImage";

interface Props {
  sliderImages: ISliderImage[];
}

const Jumbotron: FC<Props> = ({ sliderImages }) => {
  return (
    <>
      <Carousel>
        {sliderImages && sliderImages.map((sliderImage, index: React.Key) => {
          return (
            <Carousel.Item key={index}>
              <img
                className="img-corousel"
                key={index}
                alt={sliderImage.imageName}
                src={"data:image/png;base64," + sliderImage.imageContent}
                onClick={(e) => {
                  e.preventDefault();
                  window.open(sliderImage.targetUrl);
                }}
                height="100%"
                width="100%"
              />
            </Carousel.Item>
          );
        })}
      </Carousel>
    </>
  );
};

export default Jumbotron;
