import { UserClaims } from "@okta/okta-auth-js";
import { useOktaAuth } from "@okta/okta-react";
import { useEffect, useState } from "react";
import { appInsights } from "../appInsights";
import axios, { AxiosResponse } from "axios";

const useUserInfo = () => {
  const { authState, oktaAuth } = useOktaAuth();
  const [userInfo, setUserInfo] = useState<UserClaims|null>(null);
  const logout = async () => oktaAuth.signOut();

  useEffect(() => {
    if (!authState?.isAuthenticated) {
      // When user isn't authenticated, forget any user info
      setUserInfo(null);
    } else {
      oktaAuth
        .getUser()
        .then((info) => {
          if (info === undefined) {
            logout();
          } else {
            setUserInfo(info);
          }
        })
        .catch((e) => {
          if (!axios.isCancel(e)) {
            console.error(e);
            appInsights.trackException({ error: e });
            // setError(e);
          }
        });
    }
  }, [authState?.isAuthenticated]);

  return userInfo;
};

export default useUserInfo;
