import { FC, useEffect, useState } from "react";
import HeaderName from "../../headerName/HeaderName";
import {
  Badge,
  OverlayTrigger,
  Button,
  Table,
  Col,
  Card,
  NavDropdown,
} from "react-bootstrap";
import { useHistory, useLocation, Link } from "react-router-dom";
import { useOktaAuth } from "@okta/okta-react";
import EducationService from "../../../services/EducationService";
import { appInsights } from "../../../appInsights";
import axios from "axios";
import Spinner from "../../spinner/Spinner";
import PhysicianResourceService from "../../../services/PhysicianResourceService";
import IPhysicianResources from "../../../types/IPhysicianResources";
import Ambulatory from "./Ambulatory";
import IPortalUpdateAmbulatory from "../../../types/IPortalUpdateAmbulatory";
import Infowell from "../home/Infowell";
import HomeService from "../../../services/HomeService";
import useInfowells from "../../../hooks/useInfowells";

const sortable = require("../../../assets/images/sortable.png").default;

const PhysicianResources: FC<{
  name?: string;
}> = ({ name }) => {
  const history = useHistory();
  const [ambulatoryData, setAmbulatoryData] =
    useState<IPortalUpdateAmbulatory[]>();
  const [formData, setFormData] = useState<FormData>();

  const handleClick = () => {
    history.push("/physicianresources/medicalstaff");
  };

  const { authState } = useOktaAuth();
  const accessToken = authState?.accessToken?.accessToken;
  const cancelToken = axios.CancelToken.source();
  const [loading, setLoading] = useState(true);
  const [infowells, setInfowells] = useInfowells();
  const [physicianData, setPhysicianData] = useState<IPhysicianResources>();
  const [error, setError] = useState<Error | null>(null);

  useEffect(() => {
    setLoading(true);
    setError(null);
    PhysicianResourceService.getPhysicianResourceService(accessToken)
      .then((response) => {
        setLoading(false);
        setPhysicianData(response.data);
      })
      .catch((e: Error) => {
        if (!axios.isCancel(e)) {
          appInsights.trackException({ error: e });
           setError(e);
        }
       
        setLoading(false);
      });
    return () => {
      cancelToken.cancel();
    };
  }, []);
  
  useEffect(() => {
    setLoading(true);
    HomeService.getInfowells(accessToken)    
      .then((response) => {
        setInfowells(response.data);
        setLoading(false);
       
      })
      .catch((e) => {
        if (!axios.isCancel(e)) {
          appInsights.trackException({ error: e });
          setError(e);
        }
        
        setLoading(false);
      });
      return () => {
        cancelToken.cancel();
      };
  }, [accessToken]);
  return (
    <div>
      <HeaderName name={name} />
      <div className="container">
        {" "}
        <h2 className="heading-main">{name}</h2>
        <br />
        <Col
          sm={{ span: 7, offset: 3 }}
          className="d-flex justify-content-center"
        >
          {loading && <Spinner margin="5rem 0" />}
        </Col>
        <br />
        <div className="row no-gutters p-2 m-0">
          {physicianData?.infoWells.map((physician) => {
            return (
              <Infowell
              infowell={physician}
              likedInfowells={infowells}/>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default PhysicianResources;
