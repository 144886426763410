import { FC, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { useOktaAuth } from "@okta/okta-react";
import useUserInfo from "../../hooks/useUserInfo";
import AnnouncementService from "../../services/AnnouncementService";
import IAnnouncementBanner from "../../types/IAnnouncementBanner";
import IAnnouncementBannerHeader from "../../types/IAnnouncementBannerHeader";
import axios from "axios";
import { getFormattedDateFromDate } from "../../helpers/DateHelper";
import { Alert } from "react-bootstrap";

const LoginAsBanner: FC<{ count?: number }> = ({ count }) => {
  const { authState } = useOktaAuth();
  const accessToken = authState?.accessToken?.accessToken;
  const history = useHistory();
  const cancelToken = axios.CancelToken.source();
  const [shouldRender, setShouldRender] = useState(false);

  const [announcementData, setAnnouncementData] = useState<
    IAnnouncementBanner[]
  >([]);
  const [displayBanner, setDisplayBanner] = useState<
    IAnnouncementBannerHeader | undefined
  >();
  const [error, setError] = useState<Error | null>(null);
  const [varient, setVarient] = useState("");
  const [alertMessage , setAlertMessage] = useState("")
  let todayDate = new Date();

  useEffect(() => {
    setError(null);
    setShouldRender(false);
    let loginas = localStorage.getItem('loginAsFlag')
    let loginasEmail = localStorage.getItem('loginAsEmail')

    if(loginas !== null){
        setAlertMessage(`Caution! You have logged in as "${loginasEmail}". All the events will be logged and monitored.`)
    }
    else{
        setAlertMessage("")
    }
    
  }, [authState, count]);

  return (
    <>
      {alertMessage !== "" ? (
        <Alert
          key={1}
          variant={"danger"}
          style={{ marginBottom: "0px" }}
        >
          {alertMessage}
        </Alert>
      ) : (
        <></>
      )}
    </>
  );
};
export default LoginAsBanner;
