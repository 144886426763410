import { FC, useEffect, useState } from "react";
import INotMyPatientRequest from "../../../../types/INotMyPatientRequest";
import IPatientAccessQuery from "../../../../types/IPatientAccessQuery";
import {
  getFormattedDateFromDateTime,
  getFormattedTimeFromDateTime,
  getFormattedDateFromDate,
  getFormattedDateTime,
} from "../../../../helpers/DateHelper";
import { MDBDataTable, MDBTable, MDBTableBody, MDBTableHead } from "mdbreact";
import "./patient-access-requestlist.css";

const NotMyPatientRequestList: FC<{
  patientAccessRequest: INotMyPatientRequest[];
  handleActiveRecordClick: (id: number, activeRecord: boolean) => void;
  query: IPatientAccessQuery;
}> = ({ patientAccessRequest, query, handleActiveRecordClick }) => {
  const [tableRows, setTableRows] = useState<INotMyPatientRequest[]>([]);

  useEffect(() => {
    if (patientAccessRequest) {
      const tRows = assembleData();
      setTableRows(tRows);
    }
  }, [patientAccessRequest]);

  const onStatusClick = (patientAccess: INotMyPatientRequest) => {
    if (patientAccess.id !== null) {
      handleActiveRecordClick(patientAccess.id, !patientAccess.activeRecord);
    }
  };

  const assembleData: any = () => {
    const tableData = patientAccessRequest.map((patientAccess) => {
      if (patientAccess.modifiedOn != null) {
        return {
          id: (
            <div style={{ padding: "6px 2px 6px 2px" }}>{patientAccess.id}</div>
          ),
          user: patientAccess.user,
          patientName: patientAccess.patientName,
          providerID: patientAccess.providerID,
          providerName: patientAccess.providerName,
          patientDob: `${patientAccess.patientDob}`,
          patientID: patientAccess.patientID,

          modifiedOn:
            patientAccess?.modifiedOn != null
              ? `${patientAccess?.modifiedOn.split("T")[0]} ${
                  patientAccess?.modifiedOn.split("T")[1]
                }`
              : null,

          status: patientAccess.status,
          activeRecord: (
            <div
              id="table-text-referrals"
              onClick={() => onStatusClick(patientAccess)}
            >
              {patientAccess.activeRecord === false ? "Undo" : "Complete"}
            </div>
          ),
        };
      }
    });
    return tableData;
  };

  const cols = [
    {
      label: "ID",
      field: "id",
      sort: "disabled",
      width: 150,
    },
    {
      label: "User",
      field: "user",
      sort: "asc",
      width: 150,
    },
    {
      label: "Patient Name",
      field: "patientName",
      sort: "asc",
      width: 150,
    },
    {
      label: "Provider ID",
      field: "providerID",
      sort: "disabled",
      width: 150,
    },
    {
      label: "Provider Name",
      field: "providerName",
      sort: "disabled",
      width: 150,
    },
    {
      label: "DOB",
      field: "patientDob",
      sort: "disabled",
      width: 150,
    },
    {
      label: "Patient ID",
      field: "patientID",
      sort: "disabled",
      width: 100,
    },
    {
      label: "Last Modified",
      field: "modifiedOn",
      sort: "disabled",
      width: 150,
    },
    {
      label: "Status",
      field: "status",
      sort: "disabled",
      width: 100,
    },
    {
      label: "Action",
      field: "activeRecord",
      sort: "disabled",
      width: 150,
    },
  ];

  const data = {
    columns: cols,
    rows: tableRows !== undefined && tableRows !== null ? tableRows : [],
  };

  return (
    <>
      <MDBDataTable
        key={data.rows.length}
        responsive
        small
        data={data ? data : { columns: cols, rows: [] }}
        id="responsive-table"
        sorting="true"
      />
    </>
  );
};

export default NotMyPatientRequestList;
