import { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import { useOktaAuth } from "@okta/okta-react";
import "./reminder-popup.css";
import MButton from "../mButton/MButton";

const ReminderPopup = () => {
  //
  const [show, setShow] = useState(false);
  const { authState, oktaAuth } = useOktaAuth();
  const accessToken = authState?.accessToken?.accessToken;

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const showReminder = JSON.parse(localStorage.getItem("showReminder"));

  useEffect(() => {
    if (authState?.isAuthenticated && showReminder == true) {
      localStorage.setItem("showReminder", JSON.stringify(false));
      setShow(true);
    }
  }, [oktaAuth, authState]);

  return (
    <>
      <Modal show={show} onHide={handleClose} id="Modal-Reminder">
        <Modal.Header closeButton>
          <Modal.Title>Reminder</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          myCHLA is a secure login portal only for authorized users. Use of
          myCHLA is subject to Children’s Hospital Los Angeles’ policies,
          procedures, and terms and conditions. User access and activity within
          myCHLA will be monitored and audited. All patient information viewed
          through myCHLA is strictly confidential, and the privacy and security
          of such information is a fundamental obligation of all myCHLA users.
          Federal and state laws prohibit unauthorized access to myCHLA and the
          inappropriate access to, failure to secure, and unauthorized release
          of patient information. All violators will be prosecuted.
        </Modal.Body>
        <Modal.Footer>
          <div style={{ width: "100%" }}>Updated: June 2, 2014</div>
          <div>
            <MButton
              variant="secondary"
              type="submit"
              title="Close"
              width={"fit-content"}
              onClick={handleClose}
            >
              Close
            </MButton>
          </div>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default ReminderPopup;
