import { FC, useState } from "react";
import MButtonGroup from "../../mButton/MButtonGroup";
import styles from "./AdminSection.module.css";
import AdminPracticeSection from "./AdminPracticeSection";
import AdminPhysicianSection from "./AdminPhysicianSection";
import AdminLocationSection from "./AdminLocationSection";
import useSessionState from "../../../hooks/useSessionState";

const AdminSection: FC<{ name?: string }> = ({ name }) => {
  const TAB_1 = "Physician";
  const TAB_2 = "Practice";
  const TAB_3 = "Location"

  const [activeTab, setActiveTab] = useSessionState<typeof TAB_1 | typeof TAB_2 | typeof TAB_3>(
    "admin_active_tab",
    TAB_1
  );

  const onPhysicianClick = () => {
    setActiveTab(TAB_1);
  };
  const onPracticeClick = () => {
    setActiveTab(TAB_2);
  };
  const onLocationClick = () => {
    setActiveTab(TAB_3);
  };

  return (
    <div className="container">
      <h2 className="heading-main">{name}</h2>
      <div className={styles.topSection}>
        <MButtonGroup
          size="sm"
          buttons={[
            {
              type: "radio",
              title: "Physician",
              checked: activeTab === TAB_1,
              variant: "primary",
              onClick: onPhysicianClick,
            },
            {
              type: "radio",
              title: "Practice",
              checked: activeTab === TAB_2,
              variant: "primary",
              onClick: onPracticeClick,
            },
            {
              type: "radio",
              title: "Location",
              checked: activeTab === TAB_3,
              variant: "primary",
              onClick: onLocationClick,
            },
          ]}
        />
      </div>
      {activeTab === TAB_1 ? <AdminPhysicianSection /> : activeTab === TAB_2 ? <AdminPracticeSection />:<AdminLocationSection />}
    </div>
  );
};

export default AdminSection;
