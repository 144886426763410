import { FC, useState, useEffect } from "react";
import { useOktaAuth } from "@okta/okta-react";
import PracticeService from "../../../../services/PracticeService";
import { appInsights } from "../../../../appInsights";
import axios, { AxiosError } from "axios";
import Loader from "../../../common/Loader";
import usePracticeInfo from "../../../../hooks/usePracticeInfo";
import useUserInfo from "../../../../hooks/useUserInfo";
import IPracticeInfo from "../../../../types/IPracticeInfo";
import { OKTA_AUTH_CONFIG } from "../../../../config";
import CustomAlertModal from "../../../common/CustomAlertModal";
import { Form } from "react-bootstrap";

const MyPracticeInfo: FC<{
  onPracticeInfoUpdate?: (practice: IPracticeInfo) => void;
  practiceInfo:IPracticeInfo | undefined
}> = ({ onPracticeInfoUpdate,practiceInfo }) => {
  // const [practiceInfo, setPracticeInfo] = usePracticeInfo();
  const userInfo = useUserInfo();
  const { authState } = useOktaAuth();
  const accessToken = authState?.accessToken?.accessToken;
  const cancelToken = axios.CancelToken.source();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<AxiosError | null>(null)




  const redirectOKTA = () => {
    let email:any = userInfo?.email;
    let isInternal;
    if (/^[a-zA-Z0-9._-]+@chla\.usc\.edu$/.test(email)) {
      isInternal = true;
   } 

   
    if (isInternal) {
      window.open("https://oktalogin.chla.org/enduser/settings");
    } else {
      window.open(
        OKTA_AUTH_CONFIG.issuer
          ? OKTA_AUTH_CONFIG.issuer.split("/oauth2")[0]
          : "https://extoktalogin.chla.org/enduser/settings"
      );
    }
  };

  return (
    <div>
      {loading && (
        <div style={{ marginBottom: 10 }}>
          <Loader />
        </div>
      )}
      {practiceInfo !== undefined && (
        <>
          <div className="d-flex align-items-end">
            <h4>My Practice Info</h4>
          </div>
          <h5 className="mt-2 mb-3" id="my-practice-purple-text">
            CHLA Practice ID: {practiceInfo?.practiceId}
          </h5>
          {practiceInfo.address !== null && <><p className="m-0">{practiceInfo?.practiceName}</p>
            <p className="m-0">{practiceInfo?.address.address1}</p>
            <p className="m-0">{practiceInfo?.address.address2}</p>
            <p className="m-0">
              {practiceInfo?.address.city}, {practiceInfo?.address.state},{" "}
              {practiceInfo?.address.zip}-{practiceInfo?.address.zip4}
            </p></>}
          <p className="mt-3 mb-1">Moving?</p>
          <ul>
            <li>Is your practice moving?</li>
            <li>Are you you leaving this practice?</li>
            <li>Are you part of another practice or joining a new practice?</li>
          </ul>
          <a id="link" className="about" href="mailto:help@chla.usc.edu">Tell us about it</a>
          <h5 className="mt-3 font-weight-normal my-practice-account "  >
            myCHLA Account
          </h5>
          <p>
            Update your account information or change your password{" "}
            <a id="link" className="about" style={{cursor:'pointer'}} onClick={redirectOKTA} target="_blank">here</a>
          </p>
          <div className="switch-practice">
          <Form.Group className="ms-3 me-3 pt-2 pb-3 ">
          <Form.Label htmlFor="switch">Switch Practice</Form.Label>
          <Form.Select id="switch" onChange={(e:any) => {
                      PracticeService.changeDefaultPractice(e.target.value,
                        accessToken,
                        cancelToken)
                      .then((response) => {
                        window.location.reload()
                      }
                      )
                        .catch((err) => {
                          console.log(err);
                        });
                  }}>
          {practiceInfo?.availablePractices?.map((practice:any) => (
                      <option  key={practice.practiceId} value={practice.practiceId}>
                        {practice.practiceName}
                      </option>
                    ))}
          </Form.Select>
        </Form.Group>
          </div>
        </>
      )}
      <CustomAlertModal
        show={error !== null}
        handleClose={() => {
          setError(null)
        }}
        header={"Error!"}
        // @ts-ignore
        body={error?.response?.data.message || "There was an error processing your request. Please refresh the page and try again. If problems persist please contact myCHLA support."}
      />
    </div>
  );
};

export default MyPracticeInfo;
