import { FC, useEffect, useState } from "react";
import HeaderName from "../../headerName/HeaderName";
import { Table } from "react-bootstrap";
import { useHistory, useLocation } from "react-router-dom";
import { useOktaAuth } from "@okta/okta-react";
import Loader from "../../common/Loader";
import AccountService from "../../../services/AccountService";
import IAccountRequests, { AccountRequest } from "../../../types/IAccountRequests";
import CustomPagination from "../../common/CustomPagination";
import LookBackFilter from "../../common/LookBackFilter";
import SearchBar from "../../common/SearchBar";
import MButton from "../../mButton/MButton";
import { appInsights } from "../../../appInsights";
import axios from "axios";
import './accountRequest.css';
const lookBackOptions = require("../../../data/accountRequestsLookbackOptions.json");

const AccountRequests: FC<{
  name?: string;
}> = ({ name }) => {

  const location = useLocation();
  const history = useHistory();
  const handleOnClick = (request: AccountRequest) => {
    if (isHIM) {
      history.push("/accountsandaccess/accountrequests/edit/" + request.accountRequestId, request);
    } else {
      history.push("/practice/accountrequests/details/" + request.accountRequestId, request);
    }
  };
  const { authState } = useOktaAuth();
  const accessToken = authState?.accessToken?.accessToken;
  const [accountRequests, setAccountRequests] = useState<IAccountRequests>();
  const [error, setError] = useState<Error | null>(null);
  const [loading, setLoading] = useState(true);
  const [maxPages, setMaxPages] = useState(0);
  const [apiCallCount, setApiCallCount] = useState(0);

  const [pages, setPages] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [searchText, setSearchText] = useState("");
  const [lookback, setLookback] = useState(lookBackOptions[3].value);
  const pageSize = 10;

  useEffect(() => {
    setLoading(true);
    setError(null);
    AccountService.getAccountRequests(
      {
        searchTerm: searchText,
        lookBack: lookback,
        pageNumber: currentPage,
        pageSize: pageSize,
        practice: !isHIM
      },
      accessToken
    )
      .then((response) => {
        setLoading(false);
        if(maxPages == 0){
          setPages(response.data.totalPages);
          setMaxPages(response.data.totalPages);
        }
        setCurrentPage(response.data.currentPage);
        setAccountRequests(response.data);
      })
      .catch((e: Error) => {
        if (!axios.isCancel(e)) {
          console.error(e);
          appInsights.trackException({ error: e });
          setError(e);
        }

        setLoading(false);
      });
  }, [apiCallCount]);

  const navigateToPage = (page: number) => {
                    
    setCurrentPage(page);
    setAccountRequests(undefined)
    window.scrollTo(0, 0);
    setApiCallCount((c) => c + 1);
  };

  const isHIM = location.pathname === "/accountsandaccess/accountrequests";

  return (
    <div>
      <HeaderName name={name} />
      <div className="container">
        <h2 className="heading-main">{name}</h2>
        <div>
          <br />
          <div className="chart">
            <div className="d-flex row justify-content-between">
              <div className="col-sm-5 col-lg-3 mb-3">
                <LookBackFilter
                  onChange={(e) => {
                    setLookback(e.target.value)
                    setCurrentPage(1)
                    setMaxPages(0)
                    setAccountRequests(undefined)
                    window.scrollTo(0, 0);
                    setApiCallCount((c) => c + 1);
                  }}
                  options={lookBackOptions}
                  value={lookback}
                  initialValue={lookBackOptions[3]}
                />
              </div>
              {loading ? (
                <div className="col loader mb-3">
                  <Loader />
                </div>
              ) : null}
              <div className="col-sm-5 col-lg-3 mb-3">
                <SearchBar
                  placeholder="Search by First or Last Name"
                  onSearch={() => {
                    setSearchText(searchText)
                    setAccountRequests(undefined)
                    setCurrentPage(1)
                    setMaxPages(0)
                    setLookback(-1)
                    setApiCallCount((c) => c + 1);
                    window.scrollTo(0, 0);
                  }}
                  onTextChange={(text) => {
                    if(text==""){
                      setSearchText("")
                      setCurrentPage(1)
                      setMaxPages(0)
                      if(accountRequests !== undefined){
                        setLookback(-1)
                      }
                      setAccountRequests(undefined)

                      setApiCallCount((c) => c + 1);
                      window.scrollTo(0, 0);
                      }
                      setSearchText(text)
                  }}
                />
              </div>
            </div>
            {accountRequests?.data.length ==0?<h5 className= "text-center w-100">No data found</h5>:
              (
            <Table id="responsive-table-account" className="mt-2">
              <thead>
                <tr>
                  <th>ID</th>
                  <th>First Name</th>
                  <th>Last Name</th>
                  <th>Email</th>
                  <th
                  // only keep id name email status
                  // mypractice name
                  // should be lastname, firstname ordered by lastname
                  >
                    Practice
                  </th>
                  <th>Role</th>
                  <th style={{ width: 150 }}>Status</th>
                </tr>
              </thead>
              <tbody>
                {accountRequests?.data.map((request) => {
                  return (
                    <tr key={request.accountRequestId}>
                      <td data-title="Image Name" id="table-text-slider">
                        {request.accountRequestId}
                      </td>
                      <td data-title="TargetURL">{request.userFirstName}</td>
                      <td data-title="Modified By">{request.userLastName}</td>
                      <td data-title="Modified By">{request.userEmail}</td>
                      <td data-title="Modified By">{request.practiceName}</td>
                      <td data-title="Modified By">{request.expectedRole}</td>
                      <td data-title="Modified By">
                        {request.accountRequestStatus}
                      </td>
                      <td>
                        <MButton
                          variant={isHIM ? "secondary" : "primary"}
                          title={isHIM ? "Edit" : "View"}
                          onClick={() => {
                            handleOnClick(request);
                          }}
                          width={"fit-content"}
                        />
                      </td>
                    </tr>
                  );
                })}
              </tbody>

            </Table>)}
            {accountRequests?.data.length !== 0 ? (
              <div
              className="col d-flex justify-content-center"
              style={{ marginTop: "60px" }}
            >
                <CustomPagination
                  currentPage={currentPage}
                  totalPages={pages}
                  onPageChange={navigateToPage}
                  range={10}
                />
              </div>
            ) : null}
          </div>
        </div>
      </div>
    </div>
  );
};

export default AccountRequests;
