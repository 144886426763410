import { Field, useField } from "formik";
import { FC, useCallback, useEffect, useState } from "react";
import { FileError, FileRejection, useDropzone } from "react-dropzone";
import { useLocation } from "react-router-dom";
import IReferralFormField from "../../../../types/IReferralFormField";
import DeleteIcon from "../../../common/DeleteIcon";
import "./upload-attachments-new.css";

export interface UploadFile {
  file: File;
  errors: FileError[];
}

const UploadAttachmentsNew: FC<{
  fieldInfo: IReferralFormField;
  setSubmitDisabled: React.Dispatch<React.SetStateAction<boolean>>;
  files: UploadFile[];
  setFiles: React.Dispatch<React.SetStateAction<UploadFile[]>>;
  showFiles: UploadFile[];
  setShowFiles: React.Dispatch<React.SetStateAction<UploadFile[]>>;
  totalSize: number;
  setTotalSize: React.Dispatch<React.SetStateAction<number>>;
}> = ({
  fieldInfo,
  setSubmitDisabled,
  files,
  setFiles,
  showFiles,
  setShowFiles,
  totalSize,
  setTotalSize,
  ...field
}) => {
  // max size is 20 mb
  const MAX_FILE_SIZE = 20971520;
  const [_, __, helpers] = useField(fieldInfo.name);
  const [attachmentNames] = useField(fieldInfo.affects + "");
  const location = useLocation();

  useEffect(() => {
    let mFiles = files.map((f) => f.file);
    if (showFiles.length !== files.length) {
      helpers.setError("Cannot upload file with 0 bytes.");
    } else {
      helpers.setValue(mFiles);
    }
  }, [files]);

  const onDrop = useCallback(
    (acceptedFiles: File[], rejectedFiles: FileRejection[]) => {
      // Do something with the files
      const mappedAccepetedFiles = acceptedFiles.map((f) => ({
        file: f,
        errors: [],
      }));

      setShowFiles((f) => [...f, ...mappedAccepetedFiles, ...rejectedFiles]);
      setFiles((f) => [...f, ...mappedAccepetedFiles]);
      let accFileSize = 0,
        rejFileSize = 0;
      mappedAccepetedFiles.forEach((f) => (accFileSize += f.file.size));
      rejectedFiles.forEach((f) => (rejFileSize += f.file.size));
      setTotalSize((t) => t + accFileSize + rejFileSize);
    },
    []
  );
  const { getRootProps, getInputProps, isDragActive, open } = useDropzone({
    onDrop,
    // noClick: true,
    // noKeyboard: true,
    accept: "image/png, image/jpg, image/jpeg, application/pdf",
    validator: (file) => {
      if (file.size > MAX_FILE_SIZE) {
        return {
          code: "file-too-large",
          message: `File is larger than 20MB`,
        };
      }
      if (file.size == 0) {
        return {
          code: "file-too-small",
          message: `Total size is 0 bytes.`,
        };
      }
      return null;
    },
  });

  const handleRemoveFile = (f: UploadFile) => {
    setFiles((prev) => prev.filter((file) => file.file.name !== f.file.name));
    setShowFiles((prev) =>
      prev.filter((file) => file.file.name !== f.file.name)
    );
    setTotalSize((t) => t - f.file.size);
  };

  useEffect(() => {
    if (totalSize > MAX_FILE_SIZE) {
      setSubmitDisabled(true);
      console.log("setSubmitDisabled(true)");
    } else {
      setSubmitDisabled(false);
      console.log("setSubmitDisabled(false)");
    }
  }, [totalSize]);

  return (
    <div className="ua_drop_zone_container">
      <div className="col-12 col-md-8">
        <p>To ensure timely processing of your referral, please attach:</p>
        <ul style={{ listStyle: "unset" }}>
          <li>Most recent clinical notes</li>
          <li>Insurance information</li>
          <li>Demographics</li>
        </ul>
        <p>If available, please include labs, growth charts, and diagnostics</p>
      </div>
      <div
        className="col-12 col-md-8 ua_drop_zone"
        style={{ opacity: isDragActive ? 0.5 : 1 }}
        {...getRootProps()}
      >
        <img
          src={require("../../../../assets/images/cloud-upload.png").default}
          height="50"
          width="50"
        />

        {totalSize > MAX_FILE_SIZE ? (
          <div style={{ textAlign: "center", color: "#ff6263" }}>
            Total file size exceeded 20 MB
            <br />
            Please remove some files
          </div>
        ) : (
          <>
            <div>Drag files here to upload</div>
            <a className="ua_browse_files_button">or browse for files</a>
          </>
        )}
        <input {...getInputProps()} />
      </div>
      <div className="col-12 col-md-8">
        {attachmentNames.value !== "" &&
          location.pathname.includes("edit") &&
          attachmentNames.value.map((f: string) => {
            return <FileName name={f} />;
          })}

        {showFiles.map((f) => {
          if (f.errors.length <= 0) {
            return (
              <FileItem
                f={f}
                handleRemoveFile={handleRemoveFile}
                isError={false}
              />
            );
          } else {
            return (
              <FileItem
                f={f}
                handleRemoveFile={handleRemoveFile}
                isError={true}
              />
            );
          }
        })}
      </div>
    </div>
  );
};

export const FileName: FC<{
  name: string;
}> = ({ name }) => {
  return (
    <div className="ua_file_name">
      <img
        height="40"
        width="40"
        src={
          name.toLowerCase().includes("pdf")
            ? require("../../../../assets/images/pdf-file.png").default
            : require("../../../../assets/images/image-file.png").default
        }
      />
      <div
        style={{
          display: "flex",
          flex: 1,
          alignItems: "center",
          justifyContent: "space-between",
          flexDirection: "row",
        }}
      >
        <span>{name}</span>
        <DeleteIcon fillColor="#6d747b" />
      </div>
    </div>
  );
};

export const FileItem: FC<{
  f: UploadFile;
  handleRemoveFile: (f: UploadFile) => void;
  isError: boolean;
}> = ({ f, handleRemoveFile, isError }) => {
  return (
    <div className={isError ? "ua_file_item_error" : "ua_file_item"}>
      <img
        height="40"
        width="40"
        src={
          f.file.type.includes("pdf")
            ? require("../../../../assets/images/pdf-file.png").default
            : require("../../../../assets/images/image-file.png").default
        }
      />
      <div
        style={{
          display: "flex",
          flex: 1,
          alignItems: "center",
          justifyContent: "space-between",
          flexDirection: "row",
        }}
      >
        <span>
          {f.file.name}
          <br />
          {(f.file.size / 1000000).toFixed(2)} MB
        </span>
        {isError ? (
          <span>{"Cannot upload this file as " + f.errors[0].message}</span>
        ) : null}
        <img
          className="ua_remove_file_icon"
          onClick={() => {
            handleRemoveFile(f);
          }}
          height="20"
          width="20"
          src={require("../../../../assets/images/delete-icon.svg").default}
        />
      </div>
    </div>
  );
};

export default UploadAttachmentsNew;
