import { CancelTokenSource } from "axios";
import { customAxios as axios } from "./ServiceConfig";
import { API_BASE_URL } from "../config";
import IDropDownInfoWell from "../types/IDropDownInfoWell";
import IHomeData from "../types/IHomeData";
import IInfoWell from "../types/IInfoWell";
import ISliderImage from "../types/ISliderImage";
let loginas = localStorage.getItem('loginAsFlag')
let loginasEmail = localStorage.getItem('loginAsEmail')
const getHomeData = async (cancelToken?: CancelTokenSource) => {
  const result = await axios.get<IHomeData>(`${API_BASE_URL}/home`, loginas !== null ? {
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Pragma: "no-cache",
      
      loginas:loginas !== null ? loginas : JSON.stringify(false),
      email:loginasEmail !== null ? loginasEmail : "",
    },
    cancelToken: cancelToken?.token,
  }:{
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Pragma: "no-cache",
    },
    cancelToken: cancelToken?.token,
  });
  return result;
};

const getSliderImages = async (cancelToken?: CancelTokenSource) => {
  const result = await axios.get<ISliderImage[]>(
    `${API_BASE_URL}/home/homepageslidderImage`,
    loginas !== null ? {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Pragma: "no-cache",
        loginas:loginas !== null ? loginas : JSON.stringify(false),
        email:loginasEmail !== null ? loginasEmail : "",
      },
      cancelToken: cancelToken?.token,
    }:{
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Pragma: "no-cache",
      },
      cancelToken: cancelToken?.token,
    }
  );
  return result;
};

const getInfowells = async (
  token?: string,
   cancelToken?: CancelTokenSource) => {
  const result = await axios.get<IInfoWell[]>(
    `${API_BASE_URL}/home/homepageinfowells`,
    loginas !== null ? {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Pragma: "no-cache",
        Authorization: `Bearer ${token}`,
        loginas:loginas !== null ? loginas : JSON.stringify(false),
        email:loginasEmail !== null ? loginasEmail : "",
      },
      cancelToken: cancelToken?.token,
    }:{
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Pragma: "no-cache",
        Authorization: `Bearer ${token}`,
      },
      cancelToken: cancelToken?.token,
    }
  );
  return result;
};
const getDropDownWells = async (
  dropDownName?: string,
    token?: string,
   cancelToken?: CancelTokenSource) => {
  const result = await axios.get<IDropDownInfoWell[]>(
    `${API_BASE_URL}/Home/homepagedropdown/?dropDownName=${dropDownName}`,
    loginas !== null ? {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Pragma: "no-cache",
        Authorization: `Bearer ${token}`,
        loginas:loginas !== null ? loginas : JSON.stringify(false),
        email:loginasEmail !== null ? loginasEmail : "",
      },
      cancelToken: cancelToken?.token,
    }:{
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Pragma: "no-cache",
        Authorization: `Bearer ${token}`,
      },
      cancelToken: cancelToken?.token,
    }
  );
  return result;
};
const likeButton = async (
  data?: any,
    token?: string,
   cancelToken?: CancelTokenSource) => {
    const result = await axios.post(
      `${API_BASE_URL}/Home/favoriteinfowells`,
      data,
      loginas !== null ? {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Pragma: "no-cache",
          Authorization: `Bearer ${token}`,
          loginas:loginas !== null ? loginas : JSON.stringify(false),
          email:loginasEmail !== null ? loginasEmail : "",
        },
        cancelToken: cancelToken?.token,
      }:{
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Pragma: "no-cache",
          Authorization: `Bearer ${token}`,
        },
        cancelToken: cancelToken?.token,
      }
    );
  return result;
};

const GetPracticeNotActiveStatement = async (
    token?: string,
   cancelToken?: CancelTokenSource) => {
    const result = await axios.get<string>(
      `${API_BASE_URL}/Home/practiceNotActiveStatement`,
      loginas !== null ? {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Pragma: "no-cache",
          Authorization: `Bearer ${token}`,
          loginas:loginas !== null ? loginas : JSON.stringify(false),
          email:loginasEmail !== null ? loginasEmail : "",
        },
        cancelToken: cancelToken?.token,
      }:{
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Pragma: "no-cache",
          Authorization: `Bearer ${token}`,
        },
        cancelToken: cancelToken?.token,
      }
    );
  return result;
};

const HomeService = {
  getHomeData,
  getSliderImages,
  getInfowells,
  getDropDownWells,
  likeButton,
  GetPracticeNotActiveStatement,
};

export default HomeService;
