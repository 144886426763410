import { FC } from "react";
import "./headerName.css";

interface Props {
  name?: string;
}

const HeaderName: FC<Props> = ({ name }) => {
  return (
    <>
      <div className="hn-header">
        <div className="container hn-name">Home &gt; {name}</div>
      </div>
    </>
  );
};

export default HeaderName;
