import { Formik, Form as FormikForm, Field } from "formik";
import { FC, useEffect, useState } from "react";
import IFormDropdownOption from "../../../types/IFormDropdownOption";
import MButton from "../../mButton/MButton";
import CustomInputFormik from "../../common/CustomInputFormik";
import MultiSelect from "../../multiselect/MultiSelect";
import DropdownDataService from "../../../services/DropdownDataService";
import { useOktaAuth } from "@okta/okta-react";
import axios, { AxiosError } from "axios";
import IPhysicianFormDropdownData from "../../../types/IPhysicianFormDropdownData";
import AdminCreatePhysicianModal from "./components/AdminCreatePhysicianModal";
import { appInsights } from "../../../appInsights";
import * as Yup from "yup";
import ICreatePhysician from "../../../types/IPhysicianCreate";
import AdminService from "../../../services/AdminService";
import { useHistory } from "react-router-dom";
import { Alert } from "@mui/material";
import CustomAlertModal from "../../common/CustomAlertModal";
import { Form } from "react-bootstrap";
import { ErrorBoundary } from "react-error-boundary";
const AdminCreatePhysician: FC<{ name?: string }> = ({ name }) => {
  //
  const styles: React.CSSProperties | undefined = {
    display: "flex",
    flexDirection: "row",
  };
  const [options, setOptions] = useState<IFormDropdownOption[]>([]);
  const initialValue = {
    email: null,
    firstName: null,
    lastName: null,
    npiNum: null,
    midName: null,
    suffix: null,
    title: null,
    cellPhone: null,
    deaNum: null,
    deaExpireDate: null,
    boardCertified: null,
    licenseNumber: null,
    licenseState: null,
    medicaidId: null,
    medicaidState: null,
    medicareId: null,
    medicareState: null,
    medicareProviderFlag: null,
    medicalSchool: null,
    residency: null,
    yearStartedPracticing: null,
    hospitalAffiliation: null,
    preferredProvider: false,
    fochla: false,
    language1: null,
    language2: null,
    status: null,
    hdsRunDate: null,
    hdsPhyId: null,
    cernerId: null,
    starId: null,
    childrensConnect: false,
    notes: null,
    labOutreach: false,
    chlahealthNetwork: false,
    myChla: false,
    physicianSpecialties: [],
    physicianHospitalAffiliations: [],
    physicianInsuranceAccpted: [],
  };
  //
  const { authState } = useOktaAuth();
  const accessToken = authState?.accessToken?.accessToken;
  const cancelToken = axios.CancelToken.source();
  //
  const [showModal, setShowModal] = useState<boolean>(false);
  const history = useHistory();
  const [dropdownData, setDropdownData] =
    useState<IPhysicianFormDropdownData>();
  const [showSpecialties, setShowSpecialties] = useState<boolean>(false);
  const [specialties, setSpecialties] = useState<IFormDropdownOption[]>([]);
  const [showAffiliations, setShowAffiliations] = useState<boolean>(false);
  const [affiliations, setAffiliations] = useState<IFormDropdownOption[]>([]);
  const [showInsurances, setShowInsurances] = useState<boolean>(false);
  const [error, setError] = useState<AxiosError | null | any>(null);
  const [insurances, setInsurances] = useState<IFormDropdownOption[]>([]);
  const states: IFormDropdownOption[] =
    require("../../../data/states.json").map((s: any) => ({
      id: s.abbreviation,
      display: s.abbreviation,
    }));
  const languages: IFormDropdownOption[] =
    require("../../../data/languages.json").map((s: any) => ({
      id: s.name,
      display: s.name,
    }));

  useEffect(() => {
    setError(null);
    DropdownDataService.getPhysicianFormDropdownData(accessToken, cancelToken)
      .then((response) => {
        setDropdownData(response.data);
      })
      .catch((e) => {
        if (!axios.isCancel(e)) {
          console.error(e);
          appInsights.trackException({ error: e });
          setError(e);
        }
      });
  }, [options]);
  const ErrorFallback = (error: any) => {
    return (
      <Alert severity="error" style={{ color: "#EA1D36" }}>
        {"Data not Avalable"}
      </Alert>
    );
  };
  return (
    <div className="container">
      <h2 className="heading-main">{name}</h2>
      <div className="chart mt-3">
        <Formik
          initialValues={initialValue}
          validationSchema={Yup.object().shape({
            email: Yup.string().email("Email is not valid").max(255),
            cellPhone: Yup.string()
              .matches(
                /^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4}$/im,
                "Phone number is not valid"
              )
              .nullable(),
            npiNum: Yup.string().matches(/^\d{10}$/, "NPI number is not valid"),
            fax: Yup.string()
              .matches(/^\+?[0-9]{7,}$/, "Fax number is not valid")
              .nullable(),
          })}
          onSubmit={(values, { setSubmitting }) => {
            console.log(values);
            setError(null);
            if (values.deaExpireDate === "") {
              values.deaExpireDate = null;
            }
            if (values.hdsRunDate === "") {
              values.hdsRunDate = null;
            }
            // console.log(values)
            if(values.npiNum != null){
              var npi: any = values.npiNum!;
              npi = npi.toString();
              values.npiNum = npi;
            }
            AdminService.createPhysician(
              //@ts-ignore
              values as ICreatePhysician,
              accessToken,
              cancelToken
            )
              .then((response) => {
                setSubmitting(false);
                history.push("/accounts&access/physicians&practices/");
              })
              .catch((e) => {
                if (!axios.isCancel(e)) {
                  console.error(e);
                  setSubmitting(false);
                  setError(e);
                }
              });
          }}
        >
          {({ isSubmitting, setFieldValue, values }) => (
            <FormikForm>
              <Field
                label="First"
                name="firstName"
                required={true}
                type="text"
                component={CustomInputFormik}
              />
              <Field
                label="Middle"
                name="midName"
                required={false}
                type="text"
                component={CustomInputFormik}
              />
              <Field
                label="Last"
                name="lastName"
                required={true}
                type="text"
                component={CustomInputFormik}
              />
              <Field
                label="Suffix"
                name="suffix"
                required={false}
                type="typeahead"
                options={dropdownData?.suffixes.map((s, index) => ({
                  id: s,
                  display: s,
                }))}
                component={CustomInputFormik}
              />
              <Field
                label="Email"
                name="email"
                required={true}
                type="email"
                component={CustomInputFormik}
              />
              <Field
                label="Mobile Phone"
                name="cellPhone"
                required={false}
                type="text"
                component={CustomInputFormik}
              />
              <Field
                label="Board Certification"
                name="boardCertified"
                required={false}
                type="typeahead"
                options={dropdownData?.boardCertifications.map((s, index) => ({
                  id: s,
                  display: s,
                }))}
                component={CustomInputFormik}
              />
              <Field
                label="Title"
                name="title"
                required={false}
                type="typeahead"
                options={dropdownData?.titles.map((s, index) => ({
                  id: s,
                  display: s,
                }))}
                component={CustomInputFormik}
              />
              <Field
                label="NPI Number"
                name="npiNum"
                required={true}
                type="number"
                component={CustomInputFormik}
              />
              <Field
                label="DEA Number"
                name="deaNum"
                required={false}
                type="text"
                component={CustomInputFormik}
              />
              <Field
                label="DEA Expiration"
                name="deaExpireDate"
                required={false}
                type="date"
                component={CustomInputFormik}
              />
              <Field
                label="License"
                name="licenseNumber"
                required={false}
                type="text"
                component={CustomInputFormik}
              />
              <Field
                label="License State"
                name="licenseState"
                required={false}
                type="typeahead"
                options={states}
                component={CustomInputFormik}
              />
              <Field
                label="MEDICARE ID"
                name="medicareId"
                required={false}
                type="text"
                component={CustomInputFormik}
              />
              <Field
                label="MEDICARE State"
                name="medicareState"
                required={false}
                type="typeahead"
                options={states}
                component={CustomInputFormik}
              />
              <Field
                label="MEDICAID ID"
                name="medicaidId"
                required={false}
                type="text"
                component={CustomInputFormik}
              />
              <Field
                label="MEDICAID State"
                name="medicaidState"
                required={false}
                type="typeahead"
                options={states}
                component={CustomInputFormik}
              />
              <Field
                label="Notes"
                name="notes"
                required={false}
                type="textarea"
                component={CustomInputFormik}
              />
              <Field
                label="Medical School"
                name="medicalSchool"
                required={false}
                type="typeahead"
                options={dropdownData?.medicalSchools.map((s, index) => ({
                  id: s,
                  display: s,
                }))}
                component={CustomInputFormik}
              />
              <Field
                label="Residency"
                name="residency"
                required={false}
                type="typeahead"
                options={dropdownData?.residencies.map((s, index) => ({
                  id: s,
                  display: s,
                }))}
                component={CustomInputFormik}
              />
              <Field
                label="Year Started Practicing"
                name="yearStartedPracticing"
                required={false}
                type="text"
                component={CustomInputFormik}
              />
              {/* <Field
                label="System ID"
                name="cellPhone"
                required={false}
                type="text"
                component={CustomInputFormik}
              /> */}
              <Field
                label="CERNER ID"
                name="cernerId"
                required={false}
                type="number"
                component={CustomInputFormik}
              />
              <Field
                label="STAR ID"
                name="starId"
                required={false}
                type="number"
                component={CustomInputFormik}
              />
              <Field
                label="Language 1"
                name="language1"
                required={false}
                type="typeahead"
                options={languages}
                component={CustomInputFormik}
              />
              <Field
                label="Language 2"
                name="language2"
                required={false}
                type="typeahead"
                options={languages}
                component={CustomInputFormik}
              />
              <Field
                label="Status"
                name="status"
                required={false}
                type="typeahead"
                options={dropdownData?.statuses.map((s, index) => ({
                  id: s,
                  display: s,
                }))}
                component={CustomInputFormik}
              />
              <Field
                label="Preferred Provider"
                name="preferredProvider"
                required={false}
                type="checkbox"
                component={CustomInputFormik}
              />
              <Field
                label="Friends Of CHLA"
                name="fochla"
                required={false}
                type="checkbox"
                component={CustomInputFormik}
              />
              <Field
                label="myCHLA User"
                name="myChla"
                required={false}
                type="checkbox"
                component={CustomInputFormik}
              />
              <Field
                label="CHLA Care Network"
                name="chlahealthNetwork"
                required={false}
                type="checkbox"
                component={CustomInputFormik}
              />
              <Field
                label="Children's Connect"
                name="childrensConnect"
                required={false}
                type="checkbox"
                component={CustomInputFormik}
              />
              <Field
                label="Lab Outreach"
                name="labOutreach"
                required={false}
                type="checkbox"
                component={CustomInputFormik}
              />
              <div className="d-flex row align-items-center form-padding">
                <div className="col-sm-3"></div>
                <div className="col-sm-9 d-flex">
                  <div className="col p-2">
                    <Field
                      name="physicianSpecialties"
                      required={false}
                      label="Specialties"
                      component={({ field, ...props }: any) => {
                        return (
                          <MultiSelect
                            {...field}
                            {...props}
                            multipleAllowed
                            title={props.label}
                            options={specialties}
                            onAddClick={() => {
                              setShowSpecialties(true);
                            }}
                            onRemoveClick={(id) => {
                              setSpecialties((prev) => [
                                ...prev.filter((s) => s.id !== id),
                              ]);
                              setFieldValue("physicianSpecialties", [
                                ...values.physicianSpecialties.filter(
                                  (s) => s !== id
                                ),
                              ]);
                            }}
                          />
                        );
                      }}
                    />
                    <ErrorBoundary FallbackComponent={ErrorFallback}>
                      <AdminCreatePhysicianModal
                        name="Specialties"
                        show={showSpecialties}
                        handleClose={() => setShowSpecialties(false)}
                        handleSubmit={(value) => {
                          setShowSpecialties(false);
                          setFieldValue("physicianSpecialties", [
                            ...values.physicianSpecialties,
                            value.id,
                          ]);
                          setSpecialties((prev) => [...prev, value]);
                        }}
                        options={dropdownData?.specialties}
                      />
                    </ErrorBoundary>
                  </div>
                  <div className="col p-2">
                    <Field
                      name="physicianHospitalAffiliations"
                      required={false}
                      label="Hospital Affiliations"
                      component={({ field, ...props }: any) => {
                        return (
                          <MultiSelect
                            {...field}
                            {...props}
                            multipleAllowed
                            title={props.label}
                            options={affiliations}
                            onAddClick={() => {
                              setShowAffiliations(true);
                            }}
                            onRemoveClick={(id) => {
                              setAffiliations((prev) => [
                                ...prev.filter((s) => s.id !== id),
                              ]);
                              setFieldValue("physicianHospitalAffiliations", [
                                ...values.physicianHospitalAffiliations.filter(
                                  (s) => s !== id
                                ),
                              ]);
                            }}
                          />
                        );
                      }}
                    />
                    <ErrorBoundary FallbackComponent={ErrorFallback}>
                      <AdminCreatePhysicianModal
                        name="Hospital Affiliations"
                        show={showAffiliations}
                        handleClose={() => setShowAffiliations(false)}
                        handleSubmit={(value) => {
                          setShowAffiliations(false);
                          setFieldValue("physicianHospitalAffiliations", [
                            ...values.physicianHospitalAffiliations,
                            value.id,
                          ]);
                          setAffiliations((prev) => [...prev, value]);
                        }}
                        options={dropdownData?.hospitals}
                      />
                    </ErrorBoundary>
                  </div>
                  <div className="col p-2">
                    <Field
                      name="physicianInsuranceAccpted"
                      required={false}
                      label="Insurance Accepted"
                      component={({ field, ...props }: any) => {
                        return (
                          <MultiSelect
                            {...field}
                            {...props}
                            multipleAllowed
                            title={props.label}
                            options={insurances}
                            onAddClick={() => {
                              setShowInsurances(true);
                            }}
                            onRemoveClick={(id) => {
                              setInsurances((prev) => [
                                ...prev.filter((s) => s.id !== id),
                              ]);
                              setFieldValue("physicianInsuranceAccpted", [
                                ...values.physicianInsuranceAccpted.filter(
                                  (s) => s !== id
                                ),
                              ]);
                            }}
                          />
                        );
                      }}
                    />
                    <ErrorBoundary FallbackComponent={ErrorFallback}>
                      <AdminCreatePhysicianModal
                        name="Insurance Accepted"
                        show={showInsurances}
                        handleClose={() => setShowInsurances(false)}
                        handleSubmit={(value) => {
                          setShowInsurances(false);
                          setFieldValue("physicianInsuranceAccpted", [
                            ...values.physicianInsuranceAccpted,
                            value.id,
                          ]);
                          setInsurances((prev) => [...prev, value]);
                        }}
                        options={dropdownData?.insurances}
                      />
                    </ErrorBoundary>
                  </div>
                </div>
              </div>
              <div className="d-flex row align-items-center form-padding">
                <div className="col-sm-3"></div>
                <div className="col-sm-9 ">
                  <MButton
                    variant="primary"
                    type="submit"
                    title="Add"
                    width={"max-content"}
                    isSubmitting={isSubmitting}
                  />
                </div>
              </div>
            </FormikForm>
          )}
        </Formik>
      </div>
      <CustomAlertModal
        show={error !== null}
        handleClose={() => {
          setError(null);
        }}
        header={"Error!"}
        // @ts-ignore
        body={
          error?.response?.data.message ||
          "There was an error processing your request. Please refresh the page and try again. If problems persist please contact myCHLA support."
        }
      />
    </div>
  );
};

export default AdminCreatePhysician;
