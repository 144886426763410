import { FC, useEffect, useState, useRef } from "react";
import { Form, Col, Button, Tab, Tabs, Card } from "react-bootstrap";
import DateHelper, {
  getFormattedDate,
  getFormattedDateFromDate,
} from "../../../../helpers/DateHelper";
import IOnlineCME from "../../../../types/IOnlineCME";
import { useLocation } from "react-router-dom";
import ReactHtmlParser from "react-html-parser";
import MButton from "../../../mButton/MButton";
import RichTextEditor from "../../../RichTextEditor/RichTextEditor";
import { title } from "process";
import IPediatricGR from "../../../../types/IPediatricGR";

const PediatricSubForm: FC<{
  name?: string;
  pediatricData: IPediatricGR;
  editorHtml: any;
  setEditorHtml: any;
  onFormClick: (
    title: string,
    contentTitle: string,
    contentMessage: string,
    cosntentBulletLine: string,
    contentFullname: string,
    contentSignature: string
  ) => void;
}> = ({ name, pediatricData, editorHtml, setEditorHtml, onFormClick }) => {
  const isDisabled = false;
  type FormType = {
    title: string;
    eventDate: Date;
    videoUrl: string;
    isActive: boolean;
    contentTitle: string;
    contentBulletLine: string;
    contentMessage: string;
    contentFullname: string;
    contentSignature: string;
  };
  const [data, setData] = useState<FormType>({
    title: pediatricData.title,
    eventDate: pediatricData.eventDate,
    videoUrl: pediatricData.videoUrl,
    isActive: pediatricData.isActive,
    contentTitle: pediatricData.contentTitle,
    contentMessage: pediatricData.contentMessage,
    contentBulletLine: pediatricData.contentBulletLine,
    contentFullname: pediatricData.contentFullname,
    contentSignature: pediatricData.contentSignature,
  });

  const handleOnChange = (e: any, key: keyof FormType) => {
    const { [key]: mData } = data;

    switch (key) {
      case "title":
        data.title = e.target.value;
        break;

      case "contentTitle":
        data.contentTitle = e.target.value;
        break;

      case "contentFullname":
        data.contentFullname = e.target.value;
        break;

      case "contentSignature":
        data.contentSignature = e.target.value;
        break;

      default:
        break;
    }

    setData(data);

    onFormClick(
      data.title,
      data.contentTitle,
      data.contentMessage,
      "",
      data.contentFullname,
      data.contentSignature
    );
  };

  return (
    <div style={{ backgroundColor: "white" }}>
      <div>
        <Tabs
          // style={{ backgroundColor: "#dbdbdb", width: 350 }}
          defaultActiveKey="Edit in Form"
          transition={false}
        >
          <Tab eventKey="Edit in Form" title="Edit in Form">
            <div className="m-sm-5">
              <form className="m-sm-5">
                <Form.Group className="d-flex row align-items-center form-padding">
                  <Form.Label column sm={3} className="pl-0 pr-0">
                    Title<span className="p-0 asterik"> *</span>
                  </Form.Label>

                  <Col sm={9} className="pl-0 pr-0">
                    <Form.Control
                      onChange={(e) => handleOnChange(e, "title")}
                      defaultValue={pediatricData.title}
                      required
                    />
                  </Col>
                </Form.Group>
                <Form.Group className="d-flex row align-items-center form-padding">
                  <Form.Label column sm={3} className="pl-0 pr-0">
                    SubTitle
                  </Form.Label>

                  <Col sm={9} className="pl-0 pr-0">
                    <Form.Control
                      onChange={(e) => handleOnChange(e, "contentTitle")}
                      defaultValue={pediatricData.contentTitle}
                    />
                  </Col>
                </Form.Group>
                <Form.Group className="d-flex row align-items-start form-padding">
                  <Form.Label column sm={3} className="pl-0 pr-0">
                    Message
                  </Form.Label>

                  <Col sm={9} className="pl-0 pr-0">
                    <RichTextEditor
                      editorHtml={editorHtml}
                      setEditorHtml={setEditorHtml}
                    />
                  </Col>
                </Form.Group>

                <Form.Group className="d-flex row align-items-center form-padding">
                  <Form.Label column sm={3} className="pl-0 pr-0">
                    Full Name
                  </Form.Label>

                  <Col sm={9} className="pl-0 pr-0">
                    <Form.Control
                      onChange={(e) => handleOnChange(e, "contentFullname")}
                      placeholder=""
                      defaultValue={pediatricData.contentFullname}
                    />
                  </Col>
                </Form.Group>

                <Form.Group className="d-flex row align-items-center form-padding">
                  <Form.Label column sm={3} className="pl-0 pr-0">
                    Signature
                  </Form.Label>

                  <Col sm={9} className="pl-0 pr-0">
                    <Form.Control
                      placeholder=""
                      onChange={(e) => handleOnChange(e, "contentSignature")}
                      defaultValue={pediatricData.contentSignature}
                    />
                  </Col>
                </Form.Group>
              </form>
            </div>
          </Tab>
          <Tab eventKey="HTML Preview" title="HTML Preview">
            <div style={{ backgroundColor: "white" }}>
              <div style={{ backgroundColor: "white" }}>
                <br />
                <Col
                  sm={{ span: 5, offset: 3 }}
                  className="d-flex justify-content-center"
                >
                  <Card className="social p-4">
                    <div>
                      {/* {ReactHtmlParser(pgr.content)} */}
                      <h1
                        style={{
                            fontSize: 25,
                            fontWeight: "bold",
                          color: "#004877",
                        }}
                      >
                        {pediatricData.title}
                      </h1>
                      {pediatricData.contentTitle
                        ?.split("|")
                        .map((bullet, index) => {
                          return (
                            <>
                              {" "}
                              <h1
                                style={{
                                  fontSize: 20,
                                  fontWeight: "bold",
                                  color: "#004877",
                                }}
                              >
                                {bullet}
                              </h1>
                            </>
                          );
                        })}
                      <h1
                        style={{
                          fontSize: 14,
                          color: "#666666",
                        }}
                      >
                        {getFormattedDateFromDate(pediatricData.eventDate)}
                      </h1>
                      <div
                        style={{
                          fontSize: 16,
                          color: "#666666",
                        }}
                      >
                        {ReactHtmlParser(editorHtml)}
                      </div>
                      <br />
                      <h2
                        style={{
                          fontSize: 18,
                          color: "#666666",
                        }}
                      >
                        {pediatricData.contentFullname?.split("|")[0]}
                      </h2>
                      {pediatricData.contentSignature
                        ?.split("|")
                        .map((bullet, index) => {
                          return <div
                          style={{
                            fontSize: 16,
                          }}>{bullet}</div>;
                        })}

                      <MButton
                        variant="secondary"
                        disabled={false}
                        title={"Watch  " + "\u00bb"}
                        width={"100%"}
                        type="button"
                        // onClick={() => window.open(pediatricData.videoUrl)}
                      >
                        {"Watch  " + "\u00bb"}
                      </MButton>
                    </div>
                  </Card>
                </Col>
              </div>
            </div>
          </Tab>
        </Tabs>
      </div>
    </div>
  );
};

export default PediatricSubForm;
