import { CancelTokenSource } from "axios";
import { customAxios as axios } from "./ServiceConfig";
import { API_BASE_URL } from "../config";
import IReferralList from "../types/IReferralList";
import IReferralDetailed from "../types/IReferralDetailed";
import IReferralQuery from "../types/IReferralQuery";
import IReferralFormDropdownData from "../types/IReferralFormDropdownData";
import IReferral from "../types/IReferral";
let loginas = localStorage.getItem("loginAsFlag");
let loginasEmail = localStorage.getItem("loginAsEmail");
/**
 * @param query receives the body to be sent in the post request
 * @param token receives the access token of type string
 */
const getReferrals = async (
  query: IReferralQuery,
  token?: string,
  cancelToken?: CancelTokenSource
) => {
  const result = await axios.post<IReferral[]>(
    `${API_BASE_URL}/referral/viewreferrals`,
    query,
    loginas !== null
      ? {
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Pragma: "no-cache",
            Authorization: `Bearer ${token}`,
            loginas: loginas !== null ? loginas : JSON.stringify(false),
            email: loginasEmail !== null ? loginasEmail : "",
          },
          cancelToken: cancelToken?.token,
        }
      : {
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Pragma: "no-cache",
            Authorization: `Bearer ${token}`,
          },
          cancelToken: cancelToken?.token,
        }
  );
  return result;
};

/**
 * @param query receives the body to be sent in the post request
 * @param token receives the access token of type string
 */
const getPhysicianReferrals = async (
  query: IReferralQuery,
  token?: string,
  cancelToken?: CancelTokenSource
) => {
  const result = await axios.post<IReferralList>(
    `${API_BASE_URL}/referral/myreferrals`,
    query,
    loginas !== null
      ? {
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Pragma: "no-cache",
            Authorization: `Bearer ${token}`,
            loginas: loginas !== null ? loginas : JSON.stringify(false),
            email: loginasEmail !== null ? loginasEmail : "",
          },
          cancelToken: cancelToken?.token,
        }
      : {
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Pragma: "no-cache",
            Authorization: `Bearer ${token}`,
          },
          cancelToken: cancelToken?.token,
        }
  );
  return result;
};

/**
 * @param id receives the referral number
 * @param token receives the access token of type string
 */
const getReferralById = async (
  id: number,
  token?: string,
  cancelToken?: CancelTokenSource
) => {
  const result = await axios.get<IReferralDetailed>(
    `${API_BASE_URL}/referral/${id}`,
    loginas !== null
      ? {
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Pragma: "no-cache",
            Authorization: `Bearer ${token}`,
            loginas: loginas !== null ? loginas : JSON.stringify(false),
            email: loginasEmail !== null ? loginasEmail : "",
          },
          cancelToken: cancelToken?.token,
        }
      : {
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Pragma: "no-cache",
            Authorization: `Bearer ${token}`,
          },
          cancelToken: cancelToken?.token,
        }
  );
  return result;
};

/**
 * @param token receives the access token of type string
 */
const getReferralFormDataList = async (
  token?: string,
  cancelToken?: CancelTokenSource
) => {
  const result = await axios.get<IReferralFormDropdownData>(
    `${API_BASE_URL}/dropdowndata/referralform`,
    loginas !== null
      ? {
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Pragma: "no-cache",
            Authorization: `Bearer ${token}`,
            loginas: loginas !== null ? loginas : JSON.stringify(false),
            email: loginasEmail !== null ? loginasEmail : "",
          },
          cancelToken: cancelToken?.token,
        }
      : {
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Pragma: "no-cache",
            Authorization: `Bearer ${token}`,
          },
          cancelToken: cancelToken?.token,
        }
  );
  return result;
};

/**
 * @param data receives the referral id & cancellation reason
 * @param token receives the access token of type string
 */
const cancelReferral = async (
  data: {
    id: number;
    reason: string;
    referral?: IReferral | IReferralDetailed;
  },
  token?: string,
  referral?: IReferral | IReferralDetailed,
  cancelToken?: CancelTokenSource
) => {
  const result = await axios.put(
    `${API_BASE_URL}/referral/cancelreferral/`,
    referral,
    loginas !== null
      ? {
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Pragma: "no-cache",
            Authorization: `Bearer ${token}`,
            loginas: loginas !== null ? loginas : JSON.stringify(false),
            email: loginasEmail !== null ? loginasEmail : "",
          },
          cancelToken: cancelToken?.token,
        }
      : {
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Pragma: "no-cache",
            Authorization: `Bearer ${token}`,
          },
          cancelToken: cancelToken?.token,
        }
  );
  return result;
};

/**
 * @param data receives the referral form
 * @param token receives the access token of type string
 */
export async function createReferral(
  data: FormData,
  token?: string,
  cancelToken?: CancelTokenSource
) {
  for (var key in data.keys()) {
    console.log(key, data.get(key));
  }

  console.log(data)
  const result = await axios.post(
    `${API_BASE_URL}/referral`,
    data,
    loginas !== null
      ? {
          headers: {
            Accept: "application/json",
            "Content-Type": "multipart/form-data",
            Pragma: "no-cache",
            Authorization: `Bearer ${token}`,
            loginas: loginas !== null ? loginas : JSON.stringify(false),
            email: loginasEmail !== null ? loginasEmail : "",
          },
          cancelToken: cancelToken?.token,
        }
      : {
          headers: {
            Accept: "application/json",
            "Content-Type": "multipart/form-data",
            Pragma: "no-cache",
            Authorization: `Bearer ${token}`,
          },
          cancelToken: cancelToken?.token,
        }
  );
  return result;
}

/**
 * @param data receives the referral form
 * @param token receives the access token of type string
 */
export async function editReferral(
  data: FormData,
  token?: string,
  cancelToken?: CancelTokenSource
) {
  const result = await axios.put(
    `${API_BASE_URL}/referral`,
    data,
    loginas !== null
      ? {
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Pragma: "no-cache",
            Authorization: `Bearer ${token}`,
            loginas: loginas !== null ? loginas : JSON.stringify(false),
            email: loginasEmail !== null ? loginasEmail : "",
          },
          cancelToken: cancelToken?.token,
        }
      : {
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Pragma: "no-cache",
            Authorization: `Bearer ${token}`,
          },
          cancelToken: cancelToken?.token,
        }
  );
  return result;
}

const ReferralService = {
  getReferrals,
  getPhysicianReferrals,
  getReferralById,
  getReferralFormDataList,
  cancelReferral,
  createReferral,
  editReferral,
};

export default ReferralService;
