import { FC, useState } from "react";
import { Modal } from "react-bootstrap";
import AdminService from "../../../../services/AdminService";
import MButton from "../../../mButton/MButton";
import { useOktaAuth } from "@okta/okta-react";
import axios from "axios";

const DeactivateConfirmationModal: FC<{
    show: boolean;
    type: 'practice' | 'physician';
    id?: number;
    handleClose?: () => void;
    handleConfirm?: () => void;
}> = ({ show, type, id, handleClose, handleConfirm }) => {
    //
    const [isSubmitting, setIsSubmitting] = useState(false)
    //
    const { authState } = useOktaAuth();
    const accessToken = authState?.accessToken?.accessToken;
    const cancelToken = axios.CancelToken.source();
    //
    const handleRemove = () => {
        if (id !== undefined && handleConfirm !== undefined) {
            setIsSubmitting(true)
            if (type === 'practice') {
                AdminService.deactivatePractice(id, accessToken, cancelToken).then(response => {
                    setIsSubmitting(false)
                    handleConfirm()
                }).catch(e => {
                    setIsSubmitting(false)
                    handleConfirm()
                })
            } else {
                AdminService.deactivatePhysician(id, accessToken, cancelToken).then(response => {
                    setIsSubmitting(false)
                    handleConfirm()
                }).catch(e => {
                    setIsSubmitting(false)
                    handleConfirm()
                })
            }
        }
    }

    return (
        <Modal size="lg" show={show} onHide={handleClose} centered>
            <Modal.Header>Confirmation Required</Modal.Header>
            <Modal.Body>
                <div>
                    <p>Please confirm the removal of {type} with ID {id}.</p>
                </div>
            </Modal.Body>
            <Modal.Footer>
                <MButton
                    onClick={handleRemove}
                    variant="danger"
                    title="Remove"
                    width={"max-content"}
                    isSubmitting={isSubmitting}
                />
                <MButton
                    onClick={handleClose}
                    variant="secondary"
                    title="Cancel"
                    width={"max-content"}
                />
            </Modal.Footer>
        </Modal>
    );
};

export default DeactivateConfirmationModal;
