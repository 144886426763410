import { FC } from "react";
import BeatLoader from "react-spinners/BeatLoader";

const Spinner: FC<{
  /** receives a string or number to set the margin */
  margin?: string | number;
}> = ({ margin }) => {
  return (
    <div
      style={{
        textAlign: "center",
        margin: margin ? margin : 0,
      }}
    >
      <BeatLoader
        //    css={override}
        size={30}
        color={"#004877"}
      />
    </div>
  );
};

export default Spinner;
