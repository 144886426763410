import React, { useRef, useState } from "react";
import Chevron from "./Chevron";
import styles from "./CustomAccordion.module.css";

const CustomAccordion = ({ title, content, small = false,onClick=()=>{}}) => {
  const [open, setOpen] = useState(false);
  const [height, setHeight] = useState("0px");
  const contentRef = useRef(null);

  const toggleAccordion = () => {
    
    setOpen((prev) => !prev);
    if(!open){
      onClick && onClick();
    }
    // setHeight(open ? `${contentRef.current.scrollHeight}px` : "0px");
    // console.log(contentRef.current.scrollHeight)
  };

  return (
    <div>
      <div
        className={styles.item}
        style={{
          marginBottom: `${small ? "0.5rem" : "1rem"}`,
        }}
      >
        <div
          className={styles.header}
          onClick={() => toggleAccordion()}
          style={{ padding: `${small ? "0.75rem 1rem" : "1.5rem 1rem"}` }}
        >
          <div>{title}</div>
          <Chevron
            className={open ? "chevron rotate" : "chevron"}
            small={small}
          />
        </div>
        {open && (
          <>
            <div
              style={{ height: 1, background: "var(--color-mid-gray)" }}
            ></div>
            <div
              // ref={(el) => {
              //   contentRef.current = el;
              // }}
              className={styles.content}
            >
              {content}
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default CustomAccordion;
