import { FormikErrors, FormikValues } from "formik";
import { FC, ReactNode, useEffect, useState } from "react";
import referralFormContext from "./ReferralFormContext";

const ReferralFormState: FC<{ children: ReactNode }> = ({ children }) => {
    const [errors, setErrors] = useState<FormikErrors<FormikValues>>({});

    useEffect(() => {
        // console.log("STATE", errors)
    }, [errors])

    return (
        <referralFormContext.Provider value={{ errors, setErrors }}>
            {children}
        </referralFormContext.Provider>
    );
};

export default ReferralFormState;
