import { FC, useEffect, useState } from "react";
import { FormControl, InputGroup, Button } from "react-bootstrap";
// import "@fortawesome/fontawesome-free/css/all.min.css";


const SearchBar: FC<{
  placeholder: string;
  value?: string;
  source? : string;
  defaultValue?: string;
  /** triggers when search button is clicked or 'ENTER' is pressed on keyboard */
  onSearch: () => void;
  onTextChange: (text: string) => void;
  clearRecentSearch?: () => void;
}> = ({ placeholder, value = "", defaultValue ,  onSearch, onTextChange, clearRecentSearch, source }) => {
  const [text, setText] = useState(value);
  useEffect(() => {
    if(text == "") {
      if(source == 'myPatients' || source == 'sharedPatients' || source == 'viewPatients') {
        clearRecentSearch!();
      }
    }
    onTextChange(text);
  }, [text]);
  
  useEffect(() => {
    if(source == 'myPatients' || source == 'sharedPatients' || source == 'viewPatients') {
      setText(defaultValue!);
    }
  }, [defaultValue]);
  return (
    <InputGroup>
      <FormControl
        placeholder={placeholder}
        value={text}
        onChange={(e) => {
          setText(e.target.value);
        }}
        onKeyDown={(e: React.KeyboardEvent) => {
          if (e.key === "Enter") {
            onSearch();
          }
        }}
      />
      {text.length > 0 ? (
        <Button
          variant="outline-secondary"
          onClick={() => {
            if(source == 'myPatients' || source == 'sharedPatients' || source == 'viewPatients') {
              clearRecentSearch!();
            }
            setText("");
          }}
        >
          <i className="fa fa-times"></i>
        </Button>
      ) : null}
      <Button
        variant="outline-secondary"
        onClick={() => {
          onSearch();
        }}
      >
        <i className="fa fa-search"></i>
      </Button>
    </InputGroup>
  );
};

export default SearchBar;
