import { FC, useEffect, useState } from "react";
import HeaderName from "../../headerName/HeaderName";
import { Badge, OverlayTrigger, Table, Col } from "react-bootstrap";
import IPortalPracticeTip from "../../../types/IPortalPracticeTip";
import MButton from "../../mButton/MButton";
import { useHistory, useLocation } from "react-router-dom";
import CustomToggle from "../../toggle/CustomToggle";
import PracticeTipService from "../../../services/PracticeTipService";
import { useOktaAuth } from "@okta/okta-react";
import { getFormattedDateFromDate } from "../../../helpers/DateHelper";
import axios from "axios";
import Loader from "../../common/Loader";
import usePagination from "../../../hooks/usePagination";
import CustomPagination from "../../common/CustomPagination";
import { Checkbox } from "@mui/material";
import { appInsights } from "../../../appInsights";
const PracticeTipOfTheMonth: FC<{
  name?: string;
}> = ({ name }) => {
  const history = useHistory();
  const tableCellPadding = { padding: 20 };
  const tableHeadPadding = { paddingLeft: 20, paddingRight: 20 };
  const handleClick = () => {
    history.push("/portalupdates/components/newpracticetipform");
  };
  const handleOnClick = (practiceTip: IPortalPracticeTip) => {
    history.push("/portalupdates/components/editpracticetipform", practiceTip);
  };
  const [pages, setPages] = useState(0);

  const { authState } = useOktaAuth();
  const accessToken = authState?.accessToken?.accessToken;
  const [delegationToggle, setdelegationToggle] = useState(true);
  const [loading, setLoading] = useState(true);
  const cancelToken = axios.CancelToken.source();

  const [practiceTipData, setPracticeTipData] =
    useState<IPortalPracticeTip[]>();
  const [error, setError] = useState<Error | null>(null);

  const onToggle = () => {};
  useEffect(() => {
    setLoading(true);
    setError(null);
    PracticeTipService.getPracticeTipServiceList(accessToken)
      .then((response) => {
        setLoading(false);
        setPracticeTipData(response.data);
       
      })
      .catch((e: Error) => {
        if (!axios.isCancel(e)) {
          console.error(e);
          appInsights.trackException({ error: e });
           setError(e);
        }
        
        setLoading(false);
      });
    return () => {
      cancelToken.cancel();
    };
  }, []);
 

  return (
    <div className="referrals">
      <HeaderName name={name} />
      <div className="container">
        <h2 className="heading-main">{name}</h2>
        <div className="d-flex row justify-content-end" style={{ padding: 20 }}>
          <MButton
            variant="primary"
            disabled={false}
            title="Add New"
            width={"fit-content"}
            onClick={handleClick}
          />
        </div>
        <Col
          sm={{ span: 7, offset: 3 }}
          className="d-flex justify-content-center"
        >
          {loading && <Loader />}
        </Col>
        <br />

        <div className="referral">
          <Table id="responsive-table" className="mt-2" responsive>
            <thead>
              <tr>
                <th style={tableHeadPadding}>Title</th>
                <th style={tableHeadPadding}>File Name </th>
                <th style={tableHeadPadding}>Is Active</th>
                <th style={tableHeadPadding}>Publication Date</th>
                <th style={tableHeadPadding}>Modified On</th>
                <th style={tableHeadPadding}>Modified By</th>
              </tr>
            </thead>
            <tbody>
              {practiceTipData?.map((practiceTip) => {
                return (
                  <tr>
                    <td
                      style={tableCellPadding}
                      data-title="Title"
                      id="table-text-referrals"
                      onClick={() => handleOnClick(practiceTip)}
                    >
                      {practiceTip.title}
                    </td>
                    <td
                      style={{
                        maxWidth: 400,
                        ...tableCellPadding,
                        wordWrap: "break-word",
                      }}
                      data-title="File Name"
                    >
                      {practiceTip.fileName}
                    </td>
                    <td style={tableCellPadding} data-title="Is Active">
                      <Checkbox disabled  checked={practiceTip.isActive} />
                    </td>
                    <td style={tableCellPadding} data-title="Publication Date">
                      {getFormattedDateFromDate(practiceTip.publicationDate)}
                    </td>
                    <td style={tableCellPadding} data-title="Modified On">
                      {getFormattedDateFromDate(practiceTip.modifiedOn)}
                    </td>
                    <td style={tableCellPadding} data-title="Modified By">
                      {practiceTip.modifiedBy}
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </Table>
        </div>
      </div>
    </div>
  );
};

export default PracticeTipOfTheMonth;
