import React, { FC } from "react";
import { ButtonGroup, ToggleButton } from "react-bootstrap";
import styles from "./MButtonGroup.module.css";

const MButtonGroup: FC<{
  size: "lg" | "sm";
  buttons: {
    type: "radio" | "checkbox";
    title: string;
    checked: boolean;
    variant: "primary" | "outline-secondary";
    onClick: (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
  }[];
}> = ({ size, buttons }) => {
  return (
    <div id={styles.mButtonGroup}>
      <ButtonGroup size={size}>
        {buttons.map((button, index) => (
          <ToggleButton
            key={index}
            type={button.type}
            value={button.title}
            name={button.title}
            checked={button.checked}
            variant={button.variant}
            onClick={(e) => button.onClick(e)}
          >
            {button.title}
          </ToggleButton>
        ))}
      </ButtonGroup>
    </div>
  );
};

export default MButtonGroup;
