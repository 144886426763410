import { FC, useEffect, useState } from "react";
import { OKTA_AUTH_CONFIG } from "../../config";
import "@okta/okta-signin-widget/dist/css/okta-sign-in.min.css";
import { useOktaAuth } from "@okta/okta-react";
import { Tokens } from "@okta/okta-auth-js";
import { Redirect, useHistory } from "react-router-dom";
import OktaSignInWidget from "./OktaSignInWidget";
import Spinner from "../spinner/Spinner";
import ReminderPopup from "../common/ReminderPopup";
import UserService from "../../services/UserService";

const Login: FC = () => {
  // 
  const { oktaAuth, authState } = useOktaAuth();
  const [loading, setLoading] = useState(true);
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const history = useHistory();

  const { clientId, issuer, pkce, redirectUri, scopes } = OKTA_AUTH_CONFIG;
  const oktaSignInConfig = {
    /**
     * Note: when using the Sign-In Widget for an OIDC flow, it still
     * needs to be configured with the base URL for your Okta Org. Here
     * we derive it from the given issuer for convenience.
     */
    baseUrl: issuer ? issuer.split("/oauth2")[0] : "",
    clientId,
    redirectUri,
    logo: "https://ok6static.oktacdn.com/fs/bco/1/fs026al4oaNkfjFeF2p7",
    i18n: {
      //Overrides default text when using English. Override other languages by adding additional sections.
      en: {
        "primaryauth.title": "Log In", // Changes the sign in text
        "primaryauth.submit": "Log In", // Changes the sign in button
        "primaryauth.username.placeholder": "Email",
        "primaryauth.username.tooltip": "Email",
        "password.forgot.email.or.username.placeholder": "Email",
        "password.forgot.email.or.username.tooltip": "Email",
        "account.unlock.email.or.username.placeholder": "Email",
        "account.unlock.email.or.username.tooltip": "Email",
        "password.expired.title": "Your myCHLA password has expired",
        "mfa.call": "Call Me",
        //ERROR
        "errors.E0000004": "Log in failed!",
        // "model.validation.field.invalid.format.email": "This value is not a valid email address",
        // "idpDiscovery.email.placeholder": "Email"
      },
    },
    features: {
      registration: true, // Enable self-service registration flow
      rememberMe: true, // Setting to false will remove the checkbox to save username
      multiOptionalFactorEnroll: true, // Allow users to enroll in multiple optional factors before finishing the authentication flow.
      selfServiceUnlock: true, // Will enable unlock in addition to forgotten password
      smsRecovery: false, // Enable SMS-based account recovery
      callRecovery: true, // Enable voice call-based account recovery
      router: true, // Leave this set to true for the API demo,
      idpDiscovery: true,
    },
    idpDiscovery: {
      requestContext: `${window.location.href}`,
    },
    registration: {
      click: function () {
        history.push('accountsandaccess/newpracticerequest')
      },
    },
    helpSupportNumber: "(888) 631-2452",
    brandName: "myCHLA",
    authParams: {
      pkce,
      issuer,
      display: "page",
      scopes,
    },
  };

  useEffect(() => {
    // console.log(authState);

    // // after successful authentication with IdP
    // oktaAuth.session.exists().then(function (sessionExists) {
    //   setLoading(false);
    //   if (sessionExists) {
    //     oktaAuth.token.getWithoutPrompt().then(function (response) {
    //       oktaAuth.tokenManager.setTokens(response.tokens);
    //     });
    //   }

    // check to see if the user already has an okta session

    oktaAuth.session.exists().then((response) => {
      // console.log(" response : ", response);

      setLoading(false);

      if (response) {
        oktaAuth.token.getWithoutPrompt().then((response) => {
          oktaAuth.tokenManager.setTokens(response.tokens);
        });
      }
    });
  }, [oktaAuth, authState]);

  const onSuccess = (tokens: Tokens | undefined) => {
    let myItem = localStorage.getItem('okta-cache-storage');
    localStorage.clear();
    if (myItem !== null) localStorage.setItem('okta-cache-storage', myItem);
    localStorage.setItem("showReminder", JSON.stringify(true));
    oktaAuth.handleLoginRedirect(tokens);
  };

  const onError = (err: any) => {
    // console.log("ENTERED ERROR");
    console.error("Error logging in", err);
  };

  if (!authState) {
    return null;
  } else if (authState.isPending) {
    return <Spinner margin="5rem 0" />;
  } else {
    return authState.isAuthenticated ? (
      <>
        <Redirect to={{ pathname: "/" }} />
      </>
    ) : loading === true ? (
      <Spinner margin="5rem 0" />
    ) : (
      <OktaSignInWidget
        config={oktaSignInConfig}
        onSuccess={onSuccess}
        onError={onError}
      />
    );
  }
};

export default Login;
