import React from "react";
import { FC, useEffect, useState, useRef } from "react";
import HeaderName from "../../headerName/HeaderName";
import Loader from "../../common/Loader";
import { Alert, Button, Card, Tab, Tabs, Col, Nav } from "react-bootstrap";
import IPhysicianResources from "../../../types/IPhysicianResources";
import IInfoWell from "../../../types/IInfoWell";
import PediatricGRService from "../../../services/PediatricGRService";
import { useOktaAuth } from "@okta/okta-react";
import IPediatricGR from "../../../types/IPediatricGR";
import ReactHtmlParser from "react-html-parser";
import {
  getFormattedDateFromDate,
  getFormattedYear,
} from "../../../helpers/DateHelper";
import BackButton from "../../backButton/BackButton";

const sortable = require("../../../assets/images/sortable.png").default;

const MedicalStaff: FC<{
  name?: string;
}> = ({ name }) => {
  return (
    <div>
      <HeaderName name={name} />
      <BackButton  />
      <div className="container">
        {" "}
        <h2 className="heading-main">{name}</h2>
        <br />
        <div className="row no-gutters p-2 m-0" style={{ margin: "10px" }}>
          <p style={{ margin: "10px" }}>
            To request staff privileges, please email Gayane Abgaryan at
            gabgaryan@chla.usc.edu and provide the following information:
          </p>
          <ul style={{ margin: "20px" }}>
            <li>Full Name</li>
            <li>Email</li>
            <li>Specialty</li>
          </ul>
        </div>
      </div>
    </div>
  );
};

export default MedicalStaff;
