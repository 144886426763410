import "./App.css";
import AppWithRouterAccess from "./components/router/AppWithRouterAccess";
import { BrowserRouter } from "react-router-dom";
import { FC } from "react";
import MenuItemsState from "./context/MenuItemsState";
import PracticeInfoState from "./context/PracticeInfoState";

const App: FC = () => {
  return (
    <BrowserRouter>
      <MenuItemsState>
        <PracticeInfoState>
          <AppWithRouterAccess />
        </PracticeInfoState>
      </MenuItemsState>
    </BrowserRouter>
  );
};

export default App;
