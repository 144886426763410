import { CancelTokenSource } from "axios";
import { customAxios as axios } from "./ServiceConfig";
import { API_BASE_URL } from "../config";
import IAccountRequests, { AccountRequest } from "../types/IAccountRequests";
import IAllAccountsRoles from "../types/IAllRoles";
import INewPracticeRequest from "../types/INewPracticeRequest";
import IInviteNonPhysician from "../types/IInviteNonPhysician";
import IInvitePhysician from "../types/IInvitePhysician";
import IPaginationQuery from "../types/IPaginationQuery";
import IPatientAccessQuery from "../types/IPatientAccessQuery";
import IUserName from "../types/IUserName";
import ISentInvitations from "../types/ISentInvitations";
let loginas = localStorage.getItem('loginAsFlag')
let loginasEmail = localStorage.getItem('loginAsEmail')
/**
 * @param query receives the body to be sent in the post request
 * @param token receives the access token of type string
 */
const getAllAccountsList = async (
  data: IPatientAccessQuery,
  token?: string,
  cancelToken?: CancelTokenSource
) => {
  const result = await axios.post<any>(
    `${API_BASE_URL}/account/allaccounts`,
    data,
    loginas !== null ? {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Pragma: "no-cache",
        Authorization: `Bearer ${token}`,
        loginas:loginas !== null ? loginas : JSON.stringify(false),
        email:loginasEmail !== null ? loginasEmail : "",
      },
      cancelToken: cancelToken?.token,
    }:{
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Pragma: "no-cache",
        Authorization: `Bearer ${token}`,
      },
      cancelToken: cancelToken?.token,
    }
  );
  return result;
};

/**
 * @param query receives the body to be sent in the post request
 * @param token receives the access token of type string
 */
 const loginAsValidator = async (
  data: {email:string},
  token?: string,
  cancelToken?: CancelTokenSource
) => {
  const result = await axios.post<any>(
    `${API_BASE_URL}/Account/confirm/loginas`,
    data,
    loginas !== null ? {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Pragma: "no-cache",
        Authorization: `Bearer ${token}`,
        loginas:loginas !== null ? loginas : JSON.stringify(false),
        email:loginasEmail !== null ? loginasEmail : "",
      },
      cancelToken: cancelToken?.token,
    }:{
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Pragma: "no-cache",
        Authorization: `Bearer ${token}`,
      },
      cancelToken: cancelToken?.token,
    }
  );
  return result;
};
const loginAsInitiate = async (
  data: {email:string},
  token?: string,
  cancelToken?: CancelTokenSource
) => {
  const result = await axios.post<any>(
    `${API_BASE_URL}/Account/loginas`,
    data,
    loginas !== null ? {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Pragma: "no-cache",
        Authorization: `Bearer ${token}`,
        loginas:loginas !== null ? loginas : JSON.stringify(false),
        email:loginasEmail !== null ? loginasEmail : "",
      },
      cancelToken: cancelToken?.token,
    }:{
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Pragma: "no-cache",
        Authorization: `Bearer ${token}`,
      },
      cancelToken: cancelToken?.token,
    }
  );
  return result;
};

const getUserAccount = async (
  data: IUserName,
  token?: string,
  cancelToken?: CancelTokenSource
) => {
  const result = await axios.post<any>(
    `${API_BASE_URL}/Account/getuseraccount`,
    data,
    loginas !== null ? {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Pragma: "no-cache",
        Authorization: `Bearer ${token}`,
        loginas:loginas !== null ? loginas : JSON.stringify(false),
        email:loginasEmail !== null ? loginasEmail : "",
      },
      cancelToken: cancelToken?.token,
    }:{
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Pragma: "no-cache",
        Authorization: `Bearer ${token}`,
      },
      cancelToken: cancelToken?.token,
    }
  );
  return result;
};

const getVerifyOktaQuestion = async (
  data: IUserName,
  token?: string,
  cancelToken?: CancelTokenSource
) => {
  const result = await axios.post<any>(
    `${API_BASE_URL}/Account/verifysecurityquestion`,
    data,
    loginas !== null ? {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Pragma: "no-cache",
        Authorization: `Bearer ${token}`,
        loginas:loginas !== null ? loginas : JSON.stringify(false),
        email:loginasEmail !== null ? loginasEmail : "",
      },
      cancelToken: cancelToken?.token,
    }:{
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Pragma: "no-cache",
        Authorization: `Bearer ${token}`,
      },
      cancelToken: cancelToken?.token,
    }
  );
  return result;
};

const requestPasswordReset = async (
  data: IUserName,
  token?: string,
  cancelToken?: CancelTokenSource
) => {
  const result = await axios.post<any>(
    `${API_BASE_URL}/Account/requestpasswordreset`,
    data,
    loginas !== null ? {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Pragma: "no-cache",
        Authorization: `Bearer ${token}`,
        loginas:loginas !== null ? loginas : JSON.stringify(false),
        email:loginasEmail !== null ? loginasEmail : "",
      },
      cancelToken: cancelToken?.token,
    }:{
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Pragma: "no-cache",
        Authorization: `Bearer ${token}`,
      },
      cancelToken: cancelToken?.token,
    }
  );
  return result;
};

const generateTempPassword = async (
  data: IUserName,
  token?: string,
  cancelToken?: CancelTokenSource
) => {
  const result = await axios.post<any>(
    `${API_BASE_URL}/Account/generatetemppassword`,
    data,
    loginas !== null ? {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Pragma: "no-cache",
        Authorization: `Bearer ${token}`,
        loginas:loginas !== null ? loginas : JSON.stringify(false),
        email:loginasEmail !== null ? loginasEmail : "",
      },
      cancelToken: cancelToken?.token,
    }:{
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Pragma: "no-cache",
        Authorization: `Bearer ${token}`,
      },
      cancelToken: cancelToken?.token,
    }
  );
  return result;
};

const resetMFA = async (
  data: IUserName,
  token?: string,
  cancelToken?: CancelTokenSource
) => {
  const result = await axios.post<any>(
    `${API_BASE_URL}/Account/resetmfa`,
    data,
    loginas !== null ? {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Pragma: "no-cache",
        Authorization: `Bearer ${token}`,
        loginas:loginas !== null ? loginas : JSON.stringify(false),
        email:loginasEmail !== null ? loginasEmail : "",
      },
      cancelToken: cancelToken?.token,
    }:{
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Pragma: "no-cache",
        Authorization: `Bearer ${token}`,
      },
      cancelToken: cancelToken?.token,
    }
  );
  return result;
};

const accountStatus = async (
  data: IUserName,
  token?: string,
  cancelToken?: CancelTokenSource,
  status?: string
) => {
  let oktaStatus = "";
  if (status === "STAGED" || status === "PROVISIONED") {
    oktaStatus = "reactivateaccount";
  }
  if (status === "ACTIVE") {
    oktaStatus = "suspenduser";
  }
  if (status === "LOCKED_OUT") {
    oktaStatus = "unlockaccount";
  }
  if (status === "SUSPEND") {
    oktaStatus = "suspenduser";
  }
  if (status === "SUSPENDED") {
    oktaStatus = "unsuspenduser";
  }

  const result = await axios.post<any>(
    `${API_BASE_URL}/Account/${oktaStatus}`,
    data,
    loginas !== null ? {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Pragma: "no-cache",
        Authorization: `Bearer ${token}`,
        loginas:loginas !== null ? loginas : JSON.stringify(false),
        email:loginasEmail !== null ? loginasEmail : "",
      },
      cancelToken: cancelToken?.token,
    }:{
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Pragma: "no-cache",
        Authorization: `Bearer ${token}`,
      },
      cancelToken: cancelToken?.token,
    }
  );
  return result;
};

/**
 * @param id receives the patient id
 * @param token receives the access token of type string
 */
const getAllAccountRoles = async (
  token?: string,
  cancelToken?: CancelTokenSource
) => {
  const result = await axios.get<IAllAccountsRoles[]>(
    `${API_BASE_URL}/Account/allroles`,
    loginas !== null ? {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Pragma: "no-cache",
        Authorization: `Bearer ${token}`,
        loginas:loginas !== null ? loginas : JSON.stringify(false),
        email:loginasEmail !== null ? loginasEmail : "",
      },
      cancelToken: cancelToken?.token,
    }:{
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Pragma: "no-cache",
        Authorization: `Bearer ${token}`,
      },
      cancelToken: cancelToken?.token,
    }
  );
  return result;
};

const editAccountsList = async (data: any, token?: string) => {
  const result = await axios.put(`${API_BASE_URL}/Account/editaccount`, data, loginas !== null ? {
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Pragma: "no-cache",
      Authorization: `Bearer ${token}`,
      loginas:loginas !== null ? loginas : JSON.stringify(false),
      email:loginasEmail !== null ? loginasEmail : "",
    },
    
  }:{
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Pragma: "no-cache",
      Authorization: `Bearer ${token}`,
    },
    
  });
  return result;
};

const removePhysician = async (
  data: {
    practiceId: number;
    physicianId: number;
  },
  token?: string,
  cancelToken?: CancelTokenSource
) => {
  const result = await axios.post<{}>(
    `${API_BASE_URL}/account/removephysician`,
    data,
    loginas !== null ? {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Pragma: "no-cache",
        Authorization: `Bearer ${token}`,
        loginas:loginas !== null ? loginas : JSON.stringify(false),
        email:loginasEmail !== null ? loginasEmail : "",
      },
      cancelToken: cancelToken?.token,
    }:{
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Pragma: "no-cache",
        Authorization: `Bearer ${token}`,
      },
      cancelToken: cancelToken?.token,
    }
  );

  return result;
};

const removeNonPhysician = async (
  data: {
    practiceId: number;
    userId: string;
  },
  token?: string,
  cancelToken?: CancelTokenSource
) => {
  const result = await axios.post<{}>(
    `${API_BASE_URL}/account/removenonphysician`,
    data,
    loginas !== null ? {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Pragma: "no-cache",
        Authorization: `Bearer ${token}`,
        loginas:loginas !== null ? loginas : JSON.stringify(false),
        email:loginasEmail !== null ? loginasEmail : "",
      },
      cancelToken: cancelToken?.token,
    }:{
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Pragma: "no-cache",
        Authorization: `Bearer ${token}`,
      },
      cancelToken: cancelToken?.token,
    }
  );

  return result;
};

const invitePhysician = async (
  data: IInvitePhysician,
  token?: string,
  cancelToken?: CancelTokenSource
) => {
  const result = await axios.post<{}>(
    `${API_BASE_URL}/account/invite/physician`,
    data,
    loginas !== null ? {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Pragma: "no-cache",
        Authorization: `Bearer ${token}`,
        loginas:loginas !== null ? loginas : JSON.stringify(false),
        email:loginasEmail !== null ? loginasEmail : "",
      },
      cancelToken: cancelToken?.token,
    }:{
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Pragma: "no-cache",
        Authorization: `Bearer ${token}`,
      },
      cancelToken: cancelToken?.token,
    }
  );

  return result;
};

const inviteNonPhysician = async (
  data: IInviteNonPhysician,
  token?: string,
  cancelToken?: CancelTokenSource
) => {
  const result = await axios.post<{}>(
    `${API_BASE_URL}/account/invite/nonphysician`,
    data,
    loginas !== null ? {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Pragma: "no-cache",
        Authorization: `Bearer ${token}`,
        loginas:loginas !== null ? loginas : JSON.stringify(false),
        email:loginasEmail !== null ? loginasEmail : "",
      },
      cancelToken: cancelToken?.token,
    }:{
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Pragma: "no-cache",
        Authorization: `Bearer ${token}`,
      },
      cancelToken: cancelToken?.token,
    }
  );

  return result;
};

const newAccountRequest = async (data: any) => {
  const result = await axios.post<any>(
    `${API_BASE_URL}/Account/newaccountrequest`,
    data,
    loginas !== null ? {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Pragma: "no-cache",
        loginas:loginas !== null ? loginas : JSON.stringify(false),
        email:loginasEmail !== null ? loginasEmail : "",
      },
      
    }:{
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Pragma: "no-cache",
      },
      
    }
  );
  return result;
};

const getAccountRequests = async (
  query: IPaginationQuery,
  token?: string,
  cancelToken?: CancelTokenSource
) => {
  const result = await axios.post<IAccountRequests>(
    `${API_BASE_URL}/account/accountrequests`,
    query,
    loginas !== null ? {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Pragma: "no-cache",
        Authorization: `Bearer ${token}`,
        loginas:loginas !== null ? loginas : JSON.stringify(false),
        email:loginasEmail !== null ? loginasEmail : "",
      },
      cancelToken: cancelToken?.token,
    }:{
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Pragma: "no-cache",
        Authorization: `Bearer ${token}`,
      },
      cancelToken: cancelToken?.token,
    }
  );
  return result;
};

const getSentInvitations = async (
  query: IPaginationQuery,
  token?: string,
  cancelToken?: CancelTokenSource
) => {
  const result = await axios.post<ISentInvitations>(
    `${API_BASE_URL}/account/sentinvitations`,
    query,
    loginas !== null ? {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Pragma: "no-cache",
        Authorization: `Bearer ${token}`,
        loginas:loginas !== null ? loginas : JSON.stringify(false),
        email:loginasEmail !== null ? loginasEmail : "",
      },
      cancelToken: cancelToken?.token,
    }:{
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Pragma: "no-cache",
        Authorization: `Bearer ${token}`,
      },
      cancelToken: cancelToken?.token,
    }
  );
  return result;
};

const editAccountRequest = async (
  data: AccountRequest,
  token?: string,
  cancelToken?: CancelTokenSource
) => {
  const result = await axios.put<any>(
    `${API_BASE_URL}/Account/accountrequest`,
    data,
    loginas !== null ? {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Pragma: "no-cache",
        Authorization: `Bearer ${token}`,
        loginas:loginas !== null ? loginas : JSON.stringify(false),
        email:loginasEmail !== null ? loginasEmail : "",
      },
      cancelToken: cancelToken?.token,
    }:{
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Pragma: "no-cache",
        Authorization: `Bearer ${token}`,
      },
      cancelToken: cancelToken?.token,
    }
  );
  return result;
};

const emailExists = async (
  data: string,
  token?: string,
  cancelToken?: CancelTokenSource
) => {
  const result = await axios.post<boolean>(
    `${API_BASE_URL}/account/emailexists`,
    data,
    loginas !== null ? {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Pragma: "no-cache",
        Authorization: `Bearer ${token}`,
        loginas:loginas !== null ? loginas : JSON.stringify(false),
        email:loginasEmail !== null ? loginasEmail : "",
      },
      cancelToken: cancelToken?.token,
    }:{
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Pragma: "no-cache",
        Authorization: `Bearer ${token}`,
      },
      cancelToken: cancelToken?.token,
    }
  );
  return result;
};

const reinvite = async (
  data: any,
  token?: string,
  cancelToken?: CancelTokenSource
) => {
  const result = await axios.post<any>(
    `${API_BASE_URL}/Account/invitefromrequest`,
    data,
    loginas !== null ? {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Pragma: "no-cache",
        Authorization: `Bearer ${token}`,
        loginas:loginas !== null ? loginas : JSON.stringify(false),
        email:loginasEmail !== null ? loginasEmail : "",
      },
      cancelToken: cancelToken?.token,
    }:{
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Pragma: "no-cache",
        Authorization: `Bearer ${token}`,
      },
      cancelToken: cancelToken?.token,
    }
  );
  return result;
};

const AccountService = {
  getAllAccountsList,
  getUserAccount,
  getAllAccountRoles,
  editAccountsList,
  getVerifyOktaQuestion,
  requestPasswordReset,
  generateTempPassword,
  resetMFA,
  accountStatus,
  removePhysician,
  removeNonPhysician,
  invitePhysician,
  inviteNonPhysician,
  newAccountRequest,
  getAccountRequests,
  getSentInvitations,
  editAccountRequest,
  emailExists,
  reinvite,
  loginAsValidator,
  loginAsInitiate
};

export default AccountService;
