import { FC, useState, useEffect } from "react";
import { ButtonGroup, ToggleButton } from "react-bootstrap";
import { useLocation, useParams } from "react-router-dom";
import { useOktaAuth } from "@okta/okta-react";
import PatientService from "../../../services/PatientService";
import ILookbackOption from "../../../types/ILookbackOption";
import IPatient from "../../../types/IPatient";
import LookbackFilter from "../../common/LookBackFilter";
import Spinner from "../../spinner/Spinner";
import LabResults from "./components/LabResults";
import Notes from "./components/Notes";
import RadiologyResults from "./components/RadiologyResults";
import "./patient-details.css";
import Loader from "../../common/Loader";
import { ENCOUNTER } from "../../../types/IPatientEncounters";
import { getFormattedTime } from "../../../helpers/DateHelper";
import { appInsights } from "../../../appInsights";
import axios from "axios";
import BackButton from "../../backButton/BackButton";

type stateType = {
  state: {
    patient: IPatient;
  };
};

const PatientDetails: FC<{ name?: string }> = ({ name }) => {
  const { id } = useParams<{ id?: string | undefined }>();
  const { state } = useLocation<stateType>();
  const patientDetail: IPatient = state.state.patient;
  const lookBackOptions: ILookbackOption[] = require("../../../data/patientDetailsLookbackOptions.json");
  const [resultFilter, setResultFilter] = useState<"results" | "visits">(
    "results"
  );
  const { authState } = useOktaAuth();
  const accessToken = authState?.accessToken?.accessToken;
  const cancelToken = axios.CancelToken.source();
  const [visitLookbackOptions, setVisitLookbackOptions] = useState<
    ILookbackOption[]
  >([]);
  const [encounterId, setEncounterId] = useState<string>("");
  const [valueVisit, setValueVisit] = useState<number>(0);
  const [valueRange, setValueRange] = useState<number>(0);

  const [lookback, setLookback] = useState<string>(
    lookBackOptions[0].value + ""
  );

  const handleLookback = (value: number) => {
    if (resultFilter === "visits") {
      setValueVisit(value)
      setEncounterId(value + "");
    } else {
      setLookback(value + "");
      setValueRange(value)

    }
  };

  const onClickTakeSurvey = () => {
    window.open("https://ncv.microsoft.com/YcCpQpPC3L", "_blank");
  };

  const getVisitsLookBackDisplay = (enc: ENCOUNTER) => {
    if (enc.TYPE === "Inpatient") {
      return `${enc.REG_DT_TM.slice(0, -3)} - ${enc.NURSE_UNIT} | ${enc.ROOM
        } | ${enc.LOCATION}`;
    } else {
      return `${enc.REG_DT_TM.slice(0, -3)} - ${enc.LOCATION}`;
    }
  };

  useEffect(() => {
    PatientService.getPatientEncounters(
      patientDetail.personId,
      accessToken,
      cancelToken
    )
      .then((res) => {
        setVisitLookbackOptions(() =>
          res.data.RPTDATA.ENCOUNTERS.map((enc) => ({
            value: enc.ENCNTR_ID,
            display: getVisitsLookBackDisplay(enc),
          }))
        );
      })
      .catch((e) => {
        if (!axios.isCancel(e)) {
          console.error(e);
          appInsights.trackException({ error: e });

        }
      });
    return () => {
      cancelToken.cancel();
    };
  }, []);

  useEffect(() => {
    if (
      resultFilter === "visits" &&
      encounterId === "" &&
      visitLookbackOptions !== undefined &&
      visitLookbackOptions.length !== 0
    ) {
      setEncounterId(visitLookbackOptions[0].value + "");
    } else {
      setEncounterId("");
    }
  }, [resultFilter]);

  if (patientDetail === undefined || Object.keys(patientDetail).length === 0) {
    return <Spinner />;
  } else {
    let patientName = patientDetail.patientName;
    let headerName = { name: name + " > " + patientName };
    return (
      <>
        {/* <HeaderName name={headerName} /> */}
        <div id="patient-details" className="container">
          <div className="row chartLayoutContainer">
            <div className="top-content">
              <div className="top-left-content">
                <h3 className="header-blue bold me-2" style={{ position: 'absolute' }}>{patientName}</h3>
                <BackButton style={'labResults'} />
                <div className="me-2 p-0">
                  DOB: <strong>{patientDetail.dob}</strong>
                </div>
                <div className="m-0">
                  Allergies:{" "}
                  <strong>
                    {patientDetail.allergies
                      ?.map((a) => a.allergyName)
                      .join(", ")}
                  </strong>
                </div>
              </div>
            </div>
            <div className="bottom-content">
              <div className="button-group">
                <span className="p-0 ms-0 me-2">View results by</span>
                <ButtonGroup size="sm">
                  <ToggleButton
                    type="radio"
                    value="results"
                    name="results"
                    checked={resultFilter === "results"}
                    variant="outline-secondary"
                    onClick={() => {
                      setValueRange(0)
                      setEncounterId("")
                      setLookback(lookBackOptions[0].value + "");
                      setResultFilter("results")
                    }}
                  >
                    Time Range
                  </ToggleButton>
                  <ToggleButton
                    type="radio"
                    value="visits"
                    name="visits"
                    checked={resultFilter === "visits"}
                    variant="outline-secondary"
                    onClick={() => {
                      setValueVisit(0)
                      setEncounterId("")
                      setResultFilter("visits");
                    }}
                  >
                    Visits
                  </ToggleButton>
                </ButtonGroup>
              </div>
              <div className="filter">
                {resultFilter === "results" ? (
                  <LookbackFilter
                    onChange={(e) => handleLookback(e.target.value)}
                    options={lookBackOptions}
                    value={valueRange}
                  />
                ) : visitLookbackOptions !== undefined &&
                  visitLookbackOptions.length !== 0 ? (
                  <LookbackFilter
                    onChange={(e) => handleLookback(e.target.value)}
                    options={visitLookbackOptions}
                    value={valueVisit}
                  />
                ) : (
                  <Loader />
                )}
              </div>
            </div>
          </div>
          <div className="col">
            {/* <CustomAccordion title={<h1>hel</h1>} content={<div>HElllloooo</div>} /> */}
            <LabResults
              personId={id !== undefined ? id : ""}
              encounterId={encounterId}
              lookback={lookback}
            />
            <Notes
              personId={id !== undefined ? id : ""}
              patientName={patientName}
              patientDOB={patientDetail.dob}
              encounterId={encounterId}
              lookback={lookback}
            />
            <RadiologyResults
              personId={id !== undefined ? id : ""}
              encounterId={encounterId}
              lookback={lookback}
            />
          </div>
          <div>
          <a
                    className="survey"
                    target="_blank"
                    onClick={onClickTakeSurvey}
                    style={{marginBottom: '-5%'}}
                  >
                   Give us your feedback
                  </a>
          </div>
         
        </div>
      </>
    );
  }
};

export default PatientDetails;
