import { FC, ReactNode, useEffect, useState } from "react";
import usePracticeInfo from "../hooks/usePracticeInfo";
import IPracticeInfo from "../types/IPracticeInfo";
import practiceInfoContext from "./PracticeInfoContext";

const PracticeInfoState: FC<{ children: ReactNode }> = ({ children }) => {
    const [pInfo, setPInfo] = usePracticeInfo();
    const [practiceInfo, setPracticeInfo] = useState<IPracticeInfo | undefined>(pInfo);

    useEffect(() => {
        if (practiceInfo !== undefined) {
            setPInfo(practiceInfo)
        }
        setPracticeInfo(practiceInfo);
    }, [practiceInfo]);

    return (
        <practiceInfoContext.Provider value={{ practiceInfo, setPracticeInfo }}>
            {children}
        </practiceInfoContext.Provider>
    );
};

export default PracticeInfoState;
