import React, { useEffect, useState } from "react";
import { Card, Button, Form ,Col} from "react-bootstrap";
import useInfowells from "../../../hooks/useInfowells";
import HomeService from "../../../services/HomeService";
import Loader from "../../common/Loader";
import axios, { AxiosResponse } from "axios";
import { useOktaAuth } from "@okta/okta-react";
import { getFormattedDateWords } from "../../../helpers/DateHelper";
import Infowell from "./Infowell";
import styles from "./Jumbotron.module.css";
import { appInsights } from "../../../appInsights";

// import './Infowell.css'

const InfowellSection = () => {
  const { authState } = useOktaAuth();
  const accessToken = authState?.accessToken?.accessToken;
  const cancelToken = axios.CancelToken.source();
  const [infowells, setInfowells] = useInfowells();
  const [dropDownWells, setDropDownWells] = useInfowells();
  const [dropDownName, setDropDownName] = useState('');
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<Error | null>(null);
  const [formData, setFormData] = useState<FormData>();


 
  useEffect(() => {
    setLoading(true);
    HomeService.getInfowells(accessToken)    
      .then((response) => {
        setInfowells(response.data);
        setLoading(false);
      })
      .catch((e) => {
        if (!axios.isCancel(e)) {
          console.error(e);
          appInsights.trackException({ error: e });
          setError(e);
        }
       
        setLoading(false);
      });
      return () => {
        cancelToken.cancel();
      };
  }, [accessToken]);

  return (
    <div className="container">
   <br/>
      <Col
          sm={{ span: 7, offset: 3 }}
          className="d-flex justify-content-center"
        >
            {loading && <Loader />}
        </Col>
        <br />
      {infowells !== undefined && (
        <div className="row no-gutters p-2 m-0">
          {infowells.map((infoWell, index) => {
            return (
            <Infowell
            infowell={infoWell}
            home={true}
            key={index}/>
            );
          })}
        </div>
      )}
    </div>
  );
};

export default InfowellSection;
