import { FC, useEffect, useState } from "react";
import { Modal, Form, ModalBody } from "react-bootstrap";
import MButton from "../../../mButton/MButton";
import IPatient from "../../../../types/IPatient";
import { Field, Formik, Form as FormikForm } from "formik";
import CustomInputFormik from "../../../common/CustomInputFormik";
import Geocode from "react-geocode";
import IFormDropdownOption from "../../../../types/IFormDropdownOption";
import * as Yup from 'yup'
import { PracticeLocation } from "../../../../types/IPracticeCreate";
import secrets from '../../../../env.json';

const AdminCreateLocationModal: FC<{
  show: boolean;
  handleClose?: () => void;
  handleAdd?: (values: PracticeLocation) => void;
}> = ({ show, handleClose, handleAdd }) => {
  //
  const [loading, setLoading] = useState<boolean>(true);
  const [apiCall, setApiCall] = useState<number>(0);
  const [search, setSearch] = useState<string>("");
  const [showLocationModal, setShowLocationModal] = useState(false);
  const [isInitial, setIsInitial] = useState(true)
  const [isError, setIsError] = useState(false)
  const [isChecked, setIsChecked] = useState(false)

  const [formData, setFormData] = useState<PracticeLocation>({
    practiceId: 0,
    address1: "",
    address2: "",
    mailStop: "",
    city: "",
    country: "",
    state: "",
    zip: "",
    zip4: "",
    mailAddress1: "",
    mailAddress2: "",
    mailMailStop: "",
    mailCity: "",
    mailCountry: "",
    mailState: "",
    mailZip: "",
    mailZip4: "",
    contactName: "",
    phone: "",
    phoneExt: "",
    fax: "",
    email: "",
  });

  const [formFilledData, setFormFilledData] = useState<PracticeLocation>({
    practiceId: 0,
    address1: "",
    address2: "",
    mailStop: "",
    city: "",
    country: "",
    state: "",
    zip: "",
    zip4: "",
    mailAddress1: "",
    mailAddress2: "",
    mailMailStop: "",
    mailCity: "",
    mailCountry: "",
    mailState: "",
    mailZip: "",
    mailZip4: "",
    contactName: "",
    phone: "",
    phoneExt: "",
    fax: "",
    email: "",
  });

  const states: IFormDropdownOption[] =
    require("../../../../data/states.json").map((s: any) => ({
      id: s.abbreviation,
      display: s.abbreviation,
    }));

  const API_KEY = secrets.GEO_API_KEY;
  Geocode.setApiKey(API_KEY);
  Geocode.setLanguage("en");

  // useEffect(() => {
  //   // const geocoder = new google.maps.Geocoder();
  //   Geocode.fromAddress(search).then(r => {

  //     // console.log(r.results[0].address_components)
  //     var data = formData
  //     data = { ...data, ...formFilledData }

  //     var flag = true
  //     r.results[0].address_components.map((item: any, index: number) => {

  //       if (item.types[0] == "street_number" || item.types[0] == "route" || item.types[0] == 'neighborhood') {
  //         if (flag) {
  //           data.address1 = ""
  //           if(isChecked){
  //             data.mailAddress1 = ""
  //           }
  //           flag = false


  //         }

  //         data.address1 = data.address1 + item.long_name + " "
  //         if(isChecked){
  //           data.mailAddress1 = data.mailAddress1 + item.long_name + " "
  //         }
  //       }
  //       else if (item.types[0] == "postal_code") {
  //         data.zip = ""
  //         data.zip = data.zip + item.long_name
  //         if(isChecked){
  //           data.mailZip = ""
  //           data.mailZip = data.mailZip + item.long_name
  //         }
          
  //       }
  //       else if (item.types[0] == "country") {
  //         data.country = ""
  //         data.country = data.country + item.long_name
  //         if(isChecked){
  //           data.mailCountry = ""
  //           data.mailCountry = data.mailCountry + item.long_name
  //         }
  //       }
  //       else if (item.types[0] == "administrative_area_level_1") {
  //         data.state = ""
  //         data.state = data.state + item.short_name
  //         if(isChecked){
  //           data.mailState = ""
  //           data.mailState = data.mailState + item.short_name
  //         }
  //       }
  //       else if (item.types[0] == "locality") {
  //         data.city = ""
  //         data.city = data.city + item.long_name
  //         if(isChecked){
  //           data.mailCity = ""
  //           data.mailCity = data.mailCity + item.long_name
  //         }
  //       }
  //     })
  //     r.results[0].address_components.map((item: any, index: number) => {

  //       if (item.types[0] == "subpremise") {
  //         if (flag) {
  //           data.address1 = ""
  //           data.mailAddress1 = ""
  //           flag = false

  //         }

  //         data.address1 = data.address1 + item.long_name
  //         if(isChecked){
  //           data.mailAddress1 = data.mailAddress1 + item.long_name
  //         }
  //       }
        
  //     })
  //     data = { ...data, address1:data.address1.trim(),mailAddress1:data.mailAddress1.trim() }
  //     // console.log(data)
  //     setFormData(data)
  //     setShowLocationModal(true)
  //     // handleClose && handleClose()
  //   }).catch(e => {
  //     if (search !== "") {
  //       setIsError(true)
  //       setIsInitial(false)
  //     }
  //     console.error(e)
  //   })

  // }, [search])
  const AddressSearch = (str:any) => {
    if (str.trim() != "") {
      Geocode.fromAddress(str)
        .then((r) => {
          // console.log(r.results[0].address_components)
          var data = formData;
          data = { ...data, ...formFilledData };

          var flag = true;
          r.results[0].address_components.map((item: any, index: number) => {
            if (
              item.types[0] == "street_number" ||
              item.types[0] == "route" ||
              item.types[0] == "neighborhood"
            ) {
              if (flag) {
                data.address1 = "";
                if (isChecked) {
                  data.mailAddress1 = "";
                }
                flag = false;
              }

              data.address1 = data.address1 + item.long_name + " ";
              if (isChecked) {
                data.mailAddress1 = data.mailAddress1 + item.long_name + " ";
              }
            } else if (item.types[0] == "postal_code") {
              data.zip = "";
              data.zip = data.zip + item.long_name;
              if (isChecked) {
                data.mailZip = "";
                data.mailZip = data.mailZip + item.long_name;
              }
            } else if (item.types[0] == "country") {
              data.country = "";
              data.country = data.country + item.long_name;
              if (isChecked) {
                data.mailCountry = "";
                data.mailCountry = data.mailCountry + item.long_name;
              }
            } else if (item.types[0] == "administrative_area_level_1") {
              data.state = "";
              data.state = data.state + item.short_name;
              if (isChecked) {
                data.mailState = "";
                data.mailState = data.mailState + item.short_name;
              }
            } else if (item.types[0] == "locality") {
              data.city = "";
              data.city = data.city + item.long_name;
              if (isChecked) {
                data.mailCity = "";
                data.mailCity = data.mailCity + item.long_name;
              }
            }
          });
          r.results[0].address_components.map((item: any, index: number) => {
            if (item.types[0] == "subpremise") {
              if (flag) {
                data.address1 = "";
                data.mailAddress1 = "";
                flag = false;
              }

              data.address1 = data.address1 + item.long_name;
              if (isChecked) {
                data.mailAddress1 = data.mailAddress1 + item.long_name;
              }
            }
          });
          data = {
            ...data,
            address1: data.address1.trim(),
            mailAddress1: data.mailAddress1.trim(),
          };
          setFormData(data);
          setShowLocationModal(true);
          // handleClose && handleClose()
        })
        .catch((e) => {
          setIsError(true);
          if (search !== "") {
            console.log("in");

          }
          console.error(e);
        });
    } else {
      
      setIsError(true);
    }

  }
  const handleHideLocation = () =>{
                  setFormFilledData({
                    practiceId: 0,
                    address1: "",
                    address2: "",
                    mailStop: "",
                    city: "",
                    country: "",
                    state: "",
                    zip: "",
                    zip4: "",
                    mailAddress1: "",
                    mailAddress2: "",
                    mailMailStop: "",
                    mailCity: "",
                    mailCountry: "",
                    mailState: "",
                    mailZip: "",
                    mailZip4: "",
                    contactName: "",
                    phone: "",
                    phoneExt: "",
                    fax: "",
                    email: "",
                  });
                
                    setFormData({
                      practiceId: 0,
                      address1: "",
                      address2: "",
                      mailStop: "",
                      city: "",
                      country: "",
                      state: "",
                      zip: "",
                      zip4: "",
                      mailAddress1: "",
                      mailAddress2: "",
                      mailMailStop: "",
                      mailCity: "",
                      mailCountry: "",
                      mailState: "",
                      mailZip: "",
                      mailZip4: "",
                      contactName: "",
                      phone: "",
                      phoneExt: "",
                      fax: "",
                      email: "",
                    });
                  
                    setIsInitial(true)
                    setSearch("")
                    setShowLocationModal(false)
                  handleClose && handleClose()
  }
  return (
    <>
      <Modal size="xl" show={show} onHide={handleHideLocation} centered>
        {isError ? <><Modal.Body>
          Our Address Validation tool was unable to find this address. Please check the spelling and try again.
        </Modal.Body><Modal.Body>
            Where applicable, refrain from using terms such as "Office" or "Trailer" or their abbreviated versions "Ofc", "Trir" in the address2 field because it can often result in errors. Use instead "Ste" for suite.
            If you continue having problems validating an address consider putting the Address2 info into the "Mail Stop" field.
          </Modal.Body>
          <Modal.Body>
            <MButton
              onClick={() => {
                // setIsInitial(false)
                setIsError(false)
              }}
              variant="secondary"
              title={"Ok"}
              width={"max-content"}
            />
          </Modal.Body></> : <Formik
            initialValues={isInitial ? formData : formFilledData}
            validationSchema={Yup.object().shape({
              zip: Yup.string()
                .matches(
                  /(^\d{5}$)|(^\d{5}-\d{4}$)/,
                  "Zip Code is not valid"
                )
                .nullable(),
              mailZip: Yup.string()
                .matches(
                  /(^\d{5}$)|(^\d{5}-\d{4}$)/,
                  "Zip Code is not valid"
                )
                .nullable(),
              email: Yup.string().email("Email is not valid").max(255).nullable(),
              phone: Yup.string().matches(
                /^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4}$/im,
                "Phone number is not valid"
              ).nullable(),
              fax:  Yup.string().matches(
                /^\+?[0-9]{7,}$/,
                "Fax number is not valid"
              ).nullable(),
            })}
            enableReinitialize
            onSubmit={(values: any, actions) => {
              // console.log("jsonValues", values);
              if (!isInitial) {
                handleAdd && handleAdd(formFilledData)
                setFormFilledData({
                  practiceId: 0,
                  address1: "",
                  address2: "",
                  mailStop: "",
                  city: "",
                  country: "",
                  state: "",
                  zip: "",
                  zip4: "",
                  mailAddress1: "",
                  mailAddress2: "",
                  mailMailStop: "",
                  mailCity: "",
                  mailCountry: "",
                  mailState: "",
                  mailZip: "",
                  mailZip4: "",
                  contactName: "",
                  phone: "",
                  phoneExt: "",
                  fax: "",
                  email: "",
                });
              
                handleHideLocation()
              }
              setFormFilledData(values)
              var str = ""
              str = str + values.address1

              // setSearch(str)
              AddressSearch(str);
              actions.setSubmitting(false)
             
              window.scrollTo(0, 0);
              // if (handleAdd !== undefined) {
              //   handleAdd(values as PracticeLocation);
              // }
              // await new Promise((r) => setTimeout(() => r, 3000));
              // alert(JSON.stringify(values, null, 2));
              
           
            }}
          >
          {({ values, setValues ,resetForm}) => (
            <FormikForm>
              {showLocationModal ? <Modal.Header>
                The address you used requires a revision to adhere to postal standards.
                Below is the suggested version. If you feel the suggestion is not quite correct please update below and submit it.
              </Modal.Header> : null}
              <Modal.Body>
                {showLocationModal ? <><MButton
                  onClick={() => {
                    if(formData.address1 != "" && formData.city != ""){
                    // if(isInitial){
                    //   handleAdd && handleAdd(formData)
                    // }
                    // else{
                    //   setIsInitial(true)
                    // }
                    resetForm({})
                    handleAdd && handleAdd(formData)
                    setFormData({
                      practiceId: 0,
                      address1: "",
                      address2: "",
                      mailStop: "",
                      city: "",
                      country: "",
                      state: "",
                      zip: "",
                      zip4: "",
                      mailAddress1: "",
                      mailAddress2: "",
                      mailMailStop: "",
                      mailCity: "",
                      mailCountry: "",
                      mailState: "",
                      mailZip: "",
                      mailZip4: "",
                      contactName: "",
                      phone: "",
                      phoneExt: "",
                      fax: "",
                      email: "",
                    });
                  
                    handleHideLocation()
                  }else{
                    setShowLocationModal(false);
                    setIsError(true);
                  }}}
                  variant="primary"
                  title="Submit"
                  width={"max-content"}
                />
                  <MButton
                    onClick={() => {
                      // setIsInitial(false)
                      handleAdd && handleAdd(formFilledData)
                      setFormFilledData({
                        practiceId: 0,
                        address1: "",
                        address2: "",
                        mailStop: "",
                        city: "",
                        country: "",
                        state: "",
                        zip: "",
                        zip4: "",
                        mailAddress1: "",
                        mailAddress2: "",
                        mailMailStop: "",
                        mailCity: "",
                        mailCountry: "",
                        mailState: "",
                        mailZip: "",
                        mailZip4: "",
                        contactName: "",
                        phone: "",
                        phoneExt: "",
                        fax: "",
                        email: "",
                      });
                    
                      handleHideLocation()
                    }}
                    variant="secondary"
                    title={"Use Original"}
                    width={"max-content"}
                  /></> : null}
                <Field
                  label="Address"
                  name="address1"
                  required={true}
                  type="text"
                  component={CustomInputFormik}
                />
                <Field
                  label="Address 2"
                  name="address2"
                  required={false}
                  type="text"
                  component={CustomInputFormik}
                />
                <Field
                  label="Mail Stop"
                  name="mailStop"
                  required={false}
                  type="text"
                  component={CustomInputFormik}
                />
                <Field
                  label="City"
                  name="city"
                  required={true}
                  type="text"
                  component={CustomInputFormik}
                />
                <Field
                  label="State"
                  name="state"
                  required={true}
                  type="typeahead"
                  options={states}
                  placeholder=""
                  itemSelected = {true}
                  component={CustomInputFormik}
                />
                <Field
                  label="Zip"
                  name="zip"
                  required={false}
                  type="text"
                  component={CustomInputFormik}
                />
                <Field
                  label="Zip 4"
                  name="zip4"
                  required={false}
                  type="text"
                  component={CustomInputFormik}
                />
                <Field
                  label="Country"
                  name="country"
                  required={false}
                  type="text"
                  component={CustomInputFormik}
                />
                {/* ----------- */}
                <Form.Group className="d-flex row align-items-center form-padding">
                  <Form.Label className="col-sm-3">
                    <></>
                  </Form.Label>
                  <div className="col-sm-9"><Form.Check
                    label="Mailing address is same as above."
                    onChange={(e) => {
                      setIsChecked(e.target.checked)
                      if (e.target.checked) {
                        setValues({
                          ...values,
                          mailAddress1: values.address1,
                          mailAddress2: values.address2,
                          mailMailStop: values.mailStop,
                          mailCity: values.city,
                          mailState: values.state,
                          mailZip: values.zip,
                          mailZip4: values.zip4,
                          mailCountry: values.country,
                        })
                      } else {
                        setValues({
                          ...values,
                          mailAddress1: "",
                          mailAddress2: "",
                          mailMailStop: "",
                          mailCity: "",
                          mailState: "",
                          mailZip: "",
                          mailZip4: "",
                          mailCountry: "",
                        })
                      }
                    }}
                  /></div>
                </Form.Group>
                <Field
                  label="Mailing Address"
                  name="mailAddress1"
                  required={false}
                  type="text"
                  component={CustomInputFormik}
                />
                <Field
                  label="Mailing Address 2"
                  name="mailAddress2"
                  required={false}
                  type="text"
                  component={CustomInputFormik}
                />
                <Field
                  label="Mail Stop"
                  name="mailMailStop"
                  required={false}
                  type="text"
                  component={CustomInputFormik}
                />
                <Field
                  label="Mailing City"
                  name="mailCity"
                  required={false}
                  type="text"
                  component={CustomInputFormik}
                />
                <Field
                  label="Mailing State"
                  name="mailState"
                  required={false}
                  type="typeahead"
                  options={states}
                  placeholder=""
                  itemSelected = {true}
                  component={CustomInputFormik}
                />
                <Field
                  label="Mailing Zip"
                  name="mailZip"
                  required={false}
                  type="text"
                  component={CustomInputFormik}
                />
                <Field
                  label="Mailing Zip 4"
                  name="mailZip4"
                  required={false}
                  type="text"
                  component={CustomInputFormik}
                />
                <Field
                  label="Mailing Country"
                  name="mailCountry"
                  required={false}
                  type="text"
                  component={CustomInputFormik}
                />
                {/* ----------- */}
                <div style={{ visibility: 'hidden' }} >Info</div>
                <Field
                  label="Contact Person"
                  name="contactName"
                  required={false}
                  type="text"
                  component={CustomInputFormik}
                />
                <Field
                  label="Email"
                  name="email"
                  required={false}
                  type="email"
                  component={CustomInputFormik}
                />
                <Field
                  label="Phone Number"
                  name="phone"
                  required={false}
                  type="text"
                  component={CustomInputFormik}
                />
                <Field
                  label="Ext"
                  name="phoneExt"
                  required={false}
                  type="text"
                  component={CustomInputFormik}
                />
                <Field
                  label="Fax"
                  name="fax"
                  required={false}
                  type="text"
                  component={CustomInputFormik}
                />
              </Modal.Body>
              {!showLocationModal ? <Modal.Footer>
                <MButton
                  type="submit"
                  variant="primary"
                  title="Add"
                  width={"max-content"}
                />
                <MButton
                  onClick={handleHideLocation}
                  variant="secondary"
                  title={"Cancel"}
                  width={"max-content"}
                />
              </Modal.Footer> : null}
            </FormikForm>
          )}
        </Formik>}

      </Modal>

    </>
  );
};

export default AdminCreateLocationModal;
