import { useEffect, useState } from "react";
import IInfowell from '../types/IInfoWell'

type KEY = "infowells";

const getInfowellsFromSessionStorage = (
  key: KEY,
  initialValue: IInfowell[] | undefined
) => {
  const savedValue = localStorage.getItem(key);
  const savedValueJSON = savedValue && JSON.parse(savedValue);
  if (savedValueJSON) return savedValueJSON;
  if (initialValue instanceof Function) return initialValue();
  return initialValue;
};

const useInfowells = (key: KEY = "infowells", initialValue?: IInfowell[]) => {
  const [infowells, setInfowells] = useState<IInfowell[]>(() => {
    return getInfowellsFromSessionStorage(key, initialValue);
  });
  useEffect(() => {
    if (infowells !== undefined) {
      try {
        localStorage.setItem(key, JSON.stringify(infowells));
      } catch (e) {
        let msg =
          "Cannot store the images in local storage as it has exceeded the localStorage limit. Please contact the administrator";
        alert(msg);
        throw e;
      } finally {
        return;
      }
    }
  }, [infowells]);
  return [infowells, setInfowells] as const;
};

export default useInfowells;
