import { createContext } from "react";
import IMenuItem from "../types/IMenuItem";

interface IMenuItemsContext {
  menuItems: IMenuItem[] | undefined;
  setMenuItems: React.Dispatch<React.SetStateAction<IMenuItem[] | undefined>>;
}

const menuItemsContext = createContext<IMenuItemsContext>({
  menuItems: undefined,
  setMenuItems: () => {},
});

export default menuItemsContext;
