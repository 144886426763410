import FormikStepper from "../../../wizard/FormikStepper";
import FormikStep from "../../../wizard/FormikStep";
import ReferralFormStructure from "../../../../data/ReferralFormStructure";
import { createContext, FC, useContext, useEffect, useState } from "react";
import IReferralDetailed from "../../../../types/IReferralDetailed";
import IReferralFormField from "../../../../types/IReferralFormField";
import IReferral, { ReferralAppointment } from "../../../../types/IReferral";
import { Field, useField } from "formik";
import SelectInput from "../../../common/SelectInput";
import DateInput from "../../../common/DateInput";
import EmailInput from "../../../common/EmailInput";
import HiddenInput from "../../../common/HiddenInput";
import NumberInput from "../../../common/NumberInput";
import TextAreaInput from "../../../common/TextAreaInput";
import TextInput from "../../../common/TextInput";
import UploadAttachmentsNew, { UploadFile } from "./UploadAttachmentsNew";
import IReferralFormDropdownData, {
  IReferralFormDropdownDataType,
} from "../../../../types/IReferralFormDropdownData";
import * as Yup from "yup";
import referralFormContext, { referralSubmitContext } from "../../../../context/ReferralFormContext";
import IFormDropdownOption from "../../../../types/IFormDropdownOption";

const ReferralForm: FC<{
  initialValues: IReferral | IReferralDetailed | undefined;
  onSubmit: (formData: FormData) => Promise<any>;
  referralFormDropdownData: IReferralFormDropdownData;
  referral: IReferral | IReferralDetailed | undefined;
  isDisabled: boolean;
}> = ({
  initialValues,
  onSubmit,
  referralFormDropdownData,
  referral,
  isDisabled,
}) => {
  const { errors } = useContext(referralFormContext);
  const states: IFormDropdownOption[] =
    require("../../../../data/states.json").map((s: any) => ({
      id: s.abbreviation,
      display: s.abbreviation,
    }));

  const [submitDisabled, setSubmitDisabled] = useState<boolean>(false);
  const [files, setFiles] = useState<UploadFile[]>([]);
  const [showFiles, setShowFiles] = useState<UploadFile[]>([]);
  const [totalSize, setTotalSize] = useState(0);
 
  const renderErrorMessage = (field: any) => {
    return (
      errors[field.name] && (
        <div style={{ display: "flex" }}>
          <div className="col-sm-3"></div>
          <div className="col-sm-9" style={{ color: "var(--color-red)" }}>
            <span>{errors[field.name]}</span>
          </div>
        </div>
      )
    );
  };

  const [physician, setPhysician] = useState(referral?.physicianName);
  const [acuity, setAcuity] = useState(referral?.patientAcuity);
  const [patientState, setPatientState] = useState(referral?.patientState);
  const [clinicKey, setClinicKey] = useState(referral?.clinicName);
  const [guardianRelationship, setGuardianRelationship] = useState(referral?.guardianRelationship);
  const [patientSex, setPatientSex] = useState(referral?.patientSex);
  const [guardianSex, setGuardianSex] = useState(referral?.guardianSex);
  const [requestType, setRequestType] = useState(referral?.requestType);
  const [insuranceType, setInsuranceType] = useState(referral?.insuranceType);

  const patientStateId = (fieldInfo: any, options: IReferralFormDropdownDataType[]) => {
    if (patientState !== undefined && patientState !== "") {
      let result: any;
      options.map((display) => {
        if (patientState?.toLowerCase() === display.display.toLocaleLowerCase()) {
          result = display.id;
        }
      });
      fieldInfo.initialValue = result;
      setPatientState("");
    }

    return fieldInfo.initialValue;
  };
  const clinicKeyData = (fieldInfo: any, options: IReferralFormDropdownDataType[]) => {
    if (clinicKey !== undefined && clinicKey !== "") {
      let result: any;
      options.map((display) => {
        if (clinicKey?.toLowerCase() === display.display.toLocaleLowerCase()) {
          result = display.id;
        }
      });
      fieldInfo.initialValue = result;
      setClinicKey("");
    }

    return fieldInfo.initialValue;
  };
  const patientAcuityId = (fieldInfo: any, options: IReferralFormDropdownDataType[]) => {
    if (acuity !== undefined && acuity !== "") {
      let result: any;
      options.map((display) => {
        if (acuity?.toLowerCase() === display.display.toLocaleLowerCase()) {
          result = display.id;
        }
      });
      fieldInfo.initialValue = result;
      setAcuity("");
    }

    return fieldInfo.initialValue;
  };
  const guardianRelationshipId = (fieldInfo: any, options: IReferralFormDropdownDataType[]) => {
    if (guardianRelationship !== undefined && guardianRelationship !== "") {
      let result: any;
      options.map((display) => {
        if (guardianRelationship?.toLowerCase() === display.display.toLocaleLowerCase()) {
          result = display.id;
        }
      });
      fieldInfo.initialValue = result;
      setGuardianRelationship("");
    }

    return fieldInfo.initialValue;
  };
  const patientSexId = (fieldInfo: any, options: IReferralFormDropdownDataType[]) => {
    if (patientSex !== undefined && patientSex !== "") {
      let result: any;
      options.map((display) => {
        if (patientSex?.toLowerCase() === display.display.toLocaleLowerCase()) {
          result = display.id;
        }
      });
      fieldInfo.initialValue = result;
      setPatientSex("");
    }

    return fieldInfo.initialValue;
  };

  const guardianSexId = (fieldInfo: any, options: IReferralFormDropdownDataType[]) => {
    console.log("guardianSexId", options);
    console.log("guardianSex", guardianSex);
    if (guardianSex !== undefined && guardianSex !== "") {
      let result: any;
      options.map((display) => {
        if (guardianSex?.toLowerCase() === display.display.toLocaleLowerCase()) {
          console.log("guardiansex", display);
          result = display.id;
        }
      });
      fieldInfo.initialValue = result;
      setGuardianSex("");
    }
    console.log("guardianSex after setting",fieldInfo.initialValue);
    return fieldInfo.initialValue;
  }
  
  const requestTypeId = (fieldInfo: any, options: IReferralFormDropdownDataType[]) => {
    if (requestType !== undefined && requestType !== "") {
      let result: any;
      options.map((display) => {
        if (requestType?.toLowerCase() === display.display.toLocaleLowerCase()) {
          result = display.id;
        }
      });
      fieldInfo.initialValue = result;
      setRequestType("");
    }

    return fieldInfo.initialValue;
  };
  const physicianId = (fieldInfo: any, options: IReferralFormDropdownDataType[]) => {
    if (physician !== undefined && physician !== "") {
      let result: any;
      options.map((display) => {
        if (physician.toLowerCase() === display.display.toLocaleLowerCase()) {
          result = display.id;
        }
      });
      fieldInfo.initialValue = result;
      setPhysician("");
    }

    return fieldInfo.initialValue;
  };
  const insuranceTypeId = (fieldInfo: any, options: IReferralFormDropdownDataType[]) => {
    if (insuranceType !== undefined && insuranceType !== "") {
      let result: any;
      options.map((display) => {
        if (insuranceType?.toLowerCase() === display.display.toLocaleLowerCase()) {
          result = display.id;
        }
      });
      fieldInfo.initialValue = result;
      setInsuranceType("");
    }

    return fieldInfo.initialValue;
  };

  const referralFormStructure = ReferralFormStructure.filter(
    (r) => r.belongsTo === "Referral"
  );
  const patientFormStructure = ReferralFormStructure.filter(
    (r) => r.belongsTo === "Patient"
  );
  const guardianFormStructure = ReferralFormStructure.filter(
    (r) => r.belongsTo === "Guardian"
  );
  const attachmentsFormStructure = ReferralFormStructure.filter(
    (r) => r.belongsTo === "Attachments"
  );

  const renderFormField = (fieldInfo: IReferralFormField, error?: any) => {
    //console.log("field",fieldInfo)
    referralFormDropdownData.physicians.map((data) => {
      //console.log(data);
    });
    let DEFAULT_OPTION: IReferralFormDropdownDataType = {
      id: "",
      display: "Please make a selection...",
    };
    if (fieldInfo.type === "select") {
      let options: IReferralFormDropdownDataType[] = [];
      //console.log("referralFormDropdownData", referralFormDropdownData);
      if (referralFormDropdownData !== undefined) {
        switch (fieldInfo.name) {
          case "physicianId":
            if (referral !== undefined && referral.physicianName !== "") {
              let result = referralFormDropdownData.physicians.find(
                (data) => data.display === referral.physicianName
              );
              //console.log("result", result);
              if (result == undefined && referral.physicianName != undefined) {
                referralFormDropdownData.physicians.push({
                  id: -1,
                  display: referral.physicianName.toString(),
                });
              }
            }

            options = [DEFAULT_OPTION, ...referralFormDropdownData.physicians];
            break;
          case "patientState":
            options = [DEFAULT_OPTION, ...states];
            break;
          case "practiceId":
            options = [...referralFormDropdownData.practices];
            break;
          case "insuranceTypeId":
            if (referral !== undefined && referral.insuranceType !== "") {
              //console.log("insurance", referral.insuranceType);
              let insuranceTyperesult =
                referralFormDropdownData.insuranceTypes.find(
                  (data) => data.display === referral.insuranceType
                );
              if (
                insuranceTyperesult == undefined &&
                referral.insuranceType != undefined
              ) {
                referralFormDropdownData.insuranceTypes.push({
                  id: -1,
                  display: referral.insuranceType.toString(),
                });
              }
            }
            options = [
              DEFAULT_OPTION,
              ...referralFormDropdownData.insuranceTypes,
            ];
            break;
          case "patientAcuityId":
            if (referral !== undefined && referral.patientAcuity !== "") {
              //console.log("insurance", referral.patientAcuity);
              let patientAcuityresult =
                referralFormDropdownData.patientAcuitys.find(
                  (data) => data.display === referral.patientAcuity
                );
              if (
                patientAcuityresult == undefined &&
                referral.patientAcuity != undefined
              ) {
                referralFormDropdownData.patientAcuitys.push({
                  id: -1,
                  display: referral.patientAcuity.toString(),
                });
              }
            }
            options = [
              DEFAULT_OPTION,
              ...referralFormDropdownData.patientAcuitys
            ]
            break;
          case "requestTypeId":
            options = [DEFAULT_OPTION, ...referralFormDropdownData.requestTypes];
            break;
          case "clinicKey":
            if (referral !== undefined && referral.clinicName !== "") {
              let clinicKeyresult = referralFormDropdownData.clinics.find(
                (data) => data.display === referral.clinicName
              );
              if (
                clinicKeyresult == undefined &&
                referral.clinicName != undefined
              ) {
                referralFormDropdownData.clinics.push({
                  id: -1,
                  display: referral.clinicName.toString(),
                });
              }
            }
            options = [DEFAULT_OPTION, ...referralFormDropdownData.clinics];
            break;
          case "guardianRelationshipId":
            options = [
              DEFAULT_OPTION,
              ...referralFormDropdownData.guardianRelationships,
            ];
            break;
          case "patientSexId":
            options = [DEFAULT_OPTION, ...referralFormDropdownData.patientSex];
            break;
          case "guardianSexId":
              options = [DEFAULT_OPTION, ...referralFormDropdownData.guardianSex];
        }
      }

      return (
        <Field name={fieldInfo.name} key={fieldInfo.name}>
          {({ field }: any) => (
            <>
              {referral != undefined ? (
                <SelectInput
                  disabled={isDisabled}
                  fieldInfo={fieldInfo}
                  {...field}
                  value={
                    fieldInfo.name === "clinicKey"
                      ? clinicKeyData(fieldInfo, options)
                      : fieldInfo.name === "patientAcuityId"
                      ? patientAcuityId(fieldInfo, options)
                      : fieldInfo.name === "requestTypeId"
                      ? requestTypeId(fieldInfo, options)
                      : fieldInfo.name === "patientState"
                      ? patientStateId(fieldInfo, options)
                      : fieldInfo.name === "patientSexId"
                      ? patientSexId(fieldInfo, options)
                      : fieldInfo.name === "guardianSexId"
                      ? guardianSexId(fieldInfo, options)
                      : fieldInfo.name === "guardianRelationshipId"
                      ? guardianRelationshipId(fieldInfo, options)
                      : field.name === "physicianId"
                      ? physicianId(fieldInfo, options)
                      : field.name === "insuranceTypeId"
                      ? insuranceTypeId(fieldInfo, options)
                      : null
                  }
                  options={options}
                />
              ) : (
                <SelectInput
                  disabled={isDisabled}
                  fieldInfo={fieldInfo}
                  {...field}
                  options={options}
                />
              )}
              {renderErrorMessage(fieldInfo)}
            </>
          )}
        </Field>
      );
    }
    if (fieldInfo.type === "hidden") {
      return (
        <Field name={fieldInfo.name}>
          {({ field }: any) => {
            return (
              <HiddenInput
                disabled={isDisabled}
                {...field}
                fieldInfo={fieldInfo}
              />
            );
          }}
        </Field>
      );
    }
    if (fieldInfo.type === "text") {
      return (
        <Field name={fieldInfo.name}>
          {({ field }: any) => (
            <>
              <TextInput
                disabled={isDisabled}
                {...field}
                fieldInfo={fieldInfo}
              />
              {renderErrorMessage(fieldInfo)}
            </>
          )}
        </Field>
      );
    }
    if (fieldInfo.type === "typeahead") {
      return (
        <Field name={fieldInfo.name}>
          {({ field }: any) => (
            <>
              <TextInput
                disabled={isDisabled}
                {...field}
                fieldInfo={fieldInfo}
              />
              {renderErrorMessage(fieldInfo)}
            </>
          )}
        </Field>
      );
    }
    if (fieldInfo.type === "textarea") {
      return (
        <Field name={fieldInfo.name}>
          {({ field }: any) => (
            <>
              <TextAreaInput
                disabled={isDisabled}
                {...field}
                fieldInfo={fieldInfo}
              />
              {renderErrorMessage(fieldInfo)}
            </>
          )}
        </Field>
      );
    }
    if (fieldInfo.type === "number") {
      return (
        <Field name={fieldInfo.name}>
          {({ field }: any) => (
            <>
              <NumberInput
                disabled={isDisabled}
                {...field}
                fieldInfo={fieldInfo}
              />
              {renderErrorMessage(fieldInfo)}
            </>
          )}
        </Field>
      );
    }
    if (fieldInfo.type === "email") {
      return (
        <Field name={fieldInfo.name}>
          {({ field }: any) => (
            <>
              <EmailInput
                disabled={isDisabled}
                {...field}
                fieldInfo={fieldInfo}
              />
              {renderErrorMessage(fieldInfo)}
            </>
          )}
        </Field>
      );
    }
    if (fieldInfo.type === "date") {
      return (
        <Field name={fieldInfo.name}>
          {({ field }: any) => (
            <>
              <DateInput
                disabled={isDisabled}
                {...field}
                fieldInfo={fieldInfo}
              />
              {renderErrorMessage(fieldInfo)}
            </>
          )}
        </Field>
      );
    }
    if (fieldInfo.type === "file") {
      return (
        <Field name={fieldInfo.name}>
          {({ field }: any) => (
            <UploadAttachmentsNew 
            {...field} fieldInfo={fieldInfo}  
            setSubmitDisabled={setSubmitDisabled}
            files = {files}
            setFiles = {setFiles}
            showFiles = {showFiles}
            setShowFiles = {setShowFiles}
            totalSize = {totalSize}
            setTotalSize = {setTotalSize}
          />
        )}
        </Field>
      );
    }
  };

  useEffect(() => {}, [errors]);

  return (
    <referralSubmitContext.Provider value={submitDisabled}>
      <FormikStepper
        enableReinitialize
        initialValues={{
          ...initialValues,
          practiceId:
            referralFormDropdownData.practices[0] !== undefined
              ? referralFormDropdownData.practices[0].id
              : "no data",
        }}
        onSubmit={async (values, { setSubmitting }) => {
          setSubmitting(true);
          let formData = new FormData();
          Object.entries(values).map((entry) => {
            const key = entry[0];
            const value = entry[1];
            if (value !== null) {
              if (key.toLowerCase() === "id" && !isDisabled) {
                if (value !== "") {
                  formData.append("parentReferralId", value);
                }
                formData.append(key, "");
              }
              if (key.toLowerCase() === "attachedfile") {
                value.map((file: File) => {
                  formData.append(key, file);
                });
              } else {
                if (
                  key.toLowerCase() === "guardianrelationshipid" &&
                  (value === "" || value === null)
                ) {
                  formData.append(key, "0");
                } else if (value !== undefined) {
                  formData.append(key, value);
                }
              }
            }
          });
          if (!formData.has("clinicKey")) {
            formData.append("clinicKey", "-1");
          }
          if (!formData.has("insuranceTypeId")) {
            formData.append("insuranceTypeId", "-1");
          }
          if (!formData.has("requestTypeId")) {
            formData.append("requestTypeId", "-1");
          }
          await onSubmit(formData);
          setSubmitting(false);
        }}
      >
        <FormikStep label="Referral">
          {referralFormStructure.map((field) => renderFormField(field, errors))}
        </FormikStep>
        <FormikStep
          validationSchema={Yup.object().shape({
            guardianEmail: Yup.string()
              .email("Email is not valid")
              .max(255)
              .nullable(),
            phoneNumber: Yup.string().matches(
              /^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4}$/im,
              "Phone number is not valid"
            ),
            secondaryPhoneNumber: Yup.string()
              .matches(
                /^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4}$/im,
                "Phone number is not valid"
              )
              .nullable(),
            zipCode: Yup.string().matches(
              /(^\d{5}$)|(^\d{5}-\d{4}$)/,
              "Zip Code is not valid"
            ),
            practiceFax: Yup.string()
              .matches(/^\+?[0-9]{7,}$/, "Fax number is not valid")
              .nullable(),
            dateOfBirth: Yup.date()
              .min(new Date("01/01/1904"), "Enter date after 01/01/1904")
              .max(new Date(), "Cannot enter a future date")
              .required(),
            guardianDob: Yup.date()

              .min(new Date("01/01/1904"), "Enter date after 01/01/1904")
              .max(new Date(), "Cannot enter a future date")
              .required(),
            firstName: Yup.string().matches(/^[a-zA-Z]*$/, "Names should not contain spaces, numbers or special characters"),
            middleName: Yup.string().matches(/^[a-zA-Z]*$/, "Names should not contain spaces, numbers or special characters").nullable(),
            lastName: Yup.string().matches(/^[a-zA-Z]*$/, "Names should not contain spaces, numbers or special characters"),
            guardianFirstName: Yup.string().matches(/^[a-zA-Z]*$/, "Names should not contain spaces, numbers or special characters"),
            guardianLastName: Yup.string().matches(/^[a-zA-Z]*$/, "Names should not contain spaces, numbers or special characters"),
          })}
          label="Patient/Guardian"
        >
          <>
            <div className="d-flex row align-items-center form-padding">
              <div className="col-sm-3"></div>
              <h4 className="col-sm-9 mb-0 mt-3" style={{ color: "#0d9ddb" }}>
                <strong>Patient Information</strong>
              </h4>
            </div>
            {patientFormStructure.map((field) => renderFormField(field, errors))}
            <div className="d-flex row align-items-center form-padding">
              <div className="col-sm-3"></div>
              <h4 className="col-sm-9 mb-0 mt-3" style={{ color: "#0d9ddb" }}>
                <strong>Guardian Information</strong>
              </h4>
            </div>
            {guardianFormStructure.map((field) => renderFormField(field, errors))}
          </>
        </FormikStep>
        <FormikStep label="Attachments">
          {attachmentsFormStructure.map((field) =>
            renderFormField(field, errors)
          )}
        </FormikStep>
      </FormikStepper>
    </referralSubmitContext.Provider>
  );
};
export default ReferralForm;
