import { FC, useEffect, useState, useRef, useCallback } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { useOktaAuth } from "@okta/okta-react";
import { Button } from "react-bootstrap";
import HeaderName from "../../headerName/HeaderName";
import useScrollPosition from "../../../hooks/useScrollPosition";
import useSessionState from "../../../hooks/useSessionState";
import ILookbackOption from "../../../types/ILookbackOption";
import Loader from "../../common/Loader";
import IRouteState from "../../../types/IRouteState";
import IPatientAccessRequest from "../../../types/IPatientAccessRequest";
import SearchBar from "../../common/SearchBar";
import IPatientAccessQuery from "../../../types/IPatientAccessQuery";
import PatientAccessRequestList from "../myPatients/components/PatientAccessRequestList";
import ProxyPatientAccessRequestList from "../myPatients/components/ProxyPatientAccessRequestList";
import PatientAccessRequestService from "../../../services/PatientAccessRequestService";
import LookBackFilter from "../../common/LookBackFilter";
import axios from "axios";
import { appInsights } from "../../../appInsights";

const PatientAccessRequests: FC<{ name?: string }> = ({ name }) => {
  const lookBackOptions: ILookbackOption[] = require("../../../data/visitsLookbackOptions.json");

  const { authState } = useOktaAuth();
  const accessToken = authState?.accessToken?.accessToken;
  const history = useHistory();
  let pathName = history.location.pathname;

  const [patientAccessRequest, setPatientAccessRequest] = useState<IPatientAccessRequest[]>([]);

  const [searchText, setSearchText] = useSessionState(
    "searchTextPatientsList",
    ""
  );
  const [message, setMessage] = useState(false);
  const [loading, setLoading] = useState(true);

  const [query, setQuery] = useState<IPatientAccessQuery>({
    lookback: 30,
    practice:pathName === "/patientaccessrequest/patientaccessrequests"?false:true
  });

  const [apiCallCount, setApiCallCount] = useState(0);
  const [error, setError] = useState<Error>();
  const location = useLocation<IRouteState>();
  const cancelToken = axios.CancelToken.source();

  const newPatientAccessRequest = () => {
    let path = `/patients/newpatientaccessrequest`;
    history.push(path);
  };

  useEffect(() => {
    setLoading(true);
    PatientAccessRequestService.getPatientAccessRequestList(query, accessToken, cancelToken)
      .then((response) => {
        if (error !== undefined) {
          setError(undefined);
        }
        setPatientAccessRequest(response.data);
        setLoading(false);
      })
      .catch((e) => {
        if (!axios.isCancel(e)) {
          console.error(e);
          appInsights.trackException({ error: e });
          setError(e);
        }
        
        setLoading(false);
      });
    return () => {
      cancelToken.cancel();
    };
  }, [apiCallCount]);

  const navigateToPage = (page: number) => {
    setApiCallCount((c) => c + 1);
    window.scrollTo(0, 0);
  };

  const handleSearch = (searchText: string) => {
    setQuery({
      ...query,
      searchterm: searchText,
    });
  };
  const handleLookBack = (e: React.ChangeEvent<any>) => {
    let temp = query;
    temp["lookback"] = e.target.value;
    setQuery(temp);
    navigateToPage(1);
  };

  return (
    <div>
      <HeaderName name={name} />
      <div className="container">
        <h2 className="heading-main">{name}</h2>
        <div className="chart">
          {pathName === "/patientaccessrequest/patientaccessrequests" ? (
            <>
              <div className="d-flex row justify-content-between">
                <div className="col-sm-5 col-lg-3 mb-3">
                  <LookBackFilter
                    onChange={handleLookBack}
                    options={lookBackOptions}
                  />
                </div>
                {loading ? (
                  <div className="col loader mb-3">
                    <Loader />
                  </div>
                ) : null}
                <div className="col-sm-5 col-lg-3 mb-3">
                  <SearchBar
                    placeholder="Search by Patient Name or ID"
                    onSearch={() => navigateToPage(1)}
                    onTextChange={handleSearch}
                  />
                </div>
              </div>

              {patientAccessRequest.length === 0 ? (
                !loading ? (
                  <div style={{ textAlign: "center" }}>No Record Found</div>
                ) : null
              ) : (
                <>
                  <ProxyPatientAccessRequestList
                    patientAccessRequest={patientAccessRequest}
                    query={query}
                  />
                </>
              )}
            </>
          ) : (
            <>
              <div className="d-flex row justify-content-between">
                <h4
                  style={{
                    fontSize: "18px",
                    color: "#004877",
                    marginBottom: "2.5%",
                  }}
                >
                  My Recent Requests{" "}
                  <span style={{ marginLeft: "10px" }}>
                    <Button
                      style={{
                        backgroundColor: "white",
                        color: "#212529",
                        border: "1px solid #ced4da",
                        borderRadius:"100px"
                      }}
                      onClick={newPatientAccessRequest}
                    >
                      New{" "}
                      <span style={{ fontSize: "10px", paddingRight: "none" }}>
                        {" "}
                        <i className="fa fa-chevron-right"></i>
                        <i className="fa fa-chevron-right"></i>
                      </span>
                    </Button>{" "}
                  </span>
                </h4>
                <div className="col-sm-5 col-lg-3 mb-3">
                  <LookBackFilter
                    onChange={handleLookBack}
                    options={lookBackOptions}
                  />
                </div>
                {loading ? (
                  <div className="col loader mb-3">
                    <Loader />
                  </div>
                ) : null}
                <div className="col-sm-5 col-lg-3 mb-3">
                  <SearchBar
                    placeholder="Search by Patient Name"
                    onSearch={() => navigateToPage(1)}
                    onTextChange={handleSearch}
                  />
                </div>
              </div>

              {patientAccessRequest.length === 0 ? (
                !loading ? (
                  <div style={{ textAlign: "center" }}>No Record Found</div>
                ) : null
              ) : (
                <>
                  <PatientAccessRequestList
                    patientAccessRequest={patientAccessRequest}
                    query={query}
                  />
                </>
              )}
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default PatientAccessRequests;
