import { FC, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { useOktaAuth } from "@okta/okta-react";
import useUserInfo from "../../hooks/useUserInfo";
import AnnouncementService from "../../services/AnnouncementService";
import IAnnouncementBanner from "../../types/IAnnouncementBanner";
import IAnnouncementBannerHeader from "../../types/IAnnouncementBannerHeader";
import axios from "axios";
import { getFormattedDateFromDate } from "../../helpers/DateHelper";
import { Alert } from "react-bootstrap";

const DisplayBanner: FC<{ count?: number }> = ({ count }) => {
  const { authState } = useOktaAuth();
  const accessToken = authState?.accessToken?.accessToken;
  const history = useHistory();
  const cancelToken = axios.CancelToken.source();
  const [shouldRender, setShouldRender] = useState(false);

  const [announcementData, setAnnouncementData] = useState<
    IAnnouncementBanner[]
  >([]);
  const [displayBanner, setDisplayBanner] = useState<
    IAnnouncementBannerHeader | undefined
  >();
  const [error, setError] = useState<Error | null>(null);
  const [varient, setVarient] = useState("");

  let todayDate = new Date();

  useEffect(() => {
    setError(null);
    setShouldRender(false);
    AnnouncementService.getAnnouncementServiceList(accessToken)
      .then((response) => {
        setAnnouncementData(response.data);
        setShouldRender(true);
        setBanner(response.data);
      })
      .catch((e: Error) => {
        console.error(e);
        setError(e);
      });
    return () => {
      cancelToken.cancel();
    };
  }, [authState, count]);

  const setBanner = async (data: any) => {
    let todayBanners = await data?.filter((announce: any) => {
      return isValidBanner(announce);
    });

    if (todayBanners?.length == 0) {
      return;
    }
    let recentModified = todayBanners[0];
    todayBanners?.forEach((banner: any) => {
      let modifiedOn = new Date(banner.modifiedOn);
      let recentModifiedDate = new Date(recentModified.modifiedOn);
      if (
        modifiedOn >
        recentModifiedDate
      ) {
        recentModified = banner;
      }
    });
    setDisplayBanner(recentModified);
    let validTo = new Date(recentModified.validTo);
    let differenceTime = validTo.getTime() - todayDate.getTime();

    setTimeout(() => {
      setDisplayBanner(undefined);
    }, differenceTime)
    checkVarient(recentModified);
  };

  const isValidBanner = (announce: any) => {
    let validTo = new Date(announce.validTo);
    let validFrom = new Date(announce.validFrom);

    return (
      todayDate <=
      validTo &&
      todayDate >=
      validFrom
    );
  };

  const checkVarient = (announce: any) => {
    if (announce.cssClassId === 1) {
      setVarient("success");
    } else if (announce.cssClassId === 2) {
      setVarient("info");
    } else if (announce.cssClassId === 3) {
      setVarient("danger");
    } else if (announce.cssClassId === 4) {
      setVarient("warning");
    } else {
      setVarient("");
    }
  };

  return (
    <>
      {displayBanner ? (
        <Alert
          key={displayBanner.id}
          variant={varient}
          style={{ marginBottom: "0px" }}
        >
          {displayBanner?.message}
        </Alert>
      ) : (
        <></>
      )}
    </>
  );
};
export default DisplayBanner;
