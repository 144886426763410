import { FC, useEffect, useState } from "react";
import HeaderName from "../../headerName/HeaderName";
import {
  Badge,
  OverlayTrigger,
  Button,
  Table,
  Col,
  Card,
  NavDropdown,
  Form,
} from "react-bootstrap";
import { useHistory, useLocation, Link } from "react-router-dom";
import { useOktaAuth } from "@okta/okta-react";
import EducationService from "../../../services/EducationService";
import axios from "axios";
import Loader from "../../common/Loader";
import IEducation from "../../../types/IEducation";
import Spinner from "../../spinner/Spinner";
import ResourceService from "../../../services/ResourceService";
import IResources from "../../../types/IResources";
import IMentalHealth from "../../../types/IMentalHealth";
import ICareNetworkTeam from "../../../types/ICareNetworkTeam";
import IMemberDirectory from "../../../types/IMemberDirectory";
import IClinicalPathways from "../../../types/IClinicalPathways";
import IPatientFamilyEducation from "../../../types/IPatientFamilyEducation";

import Infowell from "../home/Infowell";
import HomeService from "../../../services/HomeService";
import useInfowells from "../../../hooks/useInfowells";

import { appInsights } from "../../../appInsights";

const sortable = require("../../../assets/images/sortable.png").default;

const Resources: FC<{
  name?: string;
}> = ({ name }) => {
  const history = useHistory();

  const handleOnClick = () => {
    history.push("/education/pgrinfowells");
  };
  const handleClick = () => {
    history.push("/education/cmeinfowells");
  };
  const [id, setId] = useState();
  const { authState } = useOktaAuth();
  const accessToken = authState?.accessToken?.accessToken;
  const cancelToken = axios.CancelToken.source();
  const [loading, setLoading] = useState(true);
  const [infowells, setInfowells] = useInfowells();
  const [resourceData, setResourceData] = useState<IResources>();
  const [memberDirectoryData, setMemberDirectoryData] =
    useState<IMemberDirectory[]>();
  const [careNetworkData, setCareNetworkData] = useState<ICareNetworkTeam[]>();
  const [clinicalPathwayData, setClinicalPathwayData] =
    useState<IClinicalPathways[]>();

    const [patientfamilyData, setPatientfamilyData] = useState<IPatientFamilyEducation[]>();
  const [mentalhealthData, setMentalhealthData] = useState<IMentalHealth[]>();

  const [error, setError] = useState<Error | null>(null);
  const handleOnChange = (e: any) => {
    var value = e.target.value;
    setId(value);
  };
  useEffect(() => {
    setLoading(true);
    setError(null);
    ResourceService.getResourceService(accessToken)
      .then((response) => {
        setLoading(false);
        setResourceData(response.data);
      })
      .catch((e: Error) => {
        if (!axios.isCancel(e)) {
          console.error(e);
          appInsights.trackException({ error: e });
           setError(e);
        }
      
        setLoading(false);
      });
    return () => {
      cancelToken.cancel();
    };
  }, []);
  useEffect(() => {
    setLoading(true);
    HomeService.getInfowells(accessToken)    
      .then((response) => {
        setInfowells(response.data);
        setLoading(false);
      })
      .catch((e) => {
        if (!axios.isCancel(e)) {
          console.error(e);
          appInsights.trackException({ error: e });
           setError(e);
        }
       
        setLoading(false);
      });
      return () => {
        cancelToken.cancel();
      };
  }, [accessToken]);
  if (resourceData != undefined) {
  }
  return (
    <div>
      <HeaderName name={name} />
      <div className="container">
        {" "}
        <h2 className="heading-main">{name}</h2>
        <br />
        <Col
          sm={{ span: 7, offset: 3 }}
          className="d-flex justify-content-center"
        >
          {loading && <Spinner margin="5rem 0" />}
        </Col>
        <br />
        <div className="row no-gutters p-2 m-0">
          {resourceData?.infoWells.map((resource) => {
            return (
              <Infowell
              infowell={resource}
              likedInfowells={infowells}/>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default Resources;
