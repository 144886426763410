import { Form, Col } from "react-bootstrap";
import IReferralFormField from "../../types/IReferralFormField";
import { getFormattedDate } from "../../helpers/DateHelper";
import { FC, useEffect, useState } from "react";

const DateTimeInput: FC<{
  onChange: (dt: string) => void;
  label: string;
  required?: boolean;
  defaultTime: string;
  defaultDate: string;
  from:string;

}> = ({ onChange, label, required, defaultTime,defaultDate,from}) => {
  const MAX_DATE = getFormattedDate(new Date());
  const [dateTime1, setDateTime1] = useState(defaultDate);
  const [dateTime2, setDateTime2] = useState(defaultTime);

  const handleOnChange = (e: any, key: string) => {
    let value: any;
    value = e.target.value;
    if (key === "date") {
      onChange(value + "T" + dateTime2);
      setDateTime1(value);
    } else {
      onChange(dateTime1 + "T" + value);
      setDateTime2(value);
    }
  };
  return (
    <Form.Group className="d-flex row align-items-center form-padding">
      <Form.Label column sm={3} className="pl-0 pr-0">
        {label}
        <span className="p-0 asterik"> *</span>
      </Form.Label>

      <Col sm={4} className="pl-0 pr-0">
        <Form.Control
          // max={MAX_DATE}
          min={getFormattedDate(new Date(from))}
          onChange={(e) => handleOnChange(e, "date")}
          type="date"
          required
          defaultValue={defaultDate}
        />
      </Col>
      <Col sm={4} className="pl-0 pr-0">
        <Form.Control
          onChange={(e) => handleOnChange(e, "time")}
          type="time"
          required
          defaultValue={defaultTime}
        />
      </Col>
    </Form.Group>
  );
};

export default DateTimeInput;
