import { FC } from "react";
import { Modal, Button } from "react-bootstrap";
import MButton from "../../../mButton/MButton";

const CancellationRequestModal: FC<{
  show: boolean;
  name?:string;
  date?:Date;
  handleClose?: () => void;
  reason?: string | null;
}> = ({ show,name,date,handleClose, reason}) => {
  return (
    <Modal show={show} onHide={handleClose} centered>
      <Modal.Header>Cancellation Details</Modal.Header>
      <Modal.Body>
        <>
          <div>
            <span>Cancellation Date - </span>
            <span>{date}</span>
          </div>
          <div>
            <span>Cancelled By  - </span>
            <span>{name}</span>
          </div>
          <div>
            <span>Cancellation Reason - </span>
            <span>{reason}</span>
          </div>
        </>
      </Modal.Body>
      <Modal.Footer>
        <MButton
          width={"fit-content"}
          title="Close"
          variant="secondary"
          onClick={handleClose}
        >
          Close
        </MButton>
      </Modal.Footer>
    </Modal>
  );
};

export default CancellationRequestModal;
