const COMPLIANCE = "myCHLA_Compliance";
const EMBASSY_LEAD = "myCHLA_EmbassyLead";
const EMBASSY_MEMBER = "myCHLA_EmbassyMember";
const GENERAL = "myCHLA_General";
const HEALTH_NETWORK_ADMIN = "myCHLA_HealthNetworkAdmin";
const HEALTH_NETWORK_USER = "myCHLA_HealthNetworkUser";
const HIM = "myCHLA_HIM";
const LOG_IN_AS = "myCHLA_LogInAs";
const PENDING = "myCHLA_Pending";
const PHYSICIAN = "myCHLA_Physician";
const PHYSICIAN_DATA_ADMIN = "myCHLA_PhysicianDataAdmin";
const PRACTICE_ADMIN = "myCHLA_PracticeAdmin";
const PRACTICE_OWNER = "myCHLA_PracticeOwner";
const PROVIDER_RELATIONS = "myCHLA_ProviderRelations";
const READ_ONLY_PMD = "myCHLA_ReadOnlyPMD";
const SECURITY = "myCHLA_Security";
const SERVICE_DESK = "myCHLA_ServiceDesk";
const SYSTEM_ADMIN = "myCHLA_SystemAdmin";

const RoleNames = {
  PHYSICIAN,
  PRACTICE_OWNER,
  SYSTEM_ADMIN,
  PRACTICE_ADMIN,
  GENERAL,
  HEALTH_NETWORK_ADMIN
};

export default RoleNames;
