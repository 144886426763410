import React, { FC } from "react";
import styles from "./Chevron.module.css";

const Chevron: FC<{
  className: "chevron" | "chevron rotate";
  small: boolean;
}> = ({ className, small = false }) => {
  const SIZE = small ? 20 : 24;

  return (
    <div id="chevron">
      <div
        className={
          className === "chevron"
            ? styles.chevron
            : styles.chevron + " " + styles.rotate
        }
      >
        <img
          height={SIZE}
          width={SIZE}
          src={require("../../assets/images/chevron.png").default}
        />
      </div>
    </div>
  );
};

export default Chevron;
