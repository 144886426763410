import { useEffect, useState } from "react";

const getSessionStateValue = (key: string, initialValue: any) => {
  const savedValue = localStorage.getItem(key);
  if (savedValue) return savedValue;
  if (initialValue instanceof Function) return initialValue();
  return initialValue;
};

const useSessionState = <T>(key: string, value: T) => {
  const [state, setState] = useState(() => {
    return getSessionStateValue(key, value);
  });
  useEffect(() => {
    localStorage.setItem(key, state);
  }, [state]);
  return [state, setState];
};

export default useSessionState;
