import { FC, useState } from "react";
import HeaderName from "../../headerName/HeaderName";


const UserPrivacyPolicy: FC<{
    name?: string;
}> = ({ name }) => {
    return (
        <>
            <HeaderName name="User Privacy Policy" />
            <div className="container">
                <h2 className="heading-main">User Privacy Policy</h2>
                <div className="row">
                    <div className="col-md-12">
                        <div className="chart">
                            <p>Children's Hospital Los Angeles respects the privacy of users of its websites, mobile applications, social media channels, and other online-based services (“Online Services”). This User Privacy Policy covers all these Online Services and explains what information is collected about you, how that information may be used, with whom it may be shared, and your choices about such uses and sharing.</p>
                            <p>Please also read Children’s Hospital Los Angeles’ Terms of Use. By using any of the Online Services, you agree to this User Privacy Policy and the Terms of Use.</p>
                            <strong style={{textDecoration: 'underline'}}>WHAT INFORMATION IS COLLECTED</strong>
                            <p>Children’s Hospital Los Angeles may collect personal information (information that can identify you including, but not limited to, your name, phone number, mailing address, e-mail address, username, password, and credit card information) and non-personal information (information that does not identify you including, but not limited to, information about the browser you are using and the pages visited during a single or multiple visits).</p>
                            <strong>Information You Provide</strong>
                            <p>Children's Hospital Los Angeles collects personal information from you as part of online transactions. When you contact Children’s Hospital Los Angeles or fill out online forms while using the Online Services, information is collected to help identify you on return visits to the service, categorize your questions or needs, respond to them, and, if applicable, investigate a breach of the Online Services’ terms and conditions. This information is also used to track potential problems and trends and customize responses to better serve you.</p>
                            <strong>Information From Other People And About Others</strong>
                            <p>Children’s Hospital Los Angeles may collect and store personal and non-personal information that has been provided by other people. For example, if your outside physician refers you to Children’s Hospital Los Angeles, personal information provided by that physician about you may be collected and stored. Children’s Hospital Los Angeles may also collect personal information that you have provided about other individuals. For example, if you submit an appointment request for your child, Children’s Hospital Los Angeles may store your personal information and the personal information of your child.</p>
                            <strong>Information Collected Automatically</strong>
                            <div style={{textDecoration: 'underline'}}>Cookies</div>
                            <div>Cookies are small text files that are stored on your computer or mobile device for record-keeping purposes. Children’s Hospital Los Angeles uses cookies to gather information on what pages you access or visit and what information you provide, such as survey information and online registrations. The use of cookies does not result in the collection of any personal information from you. You may choose to adjust the settings of your web browser to disallow the usage of cookies. However, if you reject cookies from the Online Services, some parts of the Online Services may not work properly.</div>
                            <br/>
                            <div style={{textDecoration: 'underline'}}>Other Technologies</div>
                            <div>Children's Hospital Los Angeles may collect personal and non-personal information about how you use the Online Services including, but not limited to, domain names visited; URLs accessed; URLs of hyperlinked sites from which you came or to which you went; website traffic patterns; searches performed; browsers used; date, frequency, and period of use; server usage statistics; Internet Protocol address; and name of Internet Service Provider. Children’s Hospital Los Angeles also may collect an anonymous identification number if you come to the Online Services from an online advertisement displayed on a third-party website.</div>
                            <br/>
                            <strong style={{textDecoration: 'underline'}}>HOW INFORMATION MAY BE USED</strong>
                            <div><strong>Online Donation Information</strong></div>
                            <p>In order to accept your donation or register you for participation in a fundraising event, Children's Hospital Los Angeles asks you to voluntarily provide personal information. This information is used to process your donation or registration, provide you with a receipt or confirmation of your donation, and communicate with you when necessary.</p>
                            <p>Should you wish, you can provide this information by either of the following methods rather than through the Online Services:</p>

                            <table>
                                <tbody><tr>
                                    <td style={{paddingLeft: '30px'}}>
                                        <span style={{textDecoration: 'underline'}}>Phone:</span>
                                        <br/>
                                        "(800) 817-4543"
                                        <br/>
                                        <br/>
                                        <span style={{textDecoration: 'underline'}}>Mail:</span>
                                        <br/>
                                        "Foundation Department"
                                        <br/>
                                        "Children's Hospital Los Angeles"
                                        <br/>
                                        "4650 Sunset Blvd., Mailstop #29"
                                        <br/>
                                        "Los Angeles, CA 90027"
                                    </td>
                                    </tr></tbody>
                            </table>
                            <br/>
                            <p>Information related to donations made through the Online Services is collected in a database maintained by Children's Hospital Los Angeles and may be used to send you e-mails or mail regarding children health updates, research findings, and other opportunities to support Children's Hospital Los Angeles.</p>
                            <strong>Communications and Subscriptions</strong>
                            <p>Children's Hospital Los Angeles may use your personal information to communicate with you. Whether you continue to receive such communications is your choice. You can request to stop communications by either clicking on the “Unsubscribe” link at the bottom of an e-mail, if available, you receive or by contacting Children’s Hospital Los Angeles through one of the means listed in the below “Contact” section of this User Privacy Policy.</p>
                            <strong>Improvement of Online Services</strong>
                            <p>Information gathered from cookies and related technologies helps Children’s Hospital Los Angeles improve the content and performance of the Online Services for you; offer online features that are helpful to you; customize the content and layout for you; and contact you to build awareness about its efforts and charitable endeavors. This information also helps diagnose problems with the Online Services.</p>
                            <strong>
                            Mobile Services
                            </strong>
                            <p>Information gathered from the download or use of any of its mobile applications is sent to Children’s Hospital Los Angeles. This information may be traceable back to you and may be used to identify you. This information may be used for any reasons deemed appropriate by Children’s Hospital Los Angeles including, but not limited to, internal evaluation of mobile services, quality control, and the development of additional supportive mobile-enabled services for product and service improvement.</p>
                            <strong>User-Submitted Content (Blogs, Bulletin Boards, Reviews, Profiles, etc.)</strong>
                            <p>Information that you submit using Children’s Hospital Los Angeles’ blog, social media sites, or other online channels should be considered public. Your posts may appear on other websites as the results of searches or through the actions of others who find your content compelling and wish to promote it on the web. Whenever you voluntarily provide personal information on publicly viewable web pages, ¬¬¬that information will be publicly available and can be collected and used by others (for example, if you share your phone number publicly, you may receive unwanted phone calls). Children’s Hospital Los Angeles cannot control who reads your postings or what others may do with the information you post, so please exercise discretion and caution with respect to your personal information. Once you have posted information, you may not be able to edit or delete such information. For more information about its social media terms and conditions, review Children’s Hospital Los Angeles’ Social Media Use policy.</p>
                            <strong>Online Comments, Surveys, and Feedback Forms</strong>
                            <p>You may be asked to complete surveys, provide comments, or complete feedback forms when using the Online Services. Children's Hospital Los Angeles uses such information to better understand your needs and preferences and to gather information about health care trends and issues.</p>
                            <strong>Job Applications</strong>
                            <p>If you submit personal information to Children’s Hospital Los Angeles through the Online Services to apply for a job, the information will be used in connection with your application. Even if you are not hired, Children’s Hospital Los Angeles may retain your personal information to contact you for any other positions and to help advocate for efforts by Children’s Hospital Los Angeles to protect the health of children.</p>
                            <strong style={{textDecoration: 'underline'}}>WHO INFORMATION MAY BE SHARED WITH</strong>
                            <p>Children’s Hospital Los Angeles wants you to understand when and with whom it may share your personal and non-personal information. Your information is never sold or rented to others. Children’s Hospital Los Angeles does not share your personal information with others except as indicated below.</p>
                            <strong>E-mails and Messages</strong>
                            <p>E-mails and other types of messages that you send to Children's Hospital Los Angeles via the Online Services may be shared with a customer service representative, medical expert, or other representative of Children's Hospital Los Angeles who may or may not be an employee. E-mails that do not require a response are discarded. E-mails that require a response are, once addressed, either archived or deleted, depending on the nature of the inquiry.</p>
                            <strong>Mailing Lists</strong>
                            <p>Children’s Hospital Los Angeles may occasionally allow other nonprofit organizations to send mail to its donors. Children’s Hospital Los Angeles carefully screens these organizations and only permits mailings from those nonprofit organizations whose services it believes may be of interest to its supporters. If you do not want to receive such mailings, please contact (800) 817-4543 or foundation@chla.usc.edu to opt out of future mailings.</p>
                            <strong>Service Providers</strong>
                            <p>Children's Hospital Los Angeles may share information with third-party service providers who perform data management or other services on its behalf to allow better service to you and other users. Personal information shared with these third-party service providers is closely monitored and is only shared as necessary. These third-party service providers are obligated to Children’s Hospital Los Angeles not to disclose or use your information for other purposes. Children’s Hospital Los Angeles may allow these authorized service providers to serve cookies from the Online Services to allow them to perform various services, such as doing analysis and research on the effectiveness of the Online Services, content, and advertising. Children’s Hospital Los Angeles may share with these service providers your financial information or payment method to process payment for any donations or other transactions you have authorized.</p>
                            <strong>Analytics</strong>
                            <p>By using Children’s Hospital Los Angeles’ Online Services, your traffic and browsing patterns are subject to the same level of scrutiny, measurement, cataloguing, and other information gathering that occurs across the Internet via the efforts of third parties such as Google, Bing, and Facebook. Children’s Hospital Los Angeles does not prevent, and is not responsible for, such third parties’ access to the browsing history you develop on the web through your personal use and interactions online.</p>
                            <strong>Other Situations</strong>
                            <p>Children’s Hospital Los Angeles may share your information in response to a subpoena or similar investigative demand, a court order, or a request for cooperation from law enforcement or any government agency; to establish or exercise its legal rights, including, but not limited to, efforts to investigate, prevent, or take other action regarding illegal activity, suspected fraud, or other wrongdoing; to protect and defend the rights, property, or safety of Children’s Hospital Los Angeles, its patients, employees, or others; to enforce the Online Services’ terms and conditions or other agreements and policies; or as otherwise required by law. In such cases, Children’s Hospital Los Angeles may raise or waive any legal objection or right available to it.</p>
                            <p>Your information may also be shared in connection with a substantial corporate transaction including, but not limited to, the sale of Children’s Hospital Los Angeles’ business, a divestiture, merger, consolidation, asset sale, bankruptcy, change in control of the company or one of its affiliates, or in preparation for any of these events. Any third party to which Children’s Hospital Los Angeles transfers or sells its assets will have the right to continue to use your personal and non-personal information in accordance with this User Privacy Policy.</p>
                            <strong style={{textDecoration: 'underline'}}>HOW CHILDREN’S HOSPITAL LOS ANGELES PROTECTS YOUR PERSONAL INFORMATION</strong>
                            <p>Children’s Hospital Los Angeles has security measures in place to protect against the loss, misuse, and alteration of your personal information under its control. The personal information you provide on the Online Services is stored on secure servers, and access to change or edit this information is restricted to those authorized by Children's Hospital Los Angeles.</p>
                            <p>E-mail should not be considered a secure and confidential means of communication. It is possible that your e-mail communication may be accessed or viewed by another Internet user while in transit to Children's Hospital Los Angeles. If you wish to keep your communication private, DO NOT E-MAIL ANY CONFIDENTIAL INFORMATION. IF YOU DO SO, IT IS AT YOUR OWN RISK.</p>  
                            <strong style={{textDecoration: 'underline'}}>YOUR CHOICES ABOUT COLLECTION AND USE OF INFORMATION</strong> 
                            <p>You can choose not to provide Children’s Hospital Los Angeles with certain information, but that may result in your being unable to use certain features of the Online Services such as registering, referring a patient, scheduling an appointment, participating in a survey, asking a question online, and initiating transactions.</p>  
                            <strong  style={{textDecoration: 'underline'}}>DO NOT TRACK SIGNALS</strong>
                            <p>Children’s Hospital Los Angeles’ Online Services do not recognize automated browser signals regarding "do not track" instructions. Please note that third parties may collect personal information about your online activities over time and across different websites when you use the Online Services or browse other websites. For more information about "do not track" signals, visit www.allaboutdnt.com.</p>
                            <strong style={{textDecoration: 'underline'}}>THIRD-PARTY WEBSITES</strong>
                            <p>Children’s Hospital Los Angeles’ Online Services may contain links to websites of outside organizations. While these links may indicate special relationships between the organizations, Children's Hospital Los Angeles is not responsible for the privacy practices of websites of these organizations. Please familiarize yourself with the privacy policies of any website you visit to determine what information is being collected.</p>
                            <strong style={{textDecoration: 'underline'}}>VISITING ONLINE SERVICES FROM OUTSIDE THE UNITED STATES</strong>
                            <p>This User Privacy Policy is intended to cover the collection of information on the Online Services from residents of the United States. If you are using the Online Services from outside the United States, please be aware that your information may be transferred to, stored, and processed in the United States, where Children’s Hospital Los Angeles’ servers are located. The data protection and other laws of the United States might not be as comprehensive as those in your country. By using the Online Services, you understand that your information may be transferred to Children’s Hospital Los Angeles’ facilities and third parties as described in this User Privacy Policy.</p>
                            <strong style={{textDecoration: 'underline'}}>CONTACT</strong>
                            <p>If you wish to change your personal information or stop communication from Children’s Hospital Los Angeles, or you have questions about this User Privacy Policy, do one of the following:</p>
                            <ul>
                                <li style={{listStyle:'disc'}}>Send an e-mail to: webmaster@chla.usc.edu
                                <br/>
                                <br/>
                                </li>

                                <li>
                                    Write to the following address:
                                    <br/>
                                    Webmaster
                                    <br/>
                                    Children's Hospital Los Angeles
                                    <br/>
                                    4650 Sunset Blvd., Mailstop #110
                                    <br/>
                                    Los Angeles, CA 90027
                                    </li>
                            </ul>
                            <strong style={{textDecoration: 'underline'}}>CHANGES TO THIS USER PRIVACY POLICY</strong>
                            <p>Children's Hospital Los Angeles may change this User Privacy Policy from time to time without notice. The most recent revision date is listed below and the posted version of this User Privacy Policy will apply to your use of the Online Services.</p>
                            <br/>
                            <p>Effective Date: July 8, 2014</p>

                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default UserPrivacyPolicy;