import { FC, useEffect, useState } from "react";
import { useOktaAuth } from "@okta/okta-react";
import { Modal } from "react-bootstrap";
import MButton from "../../../mButton/MButton";
import SearchBar from "../../../common/SearchBar";
import axios from "axios";
import PhysicianService from "../../../../services/PhysicianService";
import IPhysicianSearchResult from "../../../../types/IPhysicianSearchResult";
import Loader from "../../../common/Loader";
import { Field, Formik, Form as FormikForm, ErrorMessage } from "formik";
import CustomInputFormik from "../../../common/CustomInputFormik";
import IPracticeEdit, { PracticeLocation, PracticePhysician } from "../../../../types/IPracticeEdit";
import { appInsights } from "../../../../appInsights";
import * as Yup from 'yup'

type physicianContact = {
  contactName: string;
  email: string;
  phone: string;
  phoneExt: string;
  fax: string;
  department: string;
};

const AdminEditSearchPhysicianModal: FC<{
  show: boolean;
  handleClose: () => void;
  handleAdd: (practicePhysician: PracticePhysician, name: string) => void;
  practiceId:number | undefined
  practices:IPracticeEdit
  selectedEditPhysician? : PracticePhysician 
}> = ({ show, handleClose, handleAdd,practiceId,practices,selectedEditPhysician }) => {
  const { authState } = useOktaAuth();
  const accessToken = authState?.accessToken?.accessToken;
  const cancelToken = axios.CancelToken.source();
  const [loading, setLoading] = useState<boolean>(true);
  const [physicianSearchResults, setPhysicianSearchResults] =
    useState<IPhysicianSearchResult[]>();
  const [searchText, setSearchText] = useState("");
  const [searched, setSearched] = useState(0);
  const [renderForm, setRenderForm] = useState(false);
  const [showLocationError, setLocationError] = useState(false);
  const [selectedPhysician, setSelectedPhysician] =
    useState<IPhysicianSearchResult>();
  let initialValue = {
    contactName: selectedEditPhysician !== undefined ? selectedEditPhysician.contactName : "",
    email: selectedEditPhysician !== undefined ? selectedEditPhysician.email : "",
    phone: selectedEditPhysician !== undefined ? selectedEditPhysician.phone : "",
    phoneExt: selectedEditPhysician !== undefined ? selectedEditPhysician.phoneExt : "",
    fax: selectedEditPhysician !== undefined ? selectedEditPhysician.fax : "",
    department: selectedEditPhysician !== undefined ? selectedEditPhysician.department : "",
    location:selectedEditPhysician !== undefined ? selectedEditPhysician.homeLocId : 0
  };

  useEffect(() => {
    setLoading(true);
    PhysicianService.searchPhysician(searchText, accessToken, cancelToken)
      .then((response) => {
        setPhysicianSearchResults(response.data);
        setLoading(false);
      })
      .catch((e) => {
        if (!axios.isCancel(e)) {
          console.error(e);
          appInsights.trackException({ error: e });
          // setError(e);
        }
        setLoading(false);
      });
    return () => {
      cancelToken.cancel();
    };
  }, [searchText, searched]);
  initialValue = {
    contactName: selectedEditPhysician !== undefined ? selectedEditPhysician.contactName : "",
    email: selectedEditPhysician !== undefined ? selectedEditPhysician.email : "",
    phone: selectedEditPhysician !== undefined ? selectedEditPhysician.phone : "",
    phoneExt: selectedEditPhysician !== undefined ? selectedEditPhysician.phoneExt : "",
    fax: selectedEditPhysician !== undefined ? selectedEditPhysician.fax : "",
    department: selectedEditPhysician !== undefined ? selectedEditPhysician.department : "",
    location:selectedEditPhysician !== undefined ? selectedEditPhysician.homeLocId : 0
  };
  if (renderForm || selectedEditPhysician !== undefined) {
    return (
      <Modal show={show} onHide={handleClose} centered>
        <Formik
          enableReinitialize
          initialValues={initialValue}
          validationSchema={Yup.object().shape({
            email: Yup.string().email("Email is not valid").max(255).nullable(),
            phone: Yup.string().matches(
              /^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4}$/im,
              "Phone number is not valid"
            ).nullable(),
             fax:  Yup.string().matches(
              /^\+?[0-9]{7,}$/,
              "Fax number is not valid"
            ).nullable(),
            location:Yup.number().moreThan(0,'Please select a location').required('Please select a location')
          })}
          onSubmit={({
            contactName,
            department,
            email,
            fax,
            phone,
            phoneExt,
            location
          }) => {
            if ((selectedPhysician !== undefined || selectedEditPhysician !== undefined) && handleAdd !== undefined && location !== 0) {
             
              if(selectedPhysician !==  undefined){
                handleAdd(
                  {
                    activeRecord: true,
                    id: 0,
                    prctId: practiceId !== undefined ? practiceId : 0,
                    physId: selectedPhysician.id,
                    physicianFirstName: selectedPhysician.firstName,
                    physicianLastName: selectedPhysician.lastName,
                    physicianTitle: selectedPhysician.title,
                    homeLocId: location !== null ? location : 0,
                    contactName : contactName,
                    email: email,
                    phone: phone,
                    phoneExt:phoneExt,
                    fax: fax,
                    department: department,
                    dateCreated: new Date(),
                    createdBy: "",
                    dateModified: new Date(),
                    modifiedBy: "",
                    verified:null,
                    verifiedBy:null,
                    verifiedDate: null
                  },
                  selectedPhysician.lastName +
                  " " +
                  selectedPhysician.firstName +
                  
                  (selectedPhysician.title !== "" && selectedPhysician.title !== null ?
                    `, ${selectedPhysician.title}` : "")
                );
              }
              else if(selectedEditPhysician !== undefined){
                handleAdd(
                  {
                    activeRecord: true,
                    id: selectedEditPhysician.id,
                    prctId: selectedEditPhysician.prctId,
                    physId: selectedEditPhysician.physId,
                    physicianFirstName: selectedEditPhysician.physicianFirstName,
                    physicianLastName: selectedEditPhysician.physicianLastName,
                    physicianTitle: selectedEditPhysician.physicianTitle,
                    homeLocId: location !== null ? location : 0,
                    contactName : contactName,
                    email: email,
                    phone: phone,
                    phoneExt:phoneExt,
                    fax: fax,
                    department: department,
                    dateCreated: selectedEditPhysician.dateCreated,
                    createdBy: selectedEditPhysician.createdBy,
                    dateModified: new Date(),
                    modifiedBy: selectedEditPhysician.modifiedBy,
                    verified:null,
                    verifiedBy:null,
                    verifiedDate: null
                  },
                  selectedEditPhysician.physicianLastName +
                  " " +
                  selectedEditPhysician.physicianFirstName +
                  
                  (selectedEditPhysician.physicianTitle !== "" && selectedEditPhysician.physicianTitle !== null ?
                    `, ${selectedEditPhysician.physicianTitle}` : "")
                );
              }
              setSelectedPhysician(undefined);
              setPhysicianSearchResults(undefined);
              setSearchText("");
              setRenderForm(false);
            }
            else{
              setLocationError(true)
            }
          }}
        >
          {({ touched, errors }) => (
            <FormikForm>
              <Modal.Body>
                <>
                
                  <Field
                  label="Location"
                  name="location"
                  type="typeahead"
                  required={true}
                  itemSelected={true}
                  options={practices!= null ? practices.locations.map((s: PracticeLocation) => ({
                    id: s.id,
                    display: s.address1 + " " + s.address2 + " " + s.zip,
                  })):null}
                  placeholder=""
                  
                  component={CustomInputFormik}
                  />
                  
                  <Field
                    label="Contact Person's Name"
                    name="contactName"
                    required={false}
                    type="text"
                    component={CustomInputFormik}
                  // error={touched.contactName && }
                  />
                  <Field
                    label="Contact's Phone"
                    name="phone"
                    required={false}
                    type="text"
                    component={CustomInputFormik}
                  />
                  <Field
                    label="Ext"
                    name="phoneExt"
                    required={false}
                    type="text"
                    component={CustomInputFormik}
                  />
                  <Field
                    label="Contact's Email"
                    name="email"
                    required={false}
                    type="email"
                    component={CustomInputFormik}
                  />
                  <Field
                    label="Contact's Fax"
                    name="fax"
                    required={false}
                    type="text"
                    component={CustomInputFormik}
                  />
                  <Field
                    label="Department"
                    name="department"
                    required={false}
                    type="text"
                    component={CustomInputFormik}
                  />
                </>
              </Modal.Body>
              <Modal.Footer>
                <MButton
                  onClick={() => {
                    handleClose();
                    setSelectedPhysician(undefined);
                    setPhysicianSearchResults(undefined);
                    setSearchText("");
                    setRenderForm(false);
                  }}
                  variant="secondary"
                  title={"Cancel"}
                  width={"max-content"}
                />
                <MButton
                  variant="primary"
                  title={selectedEditPhysician !== undefined ? "Save": "Add"}
                  width={"max-content"}
                  type="submit"
                />
              </Modal.Footer>
            </FormikForm>
          )}
        </Formik>
      </Modal>
    );
  } else {
    return (
      <Modal show={show} onHide={handleClose} centered>
            <Modal.Footer>
          <MButton
            onClick={() => {
              handleClose();
              setSelectedPhysician(undefined);
              setPhysicianSearchResults(undefined);
              setSearchText("");
              setRenderForm(false);
            }}
            variant="secondary"
            title={"Cancel"}
            width={"max-content"}
          />
          <MButton
            disabled={selectedPhysician === undefined}
            onClick={() => {
              setRenderForm(true);
            }}
            variant="primary"
            title="Next"
            width={"max-content"}
          />
        </Modal.Footer>
        <Modal.Body>
          <>
          
            <div className="d-flex align-items-center">
              <div style={{ width: "100%" }}>
                <SearchBar
                onTextChange={(text) => setSearchText(text)}
                  placeholder="Search for Physician Name or NPI"
                  onSearch={() => {
                    setSearched((prev) => prev + 1);
                  }}
                />
              </div>
              <div>
                <MButton
                  small
                  variant="secondary"
                  title="Clear"
                  width={"fit-content"}
                  onClick={() => {
                    setPhysicianSearchResults(undefined);
                    setSelectedPhysician(undefined);
                  }}
                />
              </div>
            </div>

            {loading && (
              <div style={{ marginTop: "1rem" }}>
                <Loader />
              </div>
            )}
            {physicianSearchResults?.length !== 0 ? (
              <div style={{ marginTop: 8 ,cursor:'pointer'}}>
                {physicianSearchResults?.map((physician) => {
                  return (
                    <>
                      <div
                        style={{
                          margin: "4px 12px"
                          ,backgroundColor: selectedPhysician == physician ? '#0d9ddb': 'white',
                          color:selectedPhysician == physician ? '#fff': '#000'
                        }}
                        onClick={() => {
                          setSelectedPhysician(physician);
                        }}
                      >
                        {physician.lastName} {physician.firstName}
                        {physician.midName !== "" && physician.midName !== null ? ` ${physician.midName}` : ""}
                        {physician.title !== "" && physician.title !== null ? `, ${physician.title}` : ""}
                      </div>
                      <hr />
                    </>
                  );
                })}
              </div>
            ) : (
              <div>No record found</div>
            )}
            
          </>
        </Modal.Body>
    
      </Modal>
    );
  }
};

export default AdminEditSearchPhysicianModal;
