import { CancelTokenSource } from "axios";
import { customAxios as axios } from "./ServiceConfig";
import { API_BASE_URL } from "../config";
import IPatient, { IPatientResponse } from "../types/IPatient";
import IPatientEncounters from "../types/IPatientEncounters";
import IPatientQuery from "../types/IPatientQuery";
import { IPracticeAppointment } from "../types/IPracticeAppointment";
let loginas = localStorage.getItem('loginAsFlag')
let loginasEmail = localStorage.getItem('loginAsEmail')
/**
 * @param action receives the action name
 * @param lookback receives the number of days
 * @param token receives the access token of type string
 */
const getPatients = async (
  action: "viewpatients" | "sharedpatients" | "mypatients",
  query:IPatientQuery,
  token?: string,
  cancelToken?: CancelTokenSource
) => {
  const result = await axios.get<IPatientResponse>(
    `${API_BASE_URL}/patient/${action}?PageSize=${query.PageSize}&SearchText=${query.SearchText}&Lookback=${query.Lookback}&PageNumber=${query.PageNumber}`,
    loginas !== null ? {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Pragma: "no-cache",
        Authorization: `Bearer ${token}`,
        loginas:loginas !== null ? loginas : JSON.stringify(false),
        email:loginasEmail !== null ? loginasEmail : "",
      },
      cancelToken: cancelToken?.token,
    }:{
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Pragma: "no-cache",
        Authorization: `Bearer ${token}`,
      },
      cancelToken: cancelToken?.token,
    }
  );
  return result;
};

/**
 * @param id receives the personId
 * @param token receives the access token of type string
 */
const getPatientById = async (
  id: number,
  token?: string,
  cancelToken?: CancelTokenSource
) => {
  const result = await axios.get(`${API_BASE_URL}/patient/${id}`, loginas !== null ? {
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Pragma: "no-cache",
      Authorization: `Bearer ${token}`,
      loginas:loginas !== null ? loginas : JSON.stringify(false),
      email:loginasEmail !== null ? loginasEmail : "",
    },
    cancelToken: cancelToken?.token,
  }:{
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Pragma: "no-cache",
      Authorization: `Bearer ${token}`,
    },
    cancelToken: cancelToken?.token,
  });
  return result;
};

/**
 * @param id receives the personId
 * @param token receives the access token of type string
 */
const getPatientEncounters = async (
  id: string,
  token?: string,
  cancelToken?: CancelTokenSource
) => {
  const result = await axios.get<IPatientEncounters>(
    `${API_BASE_URL}/patient/${id}/encounters`,
    loginas !== null ? {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Pragma: "no-cache",
        Authorization: `Bearer ${token}`,
        loginas:loginas !== null ? loginas : JSON.stringify(false),
        email:loginasEmail !== null ? loginasEmail : "",
      },
      cancelToken: cancelToken?.token,
    }:{
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Pragma: "no-cache",
        Authorization: `Bearer ${token}`,
      },
      cancelToken: cancelToken?.token,
    }
  );
  return result;
};
/**
 * @param date receives the start Date of the query
 * @param token receives the access token of type string
 */
const getPracticeAppointments = async (
  date: string = "02/01/2022",
  token?: string
) => {
  const result = await axios.get<IPracticeAppointment>(
    `${API_BASE_URL}/patient/practiceappointments?startdate=${date}`,
    loginas !== null ? {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Pragma: "no-cache",
        Authorization: `Bearer ${token}`,
        loginas:loginas !== null ? loginas : JSON.stringify(false),
        email:loginasEmail !== null ? loginasEmail : "",
      },
    }:{
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Pragma: "no-cache",
        Authorization: `Bearer ${token}`,
      },
    }
  );
  return result;
};
/**
 * @param date receives the start Date of the query
 * @param token receives the access token of type string
 */
const getMyAppointments = async (
  date: string = "02/01/2022",
  token?: string
) => {
  const result = await axios.get<IPracticeAppointment>(
    `${API_BASE_URL}/patient/myappointments?startdate=${date}`,
    loginas !== null ? {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Pragma: "no-cache",
        Authorization: `Bearer ${token}`,
        loginas:loginas !== null ? loginas : JSON.stringify(false),
        email:loginasEmail !== null ? loginasEmail : "",
      },
    }:{
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Pragma: "no-cache",
        Authorization: `Bearer ${token}`,
      },
    }
  );
  return result;
};
/**
 * @param id receives the personId
 * @param token receives the access token of type string
 */
const hideOrSharePatient = async (
  id: string,
  pid: string,
  token?: string,
  cancelToken?: CancelTokenSource
) => {
  const result = await axios.post(
    `${API_BASE_URL}/patient/hideorshare`,
    {
      patientId: id,
      providerId: pid,
    },
    loginas !== null ? {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Pragma: "no-cache",
        Authorization: `Bearer ${token}`,
        loginas:loginas !== null ? loginas : JSON.stringify(false),
        email:loginasEmail !== null ? loginasEmail : "",
      },
      cancelToken: cancelToken?.token,
    }:{
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Pragma: "no-cache",
        Authorization: `Bearer ${token}`,
      },
      cancelToken: cancelToken?.token,
    }
  );
  return result;
};

/**
 * @param data receives the post body
 * @param token receives the access token of type string
 */
const removePatient = async (
  data: {
    patientName: string;
    providerName: string;
    patientId: string;
    providerId: string;
    dob:string;

  },
  token?: string,
  cancelToken?: CancelTokenSource
) => {
  const result = await axios.post(`${API_BASE_URL}/patient/remove`, data, loginas !== null ? {
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Pragma: "no-cache",
      Authorization: `Bearer ${token}`,
      loginas:loginas !== null ? loginas : JSON.stringify(false),
      email:loginasEmail !== null ? loginasEmail : "",
    },
    cancelToken: cancelToken?.token,
  }:{
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Pragma: "no-cache",
      Authorization: `Bearer ${token}`,
    },
    cancelToken: cancelToken?.token,
  });
  return result;
};

const PatientService = {
  getPatients,
  getPatientById,
  getPatientEncounters,
  hideOrSharePatient,
  removePatient,
  getPracticeAppointments,
  getMyAppointments,
};

export default PatientService;
