import { FC } from "react";
import { FutureVisit } from "../../../../types/IPatient";
import { Modal } from "react-bootstrap";
import MButton from "../../../mButton/MButton";

const DeleteConfirmationModal: FC<{
  show: boolean;
  handleClose?: () => void;
  handleConfirm?: () => void;
}> = ({ show, handleClose, handleConfirm }) => {
  return (
    <Modal size="lg" show={show} onHide={handleClose} centered>
      <Modal.Header>Remove patient from your list</Modal.Header>
      <Modal.Body>
        <div>
          <p>
            OK to remove this patient from your list and notify CHLA of the
            incorrect patient assignment?
          </p>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <MButton
          onClick={handleConfirm}
          variant="danger"
          title="Remove"
          width={"max-content"}
        />
        <MButton
          onClick={handleClose}
          variant="secondary"
          title="Cancel"
          width={"max-content"}
        />
      </Modal.Footer>
    </Modal>
  );
};

export default DeleteConfirmationModal;
