import { FC, useEffect, useState, useRef, useCallback } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { useOktaAuth } from "@okta/okta-react";
import { Button } from "react-bootstrap";
import HeaderName from "../../headerName/HeaderName";
import useScrollPosition from "../../../hooks/useScrollPosition";
import useSessionState from "../../../hooks/useSessionState";
import ILookbackOption from "../../../types/ILookbackOption";
import Loader from "../../common/Loader";
import IRouteState from "../../../types/IRouteState";
import IAllAccounts from "../../../types/IAllAccounts";
import SearchBar from "../../common/SearchBar";
import IPatientAccessQuery from "../../../types/IPatientAccessQuery";
import PatientAccessRequestList from "../myPatients/components/PatientAccessRequestList";
import AllAccountsList from "../allAccounts/components/AllAccountsList";
import AccountService from "../../../services/AccountService";
import LookBackFilter from "../../common/LookBackFilter";
import axios from "axios";
import { appInsights } from "../../../appInsights";

const AllAccounts: FC<{ name?: string }> = ({ name }) => {
  const { authState } = useOktaAuth();
  const accessToken = authState?.accessToken?.accessToken;

  const [allAccounts, setallAccounts] = useState<IAllAccounts[]>([]);

  const [message, setMessage] = useState(false);
  const [loading, setLoading] = useState(true);

  const [query, setQuery] = useState<IPatientAccessQuery>({
    searchterm: "",
  });

  const [apiCallCount, setApiCallCount] = useState(0);
  const [error, setError] = useState<Error>();
  const location = useLocation<IRouteState>();
  const cancelToken = axios.CancelToken.source();


  useEffect(() => {
    setLoading(true);
    AccountService.getAllAccountsList(query, accessToken, cancelToken)
      .then((response) => {
        if (error !== undefined) {
          setError(undefined);
        }
        console.log(response);
        setallAccounts(response.data);
        setLoading(false);
      })
      .catch((e) => {
        if (!axios.isCancel(e)) {
          console.error(e);
          appInsights.trackException({ error: e });
          setError(e);
        }

        setLoading(false);
      });


    return () => {
      cancelToken.cancel();
    };
  }, [apiCallCount]);

  const navigateToPage = (page: number) => {
    setApiCallCount((c) => c + 1);
    window.scrollTo(0, 0);
  };

  const handleSearch = (searchText: string) => {
    setQuery({
      searchterm: searchText,
    });
  };

  return (
    <div>
      <HeaderName name={name} />
      <div className="container">
        <h2 className="heading-main">{name}</h2>
        <div>

          <div className="col-sm-5 col-lg-3 mb-3">
            <SearchBar
              placeholder="Search by First or Last Name"
              onSearch={() => navigateToPage(1)}
              onTextChange={handleSearch}
            />

          </div>
          <div className="chart">
            <>
              {loading ? (
                <div className="col loader mb-3">
                  <Loader />
                </div>
              ) : null}
              {allAccounts.length === 0 ? (
                !loading ? (
                  <div style={{ textAlign: "center" }}>No Record Found</div>
                ) : null
              ) : (
                <>
                  <AllAccountsList allAccounts={allAccounts} query={query} />
                </>
              )}
            </>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AllAccounts;
