import Roles from "./Roles";
import RouteConfig from "./RouteConfig";
import secrets from '../env.json';

const ISSUER = secrets.REACT_APP_ISSUER;
const CLIENTID = secrets.REACT_APP_CLIENTID;
const API_BASE_URL = secrets.REACT_APP_API_ENDPOINT;

const ROUTES = RouteConfig;
const ROLES = Roles;
const REACT_APP_APPINSIGHTS_KEY = secrets.REACT_APP_APPINSIGHTS_KEY;

const OKTA_AUTH_CONFIG = {
  issuer: ISSUER,
  clientId: CLIENTID,
  redirectUri: window.location.origin + "/implicit/callback",
  scopes: ["openid", "profile", "email"],
  pkce: true,
  disableHttpsCheck: false,
};

export {
  OKTA_AUTH_CONFIG,
  API_BASE_URL,
  ROUTES,
  ROLES,
  REACT_APP_APPINSIGHTS_KEY,
};
