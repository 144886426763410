import { FC, useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import { Typeahead } from "react-bootstrap-typeahead";
import IFormDropdownOption from "../../../../types/IFormDropdownOption";
import MButton from "../../../mButton/MButton";

const AdminCreatePhysicianModal: FC<{
  show: boolean;
  name: string;
  handleClose?: () => void;
  handleSubmit?: (value: IFormDropdownOption) => void;
  options?: any;
}> = ({ show, name, handleClose, handleSubmit, options }) => {

  //
  const [selected, setSelected] = useState();
  const data = [{id: -1,name: "data not available"}]
  const handleOnSubmit = () => {
    if (handleSubmit && selected) {
      handleSubmit({
        // @ts-ignore
        id: selected[0].id,
        // @ts-ignore
        display: selected[0].name,
      });
    }
  };

  useEffect(() => {
  }, [selected]);

  //
  return (
    <Modal size="lg" show={show} onHide={handleClose} centered>
      <Modal.Header closeButton>{name}</Modal.Header>
      <Modal.Body>
        <Typeahead
          id={name}
          placeholder="Please make a selection..."
          options={(options!=undefined || options != null)?options:data}
          labelKey="name"
          // selected={selected}
          onChange={(selected) => {
            // @ts-ignore
            setSelected(selected);
          }}
        />
      </Modal.Body>
      <Modal.Footer>
        <MButton
          title="Add"
          variant="primary"
          type="submit"
          width={"fit-content"}
          onClick={handleOnSubmit}
        />
        <MButton
          title="Cancel"
          variant="secondary"
          type="button"
          width={"fit-content"}
          onClick={handleClose}
        />
      </Modal.Footer>
    </Modal>
  );
};

export default AdminCreatePhysicianModal;
