import { FC, useEffect, useState } from "react";
import { useOktaAuth } from "@okta/okta-react";
import { Redirect, useLocation } from "react-router-dom";
import HeaderName from "../../headerName/HeaderName";
import ReferralService from "../../../services/ReferralService";
import IReferral from "../../../types/IReferral";
import Spinner from "../../spinner/Spinner";
import IReferralDetailed from "../../../types/IReferralDetailed";
import ReferralFormStructure from "../../../data/ReferralFormStructure";
import "./new-referral-wizard.css";
import IReferralFormDropdownData from "../../../types/IReferralFormDropdownData";
import ReferralForm from "./components/ReferralForm";
import IPatient from "../../../types/IPatient";
import { appInsights } from "../../../appInsights";
import ReferralFormState from "../../../context/ReferralFormState";
import { Alert } from "@mui/material";

import axios from "axios";
import {
  getFormattedDateFromDateTime,
} from "../../../helpers/DateHelper";
import BackButton from "../../backButton/BackButton";
import IFormDropdownOption from "../../../types/IFormDropdownOption";

type stateType =
  | number
  | IReferral
  | {
    state: {
      patient: IPatient;
    };
  };

let initialFormValues: IReferralDetailed | {} = {};
ReferralFormStructure.forEach((r) => {
  let t: {
    [x: string]: string | number | null;
  } = { [r.name]: "" };
  if (r.initialValue) {
    t = { [r.name]: r.initialValue };
  }
  Object.assign(initialFormValues, t);
});
const NewReferralWizard: FC<{ name?: string }> = ({ name }) => {
  const location = useLocation();
  const isDisabled = location.pathname.toLowerCase().includes("editreferral");
  const [initialValues, setInitialValues] = useState<{} | IReferralDetailed | {dateOfBirth: string, guardianDob: string ,state: {patient: IPatient}}>(initialFormValues);
  const { authState } = useOktaAuth();
  const accessToken = authState?.accessToken?.accessToken;
  const [referralFormDropdownData, setReferralFormDropdownData] =
    useState<IReferralFormDropdownData>();
  const { state } = useLocation<stateType>();
  const [redirect, setRedirect] = useState({ redirect: false, message: "" });
  const states: IFormDropdownOption[] =
  require("../../../data/states.json").map((s: any) => ({
    id: s.abbreviation,
    display: s.abbreviation,
  }));
  const [referral, setReferral] = useState<IReferral>(); 
  const [route, setRoute] = useState<string>("");
  
  useEffect(() => {
    const path = window.location.href.split('/').reverse()[0];
    setRoute(path);
  }, []);
  
  useEffect(() => {
    if (route == "newreferral") setInitialValues({});
    if(typeof state !== "number" && state !== undefined){
      setInitialValues({
        ...state,
        dateOfBirth: getFormattedDateFromDateTime(
          'state' in state ? state.state.patient.dob : state.dateOfBirth
        ),
        guardianDob: getFormattedDateFromDateTime('state' in state ? undefined : state.guardianDob)
      });
    }

    ReferralService.getReferralFormDataList(accessToken)
      .then((response) => {
        setReferralFormDropdownData(response.data);
      })
      .catch((e) => {
        if (!axios.isCancel(e)) {
          console.error(e);
          appInsights.trackException({ error: e });
        }
      });

  }, [route])
  const getReferralData = () : IReferral | undefined => {
    
    if (route === "newreferral") return undefined;
    
    if (route === "refer-a-patient" && ("state" in initialValues)) {
      const referralInfo : {dateOfBirth: string, guardianDob: string ,state: {patient: IPatient}} = initialValues;
      const patient: IPatient = referralInfo.state.patient;
      const referral: IReferral = {
        cancellationReason: "",
        cernerStatus: "",
        clinicName: "",
        createdDtm: "",
        dateOfBirth: referralInfo.dateOfBirth??"",
        firstName: patient.patientName.split(', ')[1]??"",
        id: -1,
        lastName: patient.patientName.split(', ')[0]??"",
        mrn: patient.mrn.toString()??undefined,
        botMessage: "",
        botStatus: "",
        cancelationRequest: false,
        cernerReferralId: undefined,
        cernerUpdtDtm: undefined,
        clinics: undefined,
        guardianDob: referralInfo.guardianDob??undefined,
        guardianRelationship: undefined,
        guardianRelationshipId: undefined,
        insuranceType: undefined,
        insuranceTypeId: undefined,
        modifyBy: undefined,
        modifyDtm: undefined,
        parentReferralId: undefined,
        patientAcuity: undefined,
        patientSex: patient.sex??undefined,
        patientSexId: undefined,
        patientState: undefined,
        phoneNumber: undefined,
        physicianId: undefined,
        physicianName: patient.physicianName??undefined,
        reason: undefined,
        referralAppointment: undefined,
        requestType: undefined,
        secondaryPhoneNumber: undefined,
        statusId: undefined,
        zipCode: undefined,
      };
      return referral;
    }
    
    if (route === "re-refer") {
      const referral: IReferral = initialValues as IReferral;

      referral.cancellationReason = "";
      referral.cernerStatus = "";
      referral.clinicName = "";
      referral.createdDtm = "";
      referral.id = -1;
      referral.cancelationRequest = false;
      referral.clinicName = "";
      referral.clinics = undefined;
      referral.reason = undefined;

      return referral;
    }

    return undefined;
  }

  // return JSX
  if (
    referralFormDropdownData === undefined ||
    Object.keys(referralFormDropdownData).length === 0
  ) {
    return <Spinner margin="5rem 0" />;
  } else {
    if (redirect.redirect) {
      return (
        <Redirect
          to={{
            pathname: "/referrals/viewreferrals",
            state: { message: redirect.message },
          }}
        />
      );
    } else {
      return (
        <div className="referrals">
          <HeaderName name={name} />
          <BackButton />
          <div className="container">
            <h2 className="heading-main">{name}</h2>
            <div className="referral">
              <div>
                <ReferralFormState>

                  {referralFormDropdownData.practices.length !== 0 ?<ReferralForm
                    initialValues={getReferralData()}
                    onSubmit={(formData) => {
                      return new Promise((resolve, reject) => {
                        if (isDisabled) {
                          ReferralService.editReferral(formData, accessToken).then((response) => {
                            resolve(response)
                            setRedirect({ redirect: true, message: "success" });
                          })
                            .catch((e) => {
                              if (!axios.isCancel(e)) {
                                reject(e)
                                console.error(e);
                                appInsights.trackException({ error: e });
                                // setError(e);
                                setRedirect({ redirect: true, message: "error" });
                              }
                            });
                        } else {
                          ReferralService.createReferral(formData, accessToken)
                            .then((response) => {
                              resolve(response)
                              setRedirect({ redirect: true, message: "success" });
                            })
                            .catch((e) => {
                              if (!axios.isCancel(e)) {
                                reject(e)
                                console.error(e);
                                appInsights.trackException({ error: e });
                                // setError(e);
                                setRedirect({ redirect: true, message: "error" });
                              }
                            });
                        }
                      })
                    }}
                    referralFormDropdownData={referralFormDropdownData}
                    referral = {getReferralData()}
                    isDisabled={isDisabled}
                  />:<Alert severity="error" style={{ color: "#EA1D36" }}>
                  {"Practice not assigned or Practice is deactivated."}
                  </Alert>}
                </ReferralFormState>
              </div>
            </div>
          </div>
        </div>
      );
    }
  }
};

export default NewReferralWizard;
