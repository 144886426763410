import { FC } from "react";
import { Badge } from "react-bootstrap";
import MButton from "../../../mButton/MButton";
import IReferral, { ReferralAppointment } from "../../../../types/IReferral";
import IReferralQuery from "../../../../types/IReferralQuery";
import Loading from "../../../common/Loader";

const ReferralsCard: FC<{
  referrals: IReferral[];
  Loader?: boolean;
  onCancelPress: (
    cancelPressed: boolean,
    clinicName: string,
    referralId: number
  ) => void;
  onCancelRequestPress: (
    requestPressed: boolean,
    name?: string,
    date?: Date,
    reason?: string
  ) => void;
  onReferPress: (referral: IReferral) => void;
  onEditPress?: (referralId: number) => void;
  onCancelledPress: (reason: string) => void;
  onScheduledPress: (reason: ReferralAppointment) => void;

  page?: "MyReferrals" | "SharedReferrals";
}> = ({
  referrals,
  onCancelPress,
  onReferPress,
  onEditPress,
  onCancelRequestPress,
  onCancelledPress,
  onScheduledPress,
  page,
  Loader,
}) => {
  const handleCancelledClick = (status: string, reason: string|ReferralAppointment|null) => {
   
    if (status === "Cancelled" && reason !== null && reason !== "" && typeof reason === "string" ) {
      onCancelledPress(reason);
    }
    else if (status === "Patient Scheduled" && reason !== null && reason !== "" && typeof reason !== "string" ) {
      onScheduledPress(reason);
    }
  };
  return (
    <div>
      {referrals.map((referral) => {
        return (
          <div key={referral.id} id="referrals-card">
            <div data-title="Patient Name" id="table-text-referrals">
              {referral.firstName + " " + referral.lastName}
            </div>
            <div data-title="Referral No.">
              Referral No.: <strong>{referral.id}</strong>
            </div>
            <div data-title="DOB">
              DOB: <strong>{referral.dateOfBirth?.split("T")[0]}</strong>
            </div>
            <div data-title="Clinic">
              Clinic: <strong>{referral.clinicName}</strong>
            </div>
            <div data-title="Created On">
              Created On: <strong>{referral.createdDtm?.split("T")[0]}</strong>
            </div>
            {page === undefined || page === "SharedReferrals" ? (
              <div data-title="Referring Physician">
                <div>Referring Physician:</div>
                <strong>{referral.physicianName}</strong>
              </div>
            ) : null}
            <div data-title="">
              <div>Status:</div>
              <h5>
                <Badge
                  className="hover"
                  bg={
                    referral.cernerStatus === "Scheduled"
                      ? "success"
                      : referral.cernerStatus === "Cancelled"
                      ? "danger"
                      : "secondary"
                  }
                  onClick={() =>
                    handleCancelledClick(
                      referral.cernerStatus,
                      referral.cernerStatus === "Patient Scheduled" && referral.referralAppointment !== undefined 
                      ? referral.referralAppointment:referral.cancellationReason                    )
                  }
                >
                  {referral.cernerStatus}
                </Badge>
              </h5>
            </div>
            {page === "SharedReferrals" ? null : (
              <div data-title="">
                <div id="referrals-card-button-list">
                  {onEditPress != null ? <MButton
                    onClick={() => onEditPress(referral.id)}
                    title="Edit"
                    variant="secondary"
                  />:
                  <span></span>}
                      {Loader ? <Loading />: ( referral.cancelationRequest === true ? (
                          <MButton
                            onClick={() => {
                              onCancelRequestPress(
                                true,
                                referral.modifyBy,
                                referral.modifyDtm,
                                referral.cancellationReason
                              );
                            }}
                            title="Cancellation Requested"
                            color="#EA1D36"
                            //bColor="#EA1D36"
                            small
                            variant="secondary"
                            hidden={
                              referral.cernerStatus === "Cancelled"
                                ? true
                                : false
                            }
                          />
                      ) : (
                        <MButton
                          onClick={() =>
                            onCancelPress(
                              true,
                              referral.clinicName,
                              referral.id,
                            )
                          }
                          title="Request to Cancel"
                          small
                          variant="secondary"
                          hidden={
                            referral.cernerStatus === "Cancelled" ? true : false
                          }
                        />
                      ))}
                  <MButton
                    onClick={() => onReferPress(referral)}
                    title="Refer"
                    variant="accent"
                  />
                </div>
              </div>
            )}
          </div>
        );
      })}
    </div>
  );
};

export default ReferralsCard;
