import { FC } from "react";
import { Modal, Button } from "react-bootstrap";
import { ReferralAppointment } from "../../../../types/IReferral";
import MButton from "../../../mButton/MButton";

const ScheduledReasonModal: FC<{
  show: boolean;
  handleClose?: () => void;
  reason: ReferralAppointment;
}> = ({ show, handleClose, reason }) => {
  return (
    <Modal show={show} onHide={handleClose} centered>
      <Modal.Header>Appointment Details</Modal.Header>
      <Modal.Body>
        <>
          <div>
            <span>Appointment Date - </span>
            <span>{reason.aptDate}</span>
          </div>
          <div>
            <span>Duration - </span>
            <span>{reason.duration}</span>
          </div>
          <div>
            <span>Location - </span>
            <span>{reason.location}</span>
          </div>
          <div>
            <span>Resource - </span>
            <span>{reason.resource}</span>
          </div>
          <div>
            <span>State - </span>
            <span>{reason.state}</span>
          </div>
          <div>
            <span>Type - </span>
            <span>{reason.type}</span>
          </div>
        </>
      </Modal.Body>
      <Modal.Footer>
        <MButton
          width={"fit-content"}
          title="Close"
          variant="secondary"
          onClick={handleClose}
        >
          Close
        </MButton>
      </Modal.Footer>
    </Modal>
  );
};

export default ScheduledReasonModal;
