import { FC } from "react";
import { ILocation } from "../../../../types/IPracticeSearchResult";
import { Table } from "react-bootstrap";
import Loader from "../../../common/Loader";

const AdminLocationsTable: FC<{ locations: ILocation[]; count?: number }> = ({
  locations,
  count,
}) => {
  console.log(count)
  console.log(locations.length)
  if (count != 0) {
    if (locations.length == count) {
      return (
        <div>
          <Table id="lab-table" style={{ margin: 0 }}>
            <thead>
              <tr>
                <th>Address</th>
                <th>City</th>
                <th>State</th>
                <th>Zip</th>
                <th>Contact Person</th>
                <th>Email</th>
                <th>Phone</th>
                <th>Fax</th>
              </tr>
            </thead>
            <tbody>
              {locations.map((location, index) => (
                <tr key={index}>
                  <td id="table-reports-radiology">{location.address}</td>
                  <td>{location.city}</td>
                  <td>{location.state}</td>
                  <td>{location.zip}</td>
                  <td>{location.contactPerson}</td>
                  <td>{location.email}</td>
                  <td>{location.phone}</td>
                  <td>{location.fax}</td>
                </tr>
              ))}
            </tbody>
          </Table>
        </div>
      );
    } else {
      return <Loader />;
    }
  } else {
    return <div>No record found</div>;
  }
};

export default AdminLocationsTable;
