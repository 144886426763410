import { FC } from "react";
import MButton from "../../mButton/MButton";
import HeaderName from "../../headerName/HeaderName";
import { Form } from "react-bootstrap";
import { AccountRequest } from "../../../types/IAccountRequests";
import { useHistory, useLocation } from "react-router-dom";
import { getFormattedDateTime } from "../../../helpers/DateHelper";

const AccountRequestDetails: FC<{
  name?: string;
}> = ({ name }) => {
  
  type stateType = {
    state: AccountRequest;
  };
  const location = useLocation<AccountRequest>();

  const request: AccountRequest = location.state
  const history = useHistory();

  const handleBackClick = () => {
    history.goBack();
  };

  const renderDetails = (label: string, value: any) => {
    return (
      <Form.Group className="d-flex row align-items-center form-padding">
        <Form.Label className="col-sm-3">{label}</Form.Label>
        <div className="col-sm-9 mb-2">{value}</div>
      </Form.Group>
    );
  };

  return (
    <>
      <HeaderName name={name} />
      <div className="container">
        <h2 className="heading-main">{name}</h2>
        <div className="chart">
          {/* {Object.entries(request).map((v) =>
            renderDetails(v[0], v[1])
          )} */}
          <Form.Group className="d-flex row align-items-center form-padding">
            <Form.Label className="col-sm-3">ID</Form.Label>
            <div className="col-sm-9 mb-2">
              {request.accountRequestId}
            </div>
          </Form.Group>
          <Form.Group className="d-flex row align-items-center form-padding">
            <Form.Label className="col-sm-3">First Name</Form.Label>
            <div className="col-sm-9 mb-2">
              {request.userFirstName}
            </div>
          </Form.Group>
          <Form.Group className="d-flex row align-items-center form-padding">
            <Form.Label className="col-sm-3">Middle Name</Form.Label>
            <div className="col-sm-9 mb-2">
              {request.userMiddleName}
            </div>
          </Form.Group>
          <Form.Group className="d-flex row align-items-center form-padding">
            <Form.Label className="col-sm-3">Last Name</Form.Label>
            <div className="col-sm-9 mb-2">
              {request.userLastName}
            </div>
          </Form.Group>
          <Form.Group className="d-flex row align-items-center form-padding">
            <Form.Label className="col-sm-3">Email</Form.Label>
            <div className="col-sm-9 mb-2">
              {request.userEmail}
            </div>
          </Form.Group>
          <Form.Group className="d-flex row align-items-center form-padding">
            <Form.Label className="col-sm-3">Mobile Phone</Form.Label>
            <div className="col-sm-9 mb-2">
              {request.userPhone}
            </div>
          </Form.Group>
          <Form.Group className="d-flex row align-items-center form-padding">
            <Form.Label className="col-sm-3">Office Phone</Form.Label>
            <div className="col-sm-9 mb-2">
              {request.officePhone}
            </div>
          </Form.Group>
          <Form.Group className="d-flex row align-items-center form-padding">
            <Form.Label className="col-sm-3">Title</Form.Label>
            <div className="col-sm-9 mb-2">
              {request.userTitle}
            </div>
          </Form.Group>
          <Form.Group className="d-flex row align-items-center form-padding">
            <Form.Label className="col-sm-3">User Name</Form.Label>
            <div className="col-sm-9 mb-2">
              {request.userName}
            </div>
          </Form.Group>
          <Form.Group className="d-flex row align-items-center form-padding">
            <Form.Label className="col-sm-3">NPI</Form.Label>
            <div className="col-sm-9 mb-2">
              {request.npi}
            </div>
          </Form.Group>
          <Form.Group className="d-flex row align-items-center form-padding">
            <Form.Label className="col-sm-3">Medical License Number</Form.Label>
            <div className="col-sm-9 mb-2">
              {request.medicalLicenseNumber}
            </div>
          </Form.Group>
          <Form.Group className="d-flex row align-items-center form-padding">
            <Form.Label className="col-sm-3">Role</Form.Label>
            <div className="col-sm-9 mb-2">
              {request.expectedRole}
            </div>
          </Form.Group>
          <Form.Group className="d-flex row align-items-center form-padding">
            <Form.Label className="col-sm-3">Practice ID</Form.Label>
            <div className="col-sm-9 mb-2">
              {request.practiceId}
            </div>
          </Form.Group>
          <Form.Group className="d-flex row align-items-center form-padding">
            <Form.Label className="col-sm-3">Practice Name</Form.Label>
            <div className="col-sm-9 mb-2">
              {request.practiceName}
            </div>
          </Form.Group>
          <Form.Group className="d-flex row align-items-center form-padding">
            <Form.Label className="col-sm-3">Address 1</Form.Label>
            <div className="col-sm-9 mb-2">
              {request.practiceAddress1}
            </div>
          </Form.Group>
          <Form.Group className="d-flex row align-items-center form-padding">
            <Form.Label className="col-sm-3">Address 2</Form.Label>
            <div className="col-sm-9 mb-2">
              {request.practiceAddress2}
            </div>
          </Form.Group>
          <Form.Group className="d-flex row align-items-center form-padding">
            <Form.Label className="col-sm-3">City</Form.Label>
            <div className="col-sm-9 mb-2">
              {request.practiceCity}
            </div>
          </Form.Group>
          <Form.Group className="d-flex row align-items-center form-padding">
            <Form.Label className="col-sm-3">State</Form.Label>
            <div className="col-sm-9 mb-2">
              {request.practiceState}
            </div>
          </Form.Group>
          <Form.Group className="d-flex row align-items-center form-padding">
            <Form.Label className="col-sm-3">Zip</Form.Label>
            <div className="col-sm-9 mb-2">
              {request.practiceZip}
            </div>
          </Form.Group>
          <Form.Group className="d-flex row align-items-center form-padding">
            <Form.Label className="col-sm-3">Phone</Form.Label>
            <div className="col-sm-9 mb-2">
              {request.practicePhone}
            </div>
          </Form.Group>
          <Form.Group className="d-flex row align-items-center form-padding">
            <Form.Label className="col-sm-3">Fax</Form.Label>
            <div className="col-sm-9 mb-2">
              {request.practiceFax}
            </div>
          </Form.Group>
          <Form.Group className="d-flex row align-items-center form-padding">
            <Form.Label className="col-sm-3">Practice Contact Name</Form.Label>
            <div className="col-sm-9 mb-2">
              {request.practiceContactName}
            </div>
          </Form.Group>
          <Form.Group className="d-flex row align-items-center form-padding">
            <Form.Label className="col-sm-3">Practice Contact Email</Form.Label>
            <div className="col-sm-9 mb-2">
              {request.practiceContactEmail}
            </div>
          </Form.Group>
          <Form.Group className="d-flex row align-items-center form-padding">
            <Form.Label className="col-sm-3">Notes</Form.Label>
            <div className="col-sm-9 mb-2">
              {request.notes}
            </div>
          </Form.Group>
          <Form.Group className="d-flex row align-items-center form-padding">
            <Form.Label className="col-sm-3">Status</Form.Label>
            <div className="col-sm-9 mb-2">
              {request.accountRequestStatus}
            </div>
          </Form.Group>
          <Form.Group className="d-flex row align-items-center form-padding">
            <Form.Label className="col-sm-3">Request Type</Form.Label>
            <div className="col-sm-9 mb-2">
              {request.accountRequestTypeId}
            </div>
          </Form.Group>
          <Form.Group className="d-flex row align-items-center form-padding">
            <Form.Label className="col-sm-3">Created By</Form.Label>
            <div className="col-sm-9 mb-2">
              {request.createdBy}
            </div>
          </Form.Group>
          <Form.Group className="d-flex row align-items-center form-padding">
            <Form.Label className="col-sm-3">Created On</Form.Label>
            <div className="col-sm-9 mb-2">
              {getFormattedDateTime(request.createdOn)}
            </div>
          </Form.Group>
          <Form.Group className="d-flex row align-items-center form-padding">
            <Form.Label className="col-sm-3">Modified By</Form.Label>
            <div className="col-sm-9 mb-2">
              {request.modifiedBy}
            </div>
          </Form.Group>
          <Form.Group className="d-flex row align-items-center form-padding">
            <Form.Label className="col-sm-3">Modified On</Form.Label>
            <div className="col-sm-9 mb-2">
              {getFormattedDateTime(request.modifiedOn)}
            </div>
          </Form.Group>
          <Form.Group className="d-flex row align-items-center form-padding">
            <Form.Label className="col-sm-3">Is Active</Form.Label>
            <div className="col-sm-9 mb-2">
              {request.activeRecord !== null && (request.activeRecord ? "True" : "False")}
            </div>
          </Form.Group>
          <Form.Group className="d-flex row align-items-center form-padding">
            <Form.Label className="col-sm-3"></Form.Label>
            <div className="col-sm-9 mb-2">
              <MButton
                title="Back"
                variant="primary"
                width={"fit-content"}
                onClick={() => {
                  handleBackClick();
                }}
              />
            </div>
          </Form.Group>
        </div>
      </div>
    </>
  );
};

export default AccountRequestDetails;
