import { FC, useEffect, useState, useRef } from "react";
import { useOktaAuth } from "@okta/okta-react";
import { useLocation } from "react-router-dom";
import { Table } from "react-bootstrap";
import ChartService from "../../../../services/ChartService";
import IPatientNotes, { NOTEGROUP } from "../../../../types/IPatientNotes";
import Loader from "../../../common/Loader";
import NotesModal from "./NotesModal";
import { Alert } from "@mui/material";
import "./notes.css";
import CustomAccordion from "../../../accordion/CustomAccordion";
import MButton from "../../../mButton/MButton";
import { appInsights } from "../../../../appInsights";
import axios, { AxiosError } from "axios";
import { getFormattedDateTime, getFormattedTime } from "../../../../helpers/DateHelper";
import { ErrorBoundary } from "react-error-boundary";
import CustomAlertModal from "../../../common/CustomAlertModal";


const Notes: FC<{
  personId: string;
  patientName: string;
  patientDOB: string;
  encounterId?: string;
  lookback: string;
}> = ({ personId, patientName, patientDOB, encounterId, lookback }) => {
  const [loading, setLoading] = useState(true);
  const [notes, setNotes] = useState<IPatientNotes>();
  const [note, setNote] = useState<NOTEGROUP>();
  const [showModal, setShowModal] = useState<boolean>(false);
  const { authState } = useOktaAuth();
  const accessToken = authState?.accessToken?.accessToken;
  const cancelToken = axios.CancelToken.source();
  const [open, setOpen] = useState<boolean>(true);
  const [body, setBody] = useState<any>();
  const [error, setError] = useState<AxiosError | null>(null)


  useEffect(() => {
    setLoading(true);
    ChartService.getNotes(
      {
        eventId: "",
        personId: personId,
        lookback: lookback,
        encounterId: encounterId,
      },
      accessToken,
      cancelToken
    )
      .then((res) => {
        setNotes(res.data);
        setLoading(false);
      })
      .catch((e) => {
        if (!axios.isCancel(e)) {
          console.error(e);
          appInsights.trackException({ error: e });
          // setError(e);
        }
        setLoading(false);
      });
    return () => {
      cancelToken.cancel();
    };
  }, [encounterId, lookback]);


  const handleTitleClick = (note: NOTEGROUP) => {
    setNote(note);
    if (note !== undefined) {
      setBody(undefined);
      setLoading(true);
      let body = {
        NOTE_CNT: note.NOTE_CNT,
        NOTE_GROUP_PERFORMED_DATE: note.NOTE_GROUP_PERFORMED_DATE,
        NOTE_GROUP_VERIFIED_DATE: note.NOTE_GROUP_VERIFIED_DATE,
        NOTE_GROUP_TITLE: note.NOTE_GROUP_TITLE,
        NOTE_GROUP_AUTHOR: note.NOTE_GROUP_AUTHOR,
        NOTE_GROUP_AUTHOR_POSITION: note.NOTE_GROUP_AUTHOR_POSITION,
        PATIENT_NAME: patientName,
        DOB: patientDOB,
        NOTES: note.NOTES.map((n) => ({
          EVENT_ID: n.EVENT_ID + "",
          NAME_FULL: n.NAME_FULL,
          POSITION: n.POSITION,
          NOTE_PERFORMED_DATE: n.NOTE_PERFORMED_DATE,
          NOTE_SEQ: n.NOTE_SEQ,
        })),
      };

      ChartService.getNotesHTML(body, accessToken, cancelToken)
        .then((res) => {
          setLoading(false);
          setBody(res);
          window.open(res, "_blank");

          // Logic to open pdf in different tab with embed tag
          //  const newTab = window.open('', '_blank');
          // if (newTab) {
          //   newTab.document.body.innerHTML = `
          //     <div>
          //       <embed src="${res}" type="application/pdf" width="100%" height="100%" />
          //     </div>
          //   `;
          // }
        })
        .catch((e) => {
          setLoading(false);
          setError(e);
          if (!axios.isCancel(e)) {
            console.error(e);
            appInsights.trackException({ error: e });
          }
        });
    }
    return () => {
      cancelToken.cancel();
    }

  };
  const ErrorFallback = (error: any) => {
    return (
      <Alert severity="error" style={{ color: "#EA1D36" }}>
        {"Notes not Avialable"}
      </Alert>
    );
  };

  return (
    <div id="notes">
      <CustomAccordion
        title={
          <div className="header-top">
            <h4 className="header-blue bold m-0 pe-2 ">Notes</h4>
            {loading ? (
              <Loader />
            ) : (
              <h5 className="header-blue bold m-0">
                ({notes !== undefined ? notes.RPTDATA.NOTE_GROUP_CNT : 0})
              </h5>
            )}
          </div>
        }
        content={
          <>
            <p>
              This is not a comprehensive list of all notes for this patient.
              Included in this list are selected outpatient consultative notes,
              Emergency Department provider notes, and the following inpatient
              notes: history and physical, operative report, physician discharge
              summary, and inpatient consultation.
            </p>
            <div>
              {loading ? (
                <></>
              ) : // <Loader />
                notes?.RPTDATA.NOTE_GROUP_CNT === 0 ? (
                  <h5>No record found</h5>
                ) : (
                  <Table id="responsive-table-notes" striped hover>
                    <thead>
                      <tr>
                        <th>Date</th>
                        <th>Title</th>
                        <th>Author</th>
                      </tr>
                    </thead>
                    <tbody>
                      {notes?.RPTDATA.NOTE_GROUPS.map((note) => {
                        return (
                          <tr>
                            <td>{note.NOTE_GROUP_PERFORMED_DATE.slice(0, -3)}</td>
                            <td
                              id="table-title-notes"
                              onClick={() => handleTitleClick(note)}
                            >
                              {note.NOTE_GROUP_TITLE}
                            </td>
                            <td>{note.NOTE_GROUP_AUTHOR}</td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </Table>
                )}
            </div>
          </>
        }
      />
      {/* <NotesModal
        show={showModal}
        note={note}
        patientName={patientName}
        accessToken={accessToken}
        lookback={lookback}
        handleClose={() => {
          setShowModal(false);
        }}
      /> */}
      <CustomAlertModal
        show={error !== null}
        handleClose={() => {
          setError(null)
        }}
        header={"Error!"}
        // @ts-ignore
        body={error?.response?.data.message || "There was an error processing your request. Please refresh the page and try again. If problems persist please contact myCHLA support."}
      />
    </div>

  );
};

export default Notes;
