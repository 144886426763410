import { FC, useEffect } from "react";
import { Form } from "react-bootstrap";
import IReferralFormField from "../../types/IReferralFormField";
import { useFormikContext } from "formik";
// : FC<{
//   fieldInfo: IReferralFormField;
// }>
const HiddenInput = ({ fieldInfo, ...field }) => {
  return (
    <Form.Control {...field} type="hidden" required={fieldInfo.required} />
  );
};

export default HiddenInput;
