import { FC, useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import LookBackFilter from "../../common/LookBackFilter";
import SearchBar from "../../common/SearchBar";
import "../../styles.css";
import CustomAlert from "../../common/CustomAlert";
import ReferralList from "./components/ReferralList";
import ReferralCard from "./components/ReferralCard";
import IRouteState from "../../../types/IRouteState";
import CustomPagination from "../../common/CustomPagination";
import ILookbackOption from "../../../types/ILookbackOption";
import ReferralService from "../../../services/ReferralService";
import { useOktaAuth } from "@okta/okta-react";
import IReferral, { ReferralAppointment } from "../../../types/IReferral";
import IReferralQuery from "../../../types/IReferralQuery";
import Loader from "../../common/Loader";
import CancelReferralModal from "./components/CancelReferralModal";
import CancellationReasonModal from "./components/CancellationReasonModal";
import { appInsights } from "../../../appInsights";
import axios from "axios";
import { Button } from "react-bootstrap";
import MButton from "../../mButton/MButton";
import ScheduledReasonModal from "./components/ScheduledReasonModal";

interface Props {
  name?: string;
}

type modalType = {
  show: boolean;
  reason: string | null;
};
type appointmentModalType = {
  show: boolean;
  reason: ReferralAppointment;
};
type cancelModalType = {
  show: boolean;
  clinic: string;
  referralId: null | number;
};

const lookBackOptions: ILookbackOption[] = require("../../../data/referralLookbackOptions.json");

const MyReferrals: FC<Props> = ({ name }) => {
  const isBigScreen = window.innerWidth > 1000;

  const pageSize = 10;
  const [message, setMessage] = useState(false);
  const { authState } = useOktaAuth();
  const accessToken = authState?.accessToken?.accessToken;
  const [referrals, setReferrals] = useState<IReferral[]>([]);
  const [pages, setPages] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [loading, setLoading] = useState(true);
  const appointmentDetail = {
    aptDate: "",
    duration: "",
    type: "",
    location: "",
    resource: "",
    state: "",
  };
  const [showCancelReferralModal, setShowCancelReferralModal] =
    useState<cancelModalType>({
      show: false,
      clinic: "",
      referralId: null,
    });
  const [showCancelReasonModal, setShowCancelReasonModal] = useState<modalType>(
    {
      show: false,
      reason: null,
    }
  );
  const [showScheduleReasonModal, setShowScheduleReasonModal] =
    useState<appointmentModalType>({
      show: false,
      reason: appointmentDetail,
    });
  const [query, setQuery] = useState<IReferralQuery>({
    pagesize: pageSize,
    pagenumber: 1,
    sortfield: "ID",
    sortdirection: "DESC",
    lookback: 30,
  });
  const [apiCallCount, setApiCallCount] = useState(0);
  const [error, setError] = useState<Error>();
  const history = useHistory();
  const location = useLocation<IRouteState>();

  useEffect(() => {
    if (location.state !== undefined) {
      const state = { ...location.state };
      let msg = true;
      if (state.message !== undefined && apiCallCount > 0) {
        delete state.message;
        msg = false;
        history.replace({ state: state });
      }
      setMessage(msg);
    }
    setLoading(true);
    ReferralService.getPhysicianReferrals(query, accessToken)
      .then((response) => {
        if (error !== undefined) {
          setError(undefined);
        }
        setReferrals(response.data.data);
        setPages(response.data.totalPages);
        setCurrentPage(response.data.currentPage);
        setLoading(false);
      })
      .catch((e) => {
        if (!axios.isCancel(e)) {
          console.error(e);
          appInsights.trackException({ error: e });
          setError(e);
        }
        setLoading(false);
      });
  }, [apiCallCount]);

  const navigateToPage = (page: number) => {
    setCurrentPage(page);
    setQuery((q) => ({ ...q, pagenumber: page }));
    setApiCallCount((c) => c + 1);
    window.scrollTo(0, 0);
  };

  const handleSearch = (searchText: string) => {
    setQuery({
      ...query,
      searchterm: searchText,
    });
  };

  const handleLookBack = (e: React.ChangeEvent<any>) => {
    setQuery({
      ...query,
      lookback: e.target.value,
    });
    navigateToPage(1);
  };

  const newReferral = () => {
    let path = `/referrals/newreferral`;
    history.push(path);
  };

  const handleReferPatientClick = (referralId: IReferral) => {
    history.push("/referrals/re-refer", { state: { referrals } });
  };

  const handlePatientNameClick = (referralId: number) => {
    history.push("/referrals/editreferral", { state: { referralId } });
  };

  const handleCancelledClick = (reason: string) => {
    toggleCancelReasonModal(true, reason);
  };
  const handleScheduledClick = (reason: ReferralAppointment) => {
    toggleScheduleReasonModal(true, reason);
  };
  const handleReferralQuery = (referralQuery: IReferralQuery) => {
    setQuery(referralQuery);
    navigateToPage(1);
  };

  const toggleCancelReferralModal = (
    show: boolean,
    clinicName: string,
    id: number | null
  ) => {
    setShowCancelReferralModal({
      show: show,
      clinic: clinicName,
      referralId: id,
    });
  };
  const toggleCancelReasonModal = (show: boolean, reason: string | null) => {
    setShowCancelReasonModal({
      show: show,
      reason: reason,
    });
  };
  const toggleScheduleReasonModal = (
    show: boolean,
    reason: ReferralAppointment
  ) => {
    setShowScheduleReasonModal({
      show: show,
      reason: reason,
    });
  };
  const handleRemoveClick = (id: number, reason: string) => {
    toggleCancelReferralModal(false, "", id);
    ReferralService.cancelReferral(
      {
        id: id,
        reason: reason,
      },
      accessToken
    )
      .then((response) => {
        if (error !== undefined) {
          setError(undefined);
        }
        navigateToPage(1);
      })
      .catch((e) => {
        if (!axios.isCancel(e)) {
          console.error(e);
          appInsights.trackException({ error: e });
          setError(e);
        }

        // navigateToPage(1);
      });
  };
  return (<></>
    // <>
    //   {/* <HeaderName name={name} /> */}
    //   <div className="container">
    //     <h2 className="heading-main">{name}</h2>
    //     <div className="row">
    //       <div
    //         className="col-sm-5 col-lg-10 mb-3"
    //         style={{ paddingLeft: "1.5rem" }}
    //       >
    //         If your referral has been cancelled, please call 888-631-2452 with
    //         any additional questions.{" "}
    //         <i style={{ fontWeight: "bold" }}>
    //           Hover mouse over labels marked "Cancelled" to view the
    //           cancellation reason.
    //         </i>{" "}
    //         If you need to fax documentation, please fax to 323-361-8988. Please
    //         include the referral number on the fax cover sheet.
    //       </div>
    //       <div className="col-sm-5 col-lg-2 mb-3">
    //         <MButton
    //           onClick={newReferral}
    //           title="Add New Referral"
    //           small
    //           variant="secondary-light"
    //           width="auto"
    //           marginLeft="70px"
    //         />
    //       </div>
    //     </div>
    //     {error !== undefined ? (
    //       <CustomAlert isSuccessful={false} message={error.message} />
    //     ) : null}
    //     {message && location.state.message !== undefined ? (
    //       <CustomAlert
    //         isSuccessful={location.state.message === "success"}
    //         message={
    //           location.state.message === "success"
    //             ? 'Successfully added new "Referral" record. Thank you for your referral.'
    //             : 'There was a problem adding new "Referral" record.'
    //         }
    //       />
    //     ) : null}
    //     <div className="chart">
    //       <div className="d-flex row justify-content-between">
    //         <div className="col-sm-5 col-lg-3 mb-3">
    //           <LookBackFilter
    //             onChange={handleLookBack}
    //             options={lookBackOptions}
    //           />
    //         </div>
    //         {loading ? (
    //           <div className="col loader mb-3">
    //             <Loader />
    //           </div>
    //         ) : null}
    //         <div className="col-sm-5 col-lg-3 mb-3">
    //           <SearchBar
    //             placeholder="Search by Patient Name"
    //             onSearch={() => navigateToPage(1)}
    //             onTextChange={handleSearch}
    //           />
    //         </div>
    //       </div>
    //       {referrals.length === 0 ? (
    //         !loading ? (
    //           <div style={{ textAlign: "center" }}>No Record Found</div>
    //         ) : null
    //       ) : isBigScreen ? (
    //         <ReferralList
    //           referrals={referrals}
    //           query={query}
    //           modifyQuery={handleReferralQuery}
    //           onCancelPress={toggleCancelReferralModal}
    //           onReferPress={handleReferPatientClick}
    //           onEditPress={handlePatientNameClick}
    //           onCancelledPress={handleCancelledClick}
    //           onScheduledPress={handleScheduledClick}
    //           page="MyReferrals"
    //         />
    //       ) : (
    //         <ReferralCard
    //           referrals={referrals}
    //           onCancelPress={toggleCancelReferralModal}
    //           onReferPress={handleReferPatientClick}
    //           onEditPress={handlePatientNameClick}
    //           onCancelledPress={handleCancelledClick}
    //           onScheduledPress={handleScheduledClick}
    //           page="MyReferrals"
    //         />
    //       )}
    //       {referrals.length !== 0 ? (
    //         <div
    //           className="col d-flex justify-content-center"
    //           style={{ marginTop: "60px" }}
    //         >
    //           <CustomPagination
    //             currentPage={currentPage}
    //             totalPages={pages}
    //             onPageChange={navigateToPage}
    //             range={5}
    //           />
    //         </div>
    //       ) : null}
    //     </div>
    //   </div>
    //   <CancelReferralModal
    //     show={showCancelReferralModal.show}
    //     clinicName={showCancelReferralModal.clinic}
    //     referralId={showCancelReferralModal.referralId}
    //     handleClose={() => toggleCancelReferralModal(false, "", null)}
    //     handleRemove={handleRemoveClick}
    //   />
    //   <CancellationReasonModal
    //     show={showCancelReasonModal.show}
    //     reason={showCancelReasonModal.reason}
    //     handleClose={() => toggleCancelReasonModal(false, null)}
    //   />
    //   <ScheduledReasonModal
    //     show={showScheduleReasonModal.show}
    //     reason={showScheduleReasonModal.reason}
    //     handleClose={() => toggleScheduleReasonModal(false, appointmentDetail)}
    //   />
    // </>
  );
};

export default MyReferrals;
