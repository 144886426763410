import React from "react";
import { FC, useEffect, useState } from "react";
import HeaderName from "../../headerName/HeaderName";
import { Badge, OverlayTrigger, Table, Col,Form } from "react-bootstrap";
import FormPopover from "../../common/FormPopover";
import IPediatricGR from "../../../types/IPediatricGR";
import MButton from "../../mButton/MButton";
import CustomToggle from "../../toggle/CustomToggle";
import PediatricGRService from "../../../services/PediatricGRService";
import { useOktaAuth } from "@okta/okta-react";
import { getFormattedDateFromDate } from "../../../helpers/DateHelper";
import Loader from "../../common/Loader";
import usePagination from "../../../hooks/usePagination";
import CustomPagination from "../../common/CustomPagination";
import { Redirect, useHistory, useLocation } from "react-router-dom";
import { Checkbox } from "@mui/material";
import { appInsights } from "../../../appInsights";
import axios from "axios";

const sortable = require("../../../assets/images/sortable.png").default;

const PediatricGrandRounds: FC<{
  name?: string;
}> = ({ name }) => {
  const history = useHistory();
  const tableCellPadding = { padding: 20 };
  const tableHeadPadding = { paddingLeft: 20, paddingRight: 20 };
  const handleClick = () => {
    history.push("/portalupdates/components/newpediatricform");
  };
  const handleOnClick = (pediatric: any) => {
    const pediatricEditValue = pediatric;
    history.push("/portalupdates/components/editpediatric", pediatricEditValue);
  };
  const { authState } = useOktaAuth();
  const accessToken = authState?.accessToken?.accessToken;
  const [loading, setLoading] = useState(true);
  const [pediatricData, setPediatricData] = useState<IPediatricGR[]>();
  const [error, setError] = useState<Error | null>(null);

  useEffect(() => {
    setLoading(true);
    setError(null);
    PediatricGRService.getPediatricGRServiceList(accessToken)
      .then((response) => {
        setLoading(false);
        console.log(response.data);
        setPediatricData(response.data);
      })
      .catch((e: Error) => {
        if (!axios.isCancel(e)) {
          console.error(e);
          appInsights.trackException({ error: e });
          setError(e);
        }
       
        setLoading(false);
      });
  }, []);
  return (
    <div className="referrals">
      <HeaderName name={name} />
      <div className="container">
        <h2 className="heading-main">{name}</h2>
        <div
            className="d-flex row justify-content-end"
            style={{ padding: 20 }}
          >
          <MButton
            variant="primary"
            disabled={false}
            title="Add New"
            width={"fit-content"}
            onClick={handleClick}
          />
        </div>
        <Col
          sm={{ span: 7, offset: 3 }}
          className="d-flex justify-content-center"
        >
          {loading && <Loader />}
        </Col>
        <br />
        <div className="referral">
          <Table id="responsive-table" className="mt-2" responsive>
            <thead>
              <tr>
                <th style={tableHeadPadding}>Title</th>
                <th style={tableHeadPadding}>Event Date</th>
                <th style={tableHeadPadding}>Is Active</th>
                <th style={tableHeadPadding}>Modified On</th>
                <th style={tableHeadPadding}>Modified By</th>
              </tr>
            </thead>
            <tbody>
              {pediatricData?.map((pediatric) => {
                
                
                return (
                  <tr key={pediatric.id}>
                    <td
                      style={tableCellPadding}
                      onClick={() => {
                        console.log("pediatric: ", pediatric);
                        handleOnClick(pediatric)
                      }}
                      data-title="Title"
                      id="table-text-ambulatory"
                    >
                      {pediatric.title}
                    </td>
                    <td style={tableCellPadding} data-title="Event Date">
                      {getFormattedDateFromDate(pediatric.eventDate)}
                    </td>
                    <td style={tableCellPadding} data-title="Is Active">
                    <Checkbox disabled checked={pediatric.isActive} />
                    </td>

                    <td style={tableCellPadding} data-title="Modified On">
                      {getFormattedDateFromDate(pediatric.modifiedOn)}
                    </td>
                    <td style={tableCellPadding} data-title="Modified By">
                      {pediatric.modifiedBy}
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </Table>
        </div>
      </div>
    </div>
  );
};

export default PediatricGrandRounds;
