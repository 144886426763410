import { FC, useEffect, useState } from "react";
import HeaderName from "../../../headerName/HeaderName";
import { Form, Col, Button } from "react-bootstrap";
import DateHelper, {
  getFormattedDate,
  getFormattedDateFromDateTime,
} from "../../../../helpers/DateHelper";
import IDivisionMonth from "../../../../types/IDivisionMonth";
import { Redirect, useHistory, useLocation } from "react-router-dom";
import DivisionMonthService from "../../../../services/DivisionMonthService";
import { useOktaAuth } from "@okta/okta-react";
import { Alert } from "@mui/material";
import ClinicalCarePathways from "../ClinicalCarePathways";
import ClinicalPathwayService from "../../../../services/ClinicalPathwayService";
import MButton from "../../../mButton/MButton";
import BackButton from "../../../backButton/BackButton";
import Loader from "../../../common/Loader";
import { appInsights } from "../../../../appInsights";
import axios from "axios";
import IDropDownInfoWell from "../../../../types/IDropDownInfoWell";
import IClinicalType from "../../../../types/IClinicalType";
const EditClinicalCarePathways: FC<{
  name?: string;
}> = ({ name }) => {
  const isDisabled = false;
  const [delegationToggle, setdelegationToggle] = useState(true);
  const { authState } = useOktaAuth();
  const accessToken = authState?.accessToken?.accessToken;
  const [redirect, setRedirect] = useState({ redirect: false, message: "" });
  const { state: division } = useLocation<IDivisionMonth>();
  const [data, setData] = useState(division);
  const DATE = getFormattedDate(new Date());
  const [loading, setLoading] = useState(false);
  const [clinicalTypeData, setClinicalTypeData] = useState<IClinicalType[]>();
  const [error, setError] = useState<Error | null>(null);
  const cancelToken = axios.CancelToken.source();
  const { state: dropDownWells } = useLocation<IDropDownInfoWell[]>();
  const [formData, setFormData] = useState<FormData>();
  
  const [value, setValue] = useState("");
  const handleOnChange = (e: any, key: string) => {
    let value: any;
    if (key === "Attachment") {
      value = e.target.files[0];
    } else if (key === "isActive") {
      value = e.target.checked;
    } else {
      value = e.target.value;
    }
    setData((prev) => ({ ...prev, [key]: value }));
  };

  let form = require("../../../../data/newAmbulatorySchedules.json");
  const handleOnChangeClinical = (id: any, key?: any) => {
    if(key==='clinicalCareId'){

      setData((prev) => ({ ...prev, [key]: id.target.value }));
     
    }};
  const handleSubmit = (e: any) => {
    e.preventDefault();
    if (data === undefined) {
      {window.scrollTo(0, 0)}
      alert("Cannot submit an empty form. Please attach an image to upload.");
    } else {
      setLoading(true);
      let formData = new FormData();

      Object.entries(data).map((entry) => {
        const key = entry[0];
        const value: any = entry[1];
        if (value !== null) {
          formData.append(key, value);
        }
      });
      {window.scrollTo(0, 0)}
      ClinicalPathwayService.editClinicalPathwayServiceRequest(
        formData,
        accessToken
      )
        .then((response) => {
          {window.scrollTo(0, 0)}
          setLoading(false);
          setRedirect({ redirect: false, message: "success" });
          setTimeout(() => {
            setLoading(false)
            setRedirect({ redirect: true, message: "" });
          }, 3000);
        })
        .catch((err) => {
          {window.scrollTo(0, 0)}
          if (!axios.isCancel(e)) {
            console.error(e);
            appInsights.trackException({ error: e });
            // setError(e);
          }
          setLoading(false);
          setRedirect({ redirect: false, message: "error" });
        });
    }
  };

  useEffect(() => {
    setLoading(true);
    setError(null);
    ClinicalPathwayService.getClinicalType(accessToken)
      .then((response) => {
        setLoading(false);
        setClinicalTypeData(response.data);
      })
      .catch((e: Error) => {
        if (!axios.isCancel(e)) {
          console.error(e);
          appInsights.trackException({ error: e });
          setError(e);
        }

        setLoading(false);
      });
    return () => {
      cancelToken.cancel();
    };
  }, []);
  if (redirect.redirect) {
    return (
      <Redirect
        to={{
          pathname: "/portalupdates/clinicalcarepathways",
          state: { message: redirect.message },
        }}
      />
    );
  } else {
    return (
      <div className="referrals">
        <HeaderName name={name} />
        <BackButton />
        {redirect.message === "success" ? (
          <>
            {window.scrollTo(0, 0)}
            <Alert severity="success">
              Form is successfully submitted,please wait while we are
              redirecting!
            </Alert>
          </>
        ) : redirect.message === "error" ? (
          <>
            <Alert severity="error" style={{ color: "#EA1D36" }}>
              Error in submitting the form, please try again!
            </Alert>
          </>
        ) : redirect.message ? (
          <>
            <Alert severity="error" style={{ color: "#EA1D36" }}>
              {redirect.message}
            </Alert>
          </>
        ) : (
          <></>
        )}
        <div className="container">
          <h2 className="heading-main">Edit Clinical Care Pathways</h2>
          <div className="d-flex row justify-content-end"></div>

          <div className="referral">
            <form className="m-sm-5" onSubmit={handleSubmit}>
              <Form.Group className="d-flex row align-items-center form-padding">
                <Form.Label column sm={3} className="pl-0 pr-0">
                  Title <span className="p-0 asterik"> *</span>
                </Form.Label>

                <Col sm={9} className="pl-0 pr-0">
                  <Form.Control
                    required
                    onChange={(e) => handleOnChange(e, "title")}
                    placeholder=""
                    defaultValue={division.title}
                  />
                </Col>
              </Form.Group>
              <Form.Group className="d-flex row align-items-center form-padding">
                <Form.Label column sm={3} className="pl-0 pr-0">
                 Clinical Care Type 
                </Form.Label>
                <Col sm={9} className="pl-0 pr-0">
                <Form.Select
                  as="select"
                  onChange={(e) => handleOnChangeClinical(e,'clinicalCareId')}
                  aria-label="Default select example"
                  
                  value={data.clinicalCareId !== undefined ? data.clinicalCareId : division.clinicalCareId}
                >
                 
                  {clinicalTypeData
                    ?.map((option, index: number) => (
                      <>
                       
                          <option
                            key={index}
                            value={option.id}
                            title={option.type}
                          >
                            {/* {getFormattedDateWords(option.publicationDate)} :{" "} */}
                            {option.type}
                          </option>
                        
                      </>
                    ))
                    .reverse()}
                </Form.Select>
                </Col>
              </Form.Group>
              <Form.Group className="d-flex row align-items-center form-padding">
                <Form.Label column sm={3} className="pl-0 pr-0">
                  Replacement File
                </Form.Label>

                <Col sm={9} className="pl-0 pr-0">
                  <Form.Control
                    type="file"
                    onChange={(e) => handleOnChange(e, "Attachment")}
                    disabled={isDisabled}
                  />
                </Col>
              </Form.Group>
              <Form.Group className="d-flex row align-items-center form-padding">
                <Form.Label column sm={3} className="pl-0 pr-0">
                  New File <span className="p-0 asterik"> *</span>
                </Form.Label>

                <Col sm={9} className="pl-0 pr-0">
                  <Form.Control
                    onChange={(e) => handleOnChange(e, "fileName")}
                    placeholder=""
                    defaultValue={division.fileName}
                    required
                  />
                </Col>
              </Form.Group>
              <Form.Group className="d-flex row align-items-center form-padding">
                <Form.Label column sm={3} className="pl-0 pr-0">
                  IsActive
                </Form.Label>

                <Col sm={9} className="pl-0 pr-0">
                  <Form.Check
                    type="checkbox"
                    onChange={(e) => handleOnChange(e, "isActive")}
                    disabled={isDisabled}
                    defaultChecked={division.isActive}
                  />
                </Col>
              </Form.Group>
              <Form.Group className="d-flex row align-items-center form-padding">
                <Form.Label column sm={3} className="pl-0 pr-0">
                  Publication Date <span className="p-0 asterik"> *</span>
                </Form.Label>

                <Col sm={9} className="pl-0 pr-0">
                  <Form.Control
                    max={DATE}
                    onChange={(e) => handleOnChange(e, "publicationDate")}
                    type="date"
                    required
                    defaultValue={getFormattedDateFromDateTime(
                      division.publicationDate
                    )}
                  />
                </Col>
              </Form.Group>
              <Form.Group className="d-flex row">
                <Col
                  sm={{ span: 9, offset: 3 }}
                  className="d-flex justify-content-center"
                >
                  <MButton
                    variant="primary"
                    loader={loading}
                    title="Save"
                    width={"fit-content"}
                    type="submit"
                  >
                    Save
                  </MButton>
                </Col>
                <Col
                  sm={{ span: 7, offset: 3 }}
                  className="d-flex justify-content-center"
                >
                  {/* {loading && <Loader />} */}
                </Col>
              </Form.Group>
            </form>
          </div>
        </div>
      </div>
    );
  }
};

export default EditClinicalCarePathways;
