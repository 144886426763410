import { FC, ReactNode, useEffect, useState } from "react";
import useMenuItems from "../hooks/useMenuItems";
import IMenuItem from "../types/IMenuItem";
import menuItemsContext from "./MenuItemsContext";

const MenuItemsState: FC<{ children: ReactNode }> = ({ children }) => {
  const [mItems, setMItems] = useMenuItems();
  const [menuItems, setMenuItems] = useState<IMenuItem[] | undefined>(mItems);

  useEffect(() => {
    setMItems(menuItems);
  }, [menuItems]);

  return (
    <menuItemsContext.Provider value={{ menuItems, setMenuItems }}>
      {children}
    </menuItemsContext.Provider>
  );
};

export default MenuItemsState;
