import { FC, useEffect, useState } from "react";
import HeaderName from "../../headerName/HeaderName";
import { Badge, OverlayTrigger, Table, Col } from "react-bootstrap";
import IOnlineCME from "../../../types/IOnlineCME";
import MButton from "../../mButton/MButton";
import CustomToggle from "../../toggle/CustomToggle";
import OnlineCMEService from "../../../services/OnlineCMEService";
import { useOktaAuth } from "@okta/okta-react";
import { getFormattedDateFromDate } from "../../../helpers/DateHelper";
import Loader from "../../common/Loader";
import usePagination from "../../../hooks/usePagination";
import CustomPagination from "../../common/CustomPagination";
import { Redirect, useHistory, useLocation } from "react-router-dom";
import { Checkbox } from "@mui/material";
import { appInsights } from "../../../appInsights";
import axios from "axios";

const OnlineCMELectures: FC<{
  name?: string;
}> = ({ name }) => {
  const history = useHistory();
  const tableCellPadding = { padding: 20 };
  const tableHeadPadding = { paddingLeft: 20, paddingRight: 20 };
  const handleClick = () => {
    history.push("/portalupdates/components/newonlineCMEforms");
  };
  const handleOnClick = (cme: IOnlineCME) => {
    history.push("/portalupdates/components/editonlineCME", cme);
  };
  const { authState } = useOktaAuth();
  const accessToken = authState?.accessToken?.accessToken;
  const [loading, setLoading] = useState(true);

  const [onlineCMEData, setOnlineCMEData] = useState<IOnlineCME[]>();
  const [error, setError] = useState<Error | null>(null);

  const onToggle = () => {};
  useEffect(() => {
    setLoading(true);
    setError(null);
    OnlineCMEService.getOnlineCMEServiceList(accessToken)
      .then((response) => {
        setLoading(false);
        setOnlineCMEData(response.data);
      })
      .catch((e: Error) => {
        if (!axios.isCancel(e)) {
          console.error(e);
          appInsights.trackException({ error: e });
          setError(e);
        }
        
        setLoading(false);
      });
  }, []);
  return (
    <div className="referrals">
      <HeaderName name={name} />
      <div className="container">
        <h2 className="heading-main">{name}</h2>
        <div
            className="d-flex row justify-content-end"
            style={{ padding: 20 }}
          >
          <MButton
            variant="primary"
            disabled={false}
            title="Add New"
            width={"fit-content"}
            onClick={handleClick}
          />
        </div>
        <Col
          sm={{ span: 7, offset: 3 }}
          className="d-flex justify-content-center"
        >
          {loading && <Loader />}
        </Col>
        <br />

        <div className="referral">
          <Table id="responsive-table" className="mt-2" responsive>
            <thead>
              <tr>
                <th style={tableHeadPadding}>Title</th>
                <th style={tableHeadPadding}>Event Date</th>
                <th style={tableHeadPadding}>VideoURL</th>
                <th style={tableHeadPadding}>Is Active</th>
                <th style={tableHeadPadding}>Modified On</th>
                <th style={tableHeadPadding}>Modified By</th>
              </tr>
            </thead>
            <tbody>
              {onlineCMEData?.map((cme) => {
                return (
                  <tr key={cme.id}>
                    <td
                      style={tableCellPadding}
                      onClick={() => handleOnClick(cme)}
                      data-title="Title"
                      id="table-text-ambulatory"
                    >
                      {cme.title}
                    </td>
                    <td style={tableCellPadding} data-title="Event Date">
                      {getFormattedDateFromDate(cme.eventDate)}
                    </td>
                    <td style={tableCellPadding} data-title="VideoURL">
                      {cme.videoUrl}
                    </td>
                    <td style={tableCellPadding} data-title="Is Active">
                    <Checkbox disabled 
                        checked={cme.isActive}
                                             />
                    </td>

                    <td style={tableCellPadding} data-title="Modified On">
                      {getFormattedDateFromDate(cme.modifiedOn)}
                    </td>
                    <td style={tableCellPadding} data-title="Modified By">
                      {cme.modifiedBy}
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </Table>
          {/* <div className="col d-flex justify-content-center">
            <CustomPagination
              currentPage={page}
              onPageChange={(p) => {
                setPage(p);
              }}
              totalPages={totalPages}
              range={5}
            />
          </div> */}
        </div>
      </div>
    </div>
  );
};

export default OnlineCMELectures;
