import IRecentSearchPatient from "../types/IRecentSearchPatient";

const RECENT_SEARCH = "recentSearch";

/**
 * @param searchedItem accepts the searched entity to be stored in local storage
 */
export const setRecentSearch = (searchedItem: IRecentSearchPatient) => {
  let recentSearch = localStorage.getItem(RECENT_SEARCH);
  if (recentSearch === null) {
    localStorage.setItem(RECENT_SEARCH, JSON.stringify([searchedItem]));
  } else {
    let recentSearchArray: IRecentSearchPatient[] = JSON.parse(recentSearch);
    if (recentSearchArray.find((e) => e.name === searchedItem.name) !== null) {
      recentSearchArray = recentSearchArray.filter(
        (e) => e.name !== searchedItem.name
      );
      recentSearchArray.unshift(searchedItem);
      localStorage.setItem(RECENT_SEARCH, JSON.stringify(recentSearchArray));
    }
  }
};

export const getRecentSearch = (): IRecentSearchPatient[] => {
  const recentSearch = localStorage.getItem(RECENT_SEARCH);
  if (recentSearch !== null) {
    return JSON.parse(recentSearch);
  } else return [];
};
