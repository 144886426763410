import IPediatricGR from "../types/IPediatricGR";

function convertOlderPediatricToNewForm(oldPGR: IPediatricGR){
    const pgr = oldPGR;
    pgr.contentMessage =      (pgr.contentBulletLine !== "" 
                              ? '<div><p>'
                              + pgr.contentMessage.split('|')[0] 
                              + '</p> <ol> <li>'
                              + pgr.contentBulletLine.split('|').filter(src => src !== "").join('</li><li>')
                              +'</ol></div>'
                              :pgr.contentMessage);

    pgr.contentTitle =  pgr.contentTitle.split('|').length > 1 
                        ? pgr.contentTitle.split('|').filter(src => src !== "").slice(1).join('|')
                        : pgr.contentTitle.split('|').filter(src => src !== "").join('|');
    pgr.contentFullname = pgr.contentFullname.split('|').filter(src => src !== "").join('|');
    pgr.contentSignature = pgr.contentSignature.split('|').filter(src => src !== "").join('|')
    

    pgr.contentBulletLine = "";

    return pgr;
}

const PediatricHelper = {
    convertOlderPediatricToNewForm,
}

export default PediatricHelper;