import { CancelTokenSource } from "axios";
import { customAxios as axios } from "./ServiceConfig";
import { API_BASE_URL } from "../config";
import IAnnouncementBanner from "../types/IAnnouncementBanner";

let loginas = localStorage.getItem('loginAsFlag')
let loginasEmail = localStorage.getItem('loginAsEmail')
/**
 * @param query receives the body to be sent in the post request
 * @param token receives the access token of type string
 */
const getAnnouncementServiceList = async (

  token?: string,
  cancelToken?: CancelTokenSource
) => {
  const result = await axios.get<IAnnouncementBanner[]>(
    `${API_BASE_URL}/portalupdate/announcement`,

    loginas !== null ? {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Pragma: "no-cache",
        Authorization: `Bearer ${token}`,
        loginas:loginas !== null ? loginas : JSON.stringify(false),
        email:loginasEmail !== null ? loginasEmail : "",
      },
      cancelToken: cancelToken?.token,
    }:{
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Pragma: "no-cache",
        Authorization: `Bearer ${token}`,
      },
      cancelToken: cancelToken?.token,
    }
  );
  return result;
};

/**
 * @param id receives the patient id
 * @param token receives the access token of type string
 */
// const getAmbulatoryRequestById = async (
//   id: number,
//   token?: string,
//   cancelToken?: CancelTokenSource
// ) => {
//   const result = await axios.get(
//     `${API_BASE_URL}/patient/getpatientaccessrequestbyid/${id}`,
//     {
//       headers: {
//         Accept: "application/json",
//         "Content-Type": "application/json",
//         Pragma: "no-cache",
//         Authorization: `Bearer ${token}`,
//       },
//       cancelToken: cancelToken?.token,
//     }
//   );
//   return result;
// };

/**
 * @param data receives the referral form
 * @param token receives the access token of type string
 */
export async function createAnnouncementServiceRequest(
  data: any,
  token?: string,
  cancelToken?: CancelTokenSource
) {
  const result = await axios.post(
    `${API_BASE_URL}/portalupdate/addannouncement`,
    data,
    loginas !== null ? {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Pragma: "no-cache",
        Authorization: `Bearer ${token}`,
        loginas:loginas !== null ? loginas : JSON.stringify(false),
        email:loginasEmail !== null ? loginasEmail : "",
      },
      cancelToken: cancelToken?.token,
    }:{
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Pragma: "no-cache",
        Authorization: `Bearer ${token}`,
      },
      cancelToken: cancelToken?.token,
    }
  );
  return result;
}

export async function editAnnouncementServiceRequest(
  data: any,
  token?: string,
  cancelToken?: CancelTokenSource
) {
  const result = await axios.put(
    `${API_BASE_URL}/portalupdate/editannouncement`,
    data,
    loginas !== null ? {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Pragma: "no-cache",
        Authorization: `Bearer ${token}`,
        loginas:loginas !== null ? loginas : JSON.stringify(false),
        email:loginasEmail !== null ? loginasEmail : "",
      },
      cancelToken: cancelToken?.token,
    }:{
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Pragma: "no-cache",
        Authorization: `Bearer ${token}`,
      },
      cancelToken: cancelToken?.token,
    }
  );
  return result;
}

const AnnouncementService = {
  getAnnouncementServiceList,
 // getAmbulatoryRequestById,
  createAnnouncementServiceRequest,
  editAnnouncementServiceRequest,
};

export default AnnouncementService ;
