import { FC } from "react";
import { Modal, Table } from "react-bootstrap";
import IPracticeLookup from "../../../../types/IPracticeLookup";
import MButton from "../../../mButton/MButton";

const AdminPracticeLookupModal: FC<{
    show: boolean;
    handleClose?: () => void;
    handleSubmit?: () => void;
    results?: IPracticeLookup[];
}> = ({ show, handleClose, handleSubmit, results }) => {
    //
    const handleOnSubmit = () => {
        if (handleSubmit) {
            handleSubmit()
        }
    }
    //
    return (
        <Modal size="lg" show={show} onHide={handleClose} centered>
            <Modal.Header>Possible Duplicates</Modal.Header>
            <Modal.Body>
                <p>
                    The Practice you are attempting to create may already be in the system.
                    Please review the potential matches below and proceed only if it does not match with any of the practices.
                </p>
                {results?.map(result => {
                    return (
                        <Table borderless style={{ margin: '4px 0px', background: 'var(--color-light-gray)' }}>
                            <thead >
                                <tr>
                                    <th style={{ paddingInlineStart: 10, width: '40%' }}>ID</th>
                                    <th >Practice Name</th>
                                    {/* <th >Locations</th>
                                    <th >Physicians</th> */}
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td style={{ paddingInlineStart: 10, width: '40%' }}>{result.practiceId}</td>
                                    <td>{result.practiceName}
                                        {result.isMyCHLA && <img src={require('../../../../assets/images/myCHLA.png').default} />}
                                    </td>
                                    {/* <td>{result.locationCount}</td>
                                    <td>{result.physicianCount}</td> */}
                                </tr>
                            </tbody>
                        </Table>
                    )
                })}
            </Modal.Body>
            <Modal.Footer>
                <MButton title="Create New Practice Anyway" variant="primary" type="submit" width={'fit-content'} onClick={handleOnSubmit} />
                <MButton title="Cancel" variant="secondary" type="button" width={'fit-content'} onClick={handleClose} />
            </Modal.Footer>
        </Modal >
    );
};

export default AdminPracticeLookupModal;
