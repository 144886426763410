import { FC, useEffect, useState } from "react";
import HeaderName from "../../../headerName/HeaderName";
import { Form, Col, Button } from "react-bootstrap";
import DateHelper, {
  getFormattedDate,
  getFormattedDateFromDateTime,
} from "../../../../helpers/DateHelper";
import { useOktaAuth } from "@okta/okta-react";
import { Alert } from "@mui/material";
import { Redirect, useHistory, useLocation } from "react-router-dom";
import EditSubForm from "./EditSubform";
import OnlineCMEService from "../../../../services/OnlineCMEService";
import IOnlineCME from "../../../../types/IOnlineCME";
import MButton from "../../../mButton/MButton";
import BackButton from "../../../backButton/BackButton";
import Loader from "../../../common/Loader";
import { appInsights } from "../../../../appInsights";
import axios from "axios";
const EditOnlineCME: FC<{
  name?: string;
}> = ({ name }) => {
  const isDisabled = false;
  const [delegationToggle, setdelegationToggle] = useState(true);
  const { authState } = useOktaAuth();
  const accessToken = authState?.accessToken?.accessToken;
  const [redirect, setRedirect] = useState({
    redirect: false,
    message: "",
    errorMessage: "",
  });
  const { state: cme } = useLocation<IOnlineCME>();
  const [formData, setFormData] = useState<any>(cme);
  const DATE = getFormattedDate(new Date());
  const [loading, setLoading] = useState(false);

  const handleFormClick = (
    title: string,
    contentTitle: string[],
    contentMessage: string[],
    contentBulletLine: string[],
    contentFullname: string[],
    contentSignature: string[]
  ) => {
    setFormData((prev: any) => ({
      ...prev,
      title: title,
      contentTitle: contentTitle,
      contentMessage: contentMessage,
      contentBulletLine: contentBulletLine,
      contentFullname: contentFullname,
      contentSignature: contentSignature,
    }));
  };
  const handleOnChange = (e: any, key: string) => {
    let value: any;
    if (key === "isActive") {
      value = e.target.checked;
    } else {
      value = e.target.value;
    }
    setFormData((prev: any) => ({ ...prev, [key]: value }));
  };

  const handleSubmit = (e: any) => {
    {
      window.scrollTo(0, 0);
    }
    e.preventDefault();
    if (formData.title === undefined || formData.title.trim() === "") {
      {
        window.scrollTo(0, 0);
      }
      setRedirect({
        redirect: false,
        message: "error",
        errorMessage: "Title is missing",
      });
      return;
    }
    for (let i = 1; i < formData.contentTitle.length; i++) {
      if (
        formData.contentTitle[i] != "" ||
        formData.contentTitle != undefined
      ) {
        if (formData.contentTitle[i].trim() == "") {
          {
            window.scrollTo(0, 0);
          }
          setRedirect({
            redirect: false,
            message: "error",
            errorMessage: "Do not put space as title",
          });
          return;
        } else {
          formData.contentTitle[i] = formData.contentTitle[i].trim();
        }
      }
    }
    for (let i = 0; i < formData.contentBulletLine.length; i++) {
      formData.contentBulletLine[i] = formData.contentBulletLine[i].trim();
    }
    for (let i = 0; i < formData.contentSignature.length; i++) {
      formData.contentSignature[i] = formData.contentSignature[i].trim();
    }
    setLoading(true);
    OnlineCMEService.editOnlineCMEServiceRequest(formData, accessToken)
      .then((response) => {
        setRedirect({ redirect: false, message: "success", errorMessage: "" });
        setTimeout(() => {
          setLoading(false);
          setRedirect({ redirect: true, message: "success", errorMessage: "" });
        }, 3000);
      })
      .catch((err) => {
        if (!axios.isCancel(e)) {
          console.error(e);
          appInsights.trackException({ error: e });
          // setError(e);
        }
        setLoading(false);
        setRedirect({
          redirect: false,
          message: "error",
          errorMessage: "Error in submitting the form, please try again!",
        });
      });
  };
  if (redirect.redirect) {
    return (
      <Redirect
        to={{
          pathname: "/portalupdates/onlinecmelectures",
          state: { message: redirect.message },
        }}
      />
    );
  } else {
    return (
      <div className="referrals">
        <HeaderName name={name} />
        <BackButton />
        {redirect.message === "success" ? (
          <>
            {window.scrollTo(0, 0)}
            <Alert severity="success">
              Form is successfully submitted,please wait while we are
              redirecting!
            </Alert>
          </>
        ) : redirect.message === "error" ? (
          <>
            <Alert severity="error" style={{ color: "#EA1D36" }}>
              {redirect.errorMessage}
            </Alert>
          </>
        ) : redirect.message ? (
          <>
            <Alert severity="error" style={{ color: "#EA1D36" }}>
              {redirect.message}
            </Alert>
          </>
        ) : (
          <></>
        )}
        <div className="container">
          <h2 className="heading-main">Edit Online CME Lectures</h2>
          <div className="d-flex row justify-content-end"></div>

          <div className="referral">
            <form className="m-sm-5" onSubmit={handleSubmit}>
              <Form.Group className="d-flex row align-items-center form-padding">
                <Form.Label column sm={3} className="pl-0 pr-0">
                  IsActive
                </Form.Label>

                <Col sm={9} className="pl-0 pr-0">
                  <Form.Check
                    type="checkbox"
                    onChange={(e) => handleOnChange(e, "isActive")}
                    disabled={isDisabled}
                    defaultChecked={cme.isActive}
                  />
                </Col>
              </Form.Group>
              <Form.Group className="d-flex row align-items-center form-padding">
                <Form.Label column sm={3} className="pl-0 pr-0">
                  Event Date <span className="p-0 asterik"> *</span>
                </Form.Label>

                <Col sm={9} className="pl-0 pr-0">
                  <Form.Control
                    max={DATE}
                    onChange={(e) => handleOnChange(e, "eventDate")}
                    type="date"
                    required
                    defaultValue={getFormattedDateFromDateTime(cme.eventDate)}
                  />
                </Col>
              </Form.Group>
              <Form.Group className="d-flex row align-items-center form-padding">
                <Form.Label column sm={3} className="pl-0 pr-0">
                  Video URL <span className="p-0 asterik"> *</span>
                </Form.Label>

                <Col sm={9} className="pl-0 pr-0">
                  <Form.Control
                    onChange={(e) => handleOnChange(e, "videoUrl")}
                    placeholder=""
                    type="Url"
                    defaultValue={cme.videoUrl}
                    required
                  />
                </Col>
              </Form.Group>

              <Form.Group className="d-flex row align-items-center form-padding">
                <Form.Label column sm={3} className="pl-0 pr-0"></Form.Label>
                <Col sm={9} className="pl-0 pr-0">
                  <EditSubForm
                    cmedata={formData}
                    onFormClick={handleFormClick}
                  />
                </Col>
              </Form.Group>
              <Form.Group className="d-flex row">
                <Col
                  sm={{ span: 9, offset: 3 }}
                  className="d-flex justify-content-center"
                >
                  <MButton
                    variant="primary"
                    loader={loading}
                    title="Save"
                    width={"fit-content"}
                    type="submit"
                  >
                    Save
                  </MButton>
                </Col>
                <Col
                  sm={{ span: 7, offset: 3 }}
                  className="d-flex justify-content-center"
                >
                  {/* {loading && <Loader />} */}
                </Col>
              </Form.Group>
            </form>
          </div>
        </div>
      </div>
    );
  }
};

export default EditOnlineCME;
