import { FC } from "react";
import { Modal } from "react-bootstrap";
import MButton from "../../../mButton/MButton";

const RemoveUserConfirmationModal: FC<{
  show: boolean;
  name?: string;
  handleClose?: () => void;
  handleConfirm?: () => void;
}> = ({ show, name = "", handleClose, handleConfirm }) => {
  return (
    <Modal size="lg" show={show} onHide={handleClose} centered>
      <Modal.Header>Confirmation Required</Modal.Header>
      <Modal.Body>
        <div>
          <p>Please confirm the removal of {name} from your practice.</p>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <MButton
          onClick={handleConfirm}
          variant="danger"
          title="Remove"
          width={"max-content"}
        />
        <MButton
          onClick={handleClose}
          variant="secondary"
          title="Cancel"
          width={"max-content"}
        />
      </Modal.Footer>
    </Modal>
  );
};

export default RemoveUserConfirmationModal;
