import { CancelTokenSource } from "axios";
import { customAxios as axios } from "./ServiceConfig";
import { API_BASE_URL } from "../config";
import IPhysicianDetail from "../types/IPhysicianDetail";
import IPhysicianSearchResult from "../types/IPhysicianSearchResult";
let loginas = localStorage.getItem('loginAsFlag')
let loginasEmail = localStorage.getItem('loginAsEmail')
/**
 * @param searchTerm receives the searched term
 * @param token receives the access token of type string
 */
const searchPhysician = async (
  searchTerm: string,
  token?: string,
  cancelToken?: CancelTokenSource
) => {
  const result = await axios.get<IPhysicianSearchResult[]>(
    //   `${API_BASE_URL}/referral/${id}`,
    `${API_BASE_URL}/physician?searchTerm=${searchTerm}`,
    loginas !== null ? {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Pragma: "no-cache",
        Authorization: `Bearer ${token}`,
        loginas:loginas !== null ? loginas : JSON.stringify(false),
        email:loginasEmail !== null ? loginasEmail : "",
      },
      cancelToken: cancelToken?.token,
    }:{
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Pragma: "no-cache",
        Authorization: `Bearer ${token}`,
      },
      cancelToken: cancelToken?.token,
    }
  );
  return result;
};

/**
 * @param token receives the access token of type string
 */
const getPhysician = async (
  id: number,
  token?: string,
  cancelToken?: CancelTokenSource
) => {
  const result = await axios.get<IPhysicianDetail>(
    `${API_BASE_URL}/physician/${id}`,
    loginas !== null ? {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Pragma: "no-cache",
        Authorization: `Bearer ${token}`,
        loginas:loginas !== null ? loginas : JSON.stringify(false),
        email:loginasEmail !== null ? loginasEmail : "",
      },
      cancelToken: cancelToken?.token,
    }:{
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Pragma: "no-cache",
        Authorization: `Bearer ${token}`,
      },
      cancelToken: cancelToken?.token,
    }
  );
  return result;
};

const PhysicianService = {
  searchPhysician,
  getPhysician,
};

export default PhysicianService;
