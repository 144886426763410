import { FC, useEffect, useState } from "react";
import { Form, Modal } from "react-bootstrap";
import MButton from "../../../mButton/MButton";
import { Field, Formik, Form as FormikForm } from "formik";
import CustomInputFormik from "../../../common/CustomInputFormik";
import IInviteNonPhysician from "../../../../types/IInviteNonPhysician";
import AccountService from "../../../../services/AccountService";
import { appInsights } from "../../../../appInsights";
import axios from "axios";
import { useOktaAuth } from "@okta/okta-react";
import * as Yup from "yup";
import CustomAlert from "../../../common/CustomAlert";

const AddNonPhysicianToPracticeModal: FC<{
  show: boolean;
  handleClose?: () => void;
  handleAdd?: () => void;
  handleError?: () => void;
}> = ({ show, handleClose, handleAdd, handleError }) => {
  const { authState } = useOktaAuth();
  const accessToken = authState?.accessToken?.accessToken;
  const cancelToken = axios.CancelToken.source();
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<boolean>(false);
  const initialValues = {
    firstName: "",
    lastName: "",
    midName: "",
    email: "",
    mobilePhone: "",
    roleId: 2,
  };

  return (
    <Modal
      size="xl"
      show={show}
      onHide={() => {
        setError(false);
        if (handleClose) {
          handleClose();
        }
      }}
      centered
    >
      <Formik
        initialValues={initialValues}
        validationSchema={Yup.object().shape({
          email: Yup.string().email("Email is not valid").max(255),
          mobilePhone: Yup.string().matches(
            /^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4}$/im,
            "Phone number is not valid"
          ),
        })}
        onSubmit={(values, { setSubmitting }) => {
          AccountService.emailExists(values.email, accessToken, cancelToken)
            .then((response) => {
              if (response.data === false) {
                setError(false);
                AccountService.inviteNonPhysician(
                  values,
                  accessToken,
                  cancelToken
                )
                  .then((response) => {
                    setSubmitting(false);
                    if (handleAdd) {
                      handleAdd();
                    }
                  })
                  .catch((e) => {
                    setSubmitting(false);
                    if (!axios.isCancel(e)) {
                      console.error(e);
                      appInsights.trackException({ error: e });
                      // setError(e);
                      if (handleError) {
                        handleError();
                      }
                    }
                  });
              } else {
                setSubmitting(false);
                setError(true);
              }
            })
            .catch((e) => {
              setError(false);
              setSubmitting(false);
              if (!axios.isCancel(e)) {
                console.error(e);
                appInsights.trackException({ error: e });
                // setError(e);
                if (handleError) {
                  handleError();
                }
              }
            });
        }}
      >
        {({ isSubmitting, values }) => (
          <FormikForm>
            <Modal.Header>Add non-physician to my practice</Modal.Header>
            <Modal.Body>
              <Field
                label="Role"
                name="roleId"
                required={true}
                component={({ field, ...props }: any) => (
                  <Form.Group className="d-flex row align-items-center form-padding">
                    <Form.Label className="col-sm-3">
                      {props.label}
                      {props.required ? (
                        <span className="p-0 asterik"> *</span>
                      ) : (
                        <></>
                      )}
                    </Form.Label>
                    <div className="col-sm-9">
                      <Form.Select
                        {...props}
                        {...field}
                        name={field.name}
                        required={props.required}
                      >
                        <option value={2}>
                          Medical record access, Non-physician
                        </option>
                        <option value={8}>
                          No medical record access needed
                        </option>
                      </Form.Select>
                    </div>
                  </Form.Group>
                )}
              />
              <Field
                label="First Name"
                name="firstName"
                required={true}
                type="text"
                component={CustomInputFormik}
              />
              <Field
                label="Middle Name"
                name="midName"
                required={false}
                type="text"
                component={CustomInputFormik}
              />
              <Field
                label="Last Name"
                name="lastName"
                required={true}
                type="text"
                component={CustomInputFormik}
              />
              <Field
                label="Email"
                name="email"
                required={true}
                type="email"
                component={CustomInputFormik}
              />
              {error && (
                <span style={{ color: "var(--color-red)", marginLeft: "25%" }}>
                  A user with this email already exists.
                </span>
              )}
              <Field
                label="Mobile Phone"
                name="mobilePhone"
                required={true}
                type="text"
                component={CustomInputFormik}
              />
            </Modal.Body>
            <Modal.Footer>
              <div style={{ flex: 1 }}>
                Note: This person will be added to your practice and
                subsequently invited to join myCHLA.
              </div>
              <MButton
                type="submit"
                variant="primary"
                title="Add"
                width={"max-content"}
                isSubmitting={isSubmitting}
              />
              <MButton
                onClick={() => {
                  setError(false);
                  if (handleClose) {
                    handleClose();
                  }
                }}
                variant="secondary"
                title={"Cancel"}
                width={"max-content"}
              />
            </Modal.Footer>
          </FormikForm>
        )}
      </Formik>
    </Modal>
  );
};

export default AddNonPhysicianToPracticeModal;
