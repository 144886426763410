import React from "react";
import { FC, useEffect, useState } from "react";
import HeaderName from "../../headerName/HeaderName";
import { Badge, OverlayTrigger, Table, Col } from "react-bootstrap";
import FormPopover from "../../common/FormPopover";
import IAnnouncementBanner from "../../../types/IAnnouncementBanner";
import IReferral from "../../../types/IReferral";
import Sortable from "../../common/Sortable";
import MButton from "../../mButton/MButton";
import { padding } from "@mui/system";
import { useHistory, useLocation } from "react-router-dom";
import CustomToggle from "../../toggle/CustomToggle";
import AnnouncementService from "../../../services/AnnouncementService";
import { useOktaAuth } from "@okta/okta-react";
import Loader from "../../common/Loader";
import axios from "axios";
import { getFormattedDateFromDate, getFormattedTimeFromDateTime } from "../../../helpers/DateHelper";
import DisplayBanner from "../../common/DisplayBanner";
import usePagination from "../../../hooks/usePagination";
import CustomPagination from "../../common/CustomPagination";
import { appInsights } from "../../../appInsights";
const sortable = require("../../../assets/images/sortable.png").default;

const AnnouncementBanner: FC<{ name?: string }> = ({ name }) => {
  const history = useHistory();
  const [apiCallCount, setApiCallCount] = useState(0);
  const { authState } = useOktaAuth();
  const accessToken = authState?.accessToken?.accessToken;
  const [delegationToggle, setdelegationToggle] = useState(true);
  const [loading, setLoading] = useState(true);
  const cancelToken = axios.CancelToken.source();
  // const [announcementData, setAnnouncementData, page, setPage, , , totalPages] =
  //   usePagination<IAnnouncementBanner>("announcement_banner");
    const [announcementData, setAnnouncementData] =
    useState<IAnnouncementBanner[]>();
  const [error, setError] = useState<Error | null>(null);
  const tableCellPadding = { padding: 10};
  const tableHeadPadding = { paddingLeft: 20, paddingRight: 20 };
  const handleClick = () => {
    history.push("/portalupdates/components/newformannouncement");
  };

  const handleOnClick = (announce: IAnnouncementBanner) => {
    history.push("/portalupdates/components/editformannouncement", announce);
  };

  const onToggle = () => {};

  useEffect(() => {
    if (announcementData === undefined || announcementData.length === 0) {
      setLoading(true);
      setError(null);
      AnnouncementService.getAnnouncementServiceList(accessToken)
        .then((response) => {
          setLoading(false);
          setAnnouncementData(response.data);
        })
        .catch((e: Error) => {
          if (!axios.isCancel(e)) {
            console.error(e);
            appInsights.trackException({ error: e });
             setError(e);
          }
          
          setLoading(false);
        });
    } else {
      setLoading(false);
    }
    return () => {
      cancelToken.cancel();
    };
  }, [apiCallCount]);

  const incrementCount = () => {
    setApiCallCount((c) => c + 1);
  };
  return (
    <>
      {/* <DisplayBanner count={apiCallCount} /> */}
      <div className="referrals">
        <HeaderName name={name} />
        <div className="container">
          <h2 className="heading-main">{name}</h2>
          <div
            className="d-flex row justify-content-end"
            style={{ padding: 20 }}
          >
            <MButton
              onClick={handleClick}
              variant="primary"
              disabled={false}
              title="Add New"
              width={"fit-content"}
            />
          </div>
          <Col
            sm={{ span: 7, offset: 3 }}
            className="d-flex justify-content-center"
          >
            {loading && <Loader />}
          </Col>
          <br />
          <div className="referral">
            <Table id="responsive-table" className="mt-2" responsive>
              <thead>
                <tr>
                  <th style={tableHeadPadding}>Message</th>
                  <th style={tableHeadPadding}>Type</th>
                  <th style={tableHeadPadding}>Valid From </th>
                  <th style={tableHeadPadding}>Valid To </th>
                  <th style={tableHeadPadding}>Modified On </th>
                  <th style={tableHeadPadding}>Modified By </th>
                </tr>
              </thead>
              <tbody>
                {announcementData?.map((announce) => {
                  return (
                    <tr key={announce.id}>
                      <td
                      style={tableCellPadding}
                        data-title="Message"
                        id="table-text-ambulatory"
                        onClick={() => handleOnClick(announce)}
                      >
                        {announce.message}
                      </td>
                      <td style={tableCellPadding} data-title="Type">
                        {announce.cssClassId === 1
                          ? "Success "
                          : announce.cssClassId === 2
                          ? "Info "
                          : announce.cssClassId === 3
                          ? " Danger"
                          : announce.cssClassId === 4
                          ? "Warning"
                          : ""}
                      </td>

                      <td style={tableCellPadding} data-title="Valid From">
                        {getFormattedDateFromDate(announce.validFrom)}
                        <br/>
                        {getFormattedTimeFromDateTime(announce.validFrom)}
                      </td>

                      <td style={tableCellPadding} data-title="Valid To">
                        {getFormattedDateFromDate(announce.validTo)}
                        <br/>
                        {getFormattedTimeFromDateTime(announce.validTo)}
                      </td>
                      <td style={tableCellPadding} data-title="Modified On">
                        {getFormattedDateFromDate(announce.modifiedOn)}
                      </td>
                      <td style={tableCellPadding} data-title="Modified By">{announce.modifiedBy}</td>
                    </tr>
                  );
                })}
              </tbody>
            </Table>
          </div>
        </div>
      </div>
    </>
  );
};

export default AnnouncementBanner;
