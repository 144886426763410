import { FC, useEffect, useState } from "react";
import HeaderName from "../../../headerName/HeaderName";
import { Redirect, useHistory, useLocation } from "react-router-dom";
import { Form, Col, Button } from "react-bootstrap";
import DateHelper, { getFormattedDate } from "../../../../helpers/DateHelper";
import AmbulatoryService from "../../../../services/AmbulatoryService";
import { useOktaAuth } from "@okta/okta-react";
import { Alert } from "@mui/material";
import MButton from "../../../mButton/MButton";
import BackButton from "../../../backButton/BackButton";
import Loader from "../../../common/Loader";
import { appInsights } from "../../../../appInsights";
import axios from "axios";

const NewAmbulatorySchedules: FC<{
  name?: string;
}> = ({ name }) => {
  const isDisabled = false;
  const { authState } = useOktaAuth();
  const accessToken = authState?.accessToken?.accessToken;
  const [redirect, setRedirect] = useState({ redirect: false, message: "" });
  const [formData, setFormData] = useState<any>({});
  const [loading, setLoading] = useState(false);

  const DATE = getFormattedDate(new Date());

  const handleOnChange = (e: any, key: any) => {
    let data: any = formData;
    if (key === "isActive") {
      data[key] = e.target.checked;
    } else {
      data[key] = e.target.value;
    }
    data.title = data.title.trimStart();
    data.title = data.title.trimEnd();
    setFormData(data);
  };
  let form = require("../../../../data/newAmbulatorySchedules.json");

  const handleSubmit = (e: any) => {
    e.preventDefault();
    if (formData?.title === undefined||formData?.title.trim() === "") {
      {window.scrollTo(0, 0)}
      setRedirect({ redirect: false, message: "Title is missing" });
      return;
    }
    formData.title = formData?.title.trimStart();
    formData.title = formData?.title.trimEnd();
    setLoading(true)
    AmbulatoryService.createNewAmbulatoryRequest(formData, accessToken)
      .then((response) => {
        setRedirect({ redirect: false, message: "success" });
        setTimeout(() => {
          setLoading(false)
          setRedirect({ redirect: true, message: "" });
        }, 3000);
      })
      .catch((err) => {
        if (!axios.isCancel(e)) {
          console.error(e);
          appInsights.trackException({ error: e });
          // setError(e);
        }
        setLoading(false)
        setRedirect({ redirect: false, message: "error" });
      });
  };
  if (redirect.redirect) {
    return (
      <Redirect
        to={{
          pathname: "/portalupdates/ambulatorycarecenter",
          state: { message: redirect.message },
        }}
      />
    );
  } else {
    return (
      <div className="referrals">
        <HeaderName name={name} />
        <BackButton />
        {redirect.message === "success" ? (
          <>
            {window.scrollTo(0, 0)}
            <Alert severity="success">
              Form is successfully submitted,please wait while we are
              redirecting!
            </Alert>
          </>
        ) : redirect.message === "error" ? (
          <>
            <Alert severity="error" style={{ color: "#EA1D36" }}>
              Error in submitting the form, please try again!
            </Alert>
          </>
        ) : redirect.message ? (
          <>
            <Alert severity="error" style={{ color: "#EA1D36" }}>
              {redirect.message}
            </Alert>
          </>
        ) : (
          <></>
        )}
        <div className="container">
          <h2 className="heading-main">New Ambulatory Schedule</h2>
          <div className="d-flex row justify-content-end"></div>

          <div className="referral">
            <form className="m-sm-5" onSubmit={handleSubmit}>
              {Object.entries(form).map((field, index) => {
                const key = field[0];
                const value: any = field[1];
                return (
                  <Form.Group
                    key={index}
                    className="d-flex row align-items-center form-padding"
                  >
                    <Form.Label column sm={3} className="pl-0 pr-0">
                      {value.display}
                      {value.required ? (
                        <span className="p-0 asterik"> *</span>
                      ) : null}
                    </Form.Label>
                    <Col sm={9} className="pl-0 pr-0">
                      {value.type === "textarea" ? (
                        <Form.Control
                          as={value.type}
                          required
                          onChange={(e) => handleOnChange(e, key)}
                          disabled={isDisabled}
                        />
                      ) : value.type === "select" &&
                        key === "announcementType" ? (
                        <Form.Control
                          as={value.type}
                          custom
                          required={value.required}
                          onChange={(e) => handleOnChange(e, key)}
                          disabled={isDisabled}
                        >
                          {value.value.length !== 0
                            ? value.value.map((v: any, index: any) => (
                                <option key={v.value} value={v.value}>
                                  {v.display}
                                </option>
                              ))
                            : null}
                        </Form.Control>
                      ) : value.type === "date" ? (
                        <Form.Control
                          max={DATE}
                          onChange={(e) => handleOnChange(e, key)}
                          type={value.type}
                          required
                          disabled={isDisabled}
                        />
                      ) : value.type === "checkbox" ? (
                        <Form.Check
                          type={value.type}
                          
                          onChange={(e) => handleOnChange(e, key)}
                          disabled={isDisabled}
                        />
                      ) : (
                        <Form.Control
                          onChange={(e) => handleOnChange(e, key)}
                          type={value.type}
                          required={value.required}
                          disabled={isDisabled}
                        />
                      )}
                    </Col>
                  </Form.Group>
                );
              })}
              <Form.Group className="d-flex row">
                <Col
                  sm={{ span: 7, offset: 3 }}
                  className="d-flex justify-content-center"
                >
                  <MButton
                    variant="primary"
                    disabled={loading}
                    title="Save"
                    width={"fit-content"}
                    type="submit"
                  >
                    Save
                  </MButton>
                </Col>
                <Col
          sm={{ span: 7, offset: 3 }}
          className="d-flex justify-content-center"
        >
          {loading && <Loader />}
        </Col>
              </Form.Group>
            </form>
          </div>
        </div>
      </div>
    );
  }
};

export default NewAmbulatorySchedules;
