import { FC, useEffect, useState } from 'react';
import { useOktaAuth } from "@okta/okta-react";
import HeaderName from "../../headerName/HeaderName";
import axios, { AxiosError } from "axios";
import BackButton from "../../backButton/BackButton";
import { Form, Col, Button, Alert } from "react-bootstrap";
import { Field, Formik, Form as FormikForm, ErrorMessage } from "formik";
import * as Yup from 'yup'
import CustomInputFormik from '../../common/CustomInputFormik';
import MButton from '../../mButton/MButton';
import useSessionState from '../../../hooks/useSessionState';
import AccountService from '../../../services/AccountService';

import { Modal } from "react-bootstrap";
// import "./reminder-popup.css";

const ConfirmationPopup:FC<{showModal:boolean;
  onClick?: (e?: any) => void;
  name:string;
  practiceName:string;
  onClose:()=>void
}> = ({showModal,name,practiceName,onClick,onClose}) => {
  //
  const [show, setShow] = useState(showModal);
  const { authState, oktaAuth } = useOktaAuth();
  const accessToken = authState?.accessToken?.accessToken;

  const handleClose = () => {
    onClose()
    setShow(false);
  }
  const handleShow = () => setShow(true);
  

  return (
    <>
      <Modal show={show} onHide={handleClose} id="Modal-Reminder">
        <Modal.Header closeButton>
          <Modal.Title>Confirm</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {`Are you sure you want to login as "${name}" of practice "${practiceName}"?`}
          <br/>
          {`Please note that your activity will be monitored and logged.`}
        </Modal.Body>
        <Modal.Footer>
          <div>
            <MButton
              variant="secondary"
              type="submit"
              title="Yes"
              width={"fit-content"}
              onClick={()=>{
                onClick && onClick()
              }}
            >
              Yes
            </MButton>
            <MButton
              variant="secondary"
              type="submit"
              title="No"
              width={"fit-content"}
              onClick={handleClose}
            >
              No
            </MButton>
          </div>
        </Modal.Footer>
      </Modal>
    </>
  );
};
const LoginAsAnotherUser: FC<{ name?: string }> = (name) => {
    // console.log(name)

    const { authState } = useOktaAuth();
    const accessToken = authState?.accessToken?.accessToken;
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(false);
    const [showModal, setshowModal] = useState(false);
    const [errorMsg, setErrorMsg] = useState("The email you entered is invalid.");
    const [userName, setUserName] = useState("");
    const [emailId, setEmailId] = useState("");
    const [practiceName, setPracticeName] = useState("");
    const [loginAsAnotherUser, setLoginAsAnotherUser] = useSessionState("loginAsAnotherUser", false);
    const [loginAsAnotherUserEmailId, setLoginAsAnotherUserEmailId] = useSessionState("loginAsAnotherUserEmailId", "");
    const cancelToken = axios.CancelToken.source();
    const handleSubmit = (e: any) => {
        e.preventDefault();

    };
    const initialValue = {
        
        email: "",
        
      };
    return (
        <div>
            <div className="container">
                <h2 className="heading-main">{name.name}</h2>
                <div className="new-patient-access-request"  >
                 {showModal === true? <ConfirmationPopup showModal={true} name={userName} practiceName={practiceName} onClick={()=>{
                  AccountService.loginAsInitiate({email:emailId},accessToken,cancelToken).then((res)=>{
                    setError(false)
                    
                    localStorage.setItem('loginAsFlag',"true")
                    localStorage.setItem('loginAsEmail',emailId)
                    window.location.href=window.location.origin
                  }).catch((error)=>{
                    setError(true)
                    setErrorMsg(error.response.data.message)
                  })
                 }} 
                 onClose={()=>{
                  setshowModal(false)
                 }}/>:null}
                {error ? (
                      <Alert
                        key={1}
                        variant={"danger"}
                        style={{ marginBottom: "0px" }}
                      >
                        {errorMsg}
                      </Alert>
                    ) : (
                      <></>
                    )}
                    <div style={{width:'50%'}}>
                    
                    
                    <Formik
                    
          initialValues={initialValue}
          validationSchema={Yup.object().shape({
            email: Yup.string().email("Email is not valid").max(255).nullable(),
           
          })}
          onSubmit={({
            
            email,
            
          }) => {

            AccountService.loginAsValidator({email:email},accessToken,cancelToken).then((res)=>{
              setError(false)
              setUserName(res.data.firstName + " " + res.data.lastName)
              setPracticeName(res.data.practiceName) 
              setEmailId(email)
              setshowModal(true)
              // localStorage.setItem('loginAsFlag',"true")
              // localStorage.setItem('loginAsEmail',email)
              // window.location.href=window.location.origin
            }).catch((error)=>{
              setError(true)
              setErrorMsg(error.response.data.message)
            })
            
            

          }}
        >
          {({ touched, errors }) => (
            <FormikForm>
                <Field
                    label="Email"
                    name="email"
                    required={true}
                    type="email"
                    component={CustomInputFormik}
                  />
                  
              <MButton
                  variant="primary"
                  title="Login As Another User"
                  width={"max-content"}
                  type="submit"
                  marginLeft={'25%'} 
                  marginTop={'3%'}                 
                />
              
            </FormikForm>
          )}
        </Formik>
        </div>
                </div>
            </div>
        </div>
    )

}

export default LoginAsAnotherUser;