import { useState, FC, useEffect } from "react";
import { Redirect, useHistory, useLocation } from "react-router-dom";
import { Form, Col, Button } from "react-bootstrap";
import { Alert } from "@mui/material";
import { useOktaAuth } from "@okta/okta-react";
import { getFormattedDate } from "../../../helpers/DateHelper";
import HeaderName from "../../headerName/HeaderName";
import PatientAccessRequestService from "../../../services/PatientAccessRequestService";
import IReferralFormDropdownData from "../../../types/IReferralFormDropdownData";
import ReferralService from "../../../services/ReferralService";
import Spinner from "../../spinner/Spinner";
import MButton from "../../mButton/MButton";
import { appInsights } from "../../../appInsights";
import axios from "axios";

const NewPatientAccessRequest: FC<{ name?: string }> = ({ name }) => {
  const [referralFormDropdownData, setReferralFormDropdownData] =
    useState<IReferralFormDropdownData>();

  const isDisabled = false;
  const { authState } = useOktaAuth();
  const accessToken = authState?.accessToken?.accessToken;
  const [redirect, setRedirect] = useState({ redirect: false, message: "" });
  const [formData, setFormData] = useState({});
  const [loading, setLoading] = useState(false);
  const DATE = getFormattedDate(new Date());

  useEffect(() => {
    ReferralService.getReferralFormDataList(accessToken)
      .then((response) => {
        setReferralFormDropdownData(response.data);
      })
      .catch((e) => {
        if (!axios.isCancel(e)) {
          console.error(e);
          appInsights.trackException({ error: e });
          // setError(e);
        }
      });
  }, []);

  const handleOnChange = (e: any, key: any) => {
    let data: any = formData;
    if (key === "patientGenderId" || key === "onBehalfPhysicianId") {
      data[key] = Number(e.target.value);
    } else {
      data[key] = e.target.value;
    }
    setFormData(data);
  };
  const handleSubmit = (e: any) => {
    e.preventDefault();
    setLoading(true);
    PatientAccessRequestService.createNewPatientAccessRequest(
      formData,
      accessToken
    )
      .then((response) => {
        // setRedirect({ redirect: true, message: "success" });
        setRedirect({ redirect: false, message: "success" });
        setTimeout(() => {
          setLoading(false)
          setRedirect({ redirect: true, message: "" });
        }, 3000);
      })
      .catch((err) => {
        if (!axios.isCancel(e)) {
          console.error(e);
          appInsights.trackException({ error: e });
          // setError(e);
        }
        setLoading(false)
        setRedirect({ redirect: false, message: "error" });
      });
  };

  let form = require("../../../data/newPatientAccessRequestForm.json");
  const DEFAULT_OPTION = "Please make a selection...";

  const { onBehalfPhysicianId } = form;

  form = {
    ...form,
    onBehalfPhysicianId: {
      ...onBehalfPhysicianId,
      value: ([{ id: "", display: DEFAULT_OPTION }] as any[])
        .concat(referralFormDropdownData?.physicians)
        .map((display) => {
          if (display !== undefined) {
            return {
              value: display.id,
              display: display.display,
            };
          }
        }),
    },
  };

  if (
    referralFormDropdownData === undefined ||
    Object.keys(referralFormDropdownData).length === 0
  ) {
    return <Spinner margin="5rem 0" />;
  }
  if (redirect.redirect) {
    return (
      <Redirect
        to={{
          pathname: "/patients/patientaccessrequests",
          state: { message: redirect.message },
        }}
      />
    );
  } else {
    return (
      <div>
        <HeaderName name={name} />
        {redirect.message === "success" ? (
          <>
            {window.scrollTo(0, 0)}
            <Alert severity="success">
              Form is successfully submitted,please wait while we are
              redirecting!
            </Alert>
          </>
        ) : redirect.message === "error" ? (
          <>
            {window.scrollTo(0, 0)}
            <Alert severity="error" style={{ color: "#EA1D36" }}>
              Error in submitting the form, please try again!
            </Alert>
          </>
        ) : redirect.message ? (
          <>
            {window.scrollTo(0, 0)}
            <Alert severity="error" style={{ color: "#EA1D36" }}>
              {redirect.message}
            </Alert>
          </>
        ) : (
          <></>
        )}
        <div className="container">
          <h2 className="heading-main">{name}</h2>
          <p style={{ margin: "10px" }}>
            Please fill out the form below to request access to a patient's
            electronic medical record. We will process your request within 48
            hours.
          </p>
          <div className="new-patient-access-request">
            <form className="m-sm-5" onSubmit={handleSubmit}>
              {Object.entries(form).map((field, index) => {
                const key = field[0];
                const value: any = field[1];
                return (
                  <Form.Group
                    key={index}
                    className="d-flex row align-items-center form-padding"
                  >
                    <Form.Label column sm={3} className="pl-0 pr-0 form-label">
                      {value.display}
                      {value.required ? (
                        <span className="p-0 asterik"> *</span>
                      ) : null}
                    </Form.Label>
                    <Col sm={9} className="pl-0 pr-0">
                      {value.type === "select" && key === "patientGenderId" ? (
                        <Form.Select
                          as={value.type}
                          custom
                          required={value.required}
                          // defaultValue={handleDefaultValue(value.type, key)}
                          onChange={(e) => handleOnChange(e, key)}
                          disabled={isDisabled}
                        >
                          {value.value.length !== 0
                            ? value.value.map((v: any, index: any) => (
                                <option key={v.value} value={v.value}>
                                  {v.display}
                                </option>
                              ))
                            : null}
                        </Form.Select>
                      ) : value.type === "select" &&
                        key === "onBehalfPhysicianId" ? (
                        <Form.Select
                          as={value.type}
                          custom
                          required={value.required}
                          // defaultValue={handleDefaultValue(value.type, key)}
                          onChange={(e) => handleOnChange(e, key)}
                          disabled={isDisabled}
                        >
                          {value.value.length !== 0
                            ? value.value.map((v: any, index: any) => (
                                <option value={v.value} key={index}>
                                  {v.display}
                                </option>
                              ))
                            : null}
                        </Form.Select>
                      ) : value.type === "textarea" ? (
                        <Form.Control
                          as={value.type}
                          required
                          // defaultValue={handleDefaultValue(value.type, key)}
                          onChange={(e) => handleOnChange(e, key)}
                          disabled={isDisabled}
                        />
                      ) : value.type === "date" ? (
                        <Form.Control
                          max={DATE}
                          onChange={(e) => handleOnChange(e, key)}
                          type={value.type}
                          required
                          // defaultValue={handleDefaultValue(value.type, key)}
                          disabled={isDisabled}
                        />
                      ) : (
                        <Form.Control
                          onChange={(e) => handleOnChange(e, key)}
                          type={value.type}
                          required={value.required}
                          // defaultValue={handleDefaultValue(value.type, key)}
                          disabled={isDisabled}
                        />
                      )}
                    </Col>
                  </Form.Group>
                );
              })}
              <Form.Group className="d-flex row">
                <Col
                  sm={{ span: 9, offset: 3 }}
                  className="d-flex justify-content-center"
                >
                  <MButton
                    variant="primary"
                    // disabled={loading}
                    loader = {loading}
                    title="Submit"
                    width={"fit-content"}
                    type="submit"
                  >
                    Submit
                  </MButton>
                </Col>
                <Col
                  sm={{ span: 7, offset: 3 }}
                  className="d-flex justify-content-center"
                >
                  {/* {loading && <Loader />} */}
                </Col>
                {/* <Col sm={{ span: 9, offset: 3 }} className="d-flex">
                  <Button className="submit-btn" type="submit">
                    Submit
                  </Button>
                </Col> */}
              </Form.Group>
            </form>
          </div>
        </div>
      </div>
    );
  }
};

export default NewPatientAccessRequest;
