import { FC, useEffect, useState } from "react";
import IAllAccounts, { AccountRolePerPractice } from "../../../../types/IAllAccounts";
import IAllAccountsRoles from "../../../../types/IAllRoles";
import IPatientAccessQuery from "../../../../types/IPatientAccessQuery";
import { useHistory } from "react-router-dom";
import { MDBDataTable } from "mdbreact";
import MButton from "../../../mButton/MButton";
import AccountService from "../../../../services/AccountService";
import { useOktaAuth } from "@okta/okta-react";
import axios from "axios";
import { appInsights } from "../../../../appInsights";
import {
  getFormattedDate,
  getFormattedTime,
  getFormattedDateTime,
  getFormattedDateFromDate,
  getFormattedDateFromDateTime,
  getFormattedDateTimeFromDateTime,
  getFormattedTimeFromDateTime
} from "../../../../helpers/DateHelper";




import "./all-accounts.css";

type stateType = {
  state: {
    account: IAllAccounts;
  };
};


const AllAccountsList: FC<{
  allAccounts: IAllAccounts[];
  query: IPatientAccessQuery;
}> = ({ allAccounts, query }) => {
  // const history = useHistory<stateType>();
  const [tableRows, setTableRows] = useState([]);
  const history = useHistory<stateType>();
  const { authState } = useOktaAuth();
  const accessToken = authState?.accessToken?.accessToken;
  const [error, setError] = useState<Error>();
  const cancelToken = axios.CancelToken.source();
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (allAccounts) {
      setTableRows(assembleData());
    }

  }, [allAccounts]);


  const onEditClick = (account: IAllAccounts) => {
    history.push(`/account/modify/${account.userId}`, { state: { account } });
  };

  const onDetailClick = (account: IAllAccounts) => {
    history.push(`/account/detail/${account.userId}`, { state: { account } });
  };

  const assembleData: any = () => {
    let isTrue = false;
    let patients = allAccounts.map((account) => {
      let domain = account.email?account.email.substring(account.email.lastIndexOf("@") + 1):null;
      if (domain === "chla.usc.edu".toLowerCase()) {
        isTrue = true;
      } else {
        isTrue = false;
      }


      return {
        firstName: account.firstName!=null?account.firstName.trim():null,
        lastName: account.lastName!=null?account.lastName.trim():null,
        email: account.email,
        practice: account?.accountRolePerPractice?.map(
          (practice: AccountRolePerPractice) => {
            return <p style={{margin: '0px'}}>{practice?.practiceName}</p>;
          }
        ),
        role: account?.accountRolePerPractice?.map(
          (role: AccountRolePerPractice) => {
            return <p style={{margin: '0px'}}>{role?.roleName}</p>;
          }
        ),
        lastLoggedIn: (`${getFormattedDateFromDate(account?.lastLoggedIn?.split('T')[0])} ${getFormattedTimeFromDateTime(account.lastLoggedIn)}`),
        button: isTrue ? (
          <MButton
            onClick={() => onDetailClick(account)}
            title="Details"
            variant="primary"
            small
            width="auto"
          />
        ) : (
          <MButton
            onClick={() => onEditClick(account)}
            title="Edit"
            variant="primary"
            small
            width="auto"
          />
        ),
      };
    });
    return patients;
  };
  const data = {
    columns: [
      {
        label: "First Name",
        field: "firstName",
        sort: "asc",
        width: 100,
      },
      {
        label: "Last Name",
        field: "lastName",
        sort: "asc",
        width: 100,
      },
      {
        label: "Email",
        field: "email",
        sort: "disabled",
        width: 100,
      },
      {
        label: "Practices",
        field: "practice",
        sort: "disabled",
        width: 100,
      },
      {
        label: "Role(s)",
        field: "role",
        sort: "disabled",
        width: 50,
      },
      {
        label: "Last Login",
        field: "lastLoggedIn",
        sort: "asc",
        width: 50,
      },
      {
        label: "",
        field: "button",
        sort: "disabled",
        width: 50,
      },
    ],
    rows: tableRows,
  };

  return (
    <MDBDataTable
      responsive
      key={data.rows.length}
      small
      data={data}
      id="responsive-table"
      sorting="true"
    />
  );
};

export default AllAccountsList;
