/* 
 Used in :
 ** Infowell.tsx

 Usage :
 ** define the OnClick logic for this Button

 *** Please mark here if you are using this component elsewhere ***
 */

import axios, { AxiosResponse } from "axios";
import { FC, useState } from "react";
import { appInsights } from "../../appInsights";
import EducationService from "../../services/EducationService";
import MButton from "../mButton/MButton";
import Spinner from "react-bootstrap/Spinner";

const GoButton: FC<{
  handleSubmit: (e?:any) => void
  loading?: boolean;
}> = ({ handleSubmit, loading=false}) => {

  return (
    <>
      {loading ? (
        <Spinner animation="border" className="m-auto" />
      ) : (
        <MButton
          variant="secondary"
          disabled={false}
          title={"Go  " + "\u00bb"}
          width={"100%"}
          type="submit"
          alignItem={"flex-end"}
          property="infowellButton"
          infoButton="infowellButton"
          onClick={handleSubmit}
        >
          {"Go  " + "\u00bb"}
        </MButton>
      )}
    </>
  );
};

export default GoButton;
