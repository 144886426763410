import { FC, useEffect, useState } from "react";
import HomeService from "../../../services/HomeService";

const PracticeNotActive: FC = () => {
  const [statement, setStatement] = useState<string>("myCHLA resources are temporarily unavailable to Your Practice. This could be the result of a practice owner change");
  useEffect(() => {
    HomeService.GetPracticeNotActiveStatement()
    .then((data) => setStatement(data.data))
    .catch((e) => setStatement('myCHLA resources are temporarily unavailable to Your Practice. This could be the result of a practice owner change'))
  }, [])
  return (
    <div className='pb-5 pt-5 d-flex align-items-center'>
      <div className="m-auto mt-5 mb-5">{statement}</div>
    </div>
  );
};

export default PracticeNotActive;