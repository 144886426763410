import { FC } from "react";
import { Popover } from "react-bootstrap";

const FormPopover = (title: string, body: string) => {
  return (
    <Popover id="form-popover">
      <Popover.Header as="h3">{title}</Popover.Header>
      <Popover.Body>{body}</Popover.Body>
    </Popover>
  );
};

export default FormPopover;
