import React from "react";
import { FC, useEffect, useState } from "react";
import HeaderName from "../../headerName/HeaderName";
import { Badge, OverlayTrigger, Table, Col } from "react-bootstrap";
import FormPopover from "../../common/FormPopover";
import IPortalUpdateAmbulatory from "../../../types/IPortalUpdateAmbulatory";
import IAmbulatoryQuery from "../../../types/IAmbulatoryQuery";
import Sortable from "../../common/Sortable";
import MButton from "../../mButton/MButton";
import CustomPagination from "../../common/CustomPagination";
import { useHistory, useLocation, Link } from "react-router-dom";
import CustomToggle from "../../toggle/CustomToggle";
import AmbulatoryService from "../../../services/AmbulatoryService";
import { useOktaAuth } from "@okta/okta-react";
import Loader from "../../common/Loader";
import { getFormattedDateFromDate } from "../../../helpers/DateHelper";
import { Checkbox } from "@mui/material";
import { appInsights } from "../../../appInsights";
import axios from "axios";
const sortable = require("../../../assets/images/sortable.png").default;

const AmbulatoryCareCenter: FC<{
  name?: string;
}> = ({ name }) => {
  const history = useHistory();
  const tableCellPadding = { padding: 20 };
  const tableHeadPadding = { paddingLeft: 20, paddingRight: 20 };
  const handleClick = () => {
    history.push("/portalupdates/components/newambulatoryschedules");
  };
  const handleOnClick = (ambulatory: IPortalUpdateAmbulatory) => {
    history.push("/portalupdates/components/editambulatory", ambulatory);
  };
  const { authState } = useOktaAuth();
  const accessToken = authState?.accessToken?.accessToken;
  const [loading, setLoading] = useState(true);

  const [ambulatoryData, setAmbulatoryData] =
    useState<IPortalUpdateAmbulatory[]>();
  const [error, setError] = useState<Error | null>(null);

  const onToggle = () => {};
  useEffect(() => {
    setLoading(true);
    setError(null);
    AmbulatoryService.getAmbulatoryList(accessToken)
      .then((response) => {
        setLoading(false);
        setAmbulatoryData(response.data);
      })
      .catch((e: Error) => {
        if (!axios.isCancel(e)) {
          console.error(e);
          appInsights.trackException({ error: e });
        setError(e);
        }
       
        setLoading(false);
      });
  }, []);

  return (
    <div>
      <HeaderName name={name} />
      <div className="container">
        <h2 className="heading-main">{name}</h2>
        <div className="d-flex row justify-content-end" style={{ padding: 20 }}>
          <MButton
            variant="primary"
            disabled={false}
            onClick={handleClick}
            title="Add New"
            width={"fit-content"}
          />
        </div>
        <Col
          sm={{ span: 7, offset: 3 }}
          className="d-flex justify-content-center"
        >
          {loading && <Loader />}
        </Col>
        <br />

        <div className="referral">
          <Table id="responsive-table" className="mt-2" responsive>
            <thead>
              <tr>
                <th style={tableHeadPadding} className="hover">
                  Title
                </th>
                <th style={tableHeadPadding}>URL </th>
                <th style={tableHeadPadding}>Is Active</th>
                <th style={tableHeadPadding}>Publication Date</th>
                <th style={tableHeadPadding}>Modified On</th>
                <th style={tableHeadPadding}>Modified By</th>
              </tr>
            </thead>
            <tbody>
              {ambulatoryData?.map((ambulatory) => {
                return (
                  <tr>
                    <td
                      style={tableCellPadding}
                      data-title="Title"
                      id="table-text-ambulatory"
                      onClick={() => handleOnClick(ambulatory)}
                    >
                      {ambulatory.title}
                    </td>
                    <td
                      style={{
                        maxWidth: 400,
                        ...tableCellPadding,
                        wordWrap: "break-word",
                      }}
                      data-title="URL"
                    >
                      {ambulatory.url}
                    </td>
                    <td style={tableCellPadding} data-title="Is Active">
                    <Checkbox disabled 
                        checked={ambulatory.isActive}
                                             />
                    </td>
                    <td style={tableCellPadding} data-title="Publication Date">
                      {getFormattedDateFromDate(ambulatory.publicationDate)}
                    </td>
                    <td style={tableCellPadding} data-title="Modified On">
                      {getFormattedDateFromDate(ambulatory.modifiedOn)}
                    </td>
                    <td style={tableCellPadding} data-title="Modified By">
                      {ambulatory.modifiedBy}
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </Table>
          <div
            className="col d-flex justify-content-center"
            style={{ marginTop: "60px" }}
          ></div>
        </div>
      </div>
    </div>
  );
};

export default AmbulatoryCareCenter;
