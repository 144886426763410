import { FC, useEffect, useState } from "react";
import HeaderName from "../../../headerName/HeaderName";
import { Redirect, useHistory, useLocation } from "react-router-dom";
import { Form, Col, Button } from "react-bootstrap";
import DateHelper, {
  getFormattedDate,
  getFormattedDateFromDateTime,
} from "../../../../helpers/DateHelper";
import PracticeTipService from "../../../../services/PracticeTipService";
import { useOktaAuth } from "@okta/okta-react";
import { Alert } from "@mui/material";
import IPortalPracticeTip from "../../../../types/IPortalPracticeTip";
import MButton from "../../../mButton/MButton";
import BackButton from "../../../backButton/BackButton";
import Loader from "../../../common/Loader";
import { TrendingUpTwoTone } from "@material-ui/icons";
import { appInsights } from "../../../../appInsights";
import axios from "axios";
const EditPracticeTipOfMonth: FC<{
  name?: string;
}> = ({ name }) => {
  const isDisabled = false;
  const [delegationToggle, setdelegationToggle] = useState(true);
  const { authState } = useOktaAuth();
  const accessToken = authState?.accessToken?.accessToken;
  const [redirect, setRedirect] = useState({ redirect: false, message: "" });
  const { state: practiceTip } = useLocation<IPortalPracticeTip>();
  const DATE = getFormattedDate(new Date());
  const [data, setData] = useState(practiceTip);
  const [loading, setLoading] = useState(false);

  const handleOnChange = (e: any, key: string) => {
    let value: any;
    if (key === "Attachment") {
      value = e.target.files[0];
    } else if (key === "isActive") {
      value = e.target.checked;
    } else {
      value = e.target.value;
    }
    setData((prev) => ({ ...prev, [key]: value }));
  };

  const handleSubmit = (e: any) => {
    e.preventDefault();
    if (data === undefined) {
      alert("Cannot submit an empty form. Please attach an image to upload.");
    } else {
      setLoading(true);
      let formData = new FormData();
      Object.entries(data).map((entry) => {
        const key = entry[0];
        const value: any = entry[1];
        if (value !== null) {
          formData.append(key, value);
        }
      });
      {window.scrollTo(0, 0)}
      PracticeTipService.editPracticeTipServiceRequest(formData, accessToken)
        .then((response) => {
          setRedirect({ redirect: false, message: "success" });
          setTimeout(() => {
            setLoading(false)
            setRedirect({ redirect: true, message: "" });
          }, 3000);
        })
        .catch((err) => {
          if (!axios.isCancel(e)) {
            console.error(e);
            appInsights.trackException({ error: e });
            // setError(e);
          }
          setLoading(false);
          setRedirect({ redirect: false, message: "error" });
        });
    }
  };
  if (redirect.redirect) {
    return (
      <Redirect
        to={{
          pathname: "/portalupdates/practicetipofthemonth",
          state: { message: redirect.message },
        }}
      />
    );
  } else {
    return (
      <div className="referrals">
        <HeaderName name={name} />
        <BackButton  />
        {redirect.message === "success" ? (
          <>
            {window.scrollTo(0, 0)}
            <Alert severity="success">
              Form is successfully submitted,please wait while we are
              redirecting!
            </Alert>
          </>
        ) : redirect.message === "error" ? (
          <>
            <Alert severity="error" style={{ color: "#EA1D36" }}>
              Error in submitting the form, please try again!
            </Alert>
          </>
        ) : redirect.message ? (
          <>
            <Alert severity="error" style={{ color: "#EA1D36" }}>
              {redirect.message}
            </Alert>
          </>
        ) : (
          <></>
        )}
        <div className="container">
          <h2 className="heading-main">Edit Practice Tip Of The Month</h2>
          <div className="d-flex row justify-content-end"></div>

          <div className="referral">
            <form className="m-sm-5" onSubmit={handleSubmit}>
              <Form.Group className="d-flex row align-items-center form-padding">
                <Form.Label column sm={3} className="pl-0 pr-0">
                  Title <span className="p-0 asterik"> *</span>
                </Form.Label>

                <Col sm={9} className="pl-0 pr-0">
                  <Form.Control
                    onChange={(e) => handleOnChange(e, "title")}
                    placeholder=""
                    defaultValue={practiceTip.title}
                    required
                  />
                </Col>
              </Form.Group>
              <Form.Group className="d-flex row align-items-center form-padding">
                <Form.Label column sm={3} className="pl-0 pr-0">
                  Existing FileName
                </Form.Label>

                <Col sm={9} className="pl-0 pr-0">
                  <Form.Control
                    placeholder=""
                    defaultValue={practiceTip.fileName}
                    disabled
                  />
                </Col>
              </Form.Group>
              <Form.Group className="d-flex row align-items-center form-padding">
                <Form.Label column sm={3} className="pl-0 pr-0">
                  Replacement File
                </Form.Label>

                <Col sm={9} className="pl-0 pr-0">
                  <Form.Control
                    type="file"
                    onChange={(e) => handleOnChange(e, "Attachment")}
                    disabled={isDisabled}
                  />
                </Col>
              </Form.Group>
              <Form.Group className="d-flex row align-items-center form-padding">
                <Form.Label column sm={3} className="pl-0 pr-0">
                  IsActive
                </Form.Label>

                <Col sm={9} className="pl-0 pr-0">
                  <Form.Check
                    type="checkbox"
                    onChange={(e) => handleOnChange(e, "isActive")}
                    // disabled={isDisabled}
                    defaultChecked={practiceTip.isActive}
                  />
                </Col>
              </Form.Group>
              <Form.Group className="d-flex row align-items-center form-padding">
                <Form.Label column sm={3} className="pl-0 pr-0">
                  Publication Date <span className="p-0 asterik"> *</span>
                </Form.Label>

                <Col sm={9} className="pl-0 pr-0">
                  <Form.Control
                    max={DATE}
                    onChange={(e) => handleOnChange(e, "publicationDate")}
                    type="date"
                    required
                    defaultValue={getFormattedDateFromDateTime(
                      practiceTip.publicationDate
                    )}
                  />
                </Col>
              </Form.Group>
              <Form.Group className="d-flex row">
                <Col
                  sm={{ span: 9, offset: 3 }}
                  className="d-flex justify-content-center"
                >
                  <MButton
                    variant="primary"
                    disabled={loading}
                    title="Save"
                    width={"fit-content"}
                    type="submit"
                  >
                    Save
                  </MButton>
                </Col>
                <Col
                  sm={{ span: 7, offset: 3 }}
                  className="d-flex justify-content-center"
                >
                  {loading && <Loader />}
                </Col>
              </Form.Group>
            </form>
          </div>
        </div>
      </div>
    );
  }
};

export default EditPracticeTipOfMonth;
