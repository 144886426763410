import { FC } from "react";

const CustomAlert: FC<{
  code?: number;
  isSuccessful: boolean;
  message: string;
}> = ({ code, isSuccessful, message }) => {
  const onClickTakeSurvey = () => {
    window.open("https://ncv.microsoft.com/1co1DazzCM", "_blank");
  };
  if (isSuccessful === true) {
    return (
      <div className="alert alert-success" role="alert">
        {message}
        <a
                    className="survey"
                    target="_blank"
                    onClick={onClickTakeSurvey}
                  >
                   How was your referral experience?
                  </a>

      </div>
    );
  } else {
    return (
      <div className="alert alert-danger" role="alert">
        {message}
      </div>
    );
  }
};

export default CustomAlert;
