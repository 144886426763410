import { FC, useState, useEffect } from "react";
import { useOktaAuth } from "@okta/okta-react";
import { Table } from "react-bootstrap";
import IPracticePhysiciansToVerify from "../../../../types/IPracticePhysiciansToVerify";
import PracticeService from "../../../../services/PracticeService";
import { appInsights } from "../../../../appInsights";
import axios, { AxiosError } from "axios";
import Loader from "../../../common/Loader";
import IPracticeRoles from "../../../../types/IPracticeRoles";
import CustomAccordion from "../../../accordion/CustomAccordion";
import usePracticeInfo from "../../../../hooks/usePracticeInfo";
import MButton from "../../../mButton/MButton";
import CustomAlertModal from "../../../common/CustomAlertModal";
import useUserRole from "../../../../hooks/useUserRole";
import { Physicians } from "../../../../types/IPracticePhysicians";


const PhysiciansToVerify: FC<{setApiCallCount:any,practiceId:number}> = ({ setApiCallCount,practiceId }) => {
  const [practiceInfo] = usePracticeInfo();
  const [practicePhysicians, setPracticePhysicians] =
    useState<IPracticePhysiciansToVerify>();
  const { authState } = useOktaAuth();
  const accessToken = authState?.accessToken?.accessToken;
  const cancelToken = axios.CancelToken.source();
  const [loading, setLoading] = useState(true);
  const [submittingYes, setSubmittingYes] = useState<number>();
  const [submittingNo, setSubmittingNo] = useState<number>();
  const [reload, setReload] = useState(false)
  const [error, setError] = useState<AxiosError | null>(null)
  const [userRole, setUserRole] = useUserRole();

  const onVerifyClick = (id: number, status: boolean) => {
    setError(null)
    if (status) {
      setSubmittingYes(id)
    } else {
      setSubmittingNo(id)
    }
    PracticeService.verifyPhysician(
      {
        physicianId: id,
        practiceId: practiceId,
        verificationStatus: status,
      },
      accessToken,
      cancelToken
    )
      .then((response) => {
        if (status) {
          setSubmittingYes(undefined)
          setApiCallCount((c:any) => c + 1); 
        } else {
          setSubmittingNo(undefined)
        }
        setReload((r) => !r)
      })
      .catch((e) => {
        if (!axios.isCancel(e)) {
          console.error(e);
          appInsights.trackException({ error: e });
          if (status) {
            setSubmittingYes(undefined)
          } else {
            setSubmittingNo(undefined)
          }
          setError(e);
        }
      });
  };

  useEffect(() => {
    setError(null)
    PracticeService.getPhysiciansToVerify(accessToken, cancelToken)
      .then((response) => {
        setPracticePhysicians(response.data);
        setLoading(false);
      })
      .catch((e) => {
        if (!axios.isCancel(e)) {
          console.error(e);
          appInsights.trackException({ error: e });
          setError(e);
          setLoading(false);
        }
      });
    return () => {
      cancelToken.cancel();
    };
  }, [reload]);
  const checkRole = ():boolean => {

    if (userRole !== undefined){
      return userRole?.practiceRoles.filter(e=>e.roleId == "3" || e.roleId == "9").length > 0 ? true : false
    }

    return false
  }
  const renderTableHeader = ()=>{ 
    if (checkRole() || practicePhysicians?.isDelegated) {
      return <th style={{ width: 80 }}>Verify</th>
    }
  }

  const renderButtons =(user:Physicians)=>{ 
    if (checkRole() || practicePhysicians?.isDelegated) {
      return <td style={{ width: 80 }}>
      <div className="btn-group btn-group-sm" role="group">
        <MButton
          onClick={() => {
            onVerifyClick(user.physicianId, true);
          }}
          title="YES"
          small
          variant="accent"
          borderBottomRightRadius="0px"
          borderTopRightRadius="0px"
          marginRight="auto"
          isSubmitting={submittingYes === user.physicianId}
        />
        <MButton
          onClick={() => {
            onVerifyClick(user.physicianId, false);
          }}
          title="NO"
          small
          variant="danger"
          borderTopLeftRadius="0px"
          borderBottomLeftRadius="0px"
          marginLeft="auto"
          isSubmitting={submittingNo === user.physicianId}
        />
      </div>
    </td>
    }
  }
  return (
    <>
      <CustomAccordion
        title={
          <div className="header-top d-flex">
            <h5 className="header-blue bold m-0 pe-2">Physicians To Verify</h5>
            {loading ? (
              <Loader />
            ) : (
              <h5 className="header-blue bold m-0">
                (
                {practicePhysicians !== undefined
                  ? practicePhysicians.data.length
                  : 0}
                )
              </h5>
            )}
          </div>
        }
        content={
          <>
            <p>
              Are the physicians below part of your practice? Please indicate Yes
              or No. Adding the physician to your practice will enable you to see
              data for all of their patients.
            </p>
            <div className="table-responsive-sm">
              <Table id="lab-table">
                <thead>
                  <tr>
                    <th>Name</th>
                    <th>NPI</th>
                    <th>Email</th>
                    {renderTableHeader()}
                    
                    
                  </tr>
                </thead>
                <tbody>
                  {practicePhysicians?.data.map((user, key) => {
                    return (
                      <tr key={key}>
                        <td>
                          {user.firstName} {user.lastName}
                        </td>
                        <td>{user.npi}</td>
                        <td>{user.email}</td>
                        {renderButtons(user)}
                        
                      </tr>
                    );
                  })}
                </tbody>
              </Table>
            </div>
          </>
        }
      />
      <CustomAlertModal
        show={error !== null}
        handleClose={() => {
          setError(null)
          // setReload(p => !p)
        }}
        header={"Error!"}
        // @ts-ignore
        body={error?.response?.data.message || "There was an error processing your request. Please refresh the page and try again. If problems persist please contact myCHLA support."}
      />
    </>
  );
};

export default PhysiciansToVerify;
