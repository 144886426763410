import { FC } from "react";
import "./custom-scale.css";

type resultStatus = "CRITICAL" | "HIGH";

const CustomScale: FC<{
  value: string;
  criticalLow?: string;
  criticalHigh?: string;
  normalLow?: string;
  normalHigh?: string;
  normalcy?: string;
}> = ({
  value,
  criticalLow,
  criticalHigh,
  normalLow,
  normalHigh,
  normalcy,
}) => {

    const renderValueOnScale = (width: number) => {
      return (
        <div
          style={{
            width: "fit-content",
            marginLeft: `${width}%`,
            textAlign: "center",
            transform: "translate(-50%, 0)",
            fontWeight: "bold",
          }}
        >
          <div>{value}</div>
          <div
            style={{
              marginTop: -6,
              marginBottom: -3,
              padding: 0,
              fontSize: 10,
            }}
          >
            ▼
          </div>
        </div>
      );
    };

    const renderBadge = (status: resultStatus | "") => {
      if (normalcy === "CRIT") {
        return (
          <div
            className="badge"
            style={{
              backgroundColor: "RGB(220, 53, 70)",
              width: "4.5rem",
              height: "fit-content",
              marginRight: "30%"
            }}
          >
            CRITICAL
          </div>
        );
      }
      if (status === "") {
        return (
          <div
            className="badge"
            style={{
              backgroundColor: "transparent",
              width: "4.5rem",
              color: "transparent",
              marginRight: "30%"
            }}
          >
            .
          </div>
        );
      }
      const isCritical = status === "CRITICAL";
      return (
        <div
          className="badge"
          style={{
            backgroundColor: isCritical ? "RGB(220, 53, 70)" : "RGB(239, 135, 0)",
            width: "4.5rem",
            height: "fit-content",
            marginRight: "30%"
          }}
        >
          {normalcy === "HI" ? "HIGH" : normalcy === "LOW" ? "LOW" : normalcy === "CRIT" ? "CRITICAL" : null}
        </div>
      );
    };

    let numberValue = parseFloat(value);

    const renderHighsAndLows = () => {
      return (
        <span>{criticalLow && criticalLow !== "" && <span>Critical Low: {criticalLow}</span>}{normalLow && normalLow !== "" && <span>Normal Low: {normalLow}</span>}{normalHigh && normalHigh !== "" && <span>Normal High: {normalHigh}</span>}{criticalHigh && criticalHigh !== "" && <span>Critical High: {criticalHigh}</span>}</span>
      )
    }

    const renderValue = () => {
      return <div><span className="renderHighAndLows" style={{ fontWeight: "bold", fontSize: 20, marginRight: 20 }}>{value}</span>{renderHighsAndLows()}{renderBadge("HIGH")}</div>;
    }

    if (isNaN(numberValue)) {
      return <>{renderValue()}</>
    }

    if (criticalHigh && criticalLow && normalHigh && normalLow) {
      if (isNaN(numberValue)) {
        return <>{renderValue()}</>
      }
      let pos = 0;
      let width = 0;
      let status: resultStatus | "" = "CRITICAL";
      let v = parseFloat(value);
      let cl = parseFloat(criticalLow);
      let ch = parseFloat(criticalHigh);
      let nl = parseFloat(normalLow);
      let nh = parseFloat(normalHigh);
      if (v < cl) {
        pos = 1;
        width = 50;
      } else if (v >= cl && v <= nl) {
        pos = 2;
        width = ((v - cl) / (nl - cl)) * 100;
        status = "HIGH";
      } else if (v >= nl && v <= nh) {
        pos = 3;
        width = ((v - nl) / (nh - nl)) * 100;
        status = "";
      } else if (v >= nh && v <= ch) {
        pos = 4;
        width = ((v - nh) / (ch - nh)) * 100;
        status = "HIGH";
      } else if (v > ch) {
        pos = 5;
        width = 50;
      }
      return (
        <div id="custom-scale">
          <div className="container">
            <div className="value-holder">
              {Array(5)
                .fill(0)
                .map((n, i) => {
                  return (
                    <div className="value">
                      {pos === i + 1 && renderValueOnScale(width)}
                    </div>
                  );
                })}
            </div>
            <div className="bars">
              <div
                className="bar"
                style={{ backgroundColor: "RGB(220, 53, 70)" }}
              ></div>
              <div
                className="bar"
                style={{ backgroundColor: "RGB(239, 135, 0)" }}
              ></div>
              <div
                className="bar"
                style={{ backgroundColor: "RGB(6, 148, 222)" }}
              ></div>
              <div
                className="bar"
                style={{ backgroundColor: "RGB(239, 135, 0)" }}
              ></div>
              <div
                className="bar"
                style={{ backgroundColor: "RGB(220, 53, 70)" }}
              ></div>
            </div>
            <div className="legends">
              <div className="legend">{criticalLow}</div>
              <div className="legend">{normalLow}</div>
              <div className="legend"></div>
              <div className="legend">{normalHigh}</div>
              <div className="legend">{criticalHigh}</div>
            </div>
          </div>
          {renderBadge(status)}
        </div>
      );
    }

    if (normalHigh && normalLow) {
      if (isNaN(numberValue)) {
        return <>{renderValue()}</>
      }
      let pos = 0;
      let width = 0;
      let status: resultStatus | "" = "HIGH";
      let v = parseFloat(value);
      let nl = parseFloat(normalLow);
      let nh = parseFloat(normalHigh);
      if (v < nl) {
        pos = 2;
        width = 50;
      } else if (v >= nl && v <= nh) {
        pos = 3;
        width = ((v - nl) / (nh - nl)) * 100;
        status = "";
      } else if (v > nh) {
        pos = 4;
        width = 50;
      }
      return (
        <div id="custom-scale">
          <div className="container">
            <div className="value-holder">
              {Array(5)
                .fill(0)
                .map((n, i) => {
                  return (
                    <div className="value">
                      {pos === i + 1 && renderValueOnScale(width)}
                    </div>
                  );
                })}
            </div>
            <div className="bars">
              <div
                className="bar"
                style={{ backgroundColor: "transparent" }}
              ></div>
              <div
                className="bar"
                style={{ backgroundColor: "RGB(239, 135, 0)" }}
              ></div>
              <div
                className="bar"
                style={{ backgroundColor: "RGB(6, 148, 222)" }}
              ></div>
              <div
                className="bar"
                style={{ backgroundColor: "RGB(239, 135, 0)" }}
              ></div>
              <div
                className="bar"
                style={{ backgroundColor: "transparent" }}
              ></div>
            </div>
            <div className="legends">
              <div className="legend"></div>
              <div className="legend">{normalLow}</div>
              <div className="legend"></div>
              <div className="legend">{normalHigh}</div>
              <div className="legend"></div>
            </div>
          </div>
          {renderBadge(status)}
        </div>
      );
    }


    return <>{renderValue()}</>
  };

export default CustomScale;
