import { FC } from "react";
import { Modal, Button } from "react-bootstrap";
import { getFormattedTime } from "../../../../helpers/DateHelper";
import { EVENTNOTE } from "../../../../types/IPatientLabResults";
import MButton from "../../../mButton/MButton";
import "./comments-modal.css";

const CommentsModal: FC<{
  show: boolean;
  eventName?: string;
  handleClose?: () => void;
  notes?: EVENTNOTE[];
}> = ({ show, eventName, handleClose, notes }) => {
  return (
    <Modal
      id="comments-modal"
      show={show}
      onHide={handleClose}
      centered
      size="xl"
    >
      <Modal.Header>Comments for {eventName}</Modal.Header>
      <Modal.Body>
        {notes?.map((note) => {
          return (
            <>
              <div className="note-title">
                Comments on {note.NOTE_DT_TM_DISP.slice(0, -3)}
              </div>
              <p className="note-body">{note.EVENT_NOTE_BLOB}</p>
            </>
          );
        })}
      </Modal.Body>
      <Modal.Footer>
        <MButton
          onClick={handleClose}
          title="Ok"
          small
          width={"max-content"}
          variant="primary"
        />
        {/* <Button variant="primary" onClick={handleClose}>
          Ok
        </Button> */}
      </Modal.Footer>
    </Modal>
  );
};

export default CommentsModal;
