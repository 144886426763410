import { FC } from "react";
import { Table } from "react-bootstrap";
import { useHistory } from "react-router-dom";
import MButton from "../../../mButton/MButton";
import "./patient-list.css";
import { setRecentSearch } from "../../../../helpers/RecentSearchHelper";
import IPatient from "../../../../types/IPatient";
// import InfiniteScroll from "react-infinite-scroll-component";
// import Loader from "../../../common/Loader";

type stateType = {
  state: {
    patient: IPatient;
  };
};

const PatientsList: FC<{
  patients: IPatient[];
  source: "MyPatients" | "SharedPatients" | "ViewPatients";

  onVisitsClick: (patient: IPatient) => void;
  onHideOrShareClick?: (patientId: string, providerId: string) => void;
  onRemoveClick: (patient: IPatient) => void;
}> = ({
  patients,
  source = "ViewPatients",
  onVisitsClick,
  onHideOrShareClick,
  onRemoveClick,
}) => {
    const tableCellPadding = { padding: 20 };
    const tableHeadPadding = { paddingLeft: 20, paddingRight: 20 };
    const history = useHistory<stateType>();

    const onReferPatientClick = (patient: IPatient) => {
      history.push(`/referrals/refer-a-patient`, { state: { patient } });
    };

    const onPatientCardClick = (patient: IPatient) => {
      let searchedItem = {
        name: patient.patientName,
        dob: patient.dob,
      };
      setRecentSearch(searchedItem);
      history.push(`/patients/${patient.personId}`, { state: { patient } });
    };

    return (
      <div id="patient-list">
        {/* UI designed with table */}
        <Table variant="borderless" responsive className="patients-table-custom">
          <thead>
            <tr>
              <th style={{ paddingLeft: 20, paddingRight: 20, maxWidth: 320 }}>
                Patient Name
              </th>
              {source !== "MyPatients" && (
                <th style={tableHeadPadding}>Referring Physician</th>
              )}
              <th></th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            {/* <InfiniteScroll
            dataLength={patients.length}
            loader={<Loader />}
            next={() => {}}
            hasMore={true}
          > */}
            {patients.map((patient, index) => {
              if (patient) {
                return (
                  <tr className="patients-card" key={patient.personId}>
                    <td style={tableCellPadding}>
                      <div className="row">
                        <div className="col-7">
                          <div className="patients-card-title">
                            <div id="link">{patient.patientName}</div>
                          </div>
                          <div>
                            DOB: <b>{patient.dob}</b>
                          </div>
                        </div>
                        <div className="col-5 sex">
                          Sex: <b>{patient.sex}</b>
                        </div>
                      </div>
                    </td>
                    {source !== "MyPatients" && (
                      <td style={tableCellPadding}>
                        <div className="h-100 d-flex align-items-end">
                          <b>{patient.physicianName}</b>
                        </div>
                      </td>
                    )}
                    <td style={tableCellPadding}>
                      <div className="row h-100">
                        <div className="mrn">
                          MRN:<b> {patient.mrn}</b>
                        </div>
                      </div>
                    </td>
                    <td style={tableCellPadding}>
                      <div className="d-flex justify-content-end">
                        <MButton
                          variant="accent"
                          onClick={() => onPatientCardClick(patient)}
                          title="View Chart"
                          small
                          width={"max-content"}
                        />
                        <MButton
                          variant="secondary"
                          onClick={() => onReferPatientClick(patient)}
                          title="Refer"
                          small
                          width={"max-content"}
                        />
                        <MButton
                          onClick={() => onVisitsClick(patient)}
                          title="Visits"
                          small
                          variant="secondary"
                          width={"max-content"}
                        />
                        {source !== "SharedPatients" &&
                          onHideOrShareClick !== undefined && (
                            <>
                              <MButton
                                onClick={() => onHideOrShareClick(patient.id, patient.providerId)}
                                title={patient.isShared ? "Hide" : "Share"}
                                small
                                variant="secondary"
                                width={"max-content"}
                              />
                              <MButton
                                onClick={() => onRemoveClick(patient)}
                                title="Remove"
                                small
                                variant="secondary"
                                width={"max-content"}
                              />
                            </>
                          )}
                      </div>
                    </td>
                  </tr>
                );
              }
            })}
            {/* </InfiniteScroll> */}
          </tbody>
        </Table>
      </div>
    );
  };

export default PatientsList;
