import React from "react";
import { FC, useEffect, useState } from "react";
import HeaderName from "../../../headerName/HeaderName";
import { Badge, OverlayTrigger, ProgressBar, Table } from "react-bootstrap";
import FormPopover from "../../../common/FormPopover";
import IReferralQuery from "../../../../types/IReferralQuery";
import IReferral from "../../../../types/IReferral";
import Sortable from "../../../common/Sortable";
import MButton from "../../../mButton/MButton";
import CustomPagination from "../../../common/CustomPagination";
import { Form, Col, Button } from "react-bootstrap";
import DateHelper, {
  getFormattedDate,
  getFormattedDateFromDateTime,
} from "../../../../helpers/DateHelper";
import PediatricSubForm from "./PediatricSubForm";
import { Redirect, useHistory, useLocation } from "react-router-dom";
import { Alert } from "@mui/material";
import { useOktaAuth } from "@okta/okta-react";
import BackButton from "../../../backButton/BackButton";
import IPediatricGR from "../../../../types/IPediatricGR";
import PediatricHelper from "../../../../helpers/PediatricHelper";
import PediatricGRService from "../../../../services/PediatricGRService";
import axios from "axios";
import { appInsights } from "../../../../appInsights";

const PediatricForm: FC<{
  name?: string;
  variant?: string;
}> = ({ name, variant }) => {
  const isDisabled = false;
  const { state: pediatric } = useLocation<any>();
  const [data, setdata] = useState<any>({
    contentMessage:
      "At the end of this activity participants should be able to: ",
    isActive: false
  });
  const [file, setFile] = useState<any>(null);
  const DATE = getFormattedDate(new Date());
  const [loading, setLoading] = useState(false);

  const { authState } = useOktaAuth();
  const accessToken = authState?.accessToken?.accessToken;
  const [progress, setProgress] = useState<number>();
  const [redirect, setRedirect] = useState({
    redirect: false,
    message: "",
    errorMessage: "",
  });
  const [editorHtml, setEditorHtml] = useState("<strong>At the end of this CME activity participants should be able to:</strong>");
  const [supportedTypes, setSupportedTypes] = useState("");
  useEffect(() => {
    if (variant === "EditPediatricForm") {
      setdata(PediatricHelper.convertOlderPediatricToNewForm(pediatric));
      setEditorHtml(PediatricHelper.convertOlderPediatricToNewForm(pediatric).contentMessage);
    }
    getPGRSupportedMediaType().then(
      supportedTypes =>
      setSupportedTypes(supportedTypes)
    ).catch(
      err =>
      setSupportedTypes("video/mp4")
    );
  }, []);

  const getPGRSupportedMediaType = async () => {
    const supportedTypesList = (await PediatricGRService.getPGRSupportedMediaType()).data;
    const supportedTypes = supportedTypesList.join(', ');
    return supportedTypes;
  }
  const handleFormClick = (
    title: string,
    contentTitle: string,
    contentMessage: string,
    contentBulletLine: string,
    contentFullname: string,
    contentSignature: string
  ) => {
    setdata((prev: any) => ({
      ...prev,
      title: title,
      contentTitle: contentTitle,
      contentMessage: contentMessage,
      contentFullname: contentFullname,
      contentSignature: contentSignature,
    }));
  };
  const handleOnChange = (e: any, key: any) => {
    let value: any;
    value = e.target.value;
    if (key === "isActive") {
      value = e.target.checked;
    } else {
      value = e.target.value;
    }

    setdata((prev: any) => ({ ...prev, [key]: value }));
  };
  const handleFile = (e: any) => {
    if (e.target.files.length === 0) {
      setFile(null);
      if (variant === "EditPediatricForm"){
        changeValue();
      }
    } else {
      setFile(e.target.files[0]);
    }
  };
  const handleSubmit = (e: any) => {
    {
      window.scrollTo(0, 0);
    }
    e.preventDefault();
    console.log(data);
    if (data.title === undefined || data.title.trim() === "") {
      {
        window.scrollTo(0, 0);
      }
      setRedirect({
        redirect: false,
        message: "error",
        errorMessage: "Title is missing",
      });
      return;
    }

    if (file === null && (variant === "NewPediatricForm" || (videoExists === false && variant === "EditPediatricForm"))) {
      const videoInput: HTMLInputElement = document.getElementById(
        "editVideo"
      ) as HTMLInputElement;
      videoInput.focus();
      return;
    }

    var formData = new FormData();

    formData.append("Title", data.title);
    formData.append("EventDate", data.eventDate.toString());

    if (file !== null) formData.append("Video", file);

    formData.append("IsActive", data.isActive.toString());
    formData.append("ContentTitle", data.contentTitle ?? "");
    formData.append("ContentMessage", editorHtml ?? "");
    formData.append("ContentBulletLine", "");
    formData.append("ContentFullname", data.contentFullname ?? "");
    formData.append("ContentSignature", data.contentSignature ?? "");

    setLoading(true);
    if (variant === "NewPediatricForm") {
      PediatricGRService.createPediatricGRServiceRequest(
        formData,
        setProgress,
        accessToken
      )
        .then((response) => {
          setRedirect({
            redirect: false,
            message: "success",
            errorMessage: "",
          });
          setTimeout(() => {
            setLoading(false);
            setRedirect({ redirect: true, message: "", errorMessage: "" });
          }, 3000);
        })
        .catch((err) => {
          if (!axios.isCancel(e)) {
            console.error(e);
            appInsights.trackException({ error: e });
            // setError(e);
          }

          setLoading(false);
          setRedirect({
            redirect: false,
            message: "error",
            errorMessage: "Error in submitting the form, please try again!",
          });
        });
    }

    if (variant === "EditPediatricForm") {
      formData.append("Id", pediatric.id.toString());
      formData.append("VideoUrl", pediatric.videoUrl);
      PediatricGRService.editPediatricGRServiceRequest(
        formData,
        setProgress,
        accessToken
      )
        .then((response) => {
          setRedirect({
            redirect: false,
            message: "success",
            errorMessage: "",
          });
          setTimeout(() => {
            setLoading(false);
            setRedirect({ redirect: true, message: "", errorMessage: "" });
          }, 3000);
        })
        .catch((err) => {
          if (!axios.isCancel(e)) {
            console.error(e);
            appInsights.trackException({ error: e });
            // setError(e);
          }

          setLoading(false);
          setRedirect({
            redirect: false,
            message: "error",
            errorMessage: "Error in submitting the form, please try again!",
          });
        });
    }
  };
  const changeValue = () => {
    if (!videoExists) return;

    if (variant === "NewPediatricForm") {
      return;
    }
    const videoInput: HTMLInputElement = document.getElementById(
      "editVideo"
    ) as HTMLInputElement;
    if (videoInput !== null && videoInput.files?.length === 0) {
      videoInput.blur();
      // Create a new File object
      const myFile = new File(["Hello World!"], `${pediatric.videoUrl}`, {
        type: "text/plain",
      });

      // Now let's create a DataTransfer to get a FileList
      const dataTransfer = new DataTransfer();
      dataTransfer.items.add(myFile);
      videoInput.files = dataTransfer.files;
    } else {
      console.log("Hello");
    }
  };

  const videoExists = data?.videoUrl?.includes("http") ? false : true;

  if (redirect.redirect) {
    return (
      <Redirect
        to={{
          pathname: "/portalupdates/pediatricgrandrounds",
          state: { message: redirect.message },
        }}
      />
    );
  } else {
    return (
      <div className="referrals">
        <HeaderName name={name} />
        <BackButton />
        {redirect.message === "success" ? (
          <>
            {window.scrollTo(0, 0)}
            <Alert severity="success">
              Form is successfully submitted,please wait while we are
              redirecting!
            </Alert>
          </>
        ) : redirect.message === "error" ? (
          <>
            <Alert severity="error" style={{ color: "#EA1D36" }}>
              {redirect.errorMessage}
            </Alert>
          </>
        ) : redirect.message ? (
          <>
            <Alert severity="error" style={{ color: "#EA1D36" }}>
              {redirect.message}
            </Alert>
          </>
        ) : (
          <></>
        )}
        <div className="container">
          <h2 className="heading-main">{name}</h2>

          <div className="d-flex row justify-content-end"></div>

          <div className="referral">
            <form className="m-sm-5" onSubmit={handleSubmit} key={data.contentMessage.length}>
              <Form.Group className="d-flex row align-items-center form-padding">
                <Form.Label column sm={3} className="pl-0 pr-0">
                  IsActive
                </Form.Label>

                <Col sm={9} className="pl-0 pr-0">
                  <Form.Check
                    type="checkbox"
                    onChange={(e) => handleOnChange(e, "isActive")}
                    disabled={isDisabled}
                    defaultChecked={data.isActive ?? false}
                  />
                </Col>
              </Form.Group>
              <Form.Group className="d-flex row align-items-center form-padding">
                <Form.Label column sm={3} className="pl-0 pr-0">
                  Event Date <span className="p-0 asterik"> *</span>
                </Form.Label>

                <Col sm={9} className="pl-0 pr-0">
                  <Form.Control
                    max={DATE}
                    onChange={(e) => handleOnChange(e, "eventDate")}
                    type="date"
                    required
                    defaultValue={getFormattedDateFromDateTime(
                      data.eventDate ?? ""
                    )}
                  />
                </Col>
              </Form.Group>
              <Form.Group className="d-flex row align-items-center form-padding">
                <Form.Label column sm={3} className="pl-0 pr-0">
                  Video <span className="p-0 asterik"> *</span>
                </Form.Label>

                <Col sm={9} className="pl-0 pr-0">
                  <>
                    <Form.Control
                      type="file"
                      accept="video/mp4"
                      onChange={(e) => handleFile(e)}
                      disabled={isDisabled}
                      id="editVideo"
                      autoFocus
                      onFocus={changeValue}
                    />
                    {file !== null && progress && (
                      <div color="red" className="mt-3 progress w-100">
                        <div
                          role="progressbar"
                          className="progress-bar"
                          style={{
                            width: `${progress}%`,
                            background: "#0D9DDB",
                          }}
                        >
                          {progress}%
                        </div>
                      </div>
                    )}
                  </>
                </Col>
                {/* {videoExists === true ? (
                  <>
                    <Col column sm={3} className="pl-0 pr-0"></Col>
                    <Col column sm={9} className="pl-0 pr-0">
                      <div
                        className="font-weight-bold small m-0 p-0"
                        style={{ color: "#EA1D36" }}
                      >
                        This will replace current video. (Optional)
                      </div>
                    </Col>
                  </>
                ) : null} */}
              </Form.Group>

              {!videoExists && (
                <Form.Group className="d-flex row align-items-center form-padding">
                  <Form.Label column sm={3} className="pl-0 pr-0">
                    Video URL
                  </Form.Label>

                  <Col sm={9} className="pl-0 pr-0">
                    <Form.Control
                      onChange={(e) => handleOnChange(e, "videoUrl")}
                      placeholder=""
                      type="Url"
                      defaultValue={pediatric.videoUrl}
                    />
                  </Col>
                </Form.Group>
              )}

              <Form.Group className="d-flex row align-items-center form-padding">
                <Form.Label column sm={3} className="pl-0 pr-0"></Form.Label>
                <Col sm={9} className="pl-0 pr-0">
                  <PediatricSubForm
                    key={data.contentMessage.length}
                    pediatricData={data}
                    editorHtml={editorHtml}
                    setEditorHtml={setEditorHtml}
                    onFormClick={handleFormClick}
                  />
                </Col>
              </Form.Group>
              <Form.Group className="d-flex row">
                <Col
                  sm={{ span: 9, offset: 3 }}
                  className="d-flex justify-content-center"
                >
                  <MButton
                    variant="primary"
                    loader={loading}
                    title="Save"
                    width={"fit-content"}
                    type="submit"
                  >
                    Save
                  </MButton>
                </Col>
                <Col
                  sm={{ span: 7, offset: 3 }}
                  className="d-flex justify-content-center"
                >
                  {/* {loading && <Loader />} */}
                </Col>
              </Form.Group>
            </form>
          </div>
        </div>
      </div>
    );
  }
};

export default PediatricForm;
