import { FC, useEffect, useState } from "react";
import HeaderName from "../../headerName/HeaderName";
import { Badge, OverlayTrigger, Table, Col } from "react-bootstrap";
import IPortalUpdateSliderImage from "../../../types/IPortalUpdateSliderImage";
import MButton from "../../mButton/MButton";
import { useHistory, useLocation } from "react-router-dom";
import CustomToggle from "../../toggle/CustomToggle";
import UploadImageService from "../../../services/UploadImageService";
import { useOktaAuth } from "@okta/okta-react";
import Loader from "../../common/Loader";
import { getFormattedDateFromDateTime } from "../../../helpers/DateHelper";
import { appInsights } from "../../../appInsights";
import axios from "axios";
const SliderImages: FC<{
  name?: string;
}> = ({ name }) => {
  const history = useHistory();
  const tableCellPadding = { padding: 10 };
  const tableHeadPadding = { paddingLeft: 20, paddingRight: 20 };
  const handleClick = () => {
    history.push("/portalupdates/components/uploadimages");
  };
  const { authState } = useOktaAuth();
  const accessToken = authState?.accessToken?.accessToken;
  const [sliderImageData, setSliderImageData] =
    useState<IPortalUpdateSliderImage[]>();
  const [error, setError] = useState<Error | null>(null);
  const [loading, setLoading] = useState(true);
  const [flag, setFlag] = useState(false);
  const onToggle = (imageData: IPortalUpdateSliderImage) => {
    setLoading(true);
    setError(null);
    imageData.activeRecord = !imageData.activeRecord;
    UploadImageService.editSliderImageRequest(imageData, accessToken)
      .then((response) => {
        setLoading(false);
        // window.location.reload(false)
        setFlag(!flag);
      })
      .catch((e: Error) => {
        if (!axios.isCancel(e)) {
          console.error(e);
          appInsights.trackException({ error: e });
          setError(e);
        }
       
        setLoading(false);
      });
  };

  useEffect(() => {
    setLoading(true);
    setError(null);
    UploadImageService.getSliderImage(accessToken)
      .then((response) => {
        setLoading(false);
        setSliderImageData(response.data);
      
      })
      .catch((e: Error) => {
        if (!axios.isCancel(e)) {
          console.error(e);
          appInsights.trackException({ error: e });
          setError(e);
        }
        
        setLoading(false);
      });
  }, [flag]);

  return (
    <div>
      <HeaderName name={name} />
      <div className="container">
        <h2 className="heading-main">{name}</h2>
        <div>
          <div
            className="d-flex row justify-content-end"
            style={{ padding: 20 }}
          >
            <Col
          sm={{ span: 7, offset: 3 }}
          className="d-flex justify-content-center"
        >
          {loading && <Loader />}
        </Col>
        <br />
            <MButton
              onClick={() => handleClick()}
              variant="primary"
              disabled={false}
              title="Add New Slider"
              width={"fit-content"}
            />
          </div>
          <br />
          <div className="chart">
            <Table id="responsive-table" className="mt-2" responsive>
              <thead>
                <tr>
                  <th>Image Name</th>
                  <th style={tableHeadPadding}>TargetURL</th>
                  <th style={tableHeadPadding}>Modified By</th>
                  <th style={tableHeadPadding}>Modified On</th>
                  <th style={tableHeadPadding}>Added to Slider?</th>
                </tr>
              </thead>
              <tbody>
                {sliderImageData?.map((imageData) => {
                  return (
                    <tr key={imageData.id}>
                      <td
                        style={tableCellPadding}
                        data-title="Image Name"
                        id="table-text-slider"
                      >
                        {imageData.imageName}
                      </td>
                      <td style={tableCellPadding} data-title="TargetURL">
                        {imageData.targetUrl}
                      </td>
                      <td style={tableCellPadding} data-title="Modified By">
                        {imageData.modifiedBy}
                      </td>
                      <td style={tableCellPadding} data-title="Modified On">
                        {getFormattedDateFromDateTime(imageData.modifiedOn)}
                      </td>

                      <td
                        style={tableCellPadding}
                        data-title="Added to Slider?"
                      >
                        <CustomToggle
                          isOn={imageData.activeRecord}
                          onToggle={() => {
                            onToggle(imageData);
                          }}
                        />
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </Table>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SliderImages;
