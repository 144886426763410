import { CancelTokenSource } from "axios";
import { customAxios as axios } from "./ServiceConfig";
import { API_BASE_URL } from "../config";
import IPhysicianCreate from "../types/IPhysicianCreate";
import IPracticeCreate from "../types/IPracticeCreate";
import IPracticeLookup from "../types/IPracticeLookup";
import IPhysicianEdit from "../types/IPhysicianEdit";
import IPracticeEdit from "../types/IPracticeEdit";

let loginas = localStorage.getItem('loginAsFlag')
let loginasEmail = localStorage.getItem('loginAsEmail')
/**
 * @param token receives the access token of type string
 */
const createPractice = async (
  data: IPracticeCreate,
  token?: string,
  cancelToken?: CancelTokenSource
) => {
  const result = await axios.post<{ id: number }>(
    `${API_BASE_URL}/practice/v2`,
    data,
    loginas !== null ? {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Pragma: "no-cache",
        Authorization: `Bearer ${token}`,
        loginas:loginas !== null ? loginas : JSON.stringify(false),
        email:loginasEmail !== null ? loginasEmail : "",
      },
      cancelToken: cancelToken?.token,
    }:{
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Pragma: "no-cache",
        Authorization: `Bearer ${token}`,
      },
      cancelToken: cancelToken?.token,
    }
  );
  return result;
};

/**
 * @param token receives the access token of type string
 */
const editPractice = async (
  data: IPracticeEdit,
  token?: string,
  cancelToken?: CancelTokenSource
) => {
  const result = await axios.put(`${API_BASE_URL}/practice`, data, loginas !== null ? {
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Pragma: "no-cache",
      Authorization: `Bearer ${token}`,
      loginas:loginas !== null ? loginas : JSON.stringify(false),
      email:loginasEmail !== null ? loginasEmail : "",
    },
    cancelToken: cancelToken?.token,
  }:{
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Pragma: "no-cache",
      Authorization: `Bearer ${token}`,
    },
    cancelToken: cancelToken?.token,
  });
  return result;
};

/**
 * @param token receives the access token of type string
 */
const createPhysician = async (
  data: IPhysicianCreate,
  token?: string,
  cancelToken?: CancelTokenSource
) => {
  const result = await axios.post<{ id: number }>(
    `${API_BASE_URL}/physician`,
    data,
    loginas !== null ? {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Pragma: "no-cache",
        Authorization: `Bearer ${token}`,
        loginas:loginas !== null ? loginas : JSON.stringify(false),
        email:loginasEmail !== null ? loginasEmail : "",
      },
      cancelToken: cancelToken?.token,
    }:{
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Pragma: "no-cache",
        Authorization: `Bearer ${token}`,
      },
      cancelToken: cancelToken?.token,
    }
  );
  return result;
};

/**
 * @param token receives the access token of type string
 */
const practiceLookup = async (
  searchTerm: string,
  token?: string,
  cancelToken?: CancelTokenSource
) => {
  const result = await axios.get<IPracticeLookup[]>(
    `${API_BASE_URL}/practice/lookup/${searchTerm}`,
    loginas !== null ? {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Pragma: "no-cache",
        Authorization: `Bearer ${token}`,
        loginas:loginas !== null ? loginas : JSON.stringify(false),
        email:loginasEmail !== null ? loginasEmail : "",
      },
      cancelToken: cancelToken?.token,
    }:{
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Pragma: "no-cache",
        Authorization: `Bearer ${token}`,
      },
      cancelToken: cancelToken?.token,
    }
  );
  return result;
};

/**
 * @param token receives the access token of type string
 */
const deactivatePractice = async (
  practiceId: number,
  token?: string,
  cancelToken?: CancelTokenSource
) => {
  const result = await axios.put(
    `${API_BASE_URL}/practice/deactivate/${practiceId}`,
    {},
    {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Pragma: "no-cache",
        Authorization: `Bearer ${token}`,
      },
      cancelToken: cancelToken?.token,
    }
  );
  return result;
};

/**
 * @param token receives the access token of type string
 */
const deactivatePhysician = async (
  physicianId: number,
  token?: string,
  cancelToken?: CancelTokenSource
) => {
  const result = await axios.put(
    `${API_BASE_URL}/physician/deactivate/${physicianId}`,
    {},
    loginas !== null ? {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Pragma: "no-cache",
        Authorization: `Bearer ${token}`,
        loginas:loginas !== null ? loginas : JSON.stringify(false),
        email:loginasEmail !== null ? loginasEmail : "",
      },
      cancelToken: cancelToken?.token,
    }:{
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Pragma: "no-cache",
        Authorization: `Bearer ${token}`,
      },
      cancelToken: cancelToken?.token,
    }
  );
  return result;
};

/**
 * @param token receives the access token of type string
 */
const editPhysician = async (
  data: IPhysicianEdit,
  token?: string,
  cancelToken?: CancelTokenSource
) => {
  const result = await axios.put(`${API_BASE_URL}/physician`, data, loginas !== null ? {
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Pragma: "no-cache",
      Authorization: `Bearer ${token}`,
      loginas:loginas !== null ? loginas : JSON.stringify(false),
      email:loginasEmail !== null ? loginasEmail : "",
    },
    cancelToken: cancelToken?.token,
  }:{
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Pragma: "no-cache",
      Authorization: `Bearer ${token}`,
    },
    cancelToken: cancelToken?.token,
  });
  return result;
};

const AdminService = {
  createPractice,
  editPractice,
  practiceLookup,
  createPhysician,
  deactivatePractice,
  deactivatePhysician,
  editPhysician,
};

export default AdminService;
