import { FC, useEffect, useState } from "react";
import HeaderName from "../../headerName/HeaderName";
import {
  Badge,
  OverlayTrigger,
  Button,
  Table,
  Col,
  Card,
  NavDropdown,
  Form,
} from "react-bootstrap";
import { useHistory, useLocation, Link } from "react-router-dom";
import { useOktaAuth } from "@okta/okta-react";
import EducationService from "../../../services/EducationService";
import axios from "axios";
import Loader from "../../common/Loader";
import IEducation from "../../../types/IEducation";
import Spinner from "../../spinner/Spinner";
import ResourceService from "../../../services/ResourceService";
import IResources from "../../../types/IResources";
import IMentalHealth from "../../../types/IMentalHealth";
import ICareNetworkTeam from "../../../types/ICareNetworkTeam";
import IMemberDirectory from "../../../types/IMemberDirectory";
import IClinicalPathways from "../../../types/IClinicalPathways";
import IPatientFamilyEducation from "../../../types/IPatientFamilyEducation";

import Infowell from "../home/Infowell";

const sortable = require("../../../assets/images/sortable.png").default;

const CareWebsite: FC<{
  name?: string;
}> = ({ name }) => {
    const [resourceData, setResourceData] = useState<IResources>();
    const [id, setId] = useState();
    const { authState } = useOktaAuth();
    const accessToken = authState?.accessToken?.accessToken;
    const cancelToken = axios.CancelToken.source();
    const [loading, setLoading] = useState(true);
    const history = useHistory();
  
    const [error, setError] = useState<Error | null>(null);
  
      const openLink = () => {
       
        window.open("https://chla.sharepoint.com/sites/HealthNetwork/SitePages/CHLA-Health-Network-Home-Page.aspx")
      }
      openLink()
      history.goBack()
  return (
    <div>
   
    </div>
  );
};

export default CareWebsite;
