import { FC } from "react";
import { Pagination } from "react-bootstrap";
import './custom-pagination.css'
const CustomPagination: FC<{
  currentPage: number;
  totalPages: number;
  onPageChange: (page: number) => void;
  range: number;
}> = ({ currentPage, totalPages, onPageChange, range }) => {
  const goToFirstPage = () => {
    if (currentPage !== 1) {
      onPageChange(1);
    }
  };

  const goToLastPage = () => {
    if (currentPage !== totalPages) {
      onPageChange(totalPages);
    }
  };

  const goToNextPage = () => {
    if (currentPage !== totalPages) {
      onPageChange(currentPage + 1);
    }
  };

  const goToPreviousPage = () => {
    if (currentPage !== 1) {
      onPageChange(currentPage - 1);
    }
  };

  const changePage = (page: number) => {
    if (currentPage !== page) {
      onPageChange(page);
    }
  };

  const getPaginatedGroup = () => {
    let start = Math.floor((currentPage - 1) / range) * range;
    let n = range;
    if (totalPages - start < range) {
      n = totalPages % range;
    }
    const paginatedGroup = new Array(n).fill(null).map((_, idx) => {
      return start + idx + 1;
    });
    return paginatedGroup;
  };

  return (
    <Pagination >
      <Pagination.First onClick={() => goToFirstPage()} />
      <Pagination.Prev onClick={() => goToPreviousPage()} />
      {getPaginatedGroup().map((item, index) => {
        return (
          <Pagination.Item
         
            key={index}
            onClick={() => changePage(item)}
            active={currentPage === item}
          >
            {item}
          </Pagination.Item>
        );
      })}
      <Pagination.Next onClick={() => goToNextPage()} />
      <Pagination.Last onClick={() => goToLastPage()} />
    </Pagination>
  );
};

export default CustomPagination;
