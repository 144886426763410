import { ROUTES } from "../config";
import IMenuItem from "../types/IMenuItem";
import IRoute from "../types/IRoute";

export const getAllRoutes = () => {
  const allowedRoutes: IRoute[] = [];
  ROUTES.map((route) => {
    if (route.title === "Home") {
      return;
    }
    allowedRoutes.push(route);
    // if (route.render !== undefined) {
    //   allowedRoutes.push(route);
    // }
    if (route.children !== undefined) {
      route.children.map((child) => {
        allowedRoutes.push(child);
      });
    }
  });
  return allowedRoutes;
};

export const getRoutesFromMenuItems = (menuItems: IMenuItem[]) => {
  const routes = getAllRoutes();
  menuItems.forEach((menu) => {
    menu.path = routes.find((r) => r.id === menu.id)?.path;
    if (menu.items.length !== 0) {
      menu.items.forEach((item) => {
        item.path = routes.find((r) => r.id === item.itemId)?.path;
      });
    }
  });
  return menuItems.sort((a, b) => a.viewOrder - b.viewOrder);
};

export const getAllowedRoutes = (roles: string[]) => {
  const allRoutes = ROUTES;
  let allowedRoutes: IRoute[] = [];
  const routeConfigs: Array<IRoute[]> = [];
  if (roles.length === 0) {
    return allRoutes;
  }
  roles.forEach((role) => {
    const routes: IRoute[] = [];
    allRoutes.forEach((route) => {
      if (route.permissions.includes(role)) {
        let newRoute: IRoute = { ...route };
        if (route.children !== undefined) {
          newRoute.children = route.children.filter((child) =>
            child.permissions.includes(role)
          );
        }
        routes.push(newRoute);
      }
    });
    routeConfigs.push(routes);
  });
  routeConfigs.forEach((routeConfig) => {
    routeConfig.forEach((route) => {
      if (allowedRoutes.findIndex((r) => r.path === route.path) === -1) {
        allowedRoutes.push(route);
      }
      let parentIndex = allowedRoutes.findIndex((r) => r.path === route.path);
      if (route.children !== undefined) {
        route.children.forEach((child) => {
          if (
            allowedRoutes[parentIndex].children?.findIndex(
              (c) => c.path === child.path
            ) === -1
          ) {
            allowedRoutes[parentIndex].children?.push(child);
          }
        });
      }
    });
  });
  return allowedRoutes;
};
