import { FormikProps, FormikValues } from "formik";
import { FC, useState } from "react";
import { Form } from "react-bootstrap";
import IFormDropdownOption from "../../types/IFormDropdownOption";
import MButton from "../mButton/MButton";

const MultiSelect: FC<{
  title: string;
  options: IFormDropdownOption[];
  multipleAllowed?: boolean;
  onSelect?: (value: number, text: string) => void;
  onAddClick?: () => void;
  onRemoveClick?: (id: number) => void;
  onDoubleClick?:(id: number,display?:string) => void;
  isLocation?:boolean;
}> = ({
  title,
  options = [],
  multipleAllowed = false,
  onSelect,
  onAddClick,
  onRemoveClick,
  onDoubleClick,
  isLocation,
  ...props
}) => {
    //
    const { name, required, form }: any = props;
    const [selected, setSelected] = useState<number>();

    const handleRemoveClick = () => {
      if (onRemoveClick !== undefined) {
        if (multipleAllowed && selected !== undefined) {
          onRemoveClick(selected);
          return;
        }
        if (!multipleAllowed && options.length !== 0) {
          onRemoveClick(options[0].id);
        }
      }
    };

    const getValue = () => {
      if (multipleAllowed === true) {
        return selected;
      } else {
        return options.at(0)?.id || undefined;
      }
    };

    return (
      <Form.Group>
        <Form.Label>
          {title}
          {required ? <span className="p-0 asterik"> *</span> : <></>}
        </Form.Label>
        <div
          style={{
            borderWidth: 1,
            background: "white",
            borderColor: "#dbdbdb",
            borderStyle: "solid",
            borderRadius: 5,
            padding: 0,
          }}
        >
          <div style={{ display: "flex", flexDirection: "row-reverse" }}>
            <div style={{ padding: 8 }}>
              <button
                onClick={() => {
                  onAddClick !== undefined && onAddClick();
                }}
                type="button"
                style={{
                  marginRight: 8,
                  width: 26,
                  textAlign: "center",
                  border: "none",
                  fontWeight: "bold",
                  borderRadius: 2,
                }}
                disabled={multipleAllowed ? false : options.length !== 0}
              >
                +
              </button>
              <button
                onClick={() => {
                  handleRemoveClick();
                }}
                disabled={
                  isLocation ? options.length === 1 :
                  multipleAllowed
                  
                  ? selected === undefined
                  
                  ? true
                  
                  : options.length === 0 : selected !== undefined ? false : true
                  
                  }
                type="button"
                style={{
                  width: 26,
                  border: "none",
                  textAlign: "center",
                  fontWeight: "bold",
                  borderRadius: 2,
                }}
              >
                -
              </button>
            </div>
          </div>
          <Form.Select
            name={name}
            style={{ border: "none", height: 150 }}
            onChange={(e: any) => {
              setSelected(parseInt(e.target.value));
            }}
            multiple
            required={required}
          // value={options && options.length > 0 ? options[0].id : undefined}
          >
            {options.map((option) => (
              <option disabled={option.disabled??false}  key={option.id} value={option.id} onDoubleClick={()=>{
                onDoubleClick && onDoubleClick(option.id,option.display)
              }}>
                {" "}
                {option.display}{" "}
              </option>
            ))}
          </Form.Select>
        </div>
      </Form.Group>
    );
  };

export default MultiSelect;
