import { CancelTokenSource } from "axios";
import { customAxios as axios } from "./ServiceConfig";
import { API_BASE_URL } from "../config";
import IPracticeEdit from "../types/IPracticeEdit";
import IPracticeInfo from "../types/IPracticeInfo";
import IPracticeNonPhysicians from "../types/IPracticeNonPhysicians";
import IPracticePhysicians from "../types/IPracticePhysicians";
import IPracticePhysiciansToVerify from "../types/IPracticePhysiciansToVerify";
import IPracticeSearchResult from "../types/IPracticeSearchResult";
let loginas = localStorage.getItem('loginAsFlag')
let loginasEmail = localStorage.getItem('loginAsEmail')
/**
 * @param searchTerm receives the searched name
 * @param token receives the access token of type string
 */
const searchPracticeByName = async (
  searchTerm: string,
  token?: string,
  cancelToken?: CancelTokenSource
) => {
  const result = await axios.get<IPracticeSearchResult[]>(
    //   `${API_BASE_URL}/referral/${id}`,
    `${API_BASE_URL}/practice?searchTerm=${searchTerm}&searchKey=name`,
    loginas !== null ? {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Pragma: "no-cache",
        Authorization: `Bearer ${token}`,
        loginas:loginas !== null ? loginas : JSON.stringify(false),
        email:loginasEmail !== null ? loginasEmail : "",
      },
      cancelToken: cancelToken?.token,
    }:{
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Pragma: "no-cache",
        Authorization: `Bearer ${token}`,
      },
      cancelToken: cancelToken?.token,
    }
  );
  return result;
};
const searchPracticeByLocation = async (
  searchTerm: string,
  token?: string,
  cancelToken?: CancelTokenSource
) => {
  const result = await axios.get<IPracticeSearchResult[]>(
    //   `${API_BASE_URL}/referral/${id}`,
    `${API_BASE_URL}/practice?searchTerm=${searchTerm}&searchKey=location`,
    loginas !== null ? {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Pragma: "no-cache",
        Authorization: `Bearer ${token}`,
        loginas:loginas !== null ? loginas : JSON.stringify(false),
        email:loginasEmail !== null ? loginasEmail : "",
      },
      cancelToken: cancelToken?.token,
    }:{
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Pragma: "no-cache",
        Authorization: `Bearer ${token}`,
      },
      cancelToken: cancelToken?.token,
    }
  );
  return result;
};
const searchPracticeById = async (
  searchTerm: string,
  token?: string,
  cancelToken?: CancelTokenSource
) => {
  const result = await axios.get<IPracticeSearchResult[]>(
    //   `${API_BASE_URL}/referral/${id}`,
    `${API_BASE_URL}/practice?searchTerm=${searchTerm}&searchKey=id`,
    loginas !== null ? {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Pragma: "no-cache",
        Authorization: `Bearer ${token}`,
        loginas:loginas !== null ? loginas : JSON.stringify(false),
        email:loginasEmail !== null ? loginasEmail : "",
      },
      cancelToken: cancelToken?.token,
    }:{
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Pragma: "no-cache",
        Authorization: `Bearer ${token}`,
      },
      cancelToken: cancelToken?.token,
    }
  );
  return result;
};

const getPracticePhysicians = async (
  token?: string,
  cancelToken?: CancelTokenSource
) => {
  const result = await axios.get<IPracticePhysicians>(
    `${API_BASE_URL}/practice/physicians`,
    loginas !== null ? {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Pragma: "no-cache",
        Authorization: `Bearer ${token}`,
        loginas:loginas !== null ? loginas : JSON.stringify(false),
        email:loginasEmail !== null ? loginasEmail : "",
      },
      cancelToken: cancelToken?.token,
    }:{
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Pragma: "no-cache",
        Authorization: `Bearer ${token}`,
      },
      cancelToken: cancelToken?.token,
    }
  );
  return result;
};

const getPracticeNonPhysicians = async (
  token?: string,
  cancelToken?: CancelTokenSource
) => {
  const result = await axios.get<IPracticeNonPhysicians>(
    `${API_BASE_URL}/practice/nonphysicians`,
    loginas !== null ? {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Pragma: "no-cache",
        Authorization: `Bearer ${token}`,
        loginas:loginas !== null ? loginas : JSON.stringify(false),
        email:loginasEmail !== null ? loginasEmail : "",
      },
      cancelToken: cancelToken?.token,
    }:{
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Pragma: "no-cache",
        Authorization: `Bearer ${token}`,
      },
      cancelToken: cancelToken?.token,
    }
  );
  return result;
};

const getPhysiciansToVerify = async (
  token?: string,
  cancelToken?: CancelTokenSource
) => {
  const result = await axios.get<IPracticePhysiciansToVerify>(
    `${API_BASE_URL}/practice/physicianstoverify`,
    loginas !== null ? {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Pragma: "no-cache",
        Authorization: `Bearer ${token}`,
        loginas:loginas !== null ? loginas : JSON.stringify(false),
        email:loginasEmail !== null ? loginasEmail : "",
      },
      cancelToken: cancelToken?.token,
    }:{
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Pragma: "no-cache",
        Authorization: `Bearer ${token}`,
      },
      cancelToken: cancelToken?.token,
    }
  );
  return result;
};

const getPracticeInfo = async (
  token?: string,
  cancelToken?: CancelTokenSource
) => {
  const result = await axios.get<IPracticeInfo>(
    `${API_BASE_URL}/practice/details/shallow`,
    loginas !== null ? {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Pragma: "no-cache",
        Authorization: `Bearer ${token}`,
        loginas:loginas !== null ? loginas : JSON.stringify(false),
        email:loginasEmail !== null ? loginasEmail : "",
      },
      cancelToken: cancelToken?.token,
    }:{
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Pragma: "no-cache",
        Authorization: `Bearer ${token}`,
      },
      cancelToken: cancelToken?.token,
    }
  );
  return result;
};

const practiceDelegation = async (
  practiceId: number,
  token?: string,
  cancelToken?: CancelTokenSource
) => {
  const result = await axios.put<{ delegationEnabled: boolean }>(
    `${API_BASE_URL}/practice/${practiceId}/delegation`,
    {},
    loginas !== null ? {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Pragma: "no-cache",
        Authorization: `Bearer ${token}`,
        loginas:loginas !== null ? loginas : JSON.stringify(false),
        email:loginasEmail !== null ? loginasEmail : "",
      },
      cancelToken: cancelToken?.token,
    }:{
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Pragma: "no-cache",
        Authorization: `Bearer ${token}`,
      },
      cancelToken: cancelToken?.token,
    }
  );

  return result;
};
const practiceDefaultLocationChange = async (
  data: {
    locationId: number;
    practiceId: number;
    
  },
  token?: string,
  cancelToken?: CancelTokenSource
) => {
  const result = await axios.put<{}>(
    `${API_BASE_URL}/Practice/locationchange`,
    data,
    loginas !== null ? {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Pragma: "no-cache",
        Authorization: `Bearer ${token}`,
        loginas:loginas !== null ? loginas : JSON.stringify(false),
        email:loginasEmail !== null ? loginasEmail : "",
      },
      cancelToken: cancelToken?.token,
    }:{
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Pragma: "no-cache",
        Authorization: `Bearer ${token}`,
      },
      cancelToken: cancelToken?.token,
    }
  );

  return result;
};
const practiceOwnerChange = async (
  data: {
    physicianId: number;
    practiceId: number;
    
  },
  token?: string,
  cancelToken?: CancelTokenSource
) => {
  const result = await axios.put<{}>(
    `${API_BASE_URL}/Practice/pochange`,
    data,
    loginas !== null ? {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Pragma: "no-cache",
        Authorization: `Bearer ${token}`,
        loginas:loginas !== null ? loginas : JSON.stringify(false),
        email:loginasEmail !== null ? loginasEmail : "",
      },
      cancelToken: cancelToken?.token,
    }:{
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Pragma: "no-cache",
        Authorization: `Bearer ${token}`,
      },
      cancelToken: cancelToken?.token,
    }
  );

  return result;
};
const verifyPhysician = async (
  data: {
    physicianId: number;
    practiceId: number;
    verificationStatus: boolean;
  },
  token?: string,
  cancelToken?: CancelTokenSource
) => {
  const result = await axios.put<{}>(
    `${API_BASE_URL}/practice/verifyPhysician`,
    data,
    loginas !== null ? {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Pragma: "no-cache",
        Authorization: `Bearer ${token}`,
        loginas:loginas !== null ? loginas : JSON.stringify(false),
        email:loginasEmail !== null ? loginasEmail : "",
      },
      cancelToken: cancelToken?.token,
    }:{
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Pragma: "no-cache",
        Authorization: `Bearer ${token}`,
      },
      cancelToken: cancelToken?.token,
    }
  );
  return result;
};

const getPractice = async (
  id: number,
  token?: string,
  cancelToken?: CancelTokenSource
) => {
  const result = await axios.get<IPracticeEdit>(
    `${API_BASE_URL}/practice/${id}`,
    loginas !== null ? {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Pragma: "no-cache",
        Authorization: `Bearer ${token}`,
        loginas:loginas !== null ? loginas : JSON.stringify(false),
        email:loginasEmail !== null ? loginasEmail : "",
      },
      cancelToken: cancelToken?.token,
    }:{
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Pragma: "no-cache",
        Authorization: `Bearer ${token}`,
      },
      cancelToken: cancelToken?.token,
    }
  );
  return result;
};
const getPhysicians = async (
  id: number,
  token?: string,
  cancelToken?: CancelTokenSource
) => {
  const result = await axios.get<any>(
    `${API_BASE_URL}/practice/${id}/physicians`,
    loginas !== null ? {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Pragma: "no-cache",
        Authorization: `Bearer ${token}`,
        loginas:loginas !== null ? loginas : JSON.stringify(false),
        email:loginasEmail !== null ? loginasEmail : "",
      },
      cancelToken: cancelToken?.token,
    }:{
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Pragma: "no-cache",
        Authorization: `Bearer ${token}`,
      },
      cancelToken: cancelToken?.token,
    }
  );
  return result;
};
const getLocations = async (
  id: number,
  token?: string,
  cancelToken?: CancelTokenSource
) => {
  const result = await axios.get<any>(
    `${API_BASE_URL}/practice/${id}/locations`,
    loginas !== null ? {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Pragma: "no-cache",
        Authorization: `Bearer ${token}`,
        loginas:loginas !== null ? loginas : JSON.stringify(false),
        email:loginasEmail !== null ? loginasEmail : "",
      },
      cancelToken: cancelToken?.token,
    }:{
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Pragma: "no-cache",
        Authorization: `Bearer ${token}`,
      },
      cancelToken: cancelToken?.token,
    }
  );
  return result;
};

const isPoPresent = async (
  id: string,
  token?: string,
  cancelToken?: CancelTokenSource
) => {
  const result = await axios.get<boolean>(
    `${API_BASE_URL}/practice/${id}/isPoPresent`,
    loginas !== null ? {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Pragma: "no-cache",
        Authorization: `Bearer ${token}`,
        loginas:loginas !== null ? loginas : JSON.stringify(false),
        email:loginasEmail !== null ? loginasEmail : "",
      },
      cancelToken: cancelToken?.token,
    }:{
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Pragma: "no-cache",
        Authorization: `Bearer ${token}`,
      },
      cancelToken: cancelToken?.token,
    }
  );
  return result;
};

const changeDefaultPractice = async (
  data?:any,
  token?: string,
  cancelToken?: CancelTokenSource
) => {

  console.log('Practice ID');
  console.log(data)
  const result = await axios.post(`${API_BASE_URL}/practice/defaultPractice`, data, loginas !== null ? {
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Pragma: "no-cache",
      Authorization: `Bearer ${token}`,
      loginas:loginas !== null ? loginas : JSON.stringify(false),
      email:loginasEmail !== null ? loginasEmail : "",
    },
    cancelToken: cancelToken?.token,
  }:{
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Pragma: "no-cache",
      Authorization: `Bearer ${token}`,
    },
    cancelToken: cancelToken?.token,
  });
  return result;
};

const PracticeService = {
  searchPracticeByName,
  searchPracticeByLocation,
  searchPracticeById,
  getPracticeNonPhysicians,
  getPracticePhysicians,
  getPhysiciansToVerify,
  getPracticeInfo,
  getPhysicians,
  getLocations,
  isPoPresent,
  practiceDelegation,
  verifyPhysician,
  getPractice,
  practiceDefaultLocationChange,
  practiceOwnerChange,
  changeDefaultPractice
};

export default PracticeService;
