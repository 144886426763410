import { FC, useEffect, useState } from "react";
import CustomAccordion from "../../../accordion/CustomAccordion";
import MButton from "../../../mButton/MButton";
import styles from "./AdminResult.module.css";
import IPracticeSearchResult from "../../../../types/IPracticeSearchResult";
import AdminPracticeLocationsTable from "./AdminLocationsTable";
import AdminPracticePhysiciansTable from "./AdminPracticePhysiciansTable";
import { useHistory } from "react-router-dom";
import DeactivateConfirmationModal from "./DeactivateConfirmationModal";
// import IPhysician from "../../../../types/IPhysician";
import {IPhysician,ILocation} from "../../../../types/IPracticeSearchResult";
import PracticeService from "../../../../services/PracticeService";
import axios from "axios";
import { useOktaAuth } from "@okta/okta-react";

const AdminPracticeResult: FC<{
  practiceSearchResult: IPracticeSearchResult;
  searchValue: string;
}> = ({ practiceSearchResult, searchValue }) => {
  //
  const [showModal, setShowModal] = useState(false);
  const [isPractice, setPractice] = useState(false);
  const [physicians, setphysicians] =  useState<IPhysician[]>([]);
  const [locations, setlocations] =  useState<ILocation[]>([]);
  const { authState } = useOktaAuth();
  const accessToken = authState?.accessToken?.accessToken;
  const cancelToken = axios.CancelToken.source();

  const history = useHistory();

  const physicianResult = () => {
   if(physicians.length == 0 && practiceSearchResult.physicianCount != 0){
    PracticeService.getPhysicians(practiceSearchResult.practiceId, accessToken, cancelToken)
    .then((response) => {
      setphysicians(response.data);
      // setLoading(false);
    })
    .catch((e) => {
      if (axios.isCancel(e)) {
        console.error(e);

      }
      else{
      // setLoading(false);
      }
    });
  }
  }
  const locationResult = () => {
    if(practiceSearchResult.locationCount != 0 && locations.length == 0){
      PracticeService.getLocations(practiceSearchResult.practiceId, accessToken, cancelToken)
      .then((response) => {
        setlocations(response.data);
        // setLoading(false);
      })
      .catch((e) => {
          console.error(e);
      });
    }
  }
  const handleEditClick = () => {
    localStorage.setItem("searchValue", searchValue);
    history.push(
      `/accounts&access/physicians&practices/edit/practice/${practiceSearchResult.practiceId}`,
      { from: "/accounts&access/physicians&practices/edit/practice" }
    );
  };
  const handdlePracticeOwner = (practiceSearchResult:any) => {
    var isflag = false;
    practiceSearchResult.physicians.map((index:any, data:any) => {
      if (
        index.isPracticeOwner &&
        practiceSearchResult.isActive &&
        practiceSearchResult.isMyCHLA
      ) {
        isflag = true;
      }
    });
    return isflag;
  };
  const handleDeactivateClick = () => {
    setShowModal(true);
  };

  return (
    <div className={styles.adminPractice}>
      <div className="d-flex mb-1">
        <div className="d-flex col">
          <div className="col">
            <b>ID</b>
            <br />
            <div className="header-blue">{practiceSearchResult.practiceId}</div>
          </div>
          <div className="col">
            <b>PRACTICE NAME</b>
            <br />
            <div className="header-blue">
              {practiceSearchResult.practiceName + " "}
              {practiceSearchResult.isActive && practiceSearchResult.isMyCHLA && (
                <img
                  src={require("../../../../assets/images/myCHLA.png").default}
                />
              )}
            </div>
          </div>
        </div>
        <div className="col d-flex" style={{ flexDirection: "row-reverse" }}>
          {practiceSearchResult.isActive && (
            <MButton
              variant="danger-outline"
              title="Deactivate"
              width={"fit-content"}
              small
              onClick={handleDeactivateClick}
            />
          )}
          <MButton
            onClick={handleEditClick}
            variant="primary"
            title="Edit"
            width={"fit-content"}
            small
          />
        </div>
      </div>
      <CustomAccordion
        small
        onClick = {physicianResult}
        title={
          <h6 className="header-blue bold m-0 pe-2">
            Physicians ({practiceSearchResult.physicianCount})
          </h6>
        }
        content={
          <AdminPracticePhysiciansTable
            physicians={physicians}
            count = {practiceSearchResult.physicianCount}
          />
        }
      />

      <CustomAccordion
        small
        onClick = {locationResult}
        title={
          <h6 className="header-blue bold m-0 pe-2">
            Locations ({practiceSearchResult.locationCount}){" "}
          </h6>
        }
        content={
          <AdminPracticeLocationsTable
            locations={locations}
            count = {practiceSearchResult.locationCount}
          />
        }
      />

      <DeactivateConfirmationModal
        show={showModal}
        type="practice"
        id={practiceSearchResult.practiceId}
        handleClose={() => {
          setShowModal(false);
        }}
        handleConfirm={() => {
          setShowModal(false);
          window.location.reload();
        }}
      />
    </div>
  );
};

export default AdminPracticeResult;
