import { FC, useEffect, useState } from "react";
import { Formik, Form as FormikForm, Field } from "formik";
import { useOktaAuth } from "@okta/okta-react";
import axios, { AxiosError } from "axios";
import { Form } from "react-bootstrap";
import IFormDropdownOption from "../../../types/IFormDropdownOption";
import CustomInputFormik from "../../common/CustomInputFormik";
import MButton from "../../mButton/MButton";
import MultiSelect from "../../multiselect/MultiSelect";
import AdminCreateLocationModal from "./components/AdminCreateLocationModal";
import AdminSearchPhysicianModal from "./components/AdminSearchPhysicianModal";
import IPracticeCreate, {
  PracticeLocation,
  PracticePhysician,
} from "../../../types/IPracticeCreate";
import * as yup from "yup";
import AdminService from "../../../services/AdminService";
import AdminPracticeLookupModal from "./components/AdminPracticeLookupModal";
import IPracticeLookup from "../../../types/IPracticeLookup";
import { useHistory } from "react-router-dom";
import { appInsights } from "../../../appInsights";
import CustomAlertModal from "../../common/CustomAlertModal";

const AdminCreatePractice: FC<{ name?: string }> = ({ name }) => {
  //
  const history = useHistory();
  const [error, setError] = useState<AxiosError | null>(null);

  const handleBackClick = () => {
    history.goBack();
  };
  const [physicianOptions, setPhysicianOptions] = useState<
    IFormDropdownOption[]
  >([]);
  const [locationOptions, setLocationOptions] = useState<IFormDropdownOption[]>(
    []
  );
  const [results, setResults] = useState<IPracticeLookup[]>([]);
  const [showLocationModal, setShowLocationModal] = useState(false);
  const [showPhysicianModal, setShowPhysicianModal] = useState(false);
  const [showPracticeLookupModal, setShowPracticeLookupModal] = useState(false);
  const [physician, setPhysician] = useState<PracticePhysician>();
  const [location, setLocation] = useState<PracticeLocation>();
  const [practiceName, setPracticeName] = useState<null | string>("");
  const [existingSimilarNames, setExistingSimilarNames] = useState<string[]>(["testpractice"]);
  const [values, setValues] = useState();
  //
  const { authState } = useOktaAuth();
  const accessToken = authState?.accessToken?.accessToken;
  const cancelToken = axios.CancelToken.source();
  //
  const json: IPracticeCreate = require("./sample.json");
  const initialValues = {
    practiceName: null,
    groupName: null,
    npi: null,
    practiceType: null,
    domain: null,
    practice_Physician: {
      practiceId: 0,
      physicianId: 0,
      practiceLocationId: 0,
      contactName: null,
      email: null,
      phone: null,
      phoneExt: null,
      fax: null,
      department: null,
    },
    practice_Location: {
      practiceId: 0,
      address1: null,
      address2: null,
      mailStop: null,
      city: null,
      country: null,
      state: null,
      zip: null,
      zip4: null,
      mailAddress1: null,
      mailAddress2: null,
      mailMailStop: null,
      mailCity: null,
      mailCountry: null,
      mailState: null,
      mailZip: null,
      mailZip4: null,
      contactName: null,
      phone: null,
      phoneExt: null,
      fax: null,
      email: null,
    },
  };

  const handleCreatePractice = (v?: any) => {
    setError(null)
    if (v !== undefined) {
      AdminService.createPractice(v, accessToken, cancelToken)
        .then((response) => {
          history.push("/accounts&access/physicians&practices/");
        })
        .catch((e) => {
          if (!axios.isCancel(e)) {
            console.error(e);
            appInsights.trackException({ error: e });
            setError(e);
          }
        });
    } else {
      if (values !== undefined) {
        AdminService.createPractice(values, accessToken, cancelToken)
          .then((response) => {
            history.push("/accounts&access/physicians&practices/");
          })
          .catch((e) => {
            if (!axios.isCancel(e)) {
              console.error(e);
              appInsights.trackException({ error: e });
              setError(e);
            }
          });
      }
    }
  };

  return (
    <div className="container">
      <h2 className="heading-main">{name}</h2>
      <div className="chart mt-3">
        <Formik
          initialValues={initialValues}
          validationSchema={yup.object().shape({
            practiceName: yup.mixed().required("Required"),
            npi: yup.string().matches(
              /^\d{10}$/,
              "NPI number is not valid"
            ).nullable(),
            domain: yup.string().url(
              "URL is not valid"
            ).nullable(),
            fax: yup.string().matches(
              /^\+?[0-9]{7,}$/,
              "Fax number is not valid"
            ).nullable(),
          })}
          onSubmit={(values: any, { setSubmitting }) => {
            setError(null)
            if(values.npi != null){
              var npinum: any = values.npi!;
              npinum = npinum.toString();
              values.npi = npinum;
            }
            for (var key in values) {
              if (key === "practice_Location") {
                for (var key_loc in values.practice_Location) {
                  if (values[key][key_loc] === "") {
                    values[key][key_loc] = null
                  }
                }
              }
              else if (key === "practice_Physician") {
                for (var key_phy in values.practice_Location) {
                  if (values[key][key_phy] === "") {
                    values[key][key_phy] = null
                  }
                }
              }
              if (key !== "practice_Location" && key !== "practice_Physician") {
                if (values[key] === "") {
                  values[key] = null
                }
              }
            }
            setValues(values);
            AdminService.practiceLookup(
              values.practiceName,
              accessToken,
              cancelToken
            )
              .then((response) => {
                setResults(response.data);
                console.log(values.practiceName)
                setSubmitting(false);
                if (response.data.length > 0) {
                  const practiceName: string = values.practiceName.toLowerCase();
                  if (response.data.filter(src => src.practiceName.toLowerCase() == practiceName).length > 0){
                    const listOfExisting = response.data.map(src => src.practiceName);
                    setExistingSimilarNames([...listOfExisting]);
                  }
                  else {
                    setShowPracticeLookupModal(true);
                  }
                } else {
                  handleCreatePractice(values);
                }
              })
              .catch((e) => {
                if (!axios.isCancel(e)) {
                  console.error(e);
                  appInsights.trackException({ error: e });
                  setError(e);
                  setSubmitting(false);
                }
              });
          }}
        >
          {({ setFieldValue, values, errors, touched, isSubmitting }) => (
            <FormikForm>
              <Field
                name="practiceName"
                id="practiceName"
                required={true}
                type="text"
                label="Practice Name"
                component={CustomInputFormik}
              />
              {existingSimilarNames.filter(src => src.toLocaleLowerCase() == practiceName?.toLocaleLowerCase()).length > 0 ? (
                <div className="d-flex row align-items-center form-padding">
                  <div className="col-sm-3"></div>
                  <div className="col-sm-9 text-danger">
                    Practice with same name already present.
                  </div>
                </div>
              ) : null}
              {touched.practiceName && setPracticeName(values.practiceName)}
              <Field
                name="groupName"
                required={false}
                type="text"
                label="Group Name"
                component={CustomInputFormik}
              />
              <Field
                name="npi"
                required={false}
                type="number"
                label="Practice NPI"
                component={CustomInputFormik}
              />
              <Field
                name="domain"
                required={false}
                type="text"
                label="Website URL"
                component={CustomInputFormik}
              />
              <Field
                name="practiceType"
                required={true}
                type="text"
                label="Practice Type"
                component={({ field, ...props }: any) => (
                  <Form.Group className="d-flex row align-items-center form-padding">
                    <Form.Label className="col-sm-3">
                      {props.label}
                      {props.required ? (
                        <span className="p-0 asterik"> *</span>
                      ) : (
                        <></>
                      )}
                    </Form.Label>
                    <div className="col-sm-9">
                      <Form.Select
                        {...field}
                        name={field.name}
                        required={props.required}
                      >
                        <option value={""}>Please make a selection...</option>
                        <option value={"Administration"}>Administration</option>
                        <option value={"Full-time Hospital Staff"}>
                          Full-time Hospital Staff
                        </option>
                        <option value={"Medical Teaching"}>
                          Medical Teaching
                        </option>
                        <option value={"Office Based Practice"}>
                          Office Based Practice
                        </option>
                        <option value={"Research"}>Research</option>
                        <option value={"Resident"}>Resident</option>
                      </Form.Select>
                    </div>
                  </Form.Group>
                )}
              />
              <div className="d-flex row align-items-center form-padding">
                <div className="col-sm-3"></div>
                <div className="col-sm-9">
                  <div className="row">
                    <div className="col-md-6">
                      <Field
                        name="practice_Physician"
                        required={true}
                        label="Practice Physician"
                        component={({ field, ...props }: any) => {
                          return (
                            <MultiSelect
                              {...field}
                              {...props}
                              title={props.label}
                              options={physicianOptions}
                              onAddClick={() => {
                                setShowPhysicianModal(true);
                              }}
                              onRemoveClick={(id) => {
                                setPhysicianOptions([]);
                                setPhysician(undefined);
                                setFieldValue("practice_Physician", "");
                              }}
                            />
                          );
                        }}
                      />
                    </div>
                    <div className="col-md-6">
                      <Field
                        name="practice_Location"
                        required={true}
                        label="Practice Location"
                        component={({ field, ...props }: any) => {
                          return (
                            <MultiSelect
                              {...field}
                              {...props}
                              title={props.label}
                              options={locationOptions}
                              onAddClick={() => {
                                setShowLocationModal(true);
                              }}
                              onRemoveClick={(id) => {
                                setLocationOptions([]);
                                setLocation(undefined);
                                setFieldValue("practice_Location", "");
                              }}
                            />
                          );
                        }}
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className="d-flex row align-items-center form-padding">
                <div className="col-sm-3"></div>
                <div className="col-sm-9 ">
                  <MButton
                    isSubmitting={isSubmitting}
                    variant="primary"
                    type="submit"
                    title="Add"
                    width={"fit-content"}
                  />
                </div>
              </div>
              <AdminCreateLocationModal
                show={showLocationModal}
                handleClose={() => {
                  setShowLocationModal(false);
                }}
                handleAdd={(practiceLocation) => {
                  setFieldValue("practice_Location", practiceLocation);
                  setShowLocationModal(false);
                  setLocation(practiceLocation);
                  setLocationOptions([
                    {
                      id: 1,
                      display: practiceLocation.address1,
                    },
                  ]);
                }}
              />
              <AdminSearchPhysicianModal
                show={showPhysicianModal}
                handleClose={() => {
                  setShowPhysicianModal(false);
                }}
                handleAdd={(physician, name) => {
                  setShowPhysicianModal(false);
                  setPhysician(physician);
                  setFieldValue("practice_Physician", physician);
                  setPhysicianOptions([
                    {
                      id: physician.physicianId,
                      display: name,
                    },
                  ]);
                }}
              />
              <AdminPracticeLookupModal
                show={showPracticeLookupModal}
                handleClose={() => setShowPracticeLookupModal(false)}
                handleSubmit={() => handleCreatePractice()}
                results={results}
              />
            </FormikForm>
          )}
        </Formik>
      </div>
      <CustomAlertModal
        show={error !== null}
        handleClose={() => {
          setError(null)
        }}
        header={"Error!"}
        // @ts-ignore
        body={error?.response?.data.message || "There was an error processing your request. Please refresh the page and try again. If problems persist please contact myCHLA support."}
      />
    </div>
  );
};

export default AdminCreatePractice;
