import { FC, useEffect, useState } from "react";
import HeaderName from "../../headerName/HeaderName";
import { Badge, OverlayTrigger, Table, Col } from "react-bootstrap";
import IPatientFamilyEducation from "../../../types/IPatientFamilyEducation";
import MButton from "../../mButton/MButton";
import { useHistory, useLocation } from "react-router-dom";
import CustomToggle from "../../toggle/CustomToggle";
import { useOktaAuth } from "@okta/okta-react";
import PatientFamilyService from "../../../services/PatientFamilyService";
import axios from "axios";
import { getFormattedDateFromDate } from "../../../helpers/DateHelper";
import Loader from "../../common/Loader";
import { Checkbox } from "@mui/material";
import { appInsights } from "../../../appInsights";

const PatientAndFamilyEducationMaterials: FC<{
  name?: string;
}> = ({ name }) => {
  const history = useHistory();
  const tableCellPadding = { padding: 20 };
  const tableHeadPadding = { paddingLeft: 20, paddingRight: 20 };
  const handleClick = () => {
    history.push("/portalupdates/newpatientandfamilyeducationmaterials");
  };
  const handleOnClick = (patientfamily: IPatientFamilyEducation) => {
    history.push("/portalupdates/editpatientandfamilyeducationmaterials", patientfamily);
  };

  const { authState } = useOktaAuth();
  const accessToken = authState?.accessToken?.accessToken;
  const cancelToken = axios.CancelToken.source();
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<Error | null>(null);
  const onToggle = () => {};

  const [patientfamilyData, setPatientfamilyData] = useState<IPatientFamilyEducation[]>();

  useEffect(() => {
    setLoading(true);
    setError(null);
    PatientFamilyService.getPatientFamilyServiceList(accessToken)
      .then((response) => {
        setLoading(false);
        setPatientfamilyData(response.data);
       
      })
      .catch((e: Error) => {
        if (!axios.isCancel(e)) {
          console.error(e);
          appInsights.trackException({ error: e });
         setError(e);
        }
       
        setLoading(false);
      });
    return () => {
      cancelToken.cancel();
    };
  }, []);

  return (
    <div className="referrals">
      <HeaderName name={name} />
      <div className="container">
        <h2 className="heading-main">{name}</h2>
        <div className="d-flex row justify-content-end" style={{ padding: 20 }}>
          <MButton
            variant="primary"
            disabled={false}
            title="Add New"
            width={"fit-content"}
            onClick={handleClick}
          />
        </div>
        <Col
          sm={{ span: 7, offset: 3 }}
          className="d-flex justify-content-center"
        >
          {loading && <Loader />}
        </Col>
        <br />

        <div className="referral">
          <Table id="responsive-table" className="mt-2" responsive>
            <thead>
              <tr>
                <th style={tableHeadPadding}>Title</th>
                <th style={tableHeadPadding}>File Name </th>
                <th style={tableHeadPadding}>Is Active</th>
                <th style={tableHeadPadding}>Publication Date</th>
                <th style={tableHeadPadding}>Modified On</th>
                <th style={tableHeadPadding}>Modified By</th>
              </tr>
            </thead>
            <tbody>
              {patientfamilyData?.map((patientfamily) => {
                return (
                  <tr>
                    <td
                      style={tableCellPadding}
                      data-title="Title"
                      id="table-text-referrals"
                      onClick={() => handleOnClick(patientfamily)}
                    >
                      {patientfamily.title}
                    </td>
                    <td style={tableCellPadding} data-title="File Name">
                      {patientfamily.fileName}
                    </td>
                    <td style={tableCellPadding} data-title="Is Active">
                    <Checkbox disabled 
                        checked={patientfamily.isActive}
                                             />
                    </td>
                    <td style={tableCellPadding} data-title="Publication Date">
                      {getFormattedDateFromDate(patientfamily.publicationDate)}
                    </td>
                    <td style={tableCellPadding} data-title="Modified On">
                      {getFormattedDateFromDate(patientfamily.modifiedOn)}
                    </td>
                    <td style={tableCellPadding} data-title="Modified By">
                      {patientfamily.modifiedBy}
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </Table>
          <div
            className="col d-flex justify-content-center"
            style={{ marginTop: "60px" }}
          ></div>
        </div>
      </div>
    </div>
  );
};

export default PatientAndFamilyEducationMaterials;
