import { FC } from "react";
import { Form } from "react-bootstrap";
import IReferralFormField from "../../types/IReferralFormField";

const EmailInput: FC<{
  fieldInfo: IReferralFormField;
}> = ({ fieldInfo, ...field }) => {
  return (
    <Form.Group className="d-flex row align-items-center form-padding">
      <Form.Label className="col-sm-3 ">
        {fieldInfo.label}
        {fieldInfo.required ? <span className="p-0 asterik"> *</span> : <></>}
      </Form.Label>
      <div className="col-sm-9">
        <Form.Control
          {...field}
          type="email"
          required={fieldInfo.required}
          // defaultValue={handleDefaultValue(value.type, key)}
          // onChange={(e) => handleOnChange(e, key)}
          // disabled={isDisabled}
        />
      </div>
    </Form.Group>
  );
};

export default EmailInput;
