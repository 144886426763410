import { useEffect, useState } from "react";
import ISliderImage from "../types/ISliderImage";

type KEY = "slider_images";

const getSliderImagesFromSessionStorage = (
  key: KEY,
  initialValue: ISliderImage[] | undefined
) => {
  const savedValue = localStorage.getItem(key);
  const savedValueJSON = savedValue && JSON.parse(savedValue);
  if (savedValueJSON) return savedValueJSON;
  if (initialValue instanceof Function) return initialValue();
  return initialValue;
};

const useSliderImages = (
  key: KEY = "slider_images",
  initialValue?: ISliderImage[]
) => {
  const [sliderImages, setSliderImages] = useState<ISliderImage[]>(() => {
    return getSliderImagesFromSessionStorage(key, initialValue);
  });
  useEffect(() => {
    if (sliderImages !== undefined) {
      try {
        localStorage.setItem(key, JSON.stringify(sliderImages));
      } catch (e) {
        let msg =
          "Cannot store the images in local storage as it has exceeded the localStorage limit. Please contact the administrator";
        alert(msg);
        throw e;
      } finally {
        return;
      }
    }
  }, [sliderImages]);
  return [sliderImages, setSliderImages] as const;
};

export default useSliderImages;
