import { FC } from "react";
import { FutureVisit } from "../../../../types/IPatient";
import { Modal } from "react-bootstrap";
import MButton from "../../../mButton/MButton";
import { getFormattedDateFromDateTime, getFormattedDateTimeFromDateTime,getFormattedTimeFromDateTime,getFormattedDateFromDate } from "../../../../helpers/DateHelper";

const VisitsModal: FC<{
  show: boolean;
  handleClose?: () => void;
  lastVisit?: { dtm: string; location: string };
  futureVisits?: FutureVisit[];
}> = ({ show, handleClose, lastVisit, futureVisits = [] }) => {
  return (
    <Modal size="xl" show={show} onHide={handleClose} centered>
      <Modal.Body>
        <div>
          <h4>Last Visit</h4>
          {lastVisit ? (
            <>
              <div>{getFormattedDateFromDate(lastVisit.dtm) +" "+getFormattedTimeFromDateTime(lastVisit.dtm) }</div>
              <p>{lastVisit.location}</p>
            </>
          ) : (
            <p>No record found</p>
          )}
          <h4>Future Visits</h4>
          {futureVisits.length !== 0 ? (
            futureVisits.map((v) => (
              <>
                <div>{getFormattedDateFromDate(v.begDtTm) +" "+getFormattedTimeFromDateTime(v.begDtTm) }</div>

                
                <p>
                  {v.location} - {v.resource}
                </p>
              </>
            ))
          ) : (
            <p>No record found</p>
          )}
        </div>
      </Modal.Body>
      <Modal.Footer>
        <MButton
          onClick={handleClose}
          variant="primary"
          title="Done"
          width={"max-content"}
        />
      </Modal.Footer>
    </Modal>
  );
};

export default VisitsModal;
