import axios, { AxiosResponse } from "axios";
import { appInsights } from "../appInsights";
import EducationService from "../services/EducationService";

const base64toBlob = (data: string) => {
  const bytes = atob(data);
  let length = bytes.length;
  let out = new Uint8Array(length);

  while (length--) {
    out[length] = bytes.charCodeAt(length);
  }

  return new Blob([out], { type: "application/pdf" });
};

function downloadPDF(blobUrl: string, name: string, setLoading: ((e?: any)=>void)) {
  // Create a link element
  const link = document.createElement("a");

  // Set link's href to point to the Blob URL
  link.href = `data:application/pdf;base64,${blobUrl}`;
  link.setAttribute("download", name);
  link.setAttribute("title", name);

  // Append link to the body
  document.body.appendChild(link);
  link.click();

  // Remove link from body
  document.body.removeChild(link);
  setLoading(false);
}

const showPDF = (base64String: string) => {
  const blob = base64toBlob(base64String);
  var pdfurl = window.URL.createObjectURL(blob);
  window.open(pdfurl);
};

const downloadFileFromEducationService = (id: any, value: string, setLoading: ((e?: any)=>void), setError: ((e?: any)=>void), accessToken?: string) => {
  setLoading(true);

  var formData = new FormData();
  formData.append("id", id);
  if (id === undefined) {
    alert("Select");
    setLoading(false);
  } else {
    EducationService.downloadPdfService(formData, accessToken)
      .then(async (response: AxiosResponse<any, any>) => {
        const base64String: string = response.data;
        downloadPDF(base64String, value ?? "no title", setLoading);
      })

      .catch((e: Error) => {
        if (!axios.isCancel(e)) {
          appInsights.trackException({ error: e });
          setLoading(false);
          setError(e);
        }
      });
  }
};


export {
    downloadFileFromEducationService,
};