/* 
 Used in :
 ** Resources.tsx
 ** InfowellSection.tsx
 ** PhysicianResources.tsx

 Usage : 
 ** To show cards in the above pages

 *** Please mark here if you are using this component elsewhere ***
 */

import React, { FC, useEffect, useState } from "react";
import { Card, Button, Form, FormControl } from "react-bootstrap";
import useInfowells from "../../../hooks/useInfowells";
import HomeService from "../../../services/HomeService";
import Loader from "../../common/Loader";
import axios, { AxiosResponse } from "axios";
import { useOktaAuth } from "@okta/okta-react";
import { getFormattedDateWords } from "../../../helpers/DateHelper";
import IInfoWell from "../../../types/IInfoWell";
import IDropDownInfoWell from "../../../types/IDropDownInfoWell";
import { TextField } from "@mui/material";
import { useHistory } from "react-router-dom";
import EducationService from "../../../services/EducationService";
import GoButton from "../../GoButton/GoButton";
import styles from "./Jumbotron.module.css";
import PhysicianResourceService from "../../../services/PhysicianResourceService";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import Favorite from "@material-ui/icons/Favorite";
import FavoriteBorder from "@material-ui/icons/FavoriteBorder";
import { appInsights } from "../../../appInsights";

import { downloadFileFromEducationService } from "../../../helpers/FileHelper";

const Infowell: FC<{
  infowell: IInfoWell;
  home?: Boolean;
  likedInfowells?: IInfoWell[];
}> = ({ infowell, home = false, likedInfowells = [] }) => {
  const { authState } = useOktaAuth();
  const accessToken = authState?.accessToken?.accessToken;
  const cancelToken = axios.CancelToken.source();
  const [dropDownWells, setDropDownWells] = useState<IDropDownInfoWell[]>();
  const [dropDownName, setDropDownName] = useState("");
  const [loading, setLoading] = useState(false);

  const [error, setError] = useState<Error | null>(null);
  const [formData, setFormData] = useState<FormData>();
  const [value, setValue] = useState("");
  const [link, setlink] = useState("");

  const handleOnChange = (id: any, e?: any) => {
    let data = new FormData();
    let title =
      e.nativeEvent.target[e.nativeEvent.target.selectedIndex].text.split(
        ": "
      )[1];

    data.append("id", id);
    setFormData(data);

    setValue(title);
    if (infowell.dropDownName === "AmbulatoryCareCenterSchedules") {
      setlink(dropDownWells?.find((a) => a.id === parseInt(id))?.url);
    }
  };
  const history = useHistory();
  const handleClick = () => {
    history.push("/physicianresources/medicalstaff");
  };
  const handleClickClinicalCare = (dropDownWells: IDropDownInfoWell[]) => {
    history.push("/carenetwork/clinicalcarepathwaysscreen", dropDownWells);
  };

  useEffect(() => {
    if (infowell.dropDownName !== null) {
      setLoading(true);
      if (infowell.dropDownName != "Clinical Care Pathways") {
        HomeService.getDropDownWells(infowell.dropDownName, accessToken)
          .then((response) => {
            var data = response.data;
            if (infowell.dropDownName === "AmbulatoryCareCenterSchedules") {
              data = response.data.sort((a, b) =>
                a.title > b.title ? 1 : b.title > a.title ? -1 : 0
              );
            }
            setDropDownWells(response.data);
            setLoading(false);
          })
          .catch((e) => {
            if (!axios.isCancel(e)) {
              appInsights.trackException({ error: e });
              setError(e);
            }
            setLoading(false);
          });
      }
    }

    return () => {
      cancelToken.cancel();
    };
  }, [dropDownName]);

  const likeButton = (id: any) => {
    setLoading(true);
    let data = {
      InfoWellId: id,
      Favorite: !isLiked(infowell.id),
    };
    HomeService.likeButton(data, accessToken)
      .then((response) => {
        setLoading(false);
        if (home) {
          window.location.reload();
        }
      })
      .catch((e) => {
        if (!axios.isCancel(e)) {
          appInsights.trackException({ error: e });
          setError(e);
        }

        setLoading(false);
      });
  };
  const isLiked = (id: any): boolean => {
    if (home) {
      return true;
    }
    var flag: boolean = false;
    likedInfowells.map((item) => {
      if (item.id == id) {
        flag = true;
      }
    });

    return flag;
  };

  return (
    <div className="col-sm-4 col-lg-3 p-2 d-flex justify-content-center">
      <Card className="social p-4" style={{ width: "25rem", display: "flex" }}>
        {infowell.pageId === 1 ? null : (
          <FormControlLabel
            control={
              <Checkbox
                icon={<FavoriteBorder color={"inherit"} />}
                checkedIcon={<Favorite color={"inherit"} />}
                defaultChecked={isLiked(infowell.id)}
                name="checked"
              />
            }
            onChange={() => likeButton(infowell.id)}
            label=""
          />
        )}
        <Card.Title className={styles.titleCard}>
          {infowell.title.split("<i").length > 1 ? (
            <>
              {infowell.title.split("<i")[0]}
              <i
                className="glyphicon glyphicon-lock de-em"
                title="Requires Login"
              ></i>
            </>
          ) : (
            infowell.title
          )}
        </Card.Title>
        {infowell.paragraph !== "" ? (
          <Card.Text
            className="mt-2"
            style={{ display: "flex", flexDirection: "column" }}
          >
            {infowell.paragraph}
          </Card.Text>
        ) : null}
        {loading &&
          infowell.title ===
            'Provider Relations Reference Guide <i class="glyphicon glyphicon-lock de-em" title="Requires Login"></i>' && (null
            // <Loader />
          )}
        {infowell.dropDownName !== null ? (
          infowell.dropDownName !== "ClinicalCarePathways" ? (
            <Form.Select
              as="select"
              onChange={(e) => handleOnChange(e.target.value, e)}
              aria-label="Default select example"
            >
              {formData === undefined ? <option>{"Select PDF"}</option> : null}
              {dropDownWells
                ?.map((option, index: number) => (option.title.trim() !== "")?(
                  <>
                    <option key={index} value={option.id} title={option.title}>
                      {infowell.dropDownName !== "AmbulatoryCareCenterSchedules"
                        ? getFormattedDateWords(option.publicationDate) + " : "
                        : null}
                      {option.title}
                    </option>
                  </>
                ):<></>)}
            </Form.Select>
          ) : null
        ) : null}
        <br />
        {infowell.dropDownName !== null &&
        infowell.dropDownName !== "AmbulatoryCareCenterSchedules" &&
        infowell.dropDownName !== "ClinicalCarePathways" ? (
          <GoButton
            handleSubmit={() => {
              downloadFileFromEducationService(
                formData?.get("id"),
                value,
                setLoading,
                setError,
                accessToken
              );
            }}
            loading = {loading}
          />
        ) : infowell.title ===
          'Provider Relations Reference Guide <i class="glyphicon glyphicon-lock de-em" title="Requires Login"></i>' ? (
          <GoButton
            handleSubmit={() => {
              downloadFileFromEducationService(
                1,
                "Provider Relations Reference Guide",
                setLoading,
                setError,
                accessToken
              );
            }}
            loading = {loading}
          />
        ) : infowell.title === "Clinical Care Pathways" ||
          dropDownWells == undefined ||
          infowell.dropDownName === "AmbulatoryCareCenterSchedules" ? (
          <GoButton
            handleSubmit={() => {
              
              if (
                infowell.title ===
                'Medical Staff Services <i class="glyphicon glyphicon-lock de-em" title="Requires Login"></i>'
              ) {
                handleClick();
              } else if (infowell.title === "Clinical Care Pathways") {
                if (dropDownWells != undefined) {
                  handleClickClinicalCare(dropDownWells);
                } else {
                  history.push(
                    "/carenetwork/clinicalcarepathwaysscreen",
                    infowell.dropDownName
                  );
                }
              } else if (
                infowell.title ===
                'Medical Staff Services <i class="glyphicon glyphicon-lock de-em" title="Requires Login"></i>'
              ) {
                handleClick();
              } else if (
                infowell.dropDownName === "AmbulatoryCareCenterSchedules"
              ) {
                if (link.length != 0) {
                  window.open(link);
                } else {
                  alert("Select a PDF");
                }
              } else {
                if (infowell.htmlContent.includes("Education")) {
                  history.push("/education");
                } else {
                  window.open(infowell.htmlContent);
                }
              }
            }}
          />
        ) : null}
      </Card>
    </div>
  );
};

export default Infowell;
